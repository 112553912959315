<template>
  <div>
    <!-- <div style="margin-bottom: 14px;
        background-color:rgb(26 118 211 / 30%);
        padding: 14px;">

      <h2 style="color:rgb(1 59 131); font-weight: 400; letter-spacing: 0.2px; margin-right: 6px; line-height: 20px;">
        <v-icon style="margin-right: 5px;">mdi-clock</v-icon>
        Class Duration
      </h2>
      <v-chip primary>Change Duration not linked</v-chip>
    </div> -->
    <!-- DURATION CONFIRM/CHANGE SECTION -->
    <v-row justify="" class="mb-8" 
      style="border: 1px solid rgb(207 205 205 / 84%);
      border-radius: 4p;
      padding: 0px;
      margin-left: 2px;
      margin-right: 2px;
      margin-top: -15px;
      align-items: center;">
      <v-col cols="4">
        <h3 style="color: rgb(52, 52, 52);font-weight: 400; 
          letter-spacing: 0.2px; margin-right: 6px; line-height: 20px;
          font-size: 16px">
          <!-- <v-icon style="margin-right: 5px;">mdi-clock</v-icon> -->
          {{ $t('detail.classDuration.label') }}  
      </h3>
      </v-col>

      <v-col cols="6" style="border: 0px solid red;">
        <div v-show="eventData.overrideDuration && eventData.overrideDuration !== ''" 
          style="margin-left: 8px;">
          <v-chip ma="0" color="#faaf15"
            :style="{ cursor: 'default', pointerEvents: 'none' }"
            flat 
            text-color="white" id="overrideDurationChip">
              {{ eventData.overrideDuration }} hrs
          </v-chip>
        </div>

        <!-- <div v-show="eventData.overrideDuration !== undefined && eventData.overrideDuration !== ''" style="margin-left: 8px;">
          <v-chip ma="0" color="#faaf15"
            :style="{ cursor: 'default', pointerEvents: 'none' }"
            flat 
            text-color="white" id="overrideDurationChip">
            ev over
            {{ eventData.overrideDuration }} hrs
          </v-chip>
        </div> -->

        <div v-show="eventData.overrideDuration === null || eventData.overrideDuration === undefined || eventData.overrideDuration === ''" style="margin-left: 8px;">
          <v-chip
            style="background:#edf5ff;cursor: default;pointer-events: none;"
            flat>
            <span style="color: #2f2f2f">
              {{ eventData.duration }} hrs
            </span>
          </v-chip>
        </div>
          <!-- <p>19h</p> -->
          <!-- <v-checkbox
            v-model="defaultDurationCheckbox"
            :label="`Confirm defualt duration for this class to continue to submit`"
            @click="confirmDefault()" 
          ></v-checkbox> -->
          <!-- <v-col cols="3" class="text-right"> -->
      <!-- </v-col> -->
      </v-col>
    
      <v-col cols="2" class="text-right">
        <v-dialog
          v-model="showOverrideDurationModal"
          persistent
          max-width="600px"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- {{ eventData }} -->
            <v-btn v-if="eventData.isLocked === false" 
              :disabled ="eventData.isPostponed"
              text small v-bind="attrs"
              v-on="on" id="changeDurBtn">
              {{ $t('detail.classDuration.changeDurationLabel') }}  
            </v-btn>
            <!-- <v-chip
              color="rgb(227 228 229)"
              dark
              v-bind="attrs"
              v-on="on"
            >
            <div style="color: grey;">
              Change Duration
            </div>
            </v-chip> -->
          </template>
        <v-card>
        <v-card-title style="border-bottom: 2px solid rgb(226, 226, 226);
          color: rgb(233 229 229);
          margin-bottom: 0px;
          background-color: rgb(18 48 86);">
            <span class="text-h5">
              {{ $t('detail.classDuration.durationChangeForm.popupTitleBar') }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row style="margin-top: 10px;">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-alert
                    dense
                    type="info"
                  >
                    Please ensure the hours, minutes, and duration note are completed before confirming a duration change. 
                  </v-alert>
                </v-col>
              </v-row>
              <!-- <p>
                Please complete both the hours and minutes fields
              </p> -->
              <v-row style="margin-top: 25px;margin-bottom: 10px">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-select v-model="selectedHours" :items="hourOptions" label="Hours*" 
                    @change="selectCustomDuration('hours')" prepend-icon=""></v-select>    
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                <v-select v-model="selectedMinutes" :items="minuteOptions" label="Minutes*" 
                  @change="selectCustomDuration('minutes')"></v-select>
                </v-col>
              </v-row>
              <div style="border: 1px solid rgb(221, 217, 217);
                padding: 6px;
                border-radius: 4px;">
                <v-col cols="12" md="12">
                  <div style="margin-bottom: 26px;font-size:16px;font-weight:600">
                    <p>
                      Please provide an explanation" / "We started/ended earlier/later because..."
                    </p>
                  </div>
                  <v-textarea
                    class="mx-1"
                    label="Note"
                    rows="1"
                    prepend-icon="mdi-comment"
                    :error="!isNoteValid"
                    v-model="durationNote"
                    @input="noteUpdated"
                    @change="noteValidation"
                    :error-messages="errorMessage"
                  ></v-textarea>
                </v-col>
              </div>
            </v-container>
            <!-- <small>*indicates required field</small> -->
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outline
              color="blue darken-1"
              text
              @click="cancelDurationOverrideForm"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="updateClassDuration()"
              :disabled="disableDurationConfirm"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>


<script>
// import { defineComponent } from '@vue/composition-api'

export default ({
  name: "duration",
  props: { eventData: Object },
  data() {
    return { 
      errorMessage: '',
      isNoteValid: true,
      showOverrideDurationModal: false,
      selectedHours: null,
      selectedMinutes: null,
      durationNote: '',
      disableDurationConfirm: true,
      overrideDuration: null,
      hourOptions: [0, 1, 2, 3],
      minuteOptions: [0, 15, 30, 45],    
    }
  },

  computed: {},

  watch: {
    selectedHours: {
      handler(newValue) {
        if(this.durationNote.length > 10) {
          this.disableDurationConfirm = false
        }
        this.selectCustomDuration('hours', newValue)
        
      },
      immediate: true
    }
  },
 
  methods: {
    isNoteRequired() {
      return (this.selectedHours || this.selectedMinutes) && !this.durationNote.trim();
      // return (this.selectedHours !== null || this.selectedMinutes !== null) && !(this.selectedHours === 0 && this.selectedMinutes === 0) && !this.durationNote.trim();
    },

    cancelDurationOverrideForm() {
      // alert('Cancel duration modal ..')
      // Reset all values of the form
      this.showOverrideDurationModal = false
      this.selectedHours = null
      this.selectedMinutes = null 
      this.durationNote = ''
      this.disableDurationConfirm = true
      this.overrideDuration = null
    },

    async convertToPercentage(durationInMinutes) {
      // Convert duration to percentage of the hour
     const percentage = await (durationInMinutes / 60) * 100;
      return percentage;
    },

    async updateClassDuration() {
      // If teacher does not select an hour value 
      if(this.selectedHours === null || this.selecetedHours === '') {
        this.selectedHours = '0'
      }
      console.log('h: ', this.selectedHours)
      console.log('m: ', this.selectedMinutes)
      console.log('n: ', this.durationNote)
      const minsToPercent = await this.convertToPercentage(this.selectedMinutes)

      const newDurationFormData = {
        hours: this.selectedHours,
        mins: minsToPercent,
        overrideNote: this.durationNote
      }
      // alert('check new dur in console.,..')
      this.$emit('confirmDurationChange', newDurationFormData)
      this.eventData.overrideDuration = this.selectedHours + '.' + minsToPercent

      // this.overrideDuration = this.selectedHours + '.' + minsToPercent
      this.showOverrideDurationModal = false
      // this.overrideDuration = this.selectedHours + '.' + this.selectedMinutes
      // console.log(this.overrideDuration)
      // this.showOverrideDurationDialog = false
      // if(this.eventData !== dur && this.eventData.duration !== 'null.null' ) { this.eventData.duration = dur }
      // else{ this.eventData.duration = this.eventData.duration }

      // alert('duration changed')
    },

    noteValidation() {
      if(this.selectedHours > 0 || this.selectedMinutes > 0) {
        if (this.durationNote.length > 10) {
        // Display an alert or take any other action to notify the user
          this.disableDurationConfirm = false
          this.isNoteValid = true
          this.errorMessage = ''
        } else {
          this.isNoteValid = false
          this.errorMessage = `Note must be at least 10 characters long`
          if(this.errorMessage = `Note must be at least 10 characters long`) {
            this.disableDurationConfirm = true
          }
        }
      } else {
        // alert('Please enter at least 10 characters.');
        // alert('Please select a duration before completing your note');
         this.errorMessage = 'Please select a new duration for the class before completing your note'
        // Do something when the validation passes, if needed
        // For example, you might want to enable a button or perform another action
        // this.checkHoursAndMinutesValue()
        // this.enableDurationConfirm = false
      }
    },

    noteUpdated() {
      console.log('updated note:  ', this.durationNote)

       // Add your validation criteria here
       if(this.durationNote.length < 10) {
        this.errorMessage = `Note must be at least 10 characters long`
       } else {
        this.errorMessage = ``
       }

       if( this.durationNote.length > 10 && this.selectedHours < 0 || this.selectedMinutes < 0) {
          this.errorMessage = `Please choose a new duration`
          this.disableDurationConfirm = false
        }
       }
    },

    selectCustomDuration(selectDurationType) {
      // console.log('hrs ', typeof this.selectedHours)
      // alert('time changed...')

      // Hours OR Minutes must have a value
      if(this.selectedMinutes === 0 && this.selectedHours === 0) {
        // alert('Cant be 0 0')
        if(this.selectedMinutes > 0 || this.selectedHours > 0) {

          this.overrideDurationSelected = true

          // Check if note is empty
          if(this.durationNote.length > 9) {
            alert('note is good too')
            // Enable confirm button - duration and note complete
            this.disableDurationConfirm = false
          } else {
            // make show note alert true here 

          }
        }
      }
      // This function will be called when either v-select value changes
      //  console.log(selectType)
      this.isNoteRequired()

      this.attendanceSubmitDisabled = true

     if (selectDurationType === 'hours') {
        console.log('Selected hours:', this.selectedHours);
        // Handle hours logic here
      } else {
        console.log('Selected minutes:', this.selectedMinutes);
        // Handle minutes logic here
      }
    // console.log('hours ', this.selectedHours)
    },


  // setup() {},

})
</script>

<style scoped>
#overrideDurationChip span {
  color: white !important;
}
#changeDurBtn:hover {
  background: rgb(159 232 253 / 51%);
  transition: background 2.0s ease; /* Transition for background color */
}
.staticChip {
  cursor: default !important;
  pointer-events: none;
}
</style>