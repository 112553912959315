// src/i18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './locales/en.js'
import fr from './locales/fr.js'

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en', // default locale
  fallbackLocale: 'en',
  messages: {
    en,
    fr
  }
});

export default i18n;