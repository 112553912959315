// Payroll API winter 2024

import axios from 'axios';
import { getLocalStorageValue, getSessionStorageValue } from '../utils/localStorageHelper';
import moment from 'moment';

const API_ENDPOINT = 'https://your-api-endpoint.com/attendance-check';
const url = process.env.VUE_APP_API_URL 

export async function checkPayPeriods(lang) {
  const language =   getLocalStorageValue('fmUserLanguage')
  const token    =   getSessionStorageValue('token')

  // Payload
  const payload = {
    language: getLocalStorageValue('fmUserLanguage'),
    token: getSessionStorageValue('token')
  }

  console.log('helper token ', payload)

  // API request
  const response = await axios.post(url +'/payroll', payload)

  console.log(response.data)
  // alert('period back?')
  return response.data

  // try {
  //   const response = await axios.get(API_ENDPOINT, {
  //     params: { teacherId }
  //   });

  //   const { payPeriod, message } = response.data;

  //   if (payPeriod) {
  //     return { payPeriod, message: null };
  //   } else if (message) {
  //     return { payPeriod: null, message };
  //   } else {
  //     throw new Error('Invalid response from server');
  //   }
  // } catch (error) {
  //   console.error('Error checking attendance:', error);
  //   return { payPeriod: null, message: 'An error occurred while checking attendance.' };
  // }
}

// export async function getClassesInReturnedPayPeriod(payPeriod) { 
//   // Assuming you have your events in sessionStorage
//   const events = JSON.parse(sessionStorage.getItem('events2') || '[]');
//   console.log('Events in helper ', events.data[0].isoStart )

//   const start = moment(payPeriod.start);
//   const end = moment(payPeriod.end);

//   return events.data.filter(event => {
//     const eventStart = moment(event.isoStart);
//     const eventEnd = moment(event.isoEnd);

//     return (eventStart.isSameOrAfter(start) && eventStart.isSameOrBefore(end)) ||
//            (eventEnd.isSameOrAfter(start) && eventEnd.isSameOrBefore(end)) ||
//            (eventStart.isBefore(start) && eventEnd.isAfter(end));
//   });
// }


// export async function getClassesInReturnedPayPeriod(payPeriod) { 
//   const events = JSON.parse(sessionStorage.getItem('events2') || '[]');

//   console.log('Pay periodz:', payPeriod.payPeriod.end);
//   console.log('Sample event startz:', events.data[0].isoStart);

//   // Ensure start and end are moment objects in UTC
//   const start = moment.utc(payPeriod.payPeriod.start);
//   const end = moment.utc(payPeriod.payPeriod.end);

//   console.log('Pay period start (UTC):', start.format());
//   console.log('Pay period end (UTC):', end.format());

//   if (!start.isValid() || !end.isValid()) {
//     console.error('Invalid pay period dates');
//     return [];
//   }

//   return events.data.filter(event => {
//     // Parse event dates, preserving their original timezone
//     const eventStart = moment(event.isoStart);
//     const eventEnd = moment(event.isoEnd);

//     if (!eventStart.isValid() || !eventEnd.isValid()) {
//       console.error('Invalid event dates:', event);
//       return false;
//     }

//     // Convert event dates to UTC for comparison
//     const eventStartUTC = eventStart.utc();
//     const eventEndUTC = eventEnd.utc();

//     // Check if the event overlaps with the given range
//     const isInRange = (eventStartUTC.isSameOrAfter(start) && eventStartUTC.isSameOrBefore(end)) ||
//                       (eventEndUTC.isSameOrAfter(start) && eventEndUTC.isSameOrBefore(end)) ||
//                       (eventStartUTC.isBefore(start) && eventEndUTC.isAfter(end));

//     console.log(`Event ${event.id || 'unknown'}: ${isInRange ? 'In range' : 'Not in range'}`);
//     console.log(`  Event start (UTC): ${eventStartUTC.format()}`);
//     console.log(`  Event end (UTC): ${eventEndUTC.format()}`);

//     return isInRange;
//   });
// }


export async function getClassesInReturnedPayPeriod(payPeriod) { 
  try {
    const events = JSON.parse(sessionStorage.getItem('events2') || '[]');

    // console.log('Full payPeriod object:', payPeriod);
    // console.log('Pay period end:', payPeriod.payPeriod?.end);
    // console.log('Events data type:', typeof events.data);
    // console.log('Events data length:', events.data?.length);

    if (events.data && events.data.length > 0) {
      console.log('Sample event start:', events.data[0].isoStart);
    } else {
      console.log('No events data available');
    }

    // Check if payPeriod.payPeriod exists and has start and end properties
    if (!payPeriod.payPeriod || !payPeriod.payPeriod.start || !payPeriod.payPeriod.end) {
      console.error('Invalid payPeriod structure:', payPeriod);
      return [];
    }

    // Ensure start and end are moment objects in UTC
    const start = moment.utc(payPeriod.payPeriod.start);
    const end = moment.utc(payPeriod.payPeriod.end);

    console.log('Pay period start (UTC):', start.isValid() ? start.format() : 'Invalid date');
    console.log('Pay period end (UTC):', end.isValid() ? end.format() : 'Invalid date');

    if (!start.isValid() || !end.isValid()) {
      console.error('Invalid pay period dates');
      return [];
    }

    if (!Array.isArray(events.data)) {
      console.error('events.data is not an array:', events.data);
      return [];
    }

    const filteredEvents = events.data.filter(event => {
      if (!event.isoStart || !event.isoEnd) {
        console.error('Event missing start or end date:', event);
        return false;
      }

      const eventStart = moment(event.isoStart);
      const eventEnd = moment(event.isoEnd);

      if (!eventStart.isValid() || !eventEnd.isValid()) {
        console.error('Invalid event dates:', event);
        return false;
      }

      // Convert event dates to UTC for comparison
      const eventStartUTC = eventStart.utc();
      const eventEndUTC = eventEnd.utc();

      // Check if the event overlaps with the given range
      const isInRange = (eventStartUTC.isSameOrAfter(start) && eventStartUTC.isSameOrBefore(end)) ||
                        (eventEndUTC.isSameOrAfter(start) && eventEndUTC.isSameOrBefore(end)) ||
                        (eventStartUTC.isBefore(start) && eventEndUTC.isAfter(end));

      // console.log(`Event ${event.id || 'unknown'}: ${isInRange ? 'In range' : 'Not in range'}`);
      // console.log(`Event start (UTC): ${eventStartUTC.format()}`);
      // console.log(`Event end (UTC): ${eventEndUTC.format()}`);

      return isInRange;
    });

    console.log('Filtered events count:', filteredEvents.length);
    return filteredEvents;

  } catch (error) {
    console.error('An error occurred in getClassesInReturnedPayPeriod:', error);
    return [];
  }
}

export function separateClassesIntoWeeks(classes, payPeriodStart) {
  const weekOneClasses = [];
  const weekTwoClasses = [];

  // Convert pay period start to a moment object
  const startOfPayPeriod = moment(payPeriodStart).startOf('day');
  
  // Calculate the start of the second week
  const startOfSecondWeek = startOfPayPeriod.clone().add(1, 'week');

  classes.forEach(classItem => {
    const classStart = moment(classItem.isoStart);

    if (classStart.isBefore(startOfSecondWeek)) {
      weekOneClasses.push(classItem);
    } else {
      weekTwoClasses.push(classItem);
    }
  });

  console.log('Week One Classes Count:', weekOneClasses.length);
  console.log('Week Two Classes Count:', weekTwoClasses.length);

  return { weekOneClasses, weekTwoClasses };
}

// export function createSubPeriodsOfOneWeekSpan(payPeriod) {} 