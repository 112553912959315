<template>
  <v-container style="margin: 20px 0px; padding: 0px;">
    <v-card>
      <v-card-title style="background: #3b75c8; color: aliceblue; font-size: 22px;">
        Participants
      </v-card-title>
      <v-data-table
        :headers="studentHeaders"
        :items="eventData.registrations"
        class="elevation-1"
        :hide-default-footer="true"
      >
        <template v-slot:item.name="{ item }" >
          <div class="stacked-info">
            <div class="info-item">{{ item.firstName }} {{ item.lastName }}</div>
            <div class="info-item">{{ item.level }}</div>
            <div class="info-item">{{ item.email }}</div>
            <div class="info-item">{{ item.phone }}</div>
          </div>
        </template>

        <!-- <template v-slot:item.absence="{ item }">
          {{ item.tallies.absence }}
        </template>
        <template v-slot:item.submitted="{ item }">
          {{ item.tallies.submitted }}
        </template> -->
        <template v-slot:item.absence="{ item }">
          <span style="margin-left: 40px;cursor: default;font-size:15px">
            {{ item.tallies.absence }}
          </span>

        </template>
        <template v-slot:item.contract="{ item }">
          <span style="margin-left: 66px;cursor: default;font-size:15px;">
            {{ item.tallies.hoursProgress }} / {{ item.tallies.contract }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'ParticipantsTable',
  props: {
    eventData: {
      type: Object,
      required: true
    },
    studentHeaders: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
/* Add any scoped styles here */
.stacked-info {
  display: flex;
  flex-direction: column;
  gap: 4px; /* Adjust this value to increase or decrease the gap between items */
  margin:10px 0px 10px 0px;
}

.info-item {
  font-size: 14px; /* Adjust as needed */
  line-height: 1.4; /* Helps with readability */
}

/* Optional: Style specific items differently */
.info-item:first-child {
  font-weight: bold; /* Makes the name stand out */
}

.info-item:nth-child(2) {
  font-style: italic; /* Styles the level */
}
</style>
