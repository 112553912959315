<template>
  <v-dialog :value="active" width="500" persistent>
    <v-card>
      <v-card-title primary-title>
        {{ title }}
      </v-card-title>
      <v-card-text class="font-weight-bold">
        <b>ATTENTION:</b> {{ text }}
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="type === 'info'" @click="$emit('close', type)" color="success">
          Close
        </v-btn>
        <v-btn v-if="type === 'alert'" @click="$emit('close', type)" color="error">
          Close
        </v-btn>
        <!-- Must pass string 'cancel' as param to $emit to differentiate from 'confirm' in attendance.vue -->
        <v-btn v-if="type === 'confirm'" @click="$emit('close', 'cancel')" color="error">
          No, return to options
        </v-btn>
        <v-btn
          v-if="type === 'confirm'"
          @click="$emit('close', type)"
          color="primary"
        >
          Yes, proceed
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "modal",

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },

  data() {
    return {};
  },
  methods: {}
};
</script>
