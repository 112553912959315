
import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { userModule } from '@/store/modules/user';
import FeedbackFab from './components/FeedbackFab.vue'
import ForceLogout from './utils/forceLogout';
import Vuetify, {
    VChip,
} from 'vuetify/lib'
// import { Route } from 'vue-router';
import axios from 'axios'

@Component({
  components: {
    FeedbackFab
  },
  mixins: [ForceLogout],
})
export default class App extends Vue {
  public devmode = false;
  public signTest = null;
  // private snackbar = true
  private dialog = false;
  private mini = false;
  private dialogText = '';
  private dialogTitle = '';
  private isRootComponent = true;
  public drawer = true;
  private fixed = false;
  private language = '';

  public isLoading = true;
  
  private adminItems: AppMenu[] = [
    // {
    //   icon: 'mdi-code-braces-box',
    //   title: 'API',
    //   vertical: 'API',
    //   link: 'developer'
    // },
    // {
    //   icon: 'mdi-code-braces-box',
    //   title: 'Admin',
    //   vertical: 'Admin',
    //   link: 'admin'
    // }
  ];

  private items: AppMenu[] = [
    // {
    //   icon: ' mdi-view-dashboard',
    //   title: 'Dashboard',
    //   vertical: 'Hours',
    //   link: 'dashboard'
    // },

    {
      icon: 'mdi-school',
      title: this.$t('sideNav.classes'),
      vertical: 'Classes',
      link: 'classes'
    },  
    {
      icon: 'mdi-calendar',
      title: this.$t('sideNav.schedule'),
      vertical: 'Calendar',
      link: 'calendar'
    },
    {
      icon: 'mdi-currency-usd',
      title: this.$t('sideNav.payroll'),
      vertical: 'Dashboard',
      link: 'pay'
    },
  
    // {
    //   icon: ' mdi-calendar-clock',
    //   title: 'Hours & Pay',
    //   vertical: 'Hours',
    //   link: 'hours'
    // },
    // {
    //   icon: ' mdi-file-multiple',
    //   title: 'Resources',
    //   vertical: 'Resources',
    //   link: 'resources'
    // },
   
    {
      icon: 'mdi-account',
      title: this.$t('sideNav.profile'),
      vertical: 'Profile',
      link: 'user'
    },

    // ADD EXTRA MENU LINK FOR ADMIN ROUTE
    // SNACKBARS ON LOGIN 
    // REMOVE BIN ICON ON TEACHER LIST
    // CALENDAR RANGE
    // WIDGETS - CLASSES TODAY - NEXT CLASS - 
    // CLASS ACCOUNT - EXPANDABLE FORM ON ATTENDANCE OR BUTTON ON EVENT IN CALENDAR
    // DOCS BUTTON ON CALENDAR EVENT CARD
    // LINK TO STUDENT PROFILE PAGE FOR TEACHER TO FILL IN PROGRESS REPORT?
    // ADD NEW EVENT ON CALENDAR FOR AVAILABILITY, AND CHANGING DATE OF CLASS (CREATE NEW CLASS EVENT OR UPDATE
    // CURRENT CLASS RECORD WITH DATE AND NOTE) 

    // {
    //   icon: 'mdi-information-outline',
    //   title: 'About This App',
    //   vertical: 'About',
    //   link: 'about'
    // },

    // Admin Menu removed for non-admin users
    // {
    //   icon: 'mdi-code-braces-box',
    //   title: 'Developer API',
    //   vertical: 'API',
    //   link: 'api-test'
    // },
    // {
    //   icon: 'mdi-account-cog',
    //   title: 'Admin',
    //   vertical: 'Admin',
    //   link: 'admin'
    // }
  ];

  // Why this button doesn't work??
  // private navBottomMenu = [
  //   {
  //     icon: 'mdi-logout',
  //     title: 'Logout',
  //     link: 'login'
  //   }

  // ];

  // private userMenus: AppMenu[]  = [
  public userMenus = [
    {
      icon: 'mdi-account',
      title: 'Profile',
      link: 'user'
    },
    {
      icon: 'mdi-logout',
      title: 'Logout',
      link: 'Login'
    }
  ];
  
  private miniVariant = false;
  private right = true;
  private rightDrawer = false;
  private menuItem = '';
  private localeKey = 0;

  // public idleTimeout = 15 * 60 * 1000; // 15 minutes in milliseconds
  public idleTimeout = 10000; // 15 minutes in milliseconds

  public idleTimer = null;


  get signedIn() {
    const logUser = userModule.isSignedIn
    console.log(logUser)
    // alert('[App.vue] get signedIn being called before login page load...')
    return userModule.isSignedIn;
  }


  get selectedLanguage() {
    console.log('local ', this.$i18n.locale)
    return this.$i18n.locale;
  }

  set selectedLanguage(lang: string) {
    // console.log('l ', lang)
    localStorage.setItem('lang', lang);
    this.$i18n.locale = lang;
    this.localeKey++; // Increment the key to force re-render
    // console.log('local set ', this.$i18n.locale)
  }


    
    // get selectedLanguage() {
    //   const lang = localStorage.getItem('lang');
    //   return lang ? lang : 'en'; // Default to 'en' if no lang in localStorage
    // }

  get user() {
    // const loggedInUser = JSON.parse(userModule.userFromStorage);localStorage
    // const loggedInUser = { firstname: 'Parker', lastname: 'Brown' }
    // const userFromSession = sessionStorage.getItem('userProfile') 
    const userFromSession = sessionStorage.getItem('userFullName') 
    console.log('name ', userFromSession)

    const userJSON = JSON.stringify(userFromSession)
    // const userFullName = userJSON.data.teacherFullName
// 
    // If user is defined, return user's name, otherwise return default name
    return userFromSession !== 'undefined undefined' ? userFromSession : ''
  }

  // for UI display purposes only
  // NOTE: Role must be checked on Node server, 
  // via Mongo record check, before giving access to admin privileges
  get isAdmin() { 
    return sessionStorage.getItem('isAdmin')
    // return false
  }

  async beforeMount() {
    console.log('Loading ...', this.isLoading)
    // await this.fetchUserData()
    this.isLoading = false
  }

  async created() {
 
    if (!this.signedIn) {
      const isForgot = await localStorage.getItem('token')
      
      await sessionStorage.clear()
      // await localStorage.clear()
      // Redirect to the login page
      // this.$router.push('/login');
      // window.location.replace("http://localhost:3101/login"); 
    }
    
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, _from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress;
        // parse meta tags
        this.$Progress.parseMeta(meta);
      } 
      //  start the progress bar
      this.$Progress.start();
      // continue page
      next();
    });
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((to, from) => {
      if (to.name !== 'ErrorPage') {
        this.menuItem = to.name;
      }
      //  finish the progress bar
      this.$Progress.finish();
    });
  }


  changeLanguage(lang) {
      this.$i18n.locale = lang
      localStorage.setItem('langauge', lang)
    }

  get activeMenuItem() {
    return this.menuItem;
  }

  async fetchUserData() {
    alert('calling http://localhost:3001/api/user from APP')
    const token = localStorage.getItem('token');
    try {
      const payload = {
        "teacherToken": token,
      }
      const response = await axios.post('/api/user', payload)
      // console.log('get user response APP.vue: ', response)
      // alert('2')
      // alert('is user in con??')
      // Check that the user is returned properly
      localStorage.setItem('userProfile', JSON.stringify(response))
    } catch(error) {
      console.log('Error from Get User endpoint: ', error)
    }
  }

  handleNavigtion(item: TODO) {
    this.menuItem = item.title;
    this.$router.push({
      name: item.link
    });
  }

  async updateLanguage() {
    localStorage.setItem('lang', this.selectedLanguage);
    // this.$i18n.locale = this.selectedLanguage;
  }
  
  // logout() {
  //   userModule.logout();
  //   // this.$router.push('/login');
  //   window.location.replace('/login');
  // }
  async handleUserActions(item: TODO) {
    if(item.title === 'EN') {}

    console.log('User menu item clicked: ', item)
    this.menuItem = item.title;

    if (item.link === 'Login') {
      // Delete session
      userModule.logout(1)      
      window.location.replace(process.env.VUE_APP_URL + '/login'); // Change for VPS to login route
      return
    }
    console.log('USER in user menu -> ', item )
    this.$router.push(item.link);
  }

  async goToUserProfile(user) {
    console.log('User from user menu link :', user)
  }

  mounted() {
      // Check if the user is on the login page
    if (window.location.pathname === '/login') {
      this.isLoggedIn = false;
      this.stopInactivityTimer();
    }

    document.title = "ACA TRAX";
    let appLoginTime 
    // localStorage.setItem('lastLoginTime', appLoginTime)
    const storedLoginTime = localStorage.getItem('lastLoginTime');
    
    if (storedLoginTime) {
      appLoginTime = new Date(storedLoginTime).toLocaleString();
    } else {
      appLoginTime = new Date().toLocaleString();
      localStorage.setItem('lastLoginTime', appLoginTime);
    }
    this.signTest = userModule.isSignedIn
  }
}
