
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class ConfirmDialog extends Vue {
  @Prop() dialogText!: string;
  @Prop() dialogTitle!: string;
  @Prop() dialog!: string;
  @Prop() user!: object;
  @Prop() passwordsMatch!: boolean;
  confirmPasswordFailMsg = ''
  mini = false;
  isRootComponent = true;

  drawer = true;
  fixed = false;
  data() {
    return {
      inputRules: [
        v => v.length > 3 || 'Minimun length is 3 characters'
      ],
      show: false,
    }
  }
  created() {}
  mounted() {}

  public cancelSetPasswordDialog(user) {
    console.log('User cancel : ', user)
    delete user.confirmPassword
    delete user.password
    this.confirmPasswordFailMsg = ''
    this.$emit('onCancel', user)
  }
  public checkPass(user): void {
    // console.log('clicked')
    // console.log(this.addNum(4, 2))
    // alert(`CheckPass Func ${JSON.stringify(user)}`) 
    if(user.password === undefined || user.confirmPassword === undefined) {
      console.log('A field is empty.')
      // user.password = ''
      // user.confirmPassword = ''
      this.confirmPasswordFailMsg = 'Please fill both fields before confirming.'
      return
    }

    // if(user.password !== '' && user.confirmPassword !== '') {
    if(user.password !== user.confirmPassword) {
      console.log('Passwords do not match. Please try again.')
      user.password = ''
      user.confirmPassword = ''
      // Add function with setTimeout to close alert after few seconds
      this.confirmPasswordFailMsg = 'Passwords do not match. Please try again.'
      return
    } else {
      this.confirmPasswordFailMsg = ''
      this.$emit('onConfirm', { user: user })
      }
    // } 

  }
}
