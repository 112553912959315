export default {
  pages: {
    classes: {
      pageTitle: 'My Classes',
      greeting: {
        welcome: 'Welcome back',
        text: 'Please review your classes for the day below.'
      },
      classDetail: {
        pageTitle: 'Class Detail',
        endSessionText: 'Current end date subject to change:',
        tabTitles: {
          attendance: 'Attendance',
          postponeReschedule: 'Postpone or Reschedule',
          schedule: 'Schedule',
        },
        tabSections: {
          attendance: {
            quickSelect: 'Quick Select',
            quickSelectText: 'Use the buttons below to select the same attendance option for all participants. Individual attendance can be changed after using a quick select option.',
            attendanceTableHeaders: {
              student: 'Student',
              attendance: 'Attendance',
              lateLeft: 'Late/Left Early'
            },
            attendanceFormOptions: {
              present: 'Present',
              absentNotice: 'Absent (Notice)',
              absentNoNotice: 'Absent (No Notice)',
              absentLMC: 'Absent (Cancelled Last Minute)',
              reset: 'Reset'
            },
            classDuration: {
              sectionlabel: 'Class Duration',
              changeDurationButtonText: 'Change',
              durationChangeForm: {
                title: 'Change Class Duration ',
                infoBoxText: 'SVP remplir ...',
                guidelineForNote: 'SVP ajouter un note...',
                cancelBtnText: 'Annuler',
                confrimBtnText: 'Confirmer'
              }
            },
          },
  
          


        }
      }
    }
  },

  loadingMessage: {
    fetchingClasses: 'Fetching classes...please wait',
    fetchingClass: 'Fetching class...please wait'
  },
  message: {
    hello: 'Welcome back',
    welcomeText: 'Please review your classes for the day below.'
  },
  title: {
    today: 'Classes Today',
    attendanceDue: 'Attendance Due',
    recentlySubmitted: 'Editable Attendance',
    myClasses: 'My Classes',
    timesheet: 'My Timesheet'
  },
  tab: {
    today: "Today",
    due: 'Attendance Due',
    submitted: 'Editable Attendance'
  },
  sideNav: {
    classes: 'Classes',
    schedule: 'Schedule',
    payroll: 'Payroll',
    profile: 'Profile'
  },
  editableTabContent: {
    modifiableInfoText: 'Classes with attendance that can be modified will appear in this list. These classes can be modified once only for 12 hours after they have been submitted.'
  },
  detail: {
    title: 'Class Detail',
    endSessionDate: 'Current end date subject to change:',
    reports: 'Reports',
    attendance: 'Attendance',
    postponeReschedule: 'Postpone or Reschedule',
    schedule: 'Schedule',
    quickSelect: 'Quick Select',
    quickSelectText: 'Use the buttons below to select the same attendance option for all participants. Individual attendance can be changed after using a quick select option.',
    alert: {
      notModifiable: 'This class is not modifiable or does not required attendance.',
    },
    attendanceOptions: {
      present: 'Present',
      absentNotice: 'Absent (Notice)',
      absentNoNotice: 'Absent (No Notice)',
      absentLMC: 'Absent (Cancelled Last Minute)',
      reset: 'Reset'
    },
    attendanceTableHeaders: {
      student: 'Student',
      attendance: 'Attendance',
      lateLeft: 'Late/Left Early'
    },
    attendanceTableRows: {
      noStudentName: 'No student name'
    },
    classDuration: {
      label: 'Class Duration',
      changeDurationLabel: 'Change',
      durationChangeForm: {
        popupTitleBar: 'Change Class Duration ',
        infoBoxText: 'SVP remplir ...',
        guidelineForNote: 'SVP ajouter un note...',
        cancelBtnText: 'Annuler',
        confrimBtnText: 'Confirmer'
      }
    },
    postponeRescheduleTab: {
      cannotPostponeRescheduleAlert: 'Group classes cannot be postponed in Trax.',
      formUseAlert: 'Only use this form if the class will take place at an alternative date or time than the original date/time.', 
      classPostponedBy: 'Class postoned by',
      postponedByLabelTeacher: 'Instructor',
      postponedByLabelStudent: 'Student',
      postponeRescheduleOptions: 'Choose from the following options',
      postponeOptionsLabelReschedule: 'Reschedule', 
      postponeOptionsLabelAddToEndOfSession: 'Add to the end of the session',
      postponeOptionsLabelUnknown: 'Do not know',
      selectNewDateHeader: 'Please select a new date and/or time for this class',

    },
    scheduleTab: {
      title: {
        contractDetails: 'Contract Details',
        classesSchedule: 'Classes Schedule',
      },
      contractListLabel: {
        course: 'Course'
      },
      contractListLabel: {
        course: 'Course',
        client: 'Client',
        language: 'Language',
        student: 'Student',
        level: 'Level',
        session: 'Session',
        day: 'Day(s)',
        time: 'Time',
        cutOffDate: 'Cut-Off date',
        total: 'Total',
        unscheduledHours: 'Unscheduled Hours'
  
      },
      contractListValue: {
        noCutOffSet: 'No Cut-Off date',
  
      }
    },
    popupText: {
      accessDenied: 'Access Denied',
      classNotStarted: 'The class is not yet started and attendances cannot be submitted at this time. Please contact ACA for assistance.'
    }
 
  },
  calendar: {
    title: 'My Schedule',
  },
  profile: {
    userProfileCardTitle: 'Profile',
    username: 'Name',
    email: 'Email',
    noEmail: 'Not available',
    activationDate: 'Trax activation date',
    preferredLanguage: 'Preferred Language on Trax',
    switchLanguage: 'Change language in Trax',
    save: 'Save Settings'
  },
  pay: {
    title: 'Timesheet',
    week: 'Week'
  },
  buttonText: {
    goToClassTxt: 'Go to class',
    editClass: 'Edit Attendance'
  }
}