import { getData, putData, postData, deleteData } from '@/utils/demo-api';
import { Entity, Event } from '@/types';
import { appModule } from './app';
import { getDefaultPagination, getPagination, getDateRange } from '@/utils/store-util';
import { get, indexOf, reject } from 'lodash';

import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { userModule } from "@/store/modules/user";

// API modules 
import { 
  checkRecordExistsForStudent, 
  checkFMToken, 
  getPrivateClasses, 
  getOneClass,
  getGroupClasses, 
  checkRecordExists, 
  getExistingRecordsForTeacher,
  getPFUClasses } from '@/utils/fm-api';
import moment from 'moment';
import axios from 'axios';
import { subMinutes } from 'date-fns';
// import { resolve } from 'path'; 
// import { resolve } from 'path';

export interface ClassState {
  // items: Entity[];
  pagination: Pagination;
  loading: boolean;
  event: Event;
  events: Event[];
  // students: Students[]
}

@Module({ store, dynamic: true, name: 'classesModule' })
class ClassesModule extends VuexModule implements ClassState {
  // public items: Entity[] = [];
  public pagination = getDefaultPagination();
  public loading = true;
  public events: Event[] = [];
  public items: Entity[] = [];
  // public event = {} as Event
  public event
  // public getExistingFmAttendanceForStudentObject 
  // public existingAttendance
  // public student = {} as Student
  public students: TODO = []
  private currentClassIndex: number

  @Mutation
  public setEvents(events: Event[]): void {
    this.events = events;
  }

  get getClasses() {
    console.log('Fetching classes from FM [classes.ts] ')
    // Check if classes in session  
    const cfs = sessionStorage.getItem('events2')
      
    const dateRange = getDateRange(1);
    console.log('Current date range: ', dateRange); // Output: "06/14/2024"

    // DEMO INSTRUCORS WITH FM API ACCOUNTS / EXISTING CLASSES
    // Ryan Lee 
    // "startDate": "05/24/2024", // mm/dd/yyyy
    // "endDate": "06/24/2024",

    // Connor Henderson - ch@e.com - hendy 
    // "startDate": "02/14/2024",
    // "endDate": "03/24/2024",

    return new Promise(async (resolve, reject) => {

      try {
        const token = localStorage.getItem('token');
        const payload = {
          "startDate": dateRange.start, // mm/dd/yyyy
          "endDate": dateRange.end,
          "teacher": token,
          "modified": "", // Dynamically add last login timestamp
          "language": "EN",
          "groupOnly": null,
          "privateOnly": null,
        }

        const response = await axios.post(process.env.VUE_APP_API_URL + '/classes2', payload, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        // this.handleResponse(response);
        console.log('FM get all classes: RESPONSE [classes.ts] ', response)
        // this.events = response;
        // this.loading = false;
        resolve(response);
      } catch (error) {
        console.error('Error fetching classes:', error);
        // this.loading = false;
        reject(error);
      }
    });
    // return this.events;
  }

  get getSingleClass(){
    const classIdx = sessionStorage.getItem('eventIndex')
    return this.events[classIdx]
  }

  get getUpdatedEvent() {
    // must change back to Int to use in this.events for index
    const eventIndex = sessionStorage.getItem('eventIndex')
    const parsedIdx = parseInt(eventIndex, 10)
    console.log('classes.getUpdatedEvent index of class -> ', parsedIdx)
    const eToUpdate = this.events[parsedIdx]
    console.log('classes.getUpdatedEvent eToUpdate -> ', eToUpdate)
    sessionStorage.setItem('eventIndex', '')
    return eToUpdate
  }

  get getItems() {
    const eventsFromStorage = sessionStorage.getItem('calEvents')
    var parsedObj = JSON.parse(eventsFromStorage)
    return parsedObj
  }

  get getClassesLength() {
    return this.events.length;
  }

  get getPrivateClasses(){
    const results = this.events.filter(c => c['isPrivate'] === true )
    return results
  }

  get getPrivateClassesLength() {
    const pLen = this.events.filter(c => c['isPrivate'] === true )
    return pLen.length 
  }

  // Use to compare to current class listings and find incomplete attendance 
  get submittedAttendanceForPeriod() {
    console.log('Students -> ', JSON.stringify(this.students))
    return this.students.filter((s) => {
      return s.attendanceSubmitted === true
    })
  }

  get classesWithAttendanceIncomplete() {
    const incompleteAttendance = this.items.filter(c => {
      return c['fieldData']['status'] === ''
    })    
    return incompleteAttendance
  }

  get studentsFromClasses() {
    const studentsAttendanceDue = this.students.filter((s) => {
      return s.attendanceSubmitted === false
    })
    return studentsAttendanceDue
  }

  get allStudentsFromClasses() {
    return this.students
  }

  get getNumStudents() {
    return this.students.length
  }
  
  get getNumNotesToTeacher(){
    // const notes = this.events.filter(c => c['teacherNotes'] !== '')
    const notes = this.items.filter(c => c['teacherNotes'] !== '')
    return notes.length
  }

  get partialCompleteAttendance() {
    const partials = this.items.filter(record => {
      const regLen = record['portalData']['class_REGISTRATIONS'].length
      const attLen = record['portalData']['class_ATTENDANCES'].length

      const regMinusAttendance = regLen - attLen 
      // if(regMinusAttendance !== 0) {
      // if(attLen > regLen) {
      //   console.log('Issue with class. More attendance records than students. Check FM -> ', record['fieldData']['__pk_Classes'])
      //   return
      // }
      if(attLen < regLen) {
        return record
      }
      // return regMinusAttendance !== a['portalData']['class_REGISTRATIONS'].length
    })
    return partials
  }

  get getNotes(){
    // return this.events.filter(c => c['teacherNotes'] !== '')
    const notes = this.items.filter(c => c['fieldData']['teacherNotes'] !== '')
      // .map(result => result['fieldData']['teacherNotes'])
    return notes
  }

  get getNum() { return 12 }

  isComplete(s, index, array) {
    return s.attendanceSubmitted === false
  }

  // get classesWithIncompleteAttendance() {
  //   const incompleteAttendance = this.events.map((e,indx) => {
  //     return e.students.some(this.isComplete)
  //   })
  //   console.log('INCOMPLETE ATT : ', incompleteAttendance)
  //   return incompleteAttendance
  // }

  // get classCount() {
  //   return this.events.length
  // }
  // get getNumClasses() {
  //   console.log('IN GET NUMS ', this.events.length)  // FIX
  //   return this.events.length
  // }

  @Action async checkForRecordModifedinFM(e) {
    const theEvent = JSON.stringify(e['fieldData']['z_ModifiedOn'])
    const lastLoginTime = sessionStorage.getItem('loginDateTime')
  }

  @Action async setIndexForCurrentClass(e) {

    const classIdx = this.events.indexOf(e)
    sessionStorage.setItem('eventIndex', JSON.stringify(classIdx))
    this.currentClassIndex = classIdx
  }

  // Call this when outcome has been submitted succesfully??
  @Action async updateAttendanceStatusAndOutcomeForClass(studentAttendanceComplete){
  // update students 
  const studentAttendancePostSubmit = JSON.stringify(studentAttendanceComplete) 
  // Find student and update the attendance status on the STUDENTS array
  // await this.students.map((s,i) => {
  //   if(s.sID === studentAttendanceComplete.sID && s.class_id === studentAttendanceComplete.classID) {
  //     this.updateAttendanceSubmittedStatus(i)
  //   }
  // }) 

  // Index always 0 for private student
  let indexOfStudent = 0 
  const classIndex = this.currentClassIndex
  const studentsArrayLen = this.events[classIndex]['students'].length

  // Set indexOfStudent if not a private student
  if(studentAttendanceComplete['studentFromGroup'] === true) {
    // indexOfStudent = studentsArrayFromEvent.indexOf(studentAttendanceComplete.sID)
    indexOfStudent = studentAttendanceComplete['sIndex']
  } else {
    // alert('classes.updateAttendanceStatusAndOutcomeForClass -> Private student ')
  }

  // get the event by index and update the attendance status
  // const dispClass = this.events[this.currentClassIndex]
  this.events[this.currentClassIndex]['students'][indexOfStudent]['attendanceSubmitted'] = true

  if(indexOfStudent === studentsArrayLen -1) {
    this.events[this.currentClassIndex]['isAttendanceComplete'] = true
    console.log(`classes: updated event -> attendance updated?.. ${JSON.stringify(this.events[this.currentClassIndex])}`)
    sessionStorage.setItem('updatedClassAttendance', JSON.stringify(this.events[this.currentClassIndex]))
  }

  // Find student in class event and update the attendance status on the EVENT array
  // await this.events.map((e,eventIndx) => {
  //   sessionStorage.setItem('eventIndx', eventIndx.toString())
  //   alert(` the event to update : ${JSON.stringify(e)}`)
  //   if(studentAttendanceComplete.classID === e.classID) {
  //     e.students.map((s,i) => { 
  //       if(s.sID === studentAttendanceComplete.sID) {
  //         console.log('The student in classes.update -> ', s)
  //         this.events[eventIndx].students[i].attendanceSubmitted = true
  //         if(i === e.students.length -1) {
  //           e.isAttendanceComplete = true
  //         }
  //       }
  //     })
  //   } 
  // })
  }

  @Mutation updateAttendanceSubmittedStatus(i) {
    this.students[i].attendanceSubmitted = true
  }

  @Action async updateEvent(e) {
    const targetClassId = e.classID
    // this.updateEventOutcome(e)
  }
  // async function updateCalendarEvent(sIndx: TODO, status: TODO) {
  //   classEvent['students'][sIndx].attendanceSubmitted = status
  //   return 
  // }

  // @Mutation updateEventOutcome(c): void {
  //   const theEventToUpdate = this.events.map(e => {
  //     return e.classID = c.classID
  //   })
  //    theEventToUpdate['outcome'] = c['outcome']
  // }

  @Mutation clearItems(events: Event[]): void {
    this.events = [];
  }

  @Action async getCurrentAttendanceStats(): Promise<TODO> {

    // For each class 
    this.events.forEach((c) => {
      // For each student
      c.students.forEach((s) => {
        console.log('classes.getCurrentAttendanceStats 2nd loop : Student = ', s['fname'])
        const data = {
          query: {"query": [{"student_id": s['Student classmates startup::_kp_student_id'], "class_id": c['classID']}]},
        }

        checkRecordExistsForStudent("checkRecordExistsForStudent", data).then(res => {
          console.log('classes.checkRecordExistsForStudent api call : RES ', res)
        })
      })
    })
  }

  // DELETE ALL CLASSES FROM STORE
  @Action async deleteAllClasses(): Promise<TODO> { 
    this.clearState()
  }

  // API v2 - get a single class when user navigated to the class detail page 
  @Action async getOneClass(): Promise<TODO> {

    this.setLoading(true);

    // return new Promise<TODO>((resolve, reject) => { 
    //   // The FM API -> getData
    //   getOneClass("getOneClass").then(async res => { 

    //     console.log('HERE classes back from fm-api -> ', res)      
    //     if(res) {
    //       console.log('One class returned: [classes.ts ] -> ', res[0])
    //       console.log('WHAT IS -> ', typeof res) 
    //       this.setDataTable(res)
    //       this.setLoading(false);
    //       resolve(res)
    //     } else {
    //       console.log('An error occured getting the class from FM...', res)
    //       reject(res)
    //     }
    //   })
    // }) 
  }

  @Action async getAllFollowUps(): Promise<TODO> {
    return new Promise<TODO>((resolve, reject) => { 
      // resolve('PFU good..')
      getPFUClasses("getPFUClasses").then(async res => { //@/utils/fm-api

      if(res) {
      
        res.forEach((c: Event, i: TODO) => {

          let classEvent = {}
          const colors = ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1']

          // Event data
          let date = moment(c['fieldData']["scheduleDate"]).format("YYYY-MM-DD");
          // console.log('date ->',  typeof date)

          if(date === 'Invalid date') {
            date = "2023-03-15"
          }

          const scheduleDate = new Date(date).toISOString().slice(0,10)
          // const scheduledDate = new Date(c['fieldData']["scheduleDate"]).toISOString().slice(0,10)
          const classID = c['fieldData']['__pk_PFU']
          const grpName = 'N/A'
          const outcome = c['fieldData']['status'] || null
          const noteToTeacher = 'Note in PFU'
          const start = scheduleDate + ' ' + c['fieldData']['startTime']
          const end = scheduleDate + ' ' + c['fieldData']['endTime']
          const displayDate = moment(start).format('MM/DD/YYYY') // Used for FM layout format
          const teacher_id = c['fieldData']['_fk_Teacher']
          const todayDate = moment(new Date())
          const classDate = moment(scheduleDate)
          let isAttendanceAvailable
        })

        const existingEventsString = sessionStorage.getItem('events');

        console.log(existingEventsString)

        let existingItems = [];
        if (existingEventsString) {
          existingItems = JSON.parse(existingEventsString);
        }

        // Append the second array of items to the existing array
        // const secondArray = [];
        const combinedItems = existingItems.concat(res);
      

        // Store the combined array back into sessionStorage
        sessionStorage.setItem('events', JSON.stringify(combinedItems));
        console.log(combinedItems)

        this.setDataTable(res)
        this.setLoading(false)
        this.setEvents(res) 
        resolve(res)
        } else {
          console.log('An error occured getting FM PFUS...', res)
          reject(res)
        }
      }).catch(error => {
        reject(error)
      });

    })
  }

  // GET ALL CLASSES 
  // Return all classes for admin - no teacher id required
  // CURRENT - July 2023 - Get all pending classes for teacher
  @Action async getAllClasses(): Promise<TODO> {
    // get classes from storage
    const cfs = await sessionStorage.getItem('events2') 

    const classesArray = JSON.parse(cfs)
    console.log('Events from Store :', classesArray[0])

    // API v2
    this.setLoading(true);

    return new Promise<TODO>((resolve, reject) => { 
      if(this.events.length > 0) {
        console.log('Events :', this.events)
      }
      // if(classesArray.length > 0) { // if true send the classes from storage back
      //   console.log('CFS [created] :', classesArray[0])
      //   resolve(classesArray)
      // }
      // else {     
        // The FM API -> getData
        getPrivateClasses("getPrivateClasses").then(async res => { //@/utils/fm-api
          
          if(res) {
            console.log('First class returned: [classes.ts ] -> ', res[0])
            // let theArr = []
            // theArr.push(res)
            // console.log('WHAT IS -> ', typeof res)
            // console.log('GET ONE [classes.ts ] -> ', res['data']['response']['data'][0])
            // const items = res['data']['response']['data']

            // var result = Object.keys(res).map((key) => [Number(key), res[key]]);
            // console.log('the result ', result);

            // const items = res
            // await this.setItems(res)
          
            res.forEach((c: Event, i: TODO) => {
              console.log('this here? -> ', c)

              let classEvent = {}
              const colors = ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1']

              // Event data
              let date = moment(c["scheduleDate"]).format("YYYY-MM-DD");

              console.log('date ->',  typeof date)

              if(date === 'Invalid date') {
                date = "2023-03-15"
              }

              const scheduleDate = new Date(date).toISOString().slice(0,10)
              console.log('Debugging date in classes.ts : ', scheduleDate)
              // const scheduledDate = new Date(c['fieldData']["scheduleDate"]).toISOString().slice(0,10)
              const classID = c['pkClasses']
              const grpName = c['groupName']
              const outcome = c['status'] || null
              const noteToTeacher = 'some note here'
              const start = scheduleDate + ' ' + c['startTime']
              const end = scheduleDate + ' ' + c['endTime']
              const displayDate = moment(start).format('MM/DD/YYYY') // Used for FM layout format
              const teacher_id = c['fkTeacher']
              const todayDate = moment(new Date())
              const classDate = moment(scheduleDate)
              let isAttendanceAvailable

              console.log('API2 schelduleDate (getAllClasses works if value) -> ', scheduleDate)
            })
            this.setDataTable(res)
            this.setLoading(false);
            this.setEvents(res) // still something funky going on here -- nail down issue with gpt
            resolve(res)
          } else {
            console.log('An error occured getting FM classes...', res)
            reject(res)
          }
        }).catch(error => {
          // Handle any network errors or errors during processing
          console.error('Network error line 648[classes.ts]:', error);
          reject(error)
        });
      // }
    }) 
  }

  @Action async getFullClassData(classID: any) {

    const eventsFromStorage = sessionStorage.getItem('events')
    console.log('GET THE ITEMS object -> ', eventsFromStorage) 

    console.log('ITEMS in classes.ts ', eventsFromStorage)
    const parsedEvents = JSON.parse(eventsFromStorage)

    const fullClass = await parsedEvents.filter(c => {
      return c['fieldData']['__pk_Classes'] === classID
    })
    const strObj = JSON.stringify(fullClass)
    return strObj
  }

  @Action setDataTable(items: any) {
    const pagination = getPagination(items);
    this.setPagination(pagination);
    this.setItems(items);
  }

  @Mutation clearState(): void {
    console.log('Clearing items....')
    this.items = [];
  }

  @Mutation setItems(items: any): void {
    console.log('SET ', items)
    this.items = items;
  }


  @Action async checkAttendanceRecords2(data): Promise<TODO> { 
    this.setLoading(true);
  }

  @Action({rawError: true}) async getExistingFmAttendanceForStudentObject(student): Promise<TODO> {

    return new Promise<TODO>((resolve, reject) => { 
        console.log('Student in getExistingFmAttendanceForStudentObject : ', JSON.stringify(student))
        // {
        //   "fname": "Maxime",
        //   "lname": "Gamache-Poitras",
        //   "sID": "S00020816",
        //   "class_id": "CLA000239287"
        // },
        const class_date = student.classDate
        const class_id = student.class_id
        const student_id = student.sID

        const data = {
          query: JSON.stringify({"query":[ {"ClassDate":class_date,"class_id": class_id,"_fk_student_id": student_id} ]}),
          // token: fmtoken
        }
      
        const config = {
          method: 'post',
          headers: { 
            // 'Authorization': data.token, 
            'Content-Type': 'application/json'
          },
          data: data.query
        }
      
        const url = '/api/checkAttendanceRecordExists'
        // const url = 'http://localhost:3001/api/checkAttendanceRecordExists'

          
          axios.post(url,config)
          // ref- https://stackoverflow.com/questions/44715661/cannot-access-this-in-axios-post-method-in-vuejs-2
            .then((response) => { // do not use "(function (response)){..} or 'this' is undefined below
              // if student record -> add isSubmitted flag to local attendance object 
              // to disable checkbox and ignore when submitting remaining attendance
              // code: "401"
              // message: "No records match the request"
              console.log('checkRecordExists 1response ', response)
              const parsedBody = JSON.parse(response.data.body)
              const message = parsedBody.messages[0].message
              // console.log('checkRecordExists 2response : message ', message)

              if(message !== "No records match the request") { // RECORD FOUND 
                console.log('RECORD FOUND ..... set attendanceSubmitted = true for student', JSON.stringify(student))
                resolve(true) 
                // return true   
              } else {
                console.log('NO RECORD FOUND ..... setting attendanceSubmitted = false for student')
                resolve(false) 
                // return false 
              }
            })
            .catch(function (error) {
              console.log('ERR in Attendance.checkAttendanceRecordsInFM : ', error);
              // reject(error)
            });
          })
  }
  
  @Action async getExistingFmAttendance(): Promise<TODO> {
    // this.context.commit('setLoading', { loading: true });
    this.setLoading(true);

    return new Promise<TODO>((resolve, reject) => { 

      this.events.map((e, classIndx) => {
        const class_date = e['displayDate'],
        class_id = e['classID']
        e['students'].map((s, studentIndx) => {

        const student_id = s['Student classmates startup::_kp_student_id']
        const fmtoken =  sessionStorage.getItem('fm-token')

        const data = {
          query: JSON.stringify({"query":[ {"ClassDate":class_date,"class_id": class_id,"_fk_student_id": student_id} ]}),
          token: fmtoken
        }
      
        const config = {
          method: 'post',
          headers: { 
            'Authorization': data.token, 
            'Content-Type': 'application/json'
          },
          data: data.query
        }
      
        const url = '/api/checkAttendanceRecordExists'
          
          axios.post(url,config)
            .then((response) => { 
              console.log('checkRecordExists 1response ', response)
              const parsedBody = JSON.parse(response.data.body)

              const message = parsedBody.messages[0].message
              console.log('checkRecordExists 2response : message ', message)

              if(message !== "No records match the request") { // RECORD FOUND 
                console.log('RECORD FOUND ..... setting attendanceSubmitted = true for student', JSON.stringify(e['name']))
                e.students[studentIndx].attendanceSubmitted = true
                console.log(`Class: ${classIndx} / Student : ${studentIndx}`)
                console.log('Setting attendance missing flag for student :' , e.students[studentIndx])
                const eventsLen = this.events.length -1
                console.log('events len .. does it not reach the classIndx value ??  -> ', eventsLen)
                const studentsLen = e['students'].length -1 
          
                if(classIndx === eventsLen) { resolve('Att. check done!') }
                // resolve(true)
                // this.attendance[i].attendanceRecordExists = true
                // console.log('RECORD ALREADY EXISTS ', this.attendance[i])
                // // this.skippedCount += 1
                // if(this.skippedCount === this.numRecordsToSubmit) { // FIX
                //   // console.log(`skippy ${this.skippedCount}`)
                //   return this.skippedCount
                // }  
              } else {
                console.log('NO RECORD FOUND ..... setting attendanceSubmitted = false for student')
                e.students[studentIndx].attendanceSubmitted = false
                console.log(`Class: ${classIndx} / Student : ${studentIndx}`)
                console.log('Setting attendance missing flag for student :' , e.students[studentIndx])
                const eventsLen = this.events.length -1
                console.log('events len .. does it not reach the classIndx value ?? -> ', eventsLen)
                const studentsLen = e['students'].length -1 

                if(classIndx === eventsLen) { resolve('Att. check done!') }
                }
            })
            .catch(function (error) {
              console.log('ERR in Attendance.checkAttendanceRecordsInFM : ', error);
              reject(error)
            });
          })
        })
      })
  }
  
  @Mutation setPagination(pagination: TODO): void {
    this.pagination = pagination;
  }
  @Mutation setLoading(loading: boolean): void {
    this.loading = loading;
  }
}

export const classesModule = getModule(ClassesModule); 
