<template>
  <div class="container">
    <!-- <v-container v-if="!tokenIsValid" max-width: 80%;>
      <div style="background: rgb(243 244 244);
        color: rgb(64 75 75);
        "
        >
        <p style="
          border: 2px solid rgb(113 148 155);
          background-color: rgb(59 225 255 / 52%);
          padding: 20px;
          color: rgb(23 102 118);
          font-size: 20px;
          border-radius: 8px;">
       
        </p>
      </div>
      <v-alert type="error">
        {{ validMsg }}
      </v-alert>
    </v-container> -->

    <v-container v-if="tokenIsValid" class="form-container">
      <v-img src="@/assets/img/small-aca.jpeg" alt="vuetify components logo" contain height="120" />
      <div style="margin-bottom: 20px; color: #024fa0;display: flex; 
        align-items: baseline;justify-content: center;">
        <h2>ACA Teacher Web Portal Registration</h2>
      </div>

      <v-alert
        prominent
        type="success"
        v-if="regSuccessAlert"
        style="margin-top: 40px;"
      >
      <v-row align="center">
        <v-col class="grow">
          Registration Successful. You can proceed to login. 
        </v-col>
        <!-- <v-col class="shrink">
          <v-btn href="http://localhost:3101/login">Go To Login</v-btn>
        </v-col> -->
      </v-row>
      
      </v-alert>
      <div v-if="regSuccessAlert" style="text-align: right;">
        <v-btn text @click="redirectToLogin" class="hover-light-blue">Go to log in page</v-btn>
      </div>
      <br><br>
      <v-form ref="form" @submit.prevent="submitRegForm" v-show="!regSuccessAlert">
        <!-- <v-text-field
          v-model="email"
          label="Email"
          :rules="emailRules"
          required
        ></v-text-field> -->
        <v-text-field
  v-model="password"
  label="Password"
  :type="showPassword ? 'text' : 'password'"
  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
  @click:append="showPassword = !showPassword"
  :rules="passwordRules"
  required
></v-text-field>

<v-text-field
  v-model="confirmPassword"
  label="Confirm Password"
  :type="showConfirmPassword ? 'text' : 'password'"
  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
  @click:append="showConfirmPassword = !showConfirmPassword"
  :rules="confirmPasswordRules"
  required
></v-text-field>
        <div style="margin-top: 30px">
          <div class="d-flex justify-end">
            <v-btn
              color="primary"
              :disabled="validForm"
              type="submit">
              Register
            </v-btn>
          </div>
        </div>

      </v-form>
    </v-container>

    <v-container else v-show="false"> 
      <v-alert 
        type="error" 
        prominent 
        class="my-5"
      >
        Sorry! An error occured with your registration. Please contact ACA for assistance.
      </v-alert>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import router from '@/router';
import { userModule } from '@/store/modules/user';
import bcrypt from 'bcryptjs';
import { has } from 'lodash';
// import { use } from 'vue/types/umd';

export default {

  beforeRouteEnter(to, from, next) {
    // Call a callback function to perform actions within the component
    next(vm => {
      vm.beforeEnterRoute()
    })
  },

  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
      processing: true,
      email: '',
      password: '',
      confirmPassword: '',
      regCode: '',
      tokenIsValid: false,
      validMsg: '',
      showForgotForm: false,
      regSuccessAlert: false
    };
  },

  computed: {
    emailRules() {
      return [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Email must be valid'
      ];
    },
    passwordRules() {
      return [
        v => !!v || 'Password is required'
      ];
    },
    confirmPasswordRules() {
      return [
        v => !!v || 'Confirm Password is required',
        v => v === this.password || 'Passwords do not match'
      ];
    },
  },

  async beforeCreate() {
  },

  async created() {
    // await localStorage.clear()
    await sessionStorage.clear()
    // V3 - extract reg token from email link 
    const token = await this.$route.query[''];
    console.log('[beforeCreate - RegForm]Toke from email SUCCESS? ', token); // Output: 'FDE49D91-4336-4502-B670-B4B3E54AADC4'
    // alert('check console for email token')local set
    console.log(process.env.VUE_APP_URL); // Output: 'FDE49D91-4336-4502-B670-B4B3E54AADC4'
    // console.log('Reg TOKEN ', this.regCode)
    // alert('local set')

    try {
      // const regToken = localStorage.getItem('reg_token')
      // console.log('TOKEN? ', regToken)
      // alert('token in store?')
      const url = process.env.VUE_APP_API_URL + '/fm-auth'
      // console.log('auth url ', url)

      axios.post(url, { token: token })
        .then((response) => {
            console.log('TOKEN FOR PY CHECK -> ', response.data.response)

            if(response.data.response === 'Token is valid.') {
              console.log('Token validation success')
              this.tokenIsValid = true
              localStorage.setItem('reg_token', token)
              localStorage.removeItem('token')
              // alert('save it')
              // Extract the JWT token from the response
              // const token = response.data;
              // localStorage.setItem('token', response.data.token);
              this.processing = false
            } else {
              window.location.replace(process.env.VUE_APP_URL + '/token-error'); 
            }
            
              // Change for VPS to login route

              // console.log('Success login: ', response)
              // localStorage.setItem('reg_token', token)
              // localStorage.removeItem('token')
              // // alert('save it')
              // // Extract the JWT token from the response
              // // const token = response.data;
              // // localStorage.setItem('token', response.data.token);
              // this.tokenIsValid = true
              // this.processing = false

              // Store the JWT token securely for future requests (e.g., in local storage)
              // this.regCode = response.data

              // this.validMsg = 'Token is valid.'
              // this.show = true
        })
      } catch (error) {
          this.validMsg = response.data.response
          this.tokenIsValid = false
          // const tokenInStorage = localStorage.getItem('token')
          localStorage.removeItem('reg_token')
          // window.location.replace(process.env.VUE_APP_URL + '/token-error'); // Change for VPS to login route
          console.log('Error validating token ', error)
      }
    // alert('API call to Python to validate JWT token here...')
    // userModule.logout()
    // Make API call to check if the token is valid
    console.log('Token from URL  ', this.$route.params.regcode)

    // if(this.$route.params.regcode === undefined) {
    //   this.tokenIsValid = false
    //   console.log('Token is undefined..check the URL')
    // }
      // Extract the token and check if valid
      // AUTH API REQUEST - check the token is still valid for the registration process 
      // axios.post('http://localhost:3001/token-check', { token: this.$route.params.regcode })
  },

  mounted(){
    // localStorage.setItem('reg_token', token)
    // alert('check regCode and being stored')

    // Get /reset to forward to forgot password form - refactor just to check for no token 
    // (ie. the link did not come from external email)
    this.regCode = this.$route.params.regcode;
    if(this.regCode === 'reset') {
      // alert('Register Form...')
      this.showForgotForm = true 
      this.tokenIsValid = null
    }
  },

  methods: {
    beforeEnterRoute() {
      // Your logic to change the value in the Vuex store
      userModule.logout()
    },

    submitRegForm() {
      // alert('sub')
      // Validate Form Input - move to external module
      this.$refs.form.validate();

      // Hash password
      // const hashedPassword = bcrypt.hashSync(this.password, 10);
      // console.log('Experimental hashed password ', hashedPassword)

      // const token = localStorage.getItem('reg_token')
      const regToken = localStorage.getItem('reg_token')
      if(regToken === null) {
        // alert('token null....')
      }
      // PAYLOAD FOR FM AUTH - NOTE PASSWORD MUST BE HASHED BUT SEEMS BUGGY ON FM SIDE
      const credentials = {
        password: this.password,
        token: regToken
      }

      // AUTH API REQUEST
      axios.post(process.env.VUE_APP_API_URL + '/register', credentials)
      .then((response) => {
        console.log('Reg Success? ', response)
        // alert('register req callback...')
        // console.log('FM AUTH RES With Token : check local storage -> ', response.data.token)

        // Store the JWT token securely for future requests (e.g., in local storage)
        // localStorage.setItem('jwtToken', token);
        if(response.data.status === 'ERROR') {
          console.log('Reg Err...', response)
          this.regSuccessAlert = false
        }

        if(response.data.response === 'FileMaker Server returned error 401, No records match the request') {
          console.log('Failure confirm...', response)
          // alert('Registration failed. Check server? ')
          this.regSuccessAlert = false
        }
        // if(response.statusText === 'OK') {
        else { 
          console.log('Success confirm...', response)
          // alert('Registration successful. Check password set in FM. Go to login.')
          this.regSuccessAlert = true
          // const url = process.env.VUE_APP_URL + '/login'
          // window.location.replace(url); 
          // userModule.logout()
          // window.location.replace("http://localhost:3101/login"); // Change for VPS to login route
        }
      })
    },
    redirectToLogin() {
      // Perform the redirect here
      // userModule.logout()
      localStorage.removeItem('reg_token')
      // window.location.href = "http://localhost:3101/login";
      // window.location.href = "http://vps-075b404d.vps.ovh.ca/login";
      window.location.href = "https://trax.lesateliers.ca/login";
    }
  }
};
</script>

<style scoped>
/* Add custom styles here */
.container {
  /* background-color: #f0f7ff; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  /* height: 100vh; */

}

.form-container {
  width: 50%;
  border: 1px solid #e0e0e0; /* Light grey border */
  border-radius: 4px;
  padding: 40px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Elevation 2 */
  background: white;
}
.v-messages__message {
  margin-bottom: 20px !important;
}
.hover-light-blue:hover {
  /* background-color: #2882f1ad !important; */
}
</style>
