// src/utils/dateFormatter.js

import { format, parseISO, parse } from 'date-fns';
import { fr } from 'date-fns/locale';
import { enUS } from 'date-fns/locale/en-US';

export function formatToFrenchDate(isoString) {
  const date = parseISO(isoString);
  return format(date, 'dd MMMM yyyy', { locale: fr });
}

export function formatToEnglishDate(isoString) {
  const date = parseISO(isoString);
  return format(date, 'dd MMMM yyyy', { locale: enUS });
}

export function formatToFrenchDateTime(isoString) {
  const date = parseISO(isoString);
  return format(date, 'dd MMMM yyyy à HH:mm', { locale: fr });
}

export function formatDDMMYYYYToFrenchDate(dateString) {
  console.log('date str ', dateString)

  const date = parse(dateString, 'MM/dd/yyyy', new Date()) || parse(dateString, 'dd/MM/yyyy', new Date());
  // Parse the input string in DD/MM/YYYY format
  // const date = parse(dateString, 'dd/MM/yyyy', new Date());

  console.log('parse ', date)
  // Format the date to French format
  return format(date, 'dd MMMM yyyy', { locale: fr });
}

export function formatDDMMYYYYToEnglishDate(dateString) {
  // Parse the input string in DD/MM/YYYY format
  // const date = parse(dateString, 'dd/MM/yyyy', new Date());
  const date = parse(dateString, 'MM/dd/yyyy', new Date()) || parse(dateString, 'dd/MM/yyyy', new Date());

  // Format the date to English format
  return format(date, 'MMMM d, yyyy', { locale: enUS });
}

export function formatEnglishDateToFrench(dateString) {
  // Parse the input string in the format "Friday, 23rd August 2024"
  const date = parse(dateString, 'EEEE, do MMMM yyyy', new Date(), { locale: enUS });
  
  // Format the date to French format
  return format(date, 'EEEE d MMMM yyyy', { locale: fr });
}

export function formatToShortEnglishDate(isoString) {
  const date = parseISO(isoString);
  return format(date, 'EEE, d MMMM', { locale: enUS });
}

export function formatToShortFrenchDate(isoString) {
  const date = parseISO(isoString);
  return format(date, 'EEE, d MMMM', { locale: fr });
}
