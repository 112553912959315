
import Table from "@/components/Table.vue";
import TableHeaderButtons from "@/components/TableHeaderButtons.vue";
import SearchPanel from "@/components/SearchPanel.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import { debounce } from "lodash";
import {
  buildSearchFilters,
  buildJsonServerQuery,
  clearSearchFilters
} from "@/utils/app-util";
import { Component } from "vue-property-decorator";
import Vue from "vue";
import { Entity } from "../types";
import { appModule } from "@/store/modules/app";
import { customerModule } from "@/store/modules/customers";
import { userModule } from "@/store/modules/user";

@Component({
  components: {
    Table,
    TableHeaderButtons,
    SearchPanel,
    ConfirmDialog
  }
})
export default class CustomerList extends Vue {
  public dialog = false;
  public dialogTitle = "API Access";
  public dialogText = "Create a password for this user.";
  public user = {};

  public passwordsMatch = true;
  private showSearchPanel = false;
  public right = true;
  public search = "";
  public headers = [
    // {
    //   text: "",
    //   value: "avatar",
    //   sortable: false
    // },
    { text: "First Name", left: true, value: "firstname" },
    { text: "Last Name", left: true, value: "lastname" },
    { text: "Email",left: true,value: "email" },
    { text: "Teacher ID",left: true,value: "teacher_id" },
    // { text: "Last Name", value: "lastname" },
    // { text: "Email", value: "email" },
    // { text: "Mobile", value: "mobile" },
    // { text: "Reward", value: "rewards" },
    // { text: "Membership", value: "membership" },
    // { text: "Actions", sortable: false, center: true }
    { text: "", value: "actions", sortable: false, center: true }
  ];

  private searchFilter = {
    contain: {
      firstname: "",
      lastname: "",
      email: "",
      mobile: ""
    }
  };
  // private customerId = "";
  private query = "";
  private color = "";
  private quickSearchFilter = "";
  private itemId = -1;

  
  async edit(item) {

    // clear classes from store
    sessionStorage.setItem('routeFrom', 'admin')
    // Add same code to change teacher name and ID in storage
    // And forward to dashboard
    console.log('Viewing teacher as Admin : ', JSON.stringify(item))
    // alert('check')

    const teacherObjToStore = {
        "firstname": item.firstname,
        "lastname": item.lastname, 
        "email": "teach@aca.com",
        "teacher_id": item.teacher_id
    }
    const t_id = item.teacher_id

    // sessionStorage.setItem('fm-user', JSON.stringify(teacherObjToStore))
    sessionStorage.setItem('viewAsTeacher', JSON.stringify(teacherObjToStore))
    sessionStorage.setItem('fm_id', JSON.stringify(t_id))

    await userModule.setUserChanged()

    // Redirect
    // window.location.replace(process.env.VUE_APP_BASE_URL + "/dashboard"); 
    window.location.href = process.env.VUE_APP_URL + "/dashboard"
  
    // this.$router.push('dashboard')
    // this.$router.push(`customer/${item.id}`);
  }
p
  remove(item: Entity) {
    console.log('Selected user ', item)
    this.dialogText = "Replace with user's name" 
    this.itemId = item.id;
    this.user = item
    this.dialog = true;
  }

  add() {
    this.$router.push("NewCustomer");
  }
  // Use to send request to backend bcrypt service  
  onConfirm(user) {
    console.log('User after confirm ->', user)
    // User module handles API access control  
    // const userFullName = this.item.firstname + ' ' + this.item.lastname
    // const newPassword = 'superstrongpass'
    if(user.password !== user.confirmPassword) {
      alert('Passwords dont match')
      this.passwordsMatch = false 
      this.remove(user)
    }
    // TODO - not being hit
    if(user.password !== undefined || user.confirmPassword !== undefined && user.password === user.confirmPassword) {
      console.log('User: ', typeof(user))
      // const parsedUser = JSON.parse(user)

      const firstname = user.user.firstname 
      const lastname =  user.user.lastname
      console.log('User password change confirmed for ', firstname)

      const result = userModule.createAPIPasswordForTeacher({ username: firstname + ' ' + lastname, password: user.user.password })
      alert(`Sending to API access service`)
      // customerModule.deleteCustomer(this.itemId);
      this.dialog = false;
    }
  }
  // onConfirm() {
  //   customerModule.deleteCustomer(this.itemId);
  //   this.dialog = false;
  // }
  onCancel(user) {
    console.log('user[teacherList] ', user)
    delete user.confirmPassword
    delete user.password
    // user.password = '';
    this.itemId = -1;
    this.dialog = false;
  }

  searchCustomers() {
    buildSearchFilters(this.searchFilter);
    this.query = buildJsonServerQuery(this.searchFilter);
    console.log(this.query)
    this.quickSearch = "";
    customerModule.searchCustomers(this.query);
    this.showSearchPanel = false;
  }

  clearSearchFilters() {
    this.showSearchPanel = !this.showSearchPanel;
    clearSearchFilters(this.searchFilter);
    customerModule.getAllCustomers();
  }

  reloadData() {
    this.query = "";
    customerModule.getAllCustomers();
  }

  updateSearchPanel() {
    this.rightDrawer = !this.rightDrawer;
  }

  cancelSearch() {
    this.showSearchPanel = false;
  }

  closeSnackbar() {
    appModule.closeNotice();
  }

  quickSearchCustomers = debounce(function() {
    customerModule.quickSearch(this.headers, this.quickSearchFilter);
  }, 500);

  get items() {
    return customerModule.items;
  }
  get pagination() {
    return customerModule.pagination;
  }
  get loading() {
    return appModule.loading;
  }
  get mode() {
    return appModule.mode;
  }
  get snackbar() {
    return appModule.snackbar;
  }
  get notice() {
    return appModule.notice;
  }
  get rightDrawer() {
    return this.showSearchPanel;
  }
  get quickSearch() {
    return this.quickSearchFilter;
  }

  set rightDrawer(_showSearchPanel: boolean) {
    this.showSearchPanel = _showSearchPanel;
  }
  set quickSearch(val) {
    this.quickSearchFilter = val;
    this.quickSearchFilter && this.quickSearchCustomers();
  }

  async created() {
    // customerModule.getAllCustomers();
    const results = await customerModule.getAllTeachers();

    console.log('[TeacherList] created -> ', results)
    // redirect to dash for unathorized after sweet alert

    
    alert('See console for results from customerModule.getAllTeachers')
    console.log('API -> ', process.env.VUE_APP_API_URL)

  }

  mounted() {
    console.log('BASE -> ', process.env.VUE_APP_BASE_URL)
  }
}
