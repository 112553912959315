<template>
  <!-- <v-row justify="center"> -->
    <v-dialog :value="active" persistent max-width="340px" style="border-radius:20px;">
      <!-- <template v-slot:activator="{ on }">
        <v-btn outlined color="teal lighten-3" dark v-on="on">OKzz</v-btn>
      </template> -->
      {{ studentPresent }}
      <v-card style="padding-top:25px;">
        <v-card-text>
        <v-card-subheader >
          <h3 class="" style="margin-bottom:20px;">Please select an outcome for this class and submit</h3>
        <!-- </v-card-title> -->
        </v-card-subheader>
            <v-radio-group v-if="studentsPresent === false"
              column
            >
              <v-radio v-for="(option,i) in options" v-bind:key="i"
                :label="option"
                :v-model="selectedOutcome = option"
                @change="updateOutcomeSelection(i,option)"
              ></v-radio>
              

            
                    <!-- <v-radio
                      label="Last Minute Cancellation"
                      value="true"
                      v-model="selection"
                      @change="updateOutcomeSelection(index,false)"
                    ></v-radio>
                     <v-radio
                      label="Postponed by Teacher"
                      value="true"
                      v-model="selection"
                      @change="updateOutcomeSelection(index,false)"
                    ></v-radio>
                     <v-radio
                      label="Postponed by Student"
                      value="true"
                      v-model="selection"
                      @change="updateOutcomeSelection(index,false)"
                    ></v-radio> -->
            </v-radio-group>
             <v-radio-group v-else
              column>
               <v-radio
                label="Class Done" 
                :v-model="selectedOutcome = 'Done'"
                @change="updateOutcomeSelection(null,'Done')"
              ></v-radio>
            </v-radio-group>
            
          <!-- <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item in options"
                  :key="item"
                >
                  <td>{{ item }}</td>
                  <td><v-checkbox v-model="selection"></v-checkbox></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table> -->
          <v-spacer></v-spacer>
          <v-divider></v-divider>
           <v-card-actions >
            <!-- Need function to filter through students and return boolean if ANY attendanceSubmitted = false -->
            <v-btn color="warning" @click="submit('cancel')">Cancel</v-btn>
            <v-btn color="primary" @click="submit('confirm')">Submit</v-btn>
          </v-card-actions>
        </v-card-text>
        <!-- <v-checkbox></v-checkbox> -->
      </v-card>
    </v-dialog>
  <!-- </v-row> -->
  
</template>

<script>
// import { defineComponent } from '@vue/composition-api'

export default ({
  name: "outcome",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    studentsPresent: {
      type: Boolean,
    },
  },
  data() {
    return {
      // outcomeDialog: false,
      selection: null,
      options: [ 
        'Last Minute Cancellation',
        'Pt',
        'PS',
        'NS', 
      ],
      selectedOutcome: null,
      
    }
  },
 
  methods: {
 
    submit(buttonType){
      alert(`type -> ${buttonType}`)
      if(buttonType === 'cancel'){
        this.$emit('close', this.selectedOutcome = null)
      }
      console.log('Outcome choice ->  ', JSON.stringify(this.selectedOutcome))
      this.$emit('close', this.selectedOutcome)
      this.selectedOutcome = null
    },
    updateOutcomeSelection(i,o){
      // alert(`in updateOutcomeSelection func :: ${JSON.stringify(o)}`)
      this.selectedOutcome = o
      console.log('Sout ', JSON.stringify(this.selectedOutcome))
      // this.$emit('close', o)
    }
  },

  setup() {},

})
</script>

<style scoped>

</style>
