<template>
  <div>
    <div v-show="event.outcome !== 'EMPTY'">
      <!-- DEBUGGING - check two count values -->
      <!-- {{ event.studentCount }}
      {{ event.attendancesCount }}  -->

      <v-col style="padding: 0px;margin-top: 0px;">
        <div
        :class="[
        'ma-1 d-flex align-center',
          {
            'done-outcome': event.outcome === 'DONE',
            'poi-outcome': event.outcome !== 'DONE'
          }
        ]"
        :style="getOutcomeStyle(event.outcome)"
        >
          <v-icon :style="{ color: getIconColor(event.outcome) }">
            {{ getIcon(event.outcome) }}
          </v-icon>

          <span style="line-height: 22px;
            margin-left: 12px;
            margin-top: 2px;
            color: #686868;
            font-size: 16px;">
            {{ event.status }} 

            <!-- DEBUGGIN ONLY -->
            <!-- {{ event }}  -->

          </span>
          <!-- <span v-if="event.status === 'Class done' && event.attendances.length === 0 || event.status === 'Class done' && event.attendances.length < event.registrations.length" style="margin-left: 12px;color: #686868;font-size:15px;font-weight: 400;" > -->
          
          <!-- ADDITIONAL NOTE TO TELL TEACHER ATTENDANCE STILL MAY NEED TO BE COMPLETED FOR SOME CLASSES WITH 'Class done' status -->
          <span v-if="event.status === 'Class done' && event.studentCount > event.attendancesCount" style="margin-left: 12px;color: #686868;font-size:15px;font-weight: 400;" >
            <!-- {{  checkAttendanceRegistrationsEquality }} -->
            <i><b>(attendance report required)</b></i>
          </span>
        </div>
      </v-col>
    </div>
    <!-- <div v-show="event.outcome === 'EMPTY' || event.outcome !== 'DONE'"
      :class="[
        'ma-1 d-flex align-left',
      ]" style="    padding: 6px 11px;
      margin-top: 4px;
      margin-left: 0px;
      background-color: rgb(236, 235, 235);
      color: #858585;">
        Class outcome pending  {{  event.outcome }}
    </div> -->
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'OutcomeStatus',
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  async created() {
    // const count = await this.checkAttendanceRegistrationsEquality()
    console.log('outcome created..', )
  },

  computed: {
  checkAttendanceRegistrationsEquality() {
    return this.performCheck();
    }
  },

  methods: {
    async classOutcomeDone() {
      if(this.event.status === 'Class done') {
        return false
      } else {
        return true
      }
    },

    async performCheck() {
      const attCount = await this.event.attendancesCount.length()
      const studentCount = await this.event.studentCount.length()
      const allStudentsAttendanceComplete = await attCount !== studentCount
      return allStudentsAttendanceComplete
    },

    getOutcomeFullText(abbreviation) {
      switch (abbreviation) {
        case 'POI':
          return 'Postponed by instructor';
        case 'POS':
          return 'Postponed by student';
        case 'DONE':
          return 'Done';
        // Add more cases as needed
        default:
          return abbreviation; // Return the original text if no match is found
      }
    },

    getOutcomeStyle(outcome) {
      switch (outcome) {
        case 'DONE':
          return {
            background: '#34ddcf69',
            padding: '6px 11px',
            borderLeft: '3px solid rgb(62 137 88)'
          };
        case 'POI':
          return {
            background: '#ff7c001c',
            padding: '6px 11px',
            borderLeft: '3px solid #faaf15'
          };
        default:
          return {
            background: '#ff7c001c',
            padding: '6px 11px',
            borderLeft: '3px solid #faaf15'
          };
      }
    },

    getIconColor(outcome) {
      switch (outcome) {
        case 'DONE': return 'rgb(62 137 87);';
        case 'POI': return '#fe6e09';
        default: return '#fe6e09';
      }
    },

    getIcon(outcome) {
      switch (outcome) {
        case 'DONE': return 'mdi-check-circle';
        case 'POI': return 'mdi-alert';
        default: return 'mdi-alert';
      }
    },
  }
};
</script>

<style scoped>
.done-outcome {
  background: rgb(155 224 164);
  padding: 6px 11px;
  border-left: 3px solid rgb(75 159 75);
}

.done-outcome span {
  color: rgb(154 223 163 / 43%);
}

.nss-outcome {
  background: rgb(67 172 187 / 24%);
  padding: 6px 11px;
  border-left: 3px solid rgb(75 159 75);
}
.nss-outcome span {
  color: #8f0565;
}
</style>