<template>
  <v-container>
    <v-flex xs12>
      <v-row style="align-items:center;margin-bottom:12px;" no-gutters>  
        <h1 class="page-title" style="color:#565656;">Hours & Pay</h1>
        <small>(in devevlopment)</small>
      </v-row>

      <v-card>
        <v-tabs
            v-model="tab"
            icons-and-text
        >
          <v-tab href="#hours">
              Submit Hours
              <v-icon>mdi-calendar-clock</v-icon>
          </v-tab>
          <v-tab href="#pay">
              Pay Request
              <v-icon>mdi-currency-usd</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item :key="1" value="hours">
            <v-card flat class="form-card">
              <v-card-text>
                <v-select
                  :items="select"
                  label="Select a hours category"
                  item-value="text"
                  v-model="selectedPayCategory"
                ></v-select>
                <v-select
                  :items="selectPayCategory"
                  label="Select hours category"
                  single-line
                ></v-select>
                <v-date-picker v-model="date"></v-date-picker>
                <br></br>

                <label for="time" class="form-label">Start Time:</label>
                <input type="time" id="start" class="form-input" name="start">
                <br></br>

                <label for="time" class="form-label">End Time:</label>
                <input type="time" id="end" class="form-input" name="end">
              </v-card-text>
              <v-card-actions>
                <v-btn class="btn"
                  @click="submitHours()"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>

          <v-tab-item :key="2" value="pay">
            <v-card flat>
                <v-card-text>Pay request form to come...</v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-flex>

  </v-container>

</template>

<script>
  import Datepicker from 'vue2-datepicker';
  // import { submitNonTeachingHours } from '@/utils/fm-api'; 
  // import moment from 'moment';
  import { format, formatDistance, formatRelative, subDays } from 'date-fns';
  import Vue from 'vue';
  import Tab from '@/components/Tab'
  import Tabs from '@/components/Tabs'

  export default {
    components: {
      Datepicker,
      Tab,
      Tabs
    },
    data () {
      return {
        tab: "hours",
        selectPayCategory: '',
        date: '',
        dialog: false,
        dialog2: false,
        dialog3: false,
        notifications: false,
        sound: true,
        widgets: false,
        items: [
          {
            title: 'Click Me',
          },
          {
            title: 'Click Me',
          },
          {
            title: 'Click Me',
          },
          {
            title: 'Click Me 2',
          },
        ],
        select: [
          { text: 'Class Preparation: ACA training taken' },
          { text: 'Administration: Client management & sales' },
          { text: 'Administration: HR & teacher training given' },
          { text: 'Administration: Learning & Development' },
          { text: 'Administration: Evaluations' },
          { text: 'Administration: Content creation & Development' },
          { text: 'Administration: Progress reports' },
          { text: 'Administration: camp management' },
          { text: 'Other: Vacation pay ($)' },
        ],
      }
    },
    methods: {
      submitHours(){
        const start = document.getElementById('start').value
        const end = document.getElementById('end').value
        const date = document.getElementById('date').value
        const category = this.selectedPayCategory

        // const d = new Date(date);
        // const d = moment(date).format('DD/MM/YYYY');
        console.log('MODATE -> ', date)

        // const fDate = new Date(Date.UTC(date));
        // const fDate = format(new Date(date), 'yyyy/dd/MM');

        const fDate = format(new Date(date), 'MM/dd/yyyy'); // dd-mm-yyyy 
        // const fDate2 = format(fDate, 'MMMM dd, yyyy');

        console.log('date 1 fns -> ', fDate);
        // => "03/17/2020"

        // console.log('date 2 -> ', fDate2);
        // => "March 17, 2020"

        const hoursObj = {
          date: fDate,
          start: start,
          end: end,
          category: category
        }


        console.log('Hours as FM object... -> ', hoursObj)

        submitNonTeachingHours("submitNonTeachingHours", hoursObj).then(res => {
          console.log('Hours[submitHours] response -> ', res)
        })
      }
    }
 
  }
</script>

<style scopde>
  body { padding-top: 40px; }
  .form-input {
    border: 1px solid #b9b6b6;
    margin-left: 12px;
    padding: 4px 10px 4px 12px;
  }
  .form-label {
    font-size: 17px;
  }
  .form-card { padding: 20px; }
  .btn { color: #847d7d; }
</style>