
<template>
  <v-container style="margin-top:-20px">
    <v-flex xs12 lg11 mx-auto>
      <!-- POSTPONE / CANCEL OPTION FORMS -->

<template>
  <v-app>
    <v-container fluid>
      <v-row justify="center">
        <v-col v-for="(card, index) in formCards" :key="index" :class="getCardClasses(index)">
          <v-slide-x-transition mode="out-in">
            <v-card class="transition-card">
              <v-card-title>Form Section {{ index + 1 }}</v-card-title>
              <v-card-text>
                <!-- Customize your form inputs for each card -->
                <template v-if="index === 0">
                  <!-- v-select for the first card -->
                  <v-select v-model="formData[index].selectedOption" :items="options" label="Select an option"></v-select>
                </template>
                <template v-else-if="index === 1">
                  <!-- v-radio-group for the second card -->
                  <!-- <v-radio-group v-model="formData[index].selectedRadio" row>
                    <v-radio label="Reschedule" value="reschedule"></v-radio>
                    <v-radio label="Add to end of session" value="add-to-end"></v-radio>
                    <v-radio label="Do not know" value="unknown"></v-radio>
                  </v-radio-group> -->

                  <v-radio-group v-model="ppRadioSelection" v-if="formData[0].selectedOption === 'Postponed by Teacher' || formData[0].selectedOption === 'Postponed by Student'">
                    <div style="display: flex;flex-direction: row;align-items: baseline;">
                      <v-radio class="pp-radio" label="Reschedule" value="reschedule"
                        @change="ppRadioSelection = 'reschedule'"></v-radio>
                      <v-radio class="pp-radio" label="Add to the end" value="add"
                      @change="ppRadioSelection = 'add'"></v-radio>
                      <v-radio class="pp-radio" label="Don't know" value="unknown"
                      @change="ppRadioSelection = 'unknown'"></v-radio>
                    </div>
                </v-radio-group>

                <v-row v-else>
                  <div style="display: flex;flex-direction: row;align-items: baseline;">
                    Class will be cancelled and not rescheduled. 
                  </div>
                </v-row>
                </template>
                <!-- Add more form inputs as needed -->
              </v-card-text>
              <v-card-actions>
                <v-btn v-if="index > 0" @click="prevCard">Back</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="nextCard">{{ index === formCards.length - 1 ? 'Submit' : 'Next' }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-slide-x-transition>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>




      <section class="form-header">
        <h3>Postpone/Cancel Class</h3>
        <small style="color: #6f6f6f;font-size: 16px;">
          Use this form only if a class did not/will not take place. 
        </small>
      </section>

      <section>
        <v-card
            class="flex-wrap" 
      
            style="padding: 10px;
            margin-bottom:0px" 
            elevation="2"
        >
          <v-flex xs12>
            <div style="background-color: rgb(255, 255, 255);
              padding: 20px;"
            >
          <div style="margin-bottom: 20px;">
            <h2>Postpone and Cancellation Options</h2>
          </div>
            <template>
              <v-select
                v-model="selectedPPCancelOption"
                :items="PPCancelOptions"
                label="Please select an option to continue"
                outlined
                dense
              ></v-select>
            </template>
            



                    <!-- <template>
                          <div class="rescheduleOptionButtons">
                            <v-btn
                              color=""
                              outlined
                              text
                              @click="ppClick()"
                            >
                              Postpone Class
                            </v-btn>
                            <v-btn
                              text
                              color=""
                              outlined
                              @click="cancelClassClick()"
                            >
                              Cancel Class
                            </v-btn>
                          </div>
                        </template> -->


              <section class="ppCancelForm" v-if="showCancelForm">
                <h2 style="background-color: #eef2f6;
                  padding: 20px;
                  color: #6f6f6f;
                  margin-bottom: 40px;">Cancellation Form
                </h2>
                <p>
                  Please select a cancellation option from the list
                </p>
                <v-select
                    :items="cancelSelectOptions"
                    v-model="cancelSelectChoice" label="Please selection from the list of options" outlined>
                  </v-select>
              </section>
                    
            </div>
          </v-flex>
        </v-card>

        <v-card style="margin-top: 20px" elevation="2"  
          v-if="selectedPPCancelOption === 'Postponed by Teacher' || selectedPPCancelOption === 'Postponed by Student'">
          <section class="ppCancelForm">                
              <section style="background-color: rgb(235, 235, 235);margin-bottom: 20px;">
                <h3>I want to</h3>
                <v-radio-group v-model="ppRadioSelection">
                  <div style="display: flex;flex-direction: row;align-items: baseline;">
                    <v-radio class="pp-radio" label="Reschedule" value="reschedule"
                      @change="ppRadioSelection = 'reschedule'"></v-radio>
                    <v-radio class="pp-radio" label="Add to the end" value="add"
                    @change="ppRadioSelection = 'add'"></v-radio>
                    <v-radio class="pp-radio" label="Don't know" value="unknown"
                    @change="ppRadioSelection = 'unknown'"></v-radio>
                  </div>
                </v-radio-group>
              </section>

              <!-- <div v-if="postponeSelectChoice === 'Choose another date'"> -->
              <div style="min-height:;padding: 0px;
                background: rgb(255, 255, 255); border-radius: 4px" v-show="ppRadioSelection === 'reschedule'">

                  <!-- Choose a new date and time  -->
                  <template>
                    <div style="min-height: 300px">
                      <h3 style="margin-bottom: 20px;letter-spacing: .25px;color: #353535">Please select a new date and/or time for this class</h3>

                      <!-- <v-dialog v-model="modal" max-width="400">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="newDate"
                            :value="newDate"
                            @click="modal = !modal"
                            placeholder="Click here to select a date for this class..."
                            prepend-icon="mdi-calendar"
                          ></v-text-field>
                        </template>

                        <v-card>
                          <v-card-title class="headline">
                            Select a Date
                          </v-card-title>

                          <v-card-text>
                            <v-date-picker v-model="newDate"></v-date-picker>
                          </v-card-text>

                          <v-card-actions>
                            <v-btn text @click="modal = false">Cancel</v-btn>
                            <v-btn text @click="onDateSelected">OK</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog> -->

                      
                      <!-- <date-picker v-model="time1" valueType="format"></date-picker> -->

                      <section style="padding: 10px 20px;">
                        <date-picker v-model="time1" type="date" placeholder="Date"
                          style="margin-right: 30px;"></date-picker>
                        <date-picker
                            v-model="time2"
                            :time-picker-options="{
                              start: '08:30',
                              step: '00:30',
                              end: '18:30',
                            }"
                            format="hh:mm a"
                            type="time"
                            placeholder="Time"
                          ></date-picker>
                      </section>  

                      <br/>

                      
                      <!-- <small>Check to make sure the date is not on or before the current date and within a certain range (a limited time into the future).</small> -->
                    </div>
                  </template>

              </div>

              <div v-if="postponeSelectChoice === 'Add class to the end of the session'">
                This class will be rescheduled to take place on xx/xx/xxxx at xx:xx
              </div>

              <br>
              <v-row style="justify-content: end;padding: 0px 12px 20px">
                <v-btn v-show="ppRadioSelection === 'byTeacher'" @click="submitNewDate()">Submit</v-btn>
              </v-row>
          </section>
        </v-card>

        <v-card style="margin-top: 20px" elevation="2">
          <section style="padding: 10px 20px;">
            <h4 v-if="showNewDateString">
              <span style="color: #353535">
                This class will be re-scheduled to take place on 
              </span>
              <u>{{ formatNewDate()['date'] }} at {{ formatNewDate()['time'] }}</u>
            </h4>
          </section>
          <section style="padding: 20px;display: flex; justify-content: end;">
            <v-btn color="primary" @click="submitNewDate()">Send</v-btn>
          </section>

        
        </v-card>
      </section>

  
    </v-flex>
    </v-container>
  </template>
  
  <script>
  import { userModule } from "@/store/modules/user";
  import axios from 'axios'
  import { getFMToken, getSeachFilters } from '@/utils/app-util';
  import { checkFMToken } from '@/utils/fm-api'; // Custom API MODULE
  import router from '@/router';
  
  export default {
     data() {
      return {

        currentCard: 0,
        formCards: [0, 1, 2], // Add more numbers if you have additional sections

        formData: Array(3).fill({ field1: '', field2: '' }), // Initialize with empty data

       message: 'The postpone page',
       options: [
          'Postponed by Teacher',
          'Postponed by Student',
          'Cancelled (more than 24 hours in advance)',
          'Last Minute Cancellation',
          'No Show',
        ],
        ppRadioSelection: null,
      }
    },
    async mounted () {
     
    },
    methods: {

      getCardClasses(index) {
        return {
          'transition-card': true,
          'd-none': this.currentCard !== index,
          'slide-in-left': this.direction === 'prev' && this.currentCard === index,
          'slide-in-right': this.direction === 'next' && this.currentCard === index,
        };
      },

      nextCard() {
      if (this.currentCard < this.formCards.length - 1) {
        this.currentCard++;
      }
      },
      prevCard() {
        if (this.currentCard > 0) {
          this.currentCard--;
        }
      },
      
    }  
  }
  </script>
<style>


.transition-card {
  position: relative;
  transition: transform 0.5s;
}

.slide-in-left-enter-active,
.slide-in-right-enter-active,
.slide-in-left-leave-active,
.slide-in-right-leave-active {
  transition: transform 0.5s;
}

.slide-in-left-enter, .slide-in-right-leave-to {
  transform: translateX(100%);
}

.slide-in-right-enter, .slide-in-left-leave-to {
  transform: translateX(-100%);
}



</style>







  