<template>
  <v-container>
    <v-row align="center" justify="center" dense>
      <v-col cols="12" sm="8" md="4" lg="7">
        <v-card elevation="2" style="padding: 40px;margin-top: 80px">
          <a href="#">
            <v-img src="@/assets/img/small-aca.jpeg" alt="vuetify components logo" contain height="120" />
          </a>
          <div class="text-center" style="color: #696666;margin-bottom: 30px;">
            <h1 class="mb-2" style="color: #0b346fdb;">Forgot / Reset Password</h1>
            <p>Enter your ACA email address to receive a password reset link.</p>
          </div>
          <div v-if="goodEmail">
            <v-alert type="success" @input="resetAlert">
              <h4>
                Please check your email for a password reset link.            
              </h4>
            </v-alert>
          </div>
          <div v-if="badEmail">
            <v-alert type="warning" @input="resetAlert">
              <h4>
                Sorry, an error occured with your request. Please contact ACA for assistance.            
              </h4>
            </v-alert>
          </div>
          <v-form v-if="showForm">
            <v-text-field 
        
              label="Your ACA email"
              filled
              name="email"
              v-model="email"
              type="email"
              required
              :error-messages="emailErrorMessage"
              :error="invalidEmail"
              @input="validateEmail"
            ></v-text-field>
            <div v-if="invalidEmail" class="text-red" style="margin-bottom: 20px;">
              Please enter a valid email address.
            </div>
            <!-- <v-text-field
              label="email"
              name="email"
              v-model="email"
              type="email"
              class="rounded-0"
              outlined
              ></v-text-field> -->
              <v-btn class="rounded-0 forgot-btn" color="#023d7b" x-large block dark @click="checkAndResetPassword()">Send</v-btn>
          </v-form>
          <div v-if="showForm" style="display:flex;justify-content:end;margin-right:9px;">
            <a style="text-decoration: none;" href="/login" target="_self">Return to login</a>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      badEmail: false,
      goodEmail: false,
      showForm: true,
      emailErrorMessage: '',
      invalidEmail: false,   
    };
  },
  async created() {
  
    },
    methods: {
      validateEmail() {
        // console.log('email check ', this.email)
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        this.invalidEmail = !emailRegex.test(this.email);
      },

      checkAndResetPassword() {
        const url = process.env.VUE_APP_API_URL + '/forgot'
        console.log('URL TRAX', url)
        console.log('Email reset : ', this.email);
      
        if (this.invalidEmail) return;

          // if (!this.isEmailValid(this.email)) {
          //   this.email = ''
          //   this.badEmail = true;
          //   return;
          // }

        // Use the FM API for Reset here
        try {
          axios.post(process.env.VUE_APP_API_URL + '/forgot', { email: this.email })
            .then((response) => {
              // Check response for valid message 
              console.log('Response from forgot password -> ', response);
              if (response.data.status !== 'OK' || response.data.name === 'Error') {
                console.log('BAD1...', response)
                this.badEmail = true;
                this.showForm = false;
                this.email = '';
                return;
              } 
              this.goodEmail = true;
              this.showForm = false;
              this.email = '';
            });
            // check the server for error using a valid email ...
        } catch (error) {
          console.log('Error validating token ', error);
        }
      },
      // Function to validate email format
      // isEmailValid(email) {
      //   const emailRegex = /.+@acaprogramming\.com$/;
      //   return emailRegex.test(email);
      // },
      resetAlert() {
        this.badEmail = false;
        this.goodEmail = false;
      }
    }
};
</script>

<style scoped>
button,
input {
  display: block;
  margin-bottom: 10px;
}
.forgot-btn {
  color: white !important;
  /* background-color:blue !important; */
  /* From Mindi */
  /* background-image: linear-gradient(to bottom,#3247a7,#4432a7); */
  /* background-image: linear-gradient(to bottom,#1483e3,#2e40c4); */
  background-image: linear-gradient(to bottom,#2588df,#013b81);;
  max-width: 100%;
  margin-top:30px;
}

#alert {
  color: red;
  margin-bottom: 10px;
}

.text-red {
  color: red;
  margin-top: -24px;
}
</style>
