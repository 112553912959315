<!-- Display contract details in the schedule tab in attendance.vue  -->
<template>
  <v-card elevation="2" style="margin: 0px;">
    <v-card-title id="contract-card-title" style="">
      {{ $t('detail.scheduleTab.title.contractDetails') }}
    </v-card-title>
    <v-card-text>
      <v-row style="justify-content: space-around;margin-bottom: 20px;">
        <v-col class="" cols="12" md="5" style="font-size: 16px;padding:10px 25px;">
          <!-- <pre>{{ eventData }}</pre> -->
          <div class="contract-item">
            <span style="color: black;">
              {{ $t('detail.scheduleTab.contractListLabel.course') }}:
            </span>
            <span>
              {{ eventData.type || 'No class type' }}
            </span>
          </div>
          
          <div class="contract-item">
            <span style="color: black;">
              {{ $t('detail.scheduleTab.contractListLabel.client') }}:
            </span>
            <span>
              {{ eventData.clientName || 'No client name' }}
            </span>
          </div>

          <div class="contract-item">
            <span style="color: black;">
              {{ $t('detail.scheduleTab.contractListLabel.language') }}: 
            </span>
            <span>
              {{ eventData.classLanguage || 'No language specified' }}
            </span>
          </div>

          <div class="contract-item">
            <span v-if="eventData.registrations.length === 1" style="color: black;">
              {{ $t('detail.scheduleTab.contractListLabel.student') }}:
            </span>
            <span v-else style="color: black;">Group #: </span>
            <span>
              {{ eventData.groupName || 'No group name' }}
            </span>
          </div>

          <div class="contract-item">
            <span style="color: black;">
              {{ $t('detail.scheduleTab.contractListLabel.level') }}:
            </span>
            <span>
              {{ eventData.registrations[0].level || 'No level' }}
            </span>
          </div>

          <div class="contract-item" v-if="eventData.virtualAccount">
            <span style="color: black;">Program: </span>
            <span>
              {{ eventData.virtualAccount || 'No virtual account' }}
            </span>
          </div>
        </v-col>

        <v-col cols="12" md="5" style="font-size: 16px;padding:0px 25px;">
          <div class="contract-item">
            <span style="color: black;">
              {{ $t('detail.scheduleTab.contractListLabel.session') }}:
            </span>
            <span>
              {{ eventData.session }}
            </span>
          </div>

          <div class="contract-item">
            <span style="color: black;">
              {{ $t('detail.scheduleTab.contractListLabel.day') }}: 
            </span>
            <span>{{ eventData.defaultSchedule[0].days }}</span>
          </div>

          <div class="contract-item">
            <span style="color: black;">
              {{ $t('detail.scheduleTab.contractListLabel.time') }}:
            </span>
            <span>{{ formatTimeAMPM(eventData.startTime, eventData.endTime) }}</span>
          </div>

          <div class="contract-item" v-show="eventData.unscheduledHours !== 0">
            <span style="color: black;">
              {{ $t('detail.scheduleTab.contractListLabel.cutOffDate') }}:
            </span>
            <span v-if="eventData.cutoffDate !== ''">
              {{ eventData.cutoffDate }}
            </span>
            <span v-else>
              {{ $t('detail.scheduleTab.contractListValue.noCutOffSet') }}
            </span>
          </div>

          <div class="contract-item">
            <span style="color: black;">
              {{ $t('detail.scheduleTab.contractListLabel.total') }}:
            </span>
            <span>22.5 hours</span>
          </div>

          <div class="contract-item" v-show="eventData.unscheduledHours !== 0">
            <span style="color: black;">
              {{ $t('detail.scheduleTab.contractListLabel.unscheduledHours') }}:
            </span>
            <span>{{ eventData.unscheduledHours }} hrs</span>
          </div>
        </v-col>
      </v-row>
      <v-row  v-if="eventData.unscheduledHours === 0 || eventData.unscheduledHours === ''">
        <!-- {{ this.eventData.unscheduledHours }} -->
        <v-col class="styled-col">
          <strong>
            <!-- Current end date subject to change: -->
            {{ $t('detail.endSessionDate') }}
          </strong>  
            {{ formatEndSessionDate(eventData.classEnd ) }}
          <!-- <end-session :class-end="eventData.classEnd"></end-session> -->
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import EndSession from './EndSession.vue'; 
import moment from 'moment';

export default {
  name: 'ContractDetails',
  props: {
    eventData: {
      type: Object,
      required: true
    },
      // Register the imported component
    components: {
      EndSession
    }
  },
  methods: {
    formatEndSessionDate(date) {
      return moment(date, 'MM/DD/YYYY').format('MMMM Do, YYYY');
    },
    formatTimeAMPM(startTime, endTime) {
      function to12HourFormat(time24) {
        const [hour, minute] = time24.split(':').map(Number);
        const period = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        return `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
      }

      function calculateDuration(start, end) {
        const [startHour, startMinute] = start.split(':').map(Number);
        const [endHour, endMinute] = end.split(':').map(Number);

        let hours = endHour - startHour;
        let minutes = endMinute - startMinute;

        if (minutes < 0) {
          minutes += 60;
          hours -= 1;
        }
        return { hours, minutes };
      }

      const startFormatted = to12HourFormat(startTime);
      const endFormatted = to12HourFormat(endTime);

      const duration = calculateDuration(startTime, endTime);
      const durationString = `${duration.hours}hr${duration.hours !== 1 ? 's' : ''}${duration.minutes > 0 ? ` ${duration.minutes}min` : ''}`;

      return `${startFormatted} - ${endFormatted} (${durationString})`;
    },
  }
}
</script>

<style scoped>

.styled-col {
  background-color: #f0f0f0; 
  text-align: center;       
  width: 100%;             
  padding: 10px;  
  font-size: 16px;
  margin-bottom: 9px;
  margin-left: 52px;
  margin-right: 52px;          
}

.contract-item {
  margin-bottom: 10px;
}
/* Add any scoped styles here */
#contract-card-title {
  font-size: 22px;
  align-self: self-start;
  justify-content: start;
  padding-top: 17px;
  /* background: rgb(46 120 178 / 93%); */
  background: rgb(59 117 200);
  margin-bottom: 33px;
  color: aliceblue;
}
</style>
