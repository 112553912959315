<template>
  <v-container style="margin-top: 60px">
  <!-- <div style="margin-top: 100px;">Payroll</div> -->
   <!-- {{  eventData['data'][0] }} -->
    <div class="page-title-wrapper" style="border-radius: 12px;margin-bottom: 24px;">
        <div class="page-header">
          <h2 style="font-weight:100;">
            <!-- {{ $t('title.timesheet') }} -->
            {{ $t('sideNav.payroll') }} 
          </h2>
          <span style="flex-direction: row;font-weight: 500;color: #f6fdfe;">
            <!-- {{ formatDate(currentDate) }} -->
            {{ currentDate }}
          </span>
        </div>
      
    </div>

    <!-- <div class="section-title">
      <h2 class="secondary-title" style="font-size: Roboto;">Timesheet</h2>
      <h2 style="font-weight: 300;">{{ payPeriod }}</h2>
    </div> -->
    <div v-if="true">
        <div class="ts-tabs" style="margin-top:0px;margin-bottom: 40px;">
          <div class="tab-links">
            <span v-for="(tab, index) in this.tabs" :key="index" class="tab"  
              style="margin-left: 0px;
                font-weight: 600;
                font-size: 18px;"
              :class="{active: selected === tab}" 
              @click="selected = tab">
                <!-- <span>{{ tab }}</span> -->
                Week 
                <span class="weekNum" :class="getWkNumBgColor(tab)">
                  {{ tab }}
                </span>
            </span>
          </div>

          <div class="tab-content">

            <div class="weekOne custom-table" v-show="this.selected === '1'">
              <!-- <span style="background-color: yellow;padding:5px"><b>Selected Week: </b></span>
              <span style="background-color: white;padding:5px">September 03 - September 10</span> -->
              <!-- add table here with filter to return only dates within the selected week -->
              <!-- {{ eventData[0].fieldData }} -->

              <!-- <simple-table :eventData="weekOneClasses2" :week="'1'" :selectedWeek="weekOneRange"
                class="custom-table"  @edit="goToClass"></simple-table> -->
                <!-- {{ weekOneRange }} -->

                <payroll-table v-if="weekOneClasses2.length > 0" :eventData="weekOneClasses2" :week="'1'" :selectedWeek="weekOneRange"
                class="custom-table"  @edit="goToClass"></payroll-table>
                <div v-else class="alert alert-info" role="alert">
                  <h2 class="alert-heading">No Payroll information found</h2>
                  <p>No classes scheduled for the selected period.</p>
                  <hr>
                  <!-- <p class="mb-0">Please choose a different period or check your schedule.</p> -->
                </div>
              <!-- <ul>
                <li v-for="(event,index) in this.twoWeekPeriod" :key="index" v-show="event < '2023-09-13'">
                  {{ event }}
                </li>
              </ul> -->
              <!-- <v-card class="total-hours-card" elevation="2">
                <v-card-title class="total-hours-title">
                  <div class="title-left">
                    <span class="total-hours-main">Total hourscc for week one: </span>
                  </div>
                  <div class="title-right">
                      <span  style="background-color: ;
                        padding: 9px;
                        color: #6b6b6b;" class="total-hours-sub">
                        15
                      </span>
                  </div>
                </v-card-title>
                <div class="checkbox">
                    <label>
                      <input type="checkbox" v-model="checkOne" @click="hoursChecked()"/>
                      I confirm that my hours are correct for this week.
                    </label> 
                    (1: {{ checkOne }}) 
                </div>
              </v-card> -->
            </div>

            <div class="weekTwo custom-table" v-show="this.selected === '2'">
              <!-- <span style="background-color: white;padding:5px"><b>Selected Week: </b></span>
              <span style="background-color:yellow;padding:5px">September 11 - September 16</span> -->
              <!-- add table here with filter to return only dates within the selected week -->
              <!-- <simple-table :eventData="weekTwoClasses2" :week="'2'"  :selectedWeek="weekTwoRange"
                @edit="goToClass">
              </simple-table> -->

              <!-- {{ weekTwoRange }} -->

                <payroll-table v-if="weekTwoClasses2.length > 0" :eventData="weekTwoClasses2" :week="'2'" :selectedWeek="weekTwoRange"
                  class="custom-table"  @edit="goToClass"></payroll-table>
                  <div v-else class="alert alert-info" role="alert">
                  <h2 class="alert-heading">No Payroll information found</h2>
                  <p>No classes scheduled for the selected period.</p>
                  <hr>
                  <!-- <p class="mb-0">Please choose a different period or check your schedule.</p> -->
                </div>

              
              <!-- <ul>
                <li v-for="(event,index) in this.twoWeekPeriod"  :key="index" v-show="event > '2023-09-13'">
                  {{ event }}
                </li>
              </ul> -->

              <!---- 
                MUST ADD THE TOTAL HOURS FOR EACH WEEK 
                Add splitting the period function to filter the dates on each table
                Dash must be reloaded to get the data back into the hours tab
                Try timesheet submit with data from vue 
                Conditional rendering on actions - edit available if within the 12hr after class OR attendance is incomplete
                Add new column or a highlight message on class rows with incomplete attendance - edge case but must be caught (FM error, Network/Server down)
              -->

            </div>
          </div>
        </div>

        <template>
          <div class="summary-section" style="padding: 5px;">
            <div class="section-title">
              <h2 class="secondary-title" style="color:">Summary</h2>
            </div>

            <v-row cols="12" style="border: 0px solid red;justify-content: space-between;">
              <v-col cols="12" md="6">
                <v-card class="total-hours-card" elevation="3" style="padding: 0px;">
                  <v-card-title class="total-hours-title" 
                    style="padding: 11px 15px;
                      display: flex; 
                      justify-content: space-between;
                      background-color: #ececec;">
                    <span class="summary-card-header">
                      Week 1
                    </span>
                    <span class="summary-card-header">
                      <!-- {{ weekOneRange['start'] }} - {{ weekOneRange['end'] }}   -->
                    </span>
                  </v-card-title> 
                  <v-divider class="summary-card-top-divider"></v-divider>
                  <div class="flex-container">
                    <span class="total-hours-content">Hours Total: </span>

                    <!-- {{ this.weekOneHours }} -->
                    <!-- <v-btn @click="validateHours(totalHours)" class="validate-button" color="#4eb05e" dark> -->
                    <div class="total-hours-figure" style="background-color:;
                      padding: 0px;
                      margin-left: 20px;
                      font-size: 22px;
                      color: #676767;">
                      {{ weekOneHours }}
                      <!-- {{ weekOneClasses2[0].duration }} -->
                    </div>
                    <!-- <div class="total-hours-content">Classes total: </div>
                    <div class="total-hours-content" style="background-color:;
                      padding: 0px;
                      color: #676767;">
                      10
                    </div> -->
                  </div>
                  <!-- <v-divider></v-divider>
                  <v-card-actions style="padding: 0px 14px;display: flex; justify-content: flex-end;">
                    <v-checkbox v-model="wkOneCheckbox">
                      <template v-slot:label>
                        <div style="color: #010101;">
                          I confirm my hours for week one
                        </div>
                      </template>
                    </v-checkbox>
                  </v-card-actions> -->
                </v-card>
              </v-col>

              <v-col cols="12" md="6">
                <v-card class="total-hours-card" elevation="3" style="padding: 0px;">
                  <v-card-title class="total-hours-title" style="padding: 11px 15px;display: flex; justify-content: space-between;
                  background-color: #ececec;">
                    <span class="summary-card-header">
                      Week 2
                    </span>
                    <span class="summary-card-header">
                      <!-- {{ weekTwoRange['start'] }} - {{ weekTwoRange['end'] }}   -->
                        
                    </span>
                        
                  </v-card-title> 
                  <v-divider class="summary-card-top-divider"></v-divider>
                  <div class="flex-container">
                    <span class="total-hours-content">Hours Total: </span>
                    <div class="total-hours-content" style="background-color:;
                      padding: 0px;
                      margin-left: 20px;
                      font-size: 22px;
                      color: #676767;">
                      {{ weekTwoHours }}
                    </div>
                  </div>
                  <!-- <v-divider></v-divider>
                  <v-card-actions  style="padding: 0px 14px;display: flex; justify-content: flex-end;">
                    <v-checkbox v-model="wkTwoCheckbox">
                      <template v-slot:label>
                        <div style="color: #010101;">
                          I confirm my hours for week two
                        </div>
                      </template>
                    </v-checkbox>
                  </v-card-actions> -->
                </v-card>
              </v-col>
            </v-row>
          </div>
          <!-- <div class="button-container" style="margin-right: 4px;">
            Bind the 'disabled' attribute to the computed property 'isSubmitDisabled'
            <v-btn color="#013b83" :disabled="isSubmitDisabled" @click="sendTimesheet" style="font-weight: 600;">Send Timesheet</v-btn>
          </div> -->
        </template>
    </div>

  <!-- <div v-show="true" class="timesheet-message">
    <p class="message-title">
      <span class="checkmark">✓</span> Your timesheets are up-to-date
    </p>
    {{ twoWeekPeriodFromFM }}
    <p class="message-body">
      Your next pay period information will be available on <strong>{{ nextPayPeriodDate }}</strong>.
    </p>
    <div v-for="event in weekOneClasses2">
      {{  event.scheduleDate }}
    </div>
  </div> -->
</v-container>
</template>

<script>

import SimpleTable from '../components/SimpleTable.vue';
import PayrollTable from '../components/PayrollTable.vue';
import { classesModule } from "@/store/modules/classes";
import { getDefaultPagination, getPagination } from '@/utils/store-util';
import { fmTimesheetPost } from '@/utils/fm-api';
import { getLocalStorageValue } from '../utils/localStorageHelper';
import { checkPayPeriods, getClassesInReturnedPayPeriod, separateClassesIntoWeeks } from '../utils/payrollHelper';
import moment from 'moment';
// const { parseISO, addDays, format, isBefore, isAfter } = require('date-fns');

export default {
  components: {
    SimpleTable,
    PayrollTable
  },
  props: {
    checked: Function, // Define a prop to receive the event data array from the parent
    edit: Function, // edit not bubbling up to parent 
    // eventData: Array,
  },

  data() {
    return {
      traxLanguageFromStorage: getLocalStorageValue('fmCommLanguage'),
      classesInPeriod: null,
      eventData: null,
      twoWeekPeriods: [],
      weekOneRange: null,
      weekTwoRange: null,
      weekOneClasses: [],
      weekTwoClasses: [],
      weekOneClasses2: [],
      weekTwoClasses2: [],
      weekOneHours: null,
      weekTwoHours: null,
      tabs: ['1', '2'],
      selected: '1',
      twoWeekPeriod: [
        '2023-09-06',
        '2023-09-06',
        '2023-09-07',
        '2023-09-08',
        '2023-09-08',
        '2023-09-10',
        '2023-09-13',
        '2023-09-14',
        '2023-09-15',
        '2023-09-18',
        '2023-09-18',
        '2023-09-21'
      ],
      checked: null,
      checkOne: false,
      checkTwo: false,
      eventsData: this.eventsData,
      wkOneCheckbox: false,
      wkTwoCheckbox: false,
      
      // API data August 2024
      twoWeekPeriodFromFM: null, 
    }
  },

  computed: {
    isSubmitDisabled() {
      // Button is disabled when both checkboxes are not checked
      return !(this.wkOneCheckbox && this.wkTwoCheckbox); 
    },
  },

  async created() {
    // Pay period request
    this.fetchPayPeriodData()

    // Generating the periods as they will probably be returned from FM
    // Usage
    // const startDate = '2023-12-24';
    // const endDate = '2024-12-24';

    // const twoWeekPeriodsISO = this.generateTwoWeekPeriodsISO(startDate,endDate)
    // console.log(JSON.stringify(twoWeekPeriodsISO, null, 2));
    // console.log(twoWeekPeriodsISO.length);

    

    
    
    
    //////////

    // const evFromStorage = await sessionStorage.getItem('events2')
    // const parsedEvents = await JSON.parse(evFromStorage)

    // this.eventData = parsedEvents
    // console.log('parsed ', parsedEvents)

    // const currentPayPeriod = await this.getPayPeriod()
    // this.payPeriod = currentPayPeriod
    // console.log('Current pay period now:  ', this.payPeriod)

    // await this.createWeeklyRanges()
    // await this.createClassesArraysForEachWeek()
    // await this.calculateHoursForEachWeek()


    // setTimeout(() => {
    //   Swal.fire({
    //       title: 'Session refresh required',
    //       text: 'Click to get the latest schedule information',
    //       icon: 'warning',
    //       confirmButtonText: 'Refresh Session'
    //     }).then((result) => {
    //       // Reload the Page
    //       console.log('Reloading dash...')
    //       location.reload();
    //     });
    // }, 4000)

    // this.currentTime = new Date().toLocaleString()

    // setInterval(() => {
    // // call classes.ts or fm-api.ts direct from here 
    //   this.currentTime = new Date().toLocaleString()
    // }, 1000)

    // Set up dates and date range for app
    const fakeNowDay = moment().format('MM/DD/YYYY')
    console.log('[created] today date ... ', fakeNowDay)

      // check from route - needs fixing
      if(this.adminViewing()) {
        // alert('You are viewing as admin...')
        const teacherInView = this.setViewingAsTeacher()
        // alert(`Viewing -> ${teacherInView['firstname']} as Admin`)
      }

      // MOVE THE ENTIRE CLASSES CHECK AND NEW API CALL LOGIC TO classesModule OR fm-api 
      // this.classesFromStore = await classesModule.getClasses
      // console.log('CFS back from classes.ts -> ', this.classesFromStore)

      // if(this.classesFromStore === 'No classes') {}

      // const newNotes = classesModule.getNotes.map(n => n)
      // this.schedulePagination = getPagination(newNotes) 

      // const signedInAsAdmin = sessionStorage.getItem('isAdmin') 
// 
      // const pAdmin = JSON.parse(signedInAsAdmin)
      // console.log('ADMIN -> ', pAdmin)

      // Avoid repeated API calls if classes already exist in app
      // TODO: schedule updates?? 
      // const eventsLen = classesModule.getClasses
      // console.log('Classes from store -> ', eventsLen)

      // if classes in store - works now but even without classes in storage??
      // alert(`classesModule.getClasses = ${classesModule.getClasses.length}`)

  },

  mounted() {
    this.createDates()
  },

  methods: {

    calculateTotalHours(events) {
      // Function to convert "HH:MM:SS" to hours
      function durationToHours(duration) {
        const [hours, minutes, seconds] = duration.split(':').map(Number);
        console.log('hrs ', hours)
        return hours + minutes / 60 + seconds / 3600;
      }

      // Calculate total hours
      const totalHours = events.reduce((total, event) => {
        if (event.duration) {
          return total + durationToHours(event.duration);
        }
        return total;
      }, 0);

      // Round to one decimal place and format
      return `${totalHours.toFixed(1)} hrs`;
    },
      // PAYROLL API - updated August 2024 for beta live testing starting Sept. 9th 
    async fetchPayPeriodData() {
      console.log('lang ', this.traxLanguageFromStorage)
      this.twoWeekPeriodFromFM = await checkPayPeriods(this.traxLanguageFromStorage)
      console.log('period ', this.twoWeekPeriodFromFM)
      this.fetchClassesInPayPeriodRange()
    },

    async fetchClassesInPayPeriodRange() {
      const classesInPeriod = await getClassesInReturnedPayPeriod(this.twoWeekPeriodFromFM) // takes the returned pay period range returned by FM 
      // console.log('here ', classesInPeriod)
      this.createWeeklyRanges()

      this.classesInPeriod = classesInPeriod
      const separatedClasses = separateClassesIntoWeeks(classesInPeriod, this.twoWeekPeriodFromFM.payPeriod.start);
      console.log('22', separatedClasses)
      this.weekOneClasses2 = separatedClasses.weekOneClasses
      this.weekTwoClasses2 = separatedClasses.weekTwoClasses
      this.calculateHoursForEachWeek()
    },


// You can then use these functions together in your component or wherever needed:
async processClasses(payPeriod) {
  const classes = await getClassesInReturnedPayPeriod(payPeriod);
  const separatedClasses = separateClassesIntoWeeks(classes, payPeriod.payPeriod.start);
  
  // Now you have separatedClasses.weekOneClasses and separatedClasses.weekTwoClasses
  // You can use these as needed in your component
},


generateTwoWeekPeriodsISO(startDateStr, endDateStr) {
  const startDate = moment(startDateStr);
  const endDate = moment(endDateStr);
  const periods = [];

  let currentStart = startDate.clone().startOf('day');
  let currentEnd = currentStart.clone().add(13, 'days').endOf('day');

  while (currentStart.isSameOrBefore(endDate)) {
    if (currentEnd.isAfter(endDate)) {
      currentEnd = endDate.clone().endOf('day');
    }

    periods.push({
      start: currentStart.toISOString(),
      end: currentEnd.toISOString()
    });

    currentStart = currentEnd.clone().add(1, 'day').startOf('day');
    currentEnd = currentStart.clone().add(13, 'days').endOf('day');
  }

  return periods;
},


    createWeeklyRanges() {
      console.log('pre separate ', this.twoWeekPeriodFromFM.payPeriod.start)
      // console.log('period: ', this.payPeriod)

      // Parse the start and end dates
      const startDate = moment(this.twoWeekPeriodFromFM.payPeriod.start);
      const endDate = moment(this.twoWeekPeriodFromFM.payPeriod.end);

      // const [startDateStr, endDateStr] = this.payPeriod.split('-')
  
      // const startDate = moment(startDateStr, 'ddd, MMM DD, YYYY');
      // const endDate = moment(endDateStr, 'ddd, MMM DD, YYYY');

      // Calculate the midpoint date by adding 7 days to the start date
      const midpointDate = startDate.clone().add(6, 'days');
      // const secondPeriodStartDate = startDate.clone().add(7, 'days');

       // Calculate the start of the second week
      const secondPeriodStartDate = midpointDate.clone().add(1, 'day');

      // Split the two-week period into two equal periods
      const firstPeriod = {
        start: startDate.format('ddd, MMM DD, YYYY'),
        end: midpointDate.format('ddd, MMM DD, YYYY'),
      };

      const secondPeriod = {
        start: secondPeriodStartDate.format('ddd, MMM DD, YYYY'),
        end: endDate.format('ddd, MMM DD, YYYY'),
      };

      console.log('week one ', firstPeriod)
      console.log('week two ', secondPeriod)
      this.weekOneRange = firstPeriod
      this.weekTwoRange = secondPeriod
    },

    async createClassesArraysForEachWeek() {

      const wk1 = await this.weekOneRange
      const wk2 = await this.weekTwoRange

      console.log('wkOne :', wk1)
      console.log('wkTwo :', wk2)

      this.weekOneClasses = await this.filterEventsByDateRange(wk1)
      this.weekTwoClasses = await this.filterEventsByDateRange(wk2)
      console.log('wk1 set ', this.weekOneClasses)
      console.log('wk2 set ', this.weekTwoClasses)
      // alert('week events set? ..')

    },

    async calculateHoursForEachWeek() {
      // let wk1 = await this.weekOneClasses
      // let wk2 = await this.weekTwoClasses

      console.log('c ', this.weekOneClasses2)

      this.weekOneHours = this.calculateTotalHours(this.weekOneClasses2)
      this.weekTwoHours = this.calculateTotalHours(this.weekTwoClasses2)

      // this.weekTwoHours = this.calculateTotalHours(this.weekTwoHours)

   
      // this.weekOneHours = await this.weekOneClasses2.reduce((totalDuration, event) => {
      //   // class is paid if - LMC, NS, or Done
      //   // class not paid if - PS, PT, H, Pending
      //   if(event['duration'] && typeof event['duration'] === 'number') {
      //     console.log('condish ', event['status'])

      //     if(event['status'] === 'Done' || event['status'] === 'LMC' || event['status'] === 'NS' ) {  
      //       return totalDuration + event['duration'];     
      //     } else {
      //       return totalDuration
      //     }
      //   }
      //   return totalDuration;
      // }, 0);

      // Inside reduce must move to higher order functions for DRY 
      this.weekTwoHours = await this.weekTwoClasses2.reduce((totalDuration, event) => {
        if(event['duration'] && typeof event['duration'] === 'number') {
          console.log('condish ', event['status'])
          if(event['status'] === 'Done' || event['status'] === 'LMC' || event['status'] === 'NS' ) {  
            return totalDuration + event['duration'];     
          } else {
            return totalDuration
          }
        }
        return totalDuration;
      }, 0);

      // this.weekOneHours = await totalHoursWk1
      // this.weekTwoHours = awaittotalHoursWk2

      console.log('wk one hrs: ', this.weekOneHours)
      console.log('wk two hrs: ', this.weekTwoHours)
      // alert('hours reduced.')
    },

    // Function to filter events within the date range
    filterEventsByDateRange(dateRange) {

      console.log('raw daterange: ', dateRange)
      const startDate = moment(dateRange.start).format('MM/DD/YYYY');
      const endDate = moment(dateRange.end).format('MM/DD/YYYY');

      console.log('ev in filter : ', this.eventData['data'][0])
      // alert('yes look now..')

      return this.eventData['data'].filter(event => {
        // console.log('start: ', startDate)
        // console.log('End: ', endDate)
        const eventDate = moment(event['scheduleDate'], 'MM/DD/YYYY');
        // console.log('FM date ', event['scheduleDate'])
        return eventDate.isBetween(startDate, endDate, null, '[]');
      });
    },

    getSelectedTabWeek(selectedWeek) {

      // if (this.payPeriod) {
      // console.log(this.payPeriod);
      // } else {
      //   if(selectedWeek === 1) {
      //     return firstPeriod
      //   } 
      //   return secondPeriod
      // }

    },
    
    setViewingAsTeacher() {
        const selectedTeacher = sessionStorage.getItem('userFullName')
        // const parsedT = JSON.parse(selectedTeacher)
        // const teacherFullName =  parsedT['firstname'] + ' ' +  parsedT['lastname'] 
        // console.log('[setViewingAsTeacher] ->', teacherFullName)
        this.viewingAsFullName = selectedTeacher
        return selectedTeacher
    },

    // NEEDS REFACTOR TO INCLUDE PAGE REFRESHES WHEN ROUTE FROM IS MISSING
    async adminViewing() {
      const routeFrom = await sessionStorage.getItem('routeFrom') 
      if(routeFrom === 'admin') {
        // alert('viewing as admin...')
        this.viewingAsAdmin = true
        return true
        // make a new FM call for specific teacher data 
      } else {
        this.viewingAsAdmin === false
        return false
        // check the store direct for classes 
      }
    },

    async getCurrentPayPeriod(currentDate, twoWeekPeriods) {
      // alert(`current date in getCurrentPayPeriod = ${JSON.stringify(currentDate)}`)
      // alert(`cd = ${JSON.stringify(currentDate)} , 2Wks = ${JSON.stringify(twoWeekPeriods)}`)

      // const dateToCheck = '2023-03-05';
      const dateToCheck = moment(currentDate);

      for (const period of twoWeekPeriods) {
        const startTime = moment(period.startTime);
        const endTime = moment(period.endTime);

        // Check if the input date is within the period
        if (dateToCheck.isBetween(startTime, endTime, null, '[]')) {
          console.log( 'Found Period ', period)
          // alert('p found')
          return period;
        }
      }

      // console.log('FP -> ', filteredPeriods)

      // if (filteredPeriods.length > 0) {
      //   // If at least one period matches, return the first matching period
      //   return {
      //     startTime: new Date(filteredPeriods[0].startTime),
      //     endTime: new Date(filteredPeriods[0].endTime),
      //   };
      // } else {
      //   alert('nothing')
      // }

      // for (const period of twoWeekPeriods) {
      //   const startTime = new Date(period.startTime);
      //   const endTime = new Date(period.endTime);

      //   if (currentDate >= startTime && currentDate <= endTime) {
      //     return {
      //       startTime,
      //       endTime,
      //     };
      //   }
      // }

      // return null; // Return null if the current date is not within any two-week period
    },

    formatTwoWeekPeriod(period) {
      console.log('formatPayPeriod ', period)
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      };

      const startTimeStr = period.startTime.toLocaleString('en-US', options);
      const endTimeStr = period.endTime.toLocaleString('en-US', options);

      return `${startTimeStr} - ${endTimeStr}`;
    },



    // Calculates the pay periods within the range of start and end dates
    calculateTwoWeekPeriods() {
      // Change for each new pay year - add to admin or see if calculable from FM 
      const startDate = '2023-12-24';
      const endDate = '2024-12-24';

      // Convert start and end dates to JavaScript Date objects
      const start = new Date(startDate);
      const end = new Date(endDate);

      // +1 forwards by one day - JS using zero indexing for dates - ie. 8th will be the 7th
      start.setDate(start.getDate() + 1);
      end.setDate(end.getDate() + 1);

      // Set the initial start date to January 8th, 2023
      let currentStartDate = start;
      console.log('start here ', currentStartDate)

      // Loop through the dates and calculate two-week periods
      while (currentStartDate <= end) {
        const currentEndDate = new Date(currentStartDate);
        currentEndDate.setDate(currentEndDate.getDate() + 13); // Two weeks from the start date

        // Adjust the end date if it goes beyond the end date specified
        const periodEndDate = currentEndDate <= end ? currentEndDate : end;

        this.twoWeekPeriods.push({
          startTime: new Date(currentStartDate),
          endTime: new Date(periodEndDate),
        });

        // Move to the next two-week period
        currentStartDate.setDate(currentStartDate.getDate() + 14);
      }
      return this.twoWeekPeriods;
    },

    // getSelectedTabWeek() {
    //   return 'Sepetember 03 - September 11'
    // },

    async getPayPeriod() {
      const result = this.calculateTwoWeekPeriods();
      // console.log('periods: ', result);
      // This will be the current date 
      const currentDate = new Date();

      // const currentDate = new Date('2023-06-06'); // YYYY-MM-DD test date - dynamically get current date in production
      const currentPeriod = await this.getCurrentPayPeriod(currentDate, result);

      console.log('Current period:', currentPeriod);
      // alert('check period is back..')
   
      this.payPeriod = this.formatTwoWeekPeriod(currentPeriod)
      return this.payPeriod
    },

    getWkNumBgColor(n) {
      console.log(n)
      if(n === '2') {
        return 'wk2Color'
      } else {
        return 'wk1Color'
      }
    },

    async goToClass(e) {
      console.log('Go to class from payroll ', e)
      sessionStorage.setItem('Selected', e)
      alert('item here')
      // alert('event in parent go to class method')
    
      // this is object - must get length
      // console.log('cls -> ', e)
      // console.log('class from dash -> ', typeof(e['portalData']['class_REGISTRATIONS']))

      // const modCheck = classesModule.checkForRecordModifedinFM(e)
      let classEvent = {}
      let color

      // Get name of single student  

      const currentDate = new Date(e['scheduleDate']);
      const dayBeforeDate = new Date(currentDate);
      dayBeforeDate.setDate(currentDate.getDate() - 1);
      console.log(e['scheduleDate']) // eg. 07/03/2023
      console.log('day before ', dayBeforeDate) 
      // alert(`set date to day before to test the attendance lock: ${dayBeforeDate}`)

      const dayLater = new Date(currentDate);
      dayLater.setDate(currentDate.getDate() + 1)
      const twentyFourHoursInMillis = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

      const timeDiff =  dayLater.getTime() - currentDate.getTime()

      let isLocked = false

      // make a fake current date one day before the date of the class
      if (currentDate.getTime() > dayBeforeDate.getTime() || timeDiff >= twentyFourHoursInMillis ) {
        console.log('Class date is before the current date. Attendance access dened.');
        // sessionStorage.setItem('isLocked', JSON.stringify(true));
        sessionStorage.setItem('isLocked', JSON.stringify(false)); // set to false to override deny access

        
        // if(timeDiff >= twentyFourHoursInMillis) {
        //   // sessionStorage.setItem('pastTwentyFour', JSON.stringify(true))
        //   sessionStorage.setItem('pastTwentyFour', JSON.stringify(false)) 

        //   // must set the message in the class detail view 
        //   // this.classLockedMessage = 'Class past 24 hours'
        // } else {
        //   // this.classLockedMessage = 'Class not yet available for attendance'
        // }
      } else {
        console.log('Class date is after the current date. Allow attendance form access.');
      }

      classEvent = { ...classEvent,
        "color": color,  
        "classID": e['__pk_Classes'], 
        "type": e['type'] || 'Data Missing' , 
        "date": e['scheduleDate'], 
        "start": e['startTime'], 
        "end": e['endTime'], 
        "noStudents": e['portalData']['class_REGISTRATIONS'].length, 
        "registrations": e['portalData']['class_REGISTRATIONS'] , 
        "noteToTeacher": e['teacherNotes'], 
        "details": [ { "note": "no notes here" }, { "msg": "hello attendance msg" } ] 
      }

      console.log('class obj ready for att -> ', classEvent)
      sessionStorage.setItem('routeFrom', 'dash')
      classesModule.setIndexForCurrentClass(e)
      console.log('indx of event in calendar', e)

      const fullData = await classesModule.getFullClassData(e['__pk_Classes']) 

      console.log('Full class data [DASH] -> ', fullData)

      const selectedClassFull = fullData

      sessionStorage.setItem('selectedClassFull', selectedClassFull)
      // alert('selectedClassFull set?...')

      // Must set the selected class format to the same as from calendar card 

      await sessionStorage.setItem('selectedClass', JSON.stringify(classEvent))
      this.$router.push({ name: 'attendance'}) 
      // this.$router.push({ name: 'attendance', query: { event: e }})
  },

    // goToClass(i) {
    //   console.log('I in TS ', i )
    //   alert('go clicked in TS..check ')
    //   this.$emit('edit', i);
    // },

    hoursChecked () {
      // alert('working it...')
      if(this.selected === 'Week One') {
        this.checkOne = !this.checkOne
        console.log('1:', this.checkOne)
        console.log('2:', this.checkTwo)
        this.allChecked()
      } else {
        this.checkTwo = !this.checkedTwo
        console.log('1:', this.checkOne)
        console.log('2:', this.checkTwo)
        this.allChecked()
      }
    },
    // STILL A BUG HERE
    // Reproduce: Check both weeks in sequence, then uncheck the second week 
    // BUG -> still says true in parent and falsely sets isHoursValidated = true

    allChecked() {
      // alert('checking if both weeks validated in timesheet vue..')
      if(this.checkOne && this.checkTwo) {
        // alert('both weeks checked by teacher...updating in parent')
        console.log('states: ', this.checkOne - this.checkTwo)
        this.$emit('checked', true);
      } else {
        console.log('states[else]: ', this.checkOne - this.checkTwo)
        this.$emit('checked', false);
        // alert('checked incomplete...isHoursValidated should be false in ClassesCompletedList.vue')
      }

    },

    createDates() { 
      // Define the start and end dates for the two-week period
      const startDate = new Date('2023-09-03');
      const endDate = new Date('2023-09-16');

      // Generate 9 random event dates within the two-week period
      for (let i = 0; i < 9; i++) {
        const randomTime = startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime());
        const randomDate = new Date(randomTime);
        // this.twoWeekPeriod.push(randomDate.toISOString().split('T')[0]);
      }

      // Sort the event dates in ascending order
      //this.twoWeekPeriod.sort();
    },

    sendTimesheet() {
      // fm-api 
      const tsObj = {
        _fk_Teacher: 1,
        periodStartDate: '10/01/2023',
        periodEndData: '10/13/2023'
      }

      fmTimesheetPost("sendTimesheet", tsObj)
      // .then(res => {
      //   console.log(res)
      //   alert('check for recordID in console...')
      // })

    }
  }
}
</script>

<style> 

@import url(https://fonts.googleapis.com/css?family=Lato:400,600,700);
/* @import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap); */


.alert-info {
  color: #0c5460;
  /* background-color: #d1ecf1; */
  background-color: #fff;
  border-color: #bee5eb;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.mb-0 {
  margin-bottom: 0;
}

.timesheet-message {
  background-color: #f0f8ff; /* Light blue background */
  border-left: 4px solid #4caf50; /* Green left border */
  padding: 15px;
  margin: 10px 0;
  border-radius: 4px;
}

.message-title {
  font-size: 18px;
  color: #2c3e50;
  margin-bottom: 8px;
}

.checkmark {
  color: #4caf50;
  margin-right: 8px;
}

.message-body {
  font-size: 16px;
  color: #34495e;
}
.page-header {
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  color: #f6fdfe;
  font-size: 19px;
  margin-right: 0px;
  /* background-color: #013b85; */
  /* background: linear-gradient(43deg, #055ccbc4, #3966ad); */
  background: linear-gradient(43deg, #055ccbc4, #2187d5);
  padding: 11px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
}

.weekNum { 
    padding: 0px;
    color: rgb(255 255 255);
    padding: 3px 8px;
}

.ts-tabs { border: 5px solid #c1d7ea; }
.tab {margin-right: 0px; padding: 23px;
  background-color: #c1d7ea; color: #456674; }
.tab-links { border: 0px solid grey;padding: 20px 0px;margin-bottom:  0px;background-color: #c1d7ea;}
.tab-content { background-color: rgb(255, 255, 255); margin-top: 0px; padding: 0px; }
.active { background-color:rgb(255, 255, 255); border: 1px solid rgb(240 248 255);
  border-bottom: 0px; color: #4e4d4d;}
  .active.span {
    border: 20px solid green;
  }
  
span:hover {cursor: pointer;}
.custom-table { margin-bottom: 30px !important; }
.checkbox { padding: 20px; background-color: #e7f9ff; margin: 0px 5px; border: 1px solid lightblue; } 
.checkbox>label { color: #31638f; } 
.total-hours-card {
  padding: 20px;
  background-color: lightgrey;
  margin-bottom: 10px;
}

.section-title {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #31638f;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Lato;
  letter-spacing: .2px;
}

.summary-card-top-divider {
  margin-bottom: 20px;
}
.summary-card-header {
  /* font-family: Roboto; */
  font-weight: 600;
  color: #696a6b;
}

.flex-container {
  display: flex;
  /* justify-content: space-evenly; Space the items as far apart as possible */
  align-items: center; /* Align items vertically in the center, if needed */
  padding: 8px 16px 20px; /* Adjust padding as needed */
}

.total-hours-content {
  font-size: 22px;
}

.button-container {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  margin-top: 10px; /* Add margin if needed */
}
.button-container span {
  color: aliceblue;
}

</style>
