<!-- EndSession.vue -->
<template>
  <div 
    ma-1 d-flex align-center
    style="padding: 6px 11px; margin-top: 4px;
      margin-left: 0px; background-color: rgb(236 235 235); text-align: center;">
    <strong> {{ $t('detail.endSessionDate')}} </strong> {{ formattedEndSessionDate }}
  </div>
</template>

<script>

import moment from 'moment';

export default {
  name: 'EndSession',
  props: {
    classEnd: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedEndSessionDate() {
      return this.formatEndSessionDate(this.classEnd);
    },
  },
  methods: {
    formatEndSessionDate(date) {
      // console.log('lang now ', this.userLanguage)
      return moment(date, 'MM/DD/YYYY').format('MMMM Do, YYYY');
    },
  },
};
</script>
