<template>
  <div style="min-height: 100vh">
    <v-container v-if="loading" class="loading-container" style="max-width: 96% !important;">
      <!-- Check later to improve loader -->
      <!-- https://stackoverflow.com/questions/60221217/how-do-i-change-the-v-progress-loader-circular-total-time-out -->
      <!-- <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular> -->
      <!-- <v-progress-circular
          :rotate="90"
          :size="100"
          :width="15"
          :value="value"
          color="primary"
        >
          {{ value }}
      </v-progress-circular> -->
        <!-- LOADING  -->
        <span class="loading-text">
          <p style="font-size: 18px;">
            <!-- Fetching classes...please wait -->
            {{ $t('loadingMessage.fetchingClasses') }}
          </p>  
        </span>
        <!-- <v-progress-linear
        color="light-blue"
        height="20"
        width="50"
        model-value="10"
        :value="value"
        striped
      >
          {{ value }}
      </v-progress-linear> -->
      <v-progress-linear color="primary" indeterminate :height="7"></v-progress-linear>
    </v-container>

    <div v-else>
     <!-- <transition name="fade"> -->
      <br><br>
      <v-container></v-container>
        <div class="page-title-wrapper" style="border-radius: 12px;">
          <div class="page-header">
            <h2 style="font-weight:100;">
              {{ $t('pages.classes.pageTitle') }}
            </h2>
            <!-- <span style="flex-direction: row;font-weight: 500;color: #f6fdfe;">
              {{ currentDate }}
            </span> -->
          </div>
        </div>

        <div class="devPanel" style="margin-right: 18px;
            margin-bottom: 40px;">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Developer API - FM A Get All Classes (example JSON)
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                      <div id="terminal" v-show="true">
                        <!-- <h2 style="margin: 0px 0px 10px 4px;"></h2> -->
                        <pre style="background: black;
                          padding: 20px;
                          margin-bottom: 30px;
                          color: lightgreen;
                          border-radius: 7px;
                          margin-right: 16px;">
                          <!-- {{ allClasses[0].virtualClass[0].platform || 'None' }} -->
                          {{ allClasses }}
                        </pre>
                      </div>
                  </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>


        </div>

        <div id="welcome" style="color: rgb(97 139 148 / 65%)">
          <!-- <p>{{ $t('message.hello', { msg: 'Conor!' }) }}</p> -->

          <h2 style="color:#043a80cf;font-weight: 500;font-size:24px;">          
            {{ $t('pages.classes.greeting.welcome') }}, {{ userFirstName }}
            <!-- {{ fmFormatDate }} -->
          </h2>
          <p style="color: #666;font-weight: 100;font-size:20px;">
            {{ $t('pages.classes.greeting.text') }}
          </p>
        </div>

        <!-- change color of class date on cards -->
        <!-- <pre>
          {{ upcoming }}  
          {{ fake2Moro }}
          {{ allClasses.data.length }}
        </pre> -->

        <v-tabs
          v-model="tab"
          class="custom-tabs"
          show-arrows
          slider-size="2"
        >
          <v-tab
            v-for="item in items"
            :key="item.tab"
          >
            {{ item.tabTitle }}
            <span v-show="item.tabIndex === 1"
              style="margin-left:7px;
                background-color: rgb(226 239 250);
                padding: 3px;
                border-radius: 12%;
                color: rgb(2 75 150);
                min-width: 27px;">
              {{ today().length }}                 
            </span>
            <span v-show="item.tabIndex === 2"
              style="margin-left: 7px;
                background-color:rgb(253 191 53);
                padding: 3px;
                border-radius: 12%;
                color: rgb(155 64 1);
                min-width: 32px;">
                {{ reportsPending().length }}     
            </span>
            <span v-show="item.tabIndex === 3"
              style="margin-left: 7px;
                background-color: rgb(34 192 75 / 53%);
                padding: 3px;
                border-radius: 12%;
                color: rgb(85 125 54);
                min-width: 27px;">
              {{ completeAttendance().length }}    
            </span>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in items"
            :key="item.tab"
            style="padding: 24px 0px;"
          >   

          <!-- TAB 1 - today   -->
          <div v-show="item.tabIndex === 1" style="margin-bottom: 0px;justify-content: space-between;">
            <h2 class="classes-page-tab-title">
              {{ $t('title.today')}} ({{ today().length }}) - 
              <small v-if="traxLanguageFromStorage === 'fr'">{{ todaysDateToFrenchFormat(currentDate) }}</small>
              <small v-if="traxLanguageFromStorage === 'en'">   
                <span style="flex-direction: row;font-weight: 500;color: #727272;">
                  {{ currentDate }} 
                </span>
              </small>
              <!-- <small> {{ currentDate }} </small> -->
            </h2>

            <div v-show="allClasses.length < 1" class="NoEventsToday"> 
              You have no classes scheduled today.
            </div>
            <div v-show="allClasses.length > 1"  style="margin-top: -45px;">
              <!-- DEBUG IF NO CLASSES 
                <div style="padding: 12px;
                background-color: #af0000e6;
                color: white;
                border-radius: 10px;">
                {{ allClasses.data }}
              </div> -->
              <!-- <pre>
              {{ allClasses.data[0] }}
              </pre> -->
              <br><br>
              <!--  Class card -->
              <v-card v-for="(event, index) in today()" :key="index"
                class="event-card" 
                style="border-left: 5px solid rgb(1 59 132 / 69%);
                margin-bottom: 32px;"
              >         
                <v-card-title style="padding: 26px 10px 0px 24px;">
                  <v-row>
                    <!-- date time -->
                    <!-- <v-col cols="2">                          
                      <div class="event-label">
                        <v-icon style="font-size: 22px;margin-right: 3px;">mdi-calendar</v-icon>
                        <span class="event-details-top"> 
                          {{ cardDateFormat(formatDate(event['scheduleDate']))['dayWeek'] }},
                          {{ cardDateFormat(formatDate(event['scheduleDate']))['month'] }}
                          {{ cardDateFormat(formatDate(event['scheduleDate']))['dayMonth'] }},
                          {{ event.scheduleDate }}
                        </span>
                        
                        <div style="margin-left: 0px;" class="event-details-top">              
                          {{ cardDateFormat(formatDate(event['scheduleDate']))['year'] }},
                        </div>
                      </div>
                    </v-col> -->

                    <v-col cols="12" class="summary">
                      <!-- Client Info -->
                      <!-- <small class="col-title">Summary</small> -->
                      <v-row style="align-items: center;
                        padding: 0px 14px 0px 14px;
                        justify-content: ;
                        margin-bottom: 7px;margin-top: 0px;">

                        <!-- If groupClass = true 
                        // clientName - groupName
                        Else 
                        clientName - studentName -->

                        <div class="event-label" style="border-right: 0px solid grey;">
                          <!-- <v-icon>mdi-account</v-icon> -->
                          <pre class="terminal" v-show="false" style="background: black;
                            padding: 20px;
                            margin-bottom: 30px;
                            color: lightgreen;
                            border-radius: 7px;
                            margin-right: 16px;">
                            {{ event }}
                          </pre>

                          <div v-if="event.groupClass" style="border: 0px solid red;" >                      
                            <h2 class="event-client-name">
                              {{ event['clientName'] || 'No Client Name.' }} 
                              <span> - </span>
                              <span>
                                {{ event['groupName'] || 'No Group #' }} 
                              </span>
                            </h2>
                          </div>

                          <div v-else>
                            <h2 class="event-client-name">
                              <!-- Commission d'accès à l'information du Québec | Group 3 -->
                              {{ event['clientName'] || 'No client name.' }} 
                              <span> - </span>
                              {{ event['groupName'] || '(Single Student - No Name in Data)' }}  
                            </h2>
                          </div>
                        </div>

                        <!-- <div v-if="event.status !== ''" 
                          style="margin-left: 5px;
                            background: #fcbf35c2;
                            font-size: 13px;
                            padding: 1px 5px;
                            color: #6b4617;
                            border-radius: 12px;
                        ">
                          <v-icon style="font-size: 21px;">mdi-list-status</v-icon>
                          <span style="cursor: default !important;">
                            {{ event.status }}
                          </span>
                        </div> -->

                          
                        <!-- <div v-show="event.attendancesCount === event.studentCount" style="color: purple"> -->
                        <div v-show="false" style="color: purple">
                          <!-- TODO - v-chip -->
                          <v-chip
                            class="ma-2"
                            color="lightgrey"
                            outlined
                            label
                            left
                            text-color="green"
                            style="color: aliceblue !important"
                          >
                          Attendance
                          <v-icon v-if="true" right style="font-size: 12px;
                            color: #ffffff;
                            background-color: #2cac06d9;
                            border-radius: 50%;
                            padding: 2px;">
                              mdi-check
                            </v-icon>

                            <v-icon v-if="false" right style="font-size: 12px;
                            color: #ffffff;
                            background-color: #dd0101d9;
                            border-radius: 50%;
                            padding: 2px;">
                              mdi-close
                            </v-icon>
                          </v-chip>
                        </div>
                      </v-row>

                      <div style="" class="secondary-detail">
                        <span
                          class="ma-2 custom-chip"
                          active="false"                 
                        >
                          <v-icon style="font-size: 21px;margin-right: 4px;">mdi-calendar</v-icon>
                          <!-- {{ event['scheduleDate'] }} -->
                            <!-- <small class="secondary-detail" style="">
                              {{ formatDateMoment(event['scheduleDate']) }}
                            </small> -->
                          <span>
                            {{ formatIsoDate(event) || 'No Date' }}
                          </span>
                          <span style="margin: 0px 6px 0px 6px; border-left: 2px solid lightgrey"></span>
                        </span>
                        
                        <span
                          class="ma-2 custom-chip"
                          color="">
                          <v-icon style="font-size: 22px;margin-right: -3px;">mdi-clock</v-icon>
                          <small class="secondary-detail">
                            {{ format24hrTime(event['startTime']) }} - {{ format24hrTime(event['endTime']) }} 
                            <!-- {{ setClassDuration(event).duration }} -->
                            <!-- ({{ formatDuration(event['duration']) }}) -->
                            <span v-show="setClassDuration(event).type === 'default'">
                              ({{ setClassDuration(event).duration }})
                            </span> 
                            <span v-show="setClassDuration(event).type === 'override'" 
                              style=" background-color: rgb(250, 175, 21);
                                border-color: rgb(250, 175, 21);
                                cursor: default;
                                pointer-events: none;
                                color: #674416;
                                border-radius: 16%;
                                padding: 3px;
                                margin-left: 7px;"
                              :class="{ 'override-duration-highlight': setClassDuration.type === 'override' }">
                              {{ setClassDuration(event).duration }}
                            </span>
                          </small>
                          <span style="margin: 0px 6px 0px 6px; border-left: 2px solid lightgrey"></span>
                        </span>

                        <span
                          class="ma-2 custom-chip"
                          color=""
                        >
                          <v-icon v-if="event['type'] === 'Phone follow-up'" style="font-size: 20px;margin-right: -3px;">
                            mdi-phone
                          </v-icon>

                          <v-icon v-else-if="event.groupClass" style="font-size: 20px;margin-right: -3px;">
                            mdi-account-group
                          </v-icon>
                          <v-icon v-else style="font-size: 20px;margin-right: -3px;">
                            mdi-account
                          </v-icon>

                          <small v-if="event['type'] !== ''" class="secondary-detail">
                            {{ event['type'] }} 
                          </small>  
                          <!-- <small v-if="event['type'] === 'Phone follow-up'" class="secondary-detail">
                            Phone Follow-Up
                          </small>  

                          <small v-else-if="event['groupClass'] === true" class="secondary-detail">
                            {{ event.type || 'Group' }}
                          </small> -->
                          <small v-else class="secondary-detail">No Class Type</small>

                          <span style="margin: 22px; border-left: 2px solid lightgrey"></span>
                        </span>

                        <span v-if="event.virtualClass[0].platform !== ''"
                          class="ma-2 custom-chip"
                          color="">
                          <v-icon style="font-size: 24px;margin-right: 8px;">mdi-video</v-icon>
                          <small class="secondary-detail">   
                            {{ event.virtualClass[0].platform }}
                          </small>
                        </span>
                        <span v-show="event.virtualClass[0].platform !== ''" style="margin: 22px; border-left: 2px solid lightgrey"></span>
                        <!-- <v-chip
                          class="ma-2 custom-chip"
                          color="">
                          <v-icon style="font-size: 24px;margin-right: 8px;">mdi-video</v-icon>
                          <small class="secondary-detail">   
                          {{ event.virtualClass[0].account || 'None'}}
                          </small>
                        </v-chip> -->

                        <span
                          class="ma-2 custom-chip"
                          color="">
                          <v-icon style="font-size: 21px;margin-right: 0px;">mdi-account-voice</v-icon>
                          <small class="secondary-detail">
                          {{ event.classLanguage || 'None' }}
                          </small>
                        </span>
                      </div>

                      <div class="secondary-detail">
                        <span v-if="event.teacherNotes !== ''"
                          class="ma-2 custom-chip"
                          color="">
                          <v-icon style="font-size: 21px;margin-right: 0px;">mdi-note</v-icon>
                          <small class="secondary-detail" style="margin:0px 0px 0px 4px;">
                            {{ event.teacherNotes || 'No notes for class' }}
                          </small>
                        </span>
                      </div>

                      <!-- ONSITE/PHYSICAL CLASS -->
                      <div v-if="event.physicalClass[0].building !== ''" class="secondary-detail">
                        <!-- <span 
                          class="ma-2 custom-chip"
                          color="">
                          <v-icon style="font-size: 21px;margin-right: 0px;">mdi-map-marker</v-icon>
                          <small class="secondary-detail" style="margin:0px 0px 0px 4px;">
                            {{ event.physicalClass }}

                            {{ event.physicalClass[0].building || 'No building' }}
                            {{ event.physicalClass[0].address || 'No address' }}
                            {{ event.physicalClass[0].city || 'No city' }}
                          </small>
                        </span> -->

                         <!-- Other event details -->
                        <formatted-address :event="event"></formatted-address>
                      </div>



<!-- POSTPONE ALERT  -->
<!-- <div style="" class="class-pp-cancel-alert">
<v-col cols="4" class="" v-if="event.isPostponed == true">      
<div
  class="ma-1 d-flex align-center"
  style="background: #ff7c001c;
    padding: 6px 11px;
    border-left: 3px solid #faaf15;">
    <v-icon style="color: #fe6e09;">mdi-alert</v-icon>
    <span style="line-height: 0px;
      margin-left: 12px;
      margin-top: 2px;
      color: #686868;
      font-size: 16px;">
      Class Postponed
    </span>
</div>
</v-col>          
</div> -->

<!-- POSTPONE ALERT  -->
<!-- <div style="" class="class-pp-cancel-alert">
<v-col cols="4" class="" v-show="event.isPostponed === true && event.registrations.length > 0">                     
<div
  class="ma-1 d-flex align-center"
  style="background: #ff7c001c;
    padding: 6px 11px;
    border-left: 3px solid #faaf15;">
    <v-icon style="color: #fe6e09;">mdi-alert</v-icon>

    <span style="line-height: 0px;
      margin-left: 12px;
      margin-top: 2px;
      color: #686868;
      font-size: 16px;">
      Class Postponed
    </span>

</div>
</v-col>          
</div> -->

                      <div style="" class="class-pp-cancel-alert" v-show="event.studentCount.length === 0">
                        <v-col cols="8" class="">         
                          <div
                            class="ma-1 d-flex align-center"
                            style="background: #ff7c001c;
                              padding: 6px 11px;
                              border-left: 3px solid #faaf15;">
                              <v-icon style="color: #fe6e09;">mdi-alert</v-icon>
                            <!-- <v-alert
                              style="margin-top: 9px !important;"
                              dense
        
                              icon="mdi-alert"
                              color="#f4b21ea8"> -->
                              <span style="line-height: 0px;
                                margin-left: 12px;
                                margin-top: 2px;
                                color: #686868;
                                font-size: 16px;">
                                Class currently has no particpants. Await an update from your ACA coordinator.
                              </span>
                            <!-- </v-alert>  -->
                          </div>
                        </v-col>          
                      </div>

                  
                      <!-- <div style="margin-left: 13px">
                        <outcome-status :event="event"></outcome-status>
                      </div> -->
                        
                        <v-col cols="6">
                          <outcome-status :event="event"></outcome-status>                        
                        </v-col> 
                    </v-col>

                    <!-- <v-col cols="3">
                      <small class="col-title">Notes</small>
                      <div class="event-label">
                        <small>Example: Statutory Holiday: no class (Periode des fetes)</small>
                      </div>
                    </v-col> -->

                    <!-- BUG : OUTCOME CAN SAY DONE WHEN CLASS STILL HAS ATTENDANCE DUE -->
              
                  </v-row>
                </v-card-title>

                        <!-- <v-card-title style="padding: 26px 20px 20px;">
                          <v-row>
                            {{ event }}
                            
                            {{ event.classesNumber }}
                            <br>
                            {{ event.clientName || 'No client name'}} -
                            {{ event.groupName || 'No group number'}} 

                        
                            <br>
                            {{ event.fkSchedule || 'No Date'}}
                            <br> 
                            {{ event.type || 'No class type' }} 
                          </v-row>
                        </v-card-title> -->
                <br><br>
                <v-divider></v-divider>
                <v-card-actions style="padding: 9px 5px 5px;">
                  <v-spacer></v-spacer>
                  <v-btn @click="attendanceForm(event)" class="class-link-btn" text dark outlined>
                    <!-- Go to Class -->
                    {{ $t('buttonText.goToClassTxt') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
          </div>

            <!-- ATTENDANCE DUE TAB -->  
            <div v-show="item.tabIndex === 2" 
              style="margin-bottom: 0px;"> 
              <h2 class="classes-page-tab-title">
                {{ $t('title.attendanceDue')}} ({{ reportsPending().length }})
                <!-- <small> {{ todaysDateToFrenchFormat(currentDate) }} </small> -->
              </h2>
              <!-- <div style="margin-left: 12px;margin-bottom:20px;color: lightslategray">
                <p v-if="reportsPending().length > 1">The following classes require attendance reports.</p>
                <p v-else>The following class requires an attendance report.</p>
              </div> -->

              <section v-if="allClasses.length > 0">

              <!-- // ADD attendance due list
              // ATTENDANCE DUE FILTERS BY THE DATE AND ARRAY OF SUBMITTED ATTENDANCE 
              
              // If attendance array is empty and time is later than class || If the length of the attendance < length of registrations  -->
                <!--  pending  -->
                <v-card v-for="(event, index) in reportsPending()" :key="index"
                  class="event-card" style="border-left: 5px solid rgb(253 191 53);margin-bottom: 32px;">

                    <!-- <v-toolbar color="#eff6fc;" style="margin-bottom: 10px;" elevation="1"> 
                      <div class="" style="border-right: 0px solid grey;margin-left: 14px;color:#575757">
                        <div v-if="event.groupClass">
                    
                          <h2 class="">
                            {{ event['clientName'] || 'FakeBlock Inc.' }} 
                            <span>  </span>
                        <v-icon>mdi-account</v-icon>

                            {{ event['groupName'] || 'Group ' + (index + 1) }} 
                          </h2>
                        </div>

                        <div v-else>
                          <h2 class="">
                            {{ event['clientName'] || 'FakeBlock Inc.' }} 
                            <span> - </span>
                            Jane Doe  
                          </h2>
                        </div>
                      </div>
                      <div style="color: purple">
                      <v-chip
                        class="ma-2"
                        color="lightgrey"
                        outlined
                        label
                        left
                        text-color="green"
                        style="color: aliceblue !important"
                      >
                        Attendance
                      <v-icon v-if="true" right style="font-size: 12px;
                        color: #ffffff;
                        background-color: #2cac06d9;
                        border-radius: 50%;
                        padding: 2px;">
                          mdi-check
                        </v-icon>

                        <v-icon v-if="false" right style="font-size: 12px;
                        color: #ffffff;
                        background-color: #dd0101d9;
                        border-radius: 50%;
                        padding: 2px;">
                          mdi-close
                        </v-icon>
                      </v-chip>
                      </div>                        
                    </v-toolbar> -->               
                  <v-card-title style="padding: 26px 10px 0px 24px;">
                    <v-row>
                      <!-- date time -->
                      <!-- <v-col cols="2">                          
                        <div class="event-label">
                          <v-icon style="font-size: 22px;margin-right: 3px;">mdi-calendar</v-icon>
                          <span class="event-details-top"> 
                            {{ cardDateFormat(formatDate(event['scheduleDate']))['dayWeek'] }},
                            {{ cardDateFormat(formatDate(event['scheduleDate']))['month'] }}
                            {{ cardDateFormat(formatDate(event['scheduleDate']))['dayMonth'] }},
                            {{ event.scheduleDate }}
                          </span>
                          
                          <div style="margin-left: 0px;" class="event-details-top">              
                            {{ cardDateFormat(formatDate(event['scheduleDate']))['year'] }},
                          </div>
                        </div>
                      </v-col> -->

                      <v-col cols="12" class="summary">
                        <!-- Client Info -->
                        <!-- <small class="col-title">Summary</small> -->
                        <v-row style="align-items: center;
                          padding: 0px 14px 0px 14px;
                          justify-content: space-between;
                          margin-bottom: 7px;margin-top: 0px;">

                          <!-- If groupClass = true 
                          // clientName - groupName
                          Else 
                          clientName - studentName -->

                          <div class="event-label" style="border-right: 0px solid grey;">
                            <!-- <v-icon>mdi-account</v-icon> -->
                            <div v-if="event.groupClass" style="border: 0px solid red;" >                      
                              <h3 class="event-client-name">
                                {{ event['clientName'] || 'No Client Name in Data.' }} 
                                <span> - </span>
                                <span>
                                  {{ event['groupName'] || 'No Group #' }} 
                                </span>
                              </h3>
                            </div>

                            <div v-else>
                              <h3 class="event-client-name">
                                <!-- Commission d'accès à l'information du Québec | Group 3 -->
                                {{ event['clientName'] || 'No Client Name' }} 
                                <span> - </span>
                                {{ event['groupName'] || '(Single Student - No Name in Data)' }}  
                              </h3>
                            </div>
                          </div>
                            
                          <div v-show="false" style="color: purple">
                            <!-- TODO - v-chip -->
                            <v-chip
                              class="ma-2"
                              color="lightgrey"
                              outlined
                              label
                              left
                              text-color="green"
                              style="color: aliceblue !important"
                            >
                            Attendance
                            <v-icon v-if="true" right style="font-size: 12px;
                              color: #ffffff;
                              background-color: #2cac06d9;
                              border-radius: 50%;
                              padding: 2px;">
                                mdi-check
                              </v-icon>

                              <v-icon v-if="false" right style="font-size: 12px;
                              color: #ffffff;
                              background-color: #dd0101d9;
                              border-radius: 50%;
                              padding: 2px;">
                                mdi-close
                              </v-icon>
                            </v-chip>
                          </div>
                        </v-row>

                      <div style="" class="secondary-detail">
                        <span
                          class="ma-2 custom-chip"
                          active="false"                 
                        >
                          <v-icon style="font-size: 21px;
                          margin-right: 4px;
                          color:grey;">mdi-calendar</v-icon>
                          <!-- {{ event['scheduleDate'] }} -->
                            <!-- <small class="secondary-detail" style="">
                              {{ formatDateMoment(event['scheduleDate']) }}
                            </small> -->
                          <span>
                            {{ formatIsoDate(event) || 'No Date' }}
                          </span>
                          <span style="margin: 0px 6px 0px 6px; border-left: 2px solid lightgrey"></span>
                        </span>
                        
                        <span
                          class="ma-2 custom-chip"
                          color="">
                          <v-icon style="font-size: 22px;margin-right: -3px;">mdi-clock</v-icon>
                          <small class="secondary-detail">
                            {{ format24hrTime(event['startTime']) }} - {{ format24hrTime(event['endTime']) }} 
                            <!-- {{ setClassDuration(event).duration }} -->
                            <!-- ({{ formatDuration(event['duration']) }}) -->
                            <span v-show="setClassDuration(event).type === 'default'">
                              ({{ setClassDuration(event).duration }})
                            </span> 
                            <span v-show="setClassDuration(event).type === 'override'" 
                              style=" background-color: rgb(250, 175, 21);
                                border-color: rgb(250, 175, 21);
                                cursor: default;
                                pointer-events: none;
                                color: #674416;
                                border-radius: 16%;
                                padding: 3px;
                                margin-left: 7px;"
                              :class="{ 'override-duration-highlight': setClassDuration.type === 'override' }">
                              {{ setClassDuration(event).duration }}
                            </span>
                          </small>
                          <span style="margin: 0px 6px 0px 6px; border-left: 2px solid lightgrey"></span>
                        </span>

                        <span
                          class="ma-2 custom-chip"
                          color=""
                        >
                          <v-icon v-if="event['type'] === 'Phone follow-up'" style="font-size: 20px;margin-right: -3px;">
                            mdi-phone
                          </v-icon>

                          <v-icon v-else-if="event.groupClass" style="font-size: 20px;margin-right: -3px;">
                            mdi-account-group
                          </v-icon>
                          <v-icon v-else style="font-size: 20px;margin-right: -3px;">
                            mdi-account
                          </v-icon>

                          <small v-if="event['type'] !== ''" class="secondary-detail">
                            {{ event['type'] }} 
                          </small>  
                          <!-- <small v-if="event['type'] === 'Phone follow-up'" class="secondary-detail">
                            Phone Follow-Up
                          </small>  

                          <small v-else-if="event['groupClass'] === true" class="secondary-detail">
                            {{ event.type || 'Group' }}
                          </small> -->
                          <small v-else class="secondary-detail">No Class Type</small>

                          <span style="margin: 22px; border-left: 2px solid lightgrey"></span>
                        </span>

                        <span v-if="event.virtualClass[0].platform !== ''"
                          class="ma-2 custom-chip"
                          color="">
                          <v-icon style="font-size: 24px;margin-right: 8px;">mdi-video</v-icon>
                          <small class="secondary-detail">   
                            {{ event.virtualClass[0].platform }}
                          </small>
                        </span>
                        <span v-show="event.virtualClass[0].platform !== ''" style="margin: 22px; border-left: 2px solid lightgrey"></span>
                        <!-- <v-chip
                          class="ma-2 custom-chip"
                          color="">
                          <v-icon style="font-size: 24px;margin-right: 8px;">mdi-video</v-icon>
                          <small class="secondary-detail">   
                          {{ event.virtualClass[0].account || 'None'}}
                          </small>
                        </v-chip> -->
                        <span
                          class="ma-2 custom-chip"
                          color="">
                          <v-icon style="font-size: 21px;margin-right: 0px;">mdi-account-voice</v-icon>
                          <small class="secondary-detail">
                          {{ event.classLanguage || 'None' }}
                          </small>
                        </span>
                      </div>

                       <!-- ONSITE/PHYSICAL CLASS -->
                       <div v-if="event.physicalClass[0].building !== ''" class="secondary-detail">
                        <!-- <span 
                          class="ma-2 custom-chip"
                          color="">
                          <v-icon style="font-size: 21px;margin-right: 0px;">mdi-map-marker</v-icon>
                          <small class="secondary-detail" style="margin:0px 0px 0px 4px;">
                            {{ event.physicalClass }}

                            {{ event.physicalClass[0].building || 'No building' }}
                            {{ event.physicalClass[0].address || 'No address' }}
                            {{ event.physicalClass[0].city || 'No city' }}
                          </small>
                        </span> -->

                         <!-- Other event details -->
                        <formatted-address :event="event"></formatted-address>
                      </div>

                      <div class="secondary-detail">
                        <v-chip v-if="event.teacherNotes !== ''"
                          class="ma-2 custom-chip"
                          color="">
                          <v-icon style="font-size: 20px;margin-right: 8px;">mdi-note</v-icon>
                          <small class="secondary-detail">{{ event.teacherNotes || 'There are no notes for this class right now'}}</small>
                        </v-chip>
                      </div>

                      <v-col cols="6">
                        <outcome-status :event="event"></outcome-status>                         
                      </v-col>  

                      <!-- <template>
                        <div v-show="event.outcome !== 'EMPTY'">
                          <v-col cols="5">
                            <div
                              :class="[
                                'ma-1 d-flex align-center',
                                {
                                  'done-outcome': event.status === 'Done',
                                  'poi-outcome': event.status === 'Postponed by instructor',
                                  'nss-outcome': event.status === 'No Show student'
                                }
                              ]"
                              :style="getOutcomeStyle(event.outcome)"
                            >
                              <v-icon :style="{ color: getIconColor(event.outcome) }">
                                {{ getIcon(event.outcome) }}
                              </v-icon>
                            
                              <span style="line-height: 0px;
                                margin-left: 12px;
                                margin-top: 2px;
                                color: #686868;
                                font-size: 16px;">
                                {{ getOutcomeFullText(event.outcome) }}
                              </span>
                            </div>
                          </v-col>
                        </div>
                      </template> -->

                        <!-- POSTPONE ALERT  -->
                        <!-- <div style="" class="class-pp-cancel-alert">
                          <v-col cols="4" class=""  v-if="event.isPostponed == true">      
                            <div
                              class="ma-1 d-flex align-center"
                              style="background: #ff7c001c;
                                padding: 6px 11px;
                                border-left: 3px solid #faaf15;">
                                <v-icon style="color: #fe6e09;">mdi-alert</v-icon>
                                <span style="line-height: 0px;
                                  margin-left: 12px;
                                  margin-top: 2px;
                                  color: #686868;
                                  font-size: 16px;">
                                  Class Postponed
                                </span>
                            </div>
                          </v-col>          
                        </div> -->
                      </v-col>                 
                    </v-row>
                  </v-card-title>
                  <br><br>
                  <v-divider></v-divider>
                  <v-card-actions style="padding: 9px 5px 5px;">
                    <v-spacer></v-spacer>
                    <v-btn @click="attendanceForm(event)" class="class-link-btn" text dark outlined>
                      {{ $t('buttonText.goToClassTxt') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </section>

              <section v-else>
                All reports are complete
              </section>
            </div>

            <!-- EDITABLE TAB  -->
            <div v-if="item.tabIndex === 3">
              <h2 class="classes-page-tab-title" style="">
                  {{ $t('title.recentlySubmitted')}} 
                <span>({{ completeAttendance().length }})</span>
              </h2>
              <div style="margin: 18px 0px;">
                <v-alert
                type="info"
                dense
                text
                outlined
              >
              <span v-if="completeAttendance().length > 0" style="color: #4a5176">
                {{ $t('editableTabContent.modifiableInfoText') }}
              </span>
              <span v-else>
                You currently have no editable classes.
              </span>
              </v-alert>
            </div>
              <!-- <p>Classes still editable (submitted within the 12hr window - Taylor mentioned longer?)</p> -->
              <!-- <p>TODO</p> -->
              
              <v-card v-for="(event, index) in completeAttendance()" :key="index"
                  class="event-card" style="border-left: 5px solid rgb(34 192 75 / 53%);margin-bottom: 32px;">
                  
                  <div v-if="completeAttendance().length === 0"
                    id="non-editable-msg"> 
                    <!-- This class attendance is no longer editable. -->
                    You have no editable classes. 
                  </div> 

                    <!-- <v-toolbar color="#eff6fc;" style="margin-bottom: 10px;" elevation="1"> 
                      <div class="" style="border-right: 0px solid grey;margin-left: 14px;color:#575757">
                        <div v-if="event.groupClass">
                    
                          <h2 class="">
                            {{ event['clientName'] || 'FakeBlock Inc.' }} 
                            <span>  </span>
                        <v-icon>mdi-account</v-icon>

                            {{ event['groupName'] || 'Group ' + (index + 1) }} 
                          </h2>
                        </div>

                        <div v-else>
                          <h2 class="">
                            {{ event['clientName'] || 'FakeBlock Inc.' }} 
                            <span> - </span>
                            Jane Doe  
                          </h2>
                        </div>
                      </div>
                      <div style="color: purple">
                      <v-chip
                        class="ma-2"
                        color="lightgrey"
                        outlined
                        label
                        left
                        text-color="green"
                        style="color: aliceblue !important"
                      >
                        Attendance
                      <v-icon v-if="true" right style="font-size: 12px;
                        color: #ffffff;
                        background-color: #2cac06d9;
                        border-radius: 50%;
                        padding: 2px;">
                          mdi-check
                        </v-icon>

                        <v-icon v-if="false" right style="font-size: 12px;
                        color: #ffffff;
                        background-color: #dd0101d9;
                        border-radius: 50%;
                        padding: 2px;">
                          mdi-close
                        </v-icon>
                      </v-chip>
                      </div>                        
                    </v-toolbar> -->
                                      
                  <v-card-title style="padding: 26px 10px 0px 24px;">
                    <v-row>
                      <!-- date time -->
                      <!-- <v-col cols="2">                          
                        <div class="event-label">
                          <v-icon style="font-size: 22px;margin-right: 3px;">mdi-calendar</v-icon>
                          <span class="event-details-top"> 
                            {{ cardDateFormat(formatDate(event['scheduleDate']))['dayWeek'] }},
                            {{ cardDateFormat(formatDate(event['scheduleDate']))['month'] }}
                            {{ cardDateFormat(formatDate(event['scheduleDate']))['dayMonth'] }},
                            {{ event.scheduleDate }}
                          </span>
                          
                          <div style="margin-left: 0px;" class="event-details-top">              
                            {{ cardDateFormat(formatDate(event['scheduleDate']))['year'] }},
                          </div>
                        </div>
                      </v-col> -->

                      <v-col cols="12" class="summary">
                        <!-- Client Info -->
                        <!-- <small class="col-title">Summary</small> -->
                        <v-row style="align-items: center;
                          padding: 0px 14px 0px 14px;
                          justify-content: ;
                          margin-bottom: 7px;margin-top: 0px;">

                          <!-- If groupClass = true 
                          // clientName - groupName
                          Else 
                          clientName - studentName -->

                          <div class="event-label" style="border-right: 0px solid grey;">
                            <!-- <v-icon>mdi-account</v-icon> -->
                            <div v-if="event.groupClass" style="border: 0px solid red;" >                      
                              <h3 class="event-client-name">
                                {{ event['clientName'] || 'No Client Name in Data.' }} 
                                <span> - </span>
                                <span>
                                  {{ event['groupName'] || 'No Group #' }} 
                                </span>
                              </h3>
                            </div>

                            <div v-else>
                              <h3 class="event-client-name">
                                <!-- Commission d'accès à l'information du Québec | Group 3 -->
                                {{ event['clientName'] || 'No client name' }} 
                                <span> - </span>
                                {{ event['groupName'] || '(Single Student - No Name in Data)' }}  
                              </h3>
                            </div>
                          </div>

                          <!-- <div v-if="event.status !== ''" 
                            style="margin-left: 5px;
                              background: #fcbf35c2;
                              font-size: 13px;
                              padding: 1px 5px;
                              color: #6b4617;
                              border-radius: 12px;
                              cursor: default !important;">
                            <v-icon style="font-size: 21px;">mdi-list-status</v-icon>
                            <span style="cursor: default !important;">
                              {{ event.status }}
                            </span>
                          </div> -->                       
                          <div v-show="false" style="color: purple">
                            <!-- TODO - v-chip -->
                            <v-chip
                              class="ma-2"
                              color="lightgrey"
                              outlined
                              label
                              left
                              text-color="green"
                              style="color: aliceblue !important"
                            >
                            Attendance
                            <v-icon v-if="true" right style="font-size: 12px;
                              color: #ffffff;
                              background-color: #2cac06d9;
                              border-radius: 50%;
                              padding: 2px;">
                                mdi-check
                              </v-icon>

                              <v-icon v-if="false" right style="font-size: 12px;
                              color: #ffffff;
                              background-color: #dd0101d9;
                              border-radius: 50%;
                              padding: 2px;">
                                mdi-close
                              </v-icon>
                            </v-chip>
                          </div>
                        </v-row>

                        <div style="" class="secondary-detail">
                          <span
                            class="ma-2 custom-chip"
                            active="false"                 
                          >
                            <v-icon style="font-size: 21px;
                            margin-right: 4px;
                            color:grey;">mdi-calendar</v-icon>
                            <!-- {{ event['scheduleDate'] }} -->
                              <!-- <small class="secondary-detail" style="">
                                {{ formatDateMoment(event['scheduleDate']) }}
                              </small> -->
                            <span>
                              {{ formatIsoDate(event) || 'No Date' }}
                            </span>
                            <span style="margin: 0px 6px 0px 6px; border-left: 2px solid lightgrey"></span>
                          </span>
                          
                          <span
                            class="ma-2 custom-chip"
                            color="">
                            <v-icon style="font-size: 22px;margin-right: -3px;">mdi-clock</v-icon>
                            <small class="secondary-detail">
                              {{ format24hrTime(event['startTime']) }} - {{ format24hrTime(event['endTime']) }} 
                              <!-- {{ setClassDuration(event).duration }} -->
                              <!-- ({{ formatDuration(event['duration']) }}) -->
                              <span v-show="setClassDuration(event).type === 'default'">
                                ({{ setClassDuration(event).duration }})
                              </span> 
                              <span v-show="setClassDuration(event).type === 'override'" 
                                style=" background-color: rgb(250, 175, 21);
                                  border-color: rgb(250, 175, 21);
                                  cursor: default;
                                  pointer-events: none;
                                  color: #674416;
                                  border-radius: 16%;
                                  padding: 3px;
                                  margin-left: 7px;"
                                :class="{ 'override-duration-highlight': setClassDuration.type === 'override' }">
                                {{ setClassDuration(event).duration }}
                              </span>
                            </small>
                            <span style="margin: 0px 6px 0px 6px; border-left: 2px solid lightgrey"></span>
                          </span>

                          <span
                            class="ma-2 custom-chip"
                            color=""
                          >
                            <v-icon v-if="event['type'] === 'Phone follow-up'" style="font-size: 20px;margin-right: -3px;">
                              mdi-phone
                            </v-icon>

                            <v-icon v-else-if="event.groupClass" style="font-size: 20px;margin-right: -3px;">
                              mdi-account-group
                            </v-icon>
                            <v-icon v-else style="font-size: 20px;margin-right: -3px;">
                              mdi-account
                            </v-icon>

                            <small v-if="event['type'] !== ''" class="secondary-detail">
                              {{ event['type'] }} 
                            </small>  
                            <!-- <small v-if="event['type'] === 'Phone follow-up'" class="secondary-detail">
                              Phone Follow-Up
                            </small>  

                            <small v-else-if="event['groupClass'] === true" class="secondary-detail">
                              {{ event.type || 'Group' }}
                            </small> -->
                            <small v-else class="secondary-detail">No Class Type</small>

                            <span style="margin: 22px; border-left: 2px solid lightgrey"></span>
                          </span>

                          <span v-if="event.virtualClass[0].platform !== ''"
                            class="ma-2 custom-chip"
                            color="">
                            <v-icon style="font-size: 24px;margin-right: 8px;">mdi-video</v-icon>
                            <small class="secondary-detail">   
                              {{ event.virtualClass[0].platform }}
                            </small>
                          </span>
                          <span v-show="event.virtualClass[0].platform !== ''" style="margin: 22px; border-left: 2px solid lightgrey"></span>
                          <!-- <v-chip
                            class="ma-2 custom-chip"
                            color="">
                            <v-icon style="font-size: 24px;margin-right: 8px;">mdi-video</v-icon>
                            <small class="secondary-detail">   
                            {{ event.virtualClass[0].account || 'None'}}
                            </small>
                          </v-chip> -->
                          <span
                            class="ma-2 custom-chip"
                            color="">
                            <v-icon style="font-size: 21px;margin-right: 0px;">mdi-account-voice</v-icon>
                            <small class="secondary-detail">
                            {{ event.classLanguage || 'None' }}
                            </small>
                          </span>

                          <!-- ONSITE/PHYSICAL CLASS - MODIFIABLE -->
                          <div v-if="event.physicalClass[0].building !== ''" class="secondary-detail">
                            <!-- <span 
                              class="ma-2 custom-chip"
                              color="">
                              <v-icon style="font-size: 21px;margin-right: 0px;">mdi-map-marker</v-icon>
                              <small class="secondary-detail" style="margin:0px 0px 0px 4px;">
                                {{ event.physicalClass }}

                                {{ event.physicalClass[0].building || 'No building' }}
                                {{ event.physicalClass[0].address || 'No address' }}
                                {{ event.physicalClass[0].city || 'No city' }}
                              </small>
                            </span> -->

                            <!-- Other event details -->
                            <formatted-address :event="event"></formatted-address>
                          </div>
                      
                          <div class="secondary-detail">
                            <v-chip v-if="event.teacherNotes !== ''"
                              class="ma-2 custom-chip"
                              color="">
                              <v-icon style="font-size: 24px;margin-right: 8px;">mdi-note</v-icon>
                              <small class="secondary-detail">
                                {{ event.teacherNotes || 'No notes for class' }}
                              </small>
                            </v-chip>
                          </div>
                          
                          <v-col cols="6">
                            <outcome-status :event="event"></outcome-status>                     
                          </v-col>   
                        </div>                  
                      </v-col>
                    </v-row>
                  </v-card-title>
                  <!-- <br><br> -->
                  <v-divider></v-divider>
                  <v-card-actions style="padding: 9px 5px 5px;">
                    <v-spacer></v-spacer>
                    <v-btn @click="attendanceForm(event)" class="class-link-btn" text dark outlined>
                      {{ $t('buttonText.editClass') }}
                    </v-btn>
                  </v-card-actions>
              </v-card>     
            </div>  
          </v-tab-item>
        </v-tabs-items>
    </div>
  </div>
</template>

<script>
  import OutcomeStatus from '../components/OutcomeStatus.vue'; 
  import { userModule } from "@/store/modules/user";
  import { classesModule } from "@/store/modules/classes"
  import axios from 'axios'
  import { getFMToken, getSeachFilters } from '@/utils/app-util';
  import { checkFMToken } from '@/utils/fm-api'; // Custom API MODULE
  import { checkIsPostponed } from '@/utils/classDataFixer.js';

  // HELPERS
  import { formatToFrenchDate, formatToFrenchDateTime, formatToShortFrenchDate, formatEnglishDateToFrench, formatToShortEnglishDate } from '../utils/dateFormatter.js';
  import { getLocalStorageValue } from '../utils/localStorageHelper';
  import { checkIfPhoneClass } from '../utils/classCardHelper.js';
  import { compareAndConvertDurations } from '../utils/classDurationHelper.js';
  import FormattedAddress from '../components/FormattedAddress.vue';
  

  import router from '@/router';
  import moment from 'moment';
  import Swal from 'sweetalert2'
  // import { filter } from 'vue/types/umd';
  
  export default {
    components: {
      OutcomeStatus,
      FormattedAddress
    },
    data () {
      return {
        traxLanguageFromStorage: null,
        fmSessionToken: '',
        currentDate: moment().format('dddd, Do MMMM YYYY'), 
        fmFormatDate: moment().format('MM/DD/YYYY'), // scheduleDate: '06/20/2024'
        tab: null,
        items: [
          { tabIndex: 1, tabTitle:  this.$t('tab.today') },
          { tabIndex: 2, tabTitle:  this.$t('tab.due') },
          { tabIndex: 3, tabTitle:   this.$t('tab.submitted') },
          // { tab: 'Attendance Due', content: 'Classes with due or incomplete attendance here. Classes after current time or incomplete.' },
          // { tab: 'Recently Submitted' },
        ],
        allClasses: null,
        selectedClassDuration: '',
        todayClassCount: 0,
        upcoming: null,
        pending: null,
        noClassesTodayMessage: '',
        fake2Moro: null,
        loading: true,
        interval: {},
        value: 0,
        userFirstName: ''
      }
    },

    // Try pull class data from session storage when API down  
    // async created() {
    //   // console.log(this.$i18n.locale); // Check selected locale
    //   alert('created')
    //   const classes = sessionStorage.getItem('events2')
    //   const classesToJSON = JSON.parse(classes)
    //   console.log('json ',classesToJSON.data)

    //   this.allClasses = classesToJSON.data
    //   console.log('from sesh ', this.allClasses)

    //   this.loading = false
    //   this.userFirstName = sessionStorage.getItem('userFirstName')
    //   clearInterval(this.interval);
    // },

    beforeCreate() {
      // const user = sessionStorage.getItem('userProfile')
      const user = JSON.parse(sessionStorage.getItem('userProfile') || 'null');
      console.log('before user ', user)
    },

    async created() {
      this.traxLanguageFromStorage = localStorage.getItem('fmCommLang') 
      console.log('traxlan', this.traxLanguageFromStorage)
      // alert('[created]classes.vue')
      // await this.fetchUserData()
      // Get user from local 
      const user = await sessionStorage.getItem('userProfile')
      const userJSON = JSON.parse(user)
      console.log('user here? ', userJSON)
      // console.log('User b4 class call ', u?serJSON.data.apiActivationDate)
      // console.log('Trax activation date: ', typeof userJSON)

      await this.fetchClasses()

        // API request for teachers classes 
        // alert('[classes.vue - created]Getting CLASSES fromm FM A...')
        // const token = localStorage.getItem('token')
        // console.log('Teacher FM token from Local: ', token)

        // this.loading = true
        // this.requestTimedOut = false
        
        // const timeoutDuration = 20000; // Adjust timeout duration as needed (in milliseconds)
        // const timeout = setTimeout(() => {
        //   alert('TIMEOUT..')
        //   this.requestTimedOut = true;
        //   this.loading = false;
        //   this.$router.push('/404'); // Redirect to error page
        // }, timeoutDuration);

        // Today's date for initial data load - pull only today's classes 
        // const currentDate = this.getCurrentDate()

        // console.log('TODAY DATE: ', currentDate)
        // alert('date today...')

        // Create payload - today classes only on first load in default tab
        // "startDate": '2023/04/18', // Replace with today's date
        // "endDate": '2023/04/18',
        // mm-dd-yyyy
        // T00000971 on 2024/04/29 and on 2024/05/01
        // "05/18/2023"- ryan ??

        // T00000932 = Conor Henderson
        // Date 2024/04/29 => 2024-04-29 => no groups 
      

        // "startDate": "06/19/2018", // mm/dd/yyyy
        // "endDate": "06/29/2018",
        // const payload = {
        //   "startDate": "04/01/2024", // mm/dd/yyyy
        //   "endDate": "04/06/2024",
        //   "teacher": token,
        //   "modified": "", // Dynamically add last login timestamp
        //   "language": "EN",
        //   "groupOnly": null,
        //   "privateOnly": null,
        // }
        
        // MOVE TO FM-API MODULE
        // axios.post('http://localhost:3001/api/classes2', payload, {
        //     headers: {
        //       Authorization: `Bearer ${token}`
        //     }
        //   }).then(async response => {

        //   if(response.data.name === 'Error') {
        //     alert(`There was an error fetching classes -> ${response.data.message}`)
        //   }

        //   console.log('Response from FM for Classes.vue ', response)
        //   const strResponse = JSON.stringify(response)

        //   if(response.data === 'An error occurred: FileMaker Server returned error 401, No records match the request') {
        //     alert('An error occurred: FileMaker Server returned error 401, No records match the request')
        //     await this.loading = false
        //     console.log('t or f ', this.loading)
        //     this.eventData = [] // create a dummy class to stop UI breaking 
        //     // return
        //   }

        //   const userFirstName = response.data[0].teacherFirstName
        //   const userLastName = response.data[0].teacherLastName

        //   sessionStorage.setItem('userFirstName', userFirstName)
        //   sessionStorage.setItem('userLastName', userLastName)
        //   sessionStorage.setItem('userFullName', userFirstName + ' ' + userLastName )

        //   sessionStorage.setItem('events2', strResponse)
        //   this.allClasses = response.data
        //   this.allClasses[2].isPostponed = true
        //   this.loading = false
        //   this.userFirstName = sessionStorage.getItem('userFirstName')
        //   clearInterval(this.interval);
        // })
        // Check storage for classes 
        // If none go straight to classesModule.getClassesFrom 
        // const classData = await JSON.parse(sessionStorage.getItem('events2'))
        // console.log('New API back ', classData)
        // alert('check new data ...')
        // this.allClasses = classData

        // API v2 - single API call
        // const promisesArray = await [this.loadPrivateClasses(), this.loadFollowUps()]

        // NEW
        // this.getPendingClasses().then(results => {
        //   this.pending = results
        //   console.log('Pending -> ', this.pending )  
        // })

        // this.getUpcomingClasses().then(results => {
        //   if(results.length < 1) { this.noClassesTodayMessage = 'You have no classes scheduled today'}
        //   this.upcoming = results
        //   console.log('Upcoming classes -> ', this.upcoming)  
        // })

    },

    async mounted () { // alert('classes.vue mounted on ACA')
    },
    
    methods: {

      formatIsoDate(c) {
        // console.log('Format the  date:', c.isoStart); // For debugging
        if(this.traxLanguageFromStorage === "fr") {
          return formatToShortFrenchDate(c.isoStart);      
        }
          return formatToShortEnglishDate(c.isoStart);      
      },

      setClassDuration(event) {
        if(event.isPostponed === true ) {
          let durationMessage
          if(this.traxLanguageFromStorage === 'en') { 
            durationMessage = 'No Duration' 
          } else { durationMessage = 'Acune Duration' }
          const classDuration = { "duration": durationMessage, "type": "default" }
          return classDuration
        }
        // Call class duration helper with the event data
        // this.selectedClassDuration = compareAndConvertDurations(event)
        return compareAndConvertDurations(event)
        console.log('Dur Helper back ', this.selectedClassDuration)
      },

      todaysDateToFrenchFormat(date) {
        // Convert the curren date from English to French - see conditional in template 
        return formatEnglishDateToFrench(date)  
      },

      getOutcomeFullText(abbreviation) {
        switch (abbreviation) {
          case 'POI':
            return 'Postponed by Instructor';
          case 'DONE':
            return 'DONE';
          case 'POS':
            return 'Postponed by learner';
          case 'LMCSBCPT':
          return 'Last minute cancellation by learner';
          // Add more cases as needed
          default:
            return abbreviation; // Return the original text if no match is found
        }
      },

      getOutcomeStyle(outcome) {
        switch (outcome) {
          case 'DONE':
            return {
              background: '#34ddcf69',
              padding: '6px 11px',
              borderLeft: '3px solid rgb(62 137 88)'
            };
          case 'POI':
            return {
              background: '#ff7c001c',
              padding: '6px 11px',
              borderLeft: '3px solid #faaf15'
            };
          default:
            return {
              background: '#ff7c001c',
              padding: '6px 11px',
              borderLeft: '3px solid #faaf15'
            };
        }
    },

      getIconColor(outcome) {
        switch (outcome) {
          case 'DONE': return 'rgb(62 137 87);';
          case 'POI': return '#fe6e09';
          default: return '#fe6e09';
        }
      },

      getIcon(outcome) {
        switch (outcome) {
          case 'DONE': return 'mdi-check-circle';
          case 'POI': return 'mdi-alert';
          default: return 'mdi-alert';
        }
      },

      async fetchUserData() {
        alert('calling http://localhost:3001/api/user from Classes')
        const token = localStorage.getItem('token');

        try {
          const payload = {
            "teacherToken": token,
          }

          const response = await axios.post('/api/user', payload)
          console.log('get user response: ', response['commLang'])
          localStorage.setItem('fmCommLang', response['commLang'] )
          // Check that the user is returned properly
          sessionStorage.setItem('userProfile', JSON.stringify(response))

        } catch(error) {
          console.log('Get user endpoint err : ', error)
          // alert('get user error in console?')
        }
      },

      async fetchClasses() {

        // const langPrefStr = JSON.parse(this.traxLanguageFromStorage)
        // Check for a last login time
        const llt = localStorage.getItem('lastLoginTime')
        // const dateRange = this.getTodaysDate();
        // Get activation date from localstorage 
        const userFromLocal = sessionStorage.getItem('userProfile')
        const userJSON = JSON.parse(userFromLocal)

        // Dates
        const apiActivationDate = userJSON['data']['apiActivationDate']
        console.log('User API Activation Date: ', apiActivationDate || 'None')
        const dateRange = this.setDateRange();
        console.log('Current date range: ', dateRange); // Output: "06/14/2024"
        // alert('date range')

        // DEMO INSTRUCORS WITH FM API ACCOUNTS / EXISTING CLASSES
        // Ryan Lee 
        // "startDate": "05/24/2024", // mm/dd/yyyy
        // "endDate": "06/24/2024",

        // Connor Henderson - ch@e.com - hendy 
        // "startDate": "02/14/2024",
        // "endDate": "03/24/2024",
        const userToken = sessionStorage.getItem('token')
        // console.log('Token from storage: ', userToken)

        try {
          let userTraxLanguage
          // const traxLanguage = this.getLocalStorageValue('fmCommLang')
          // const fmUserLanguage = this.getLocalStorageValue('fmUserLanguage')
        
          // Set the language for FM requests
          // if(fmUserLanguage === "en") { // Uses internal function to get value from local storage
          console.log('langue ', this.traxLanguageFromStorage)
          // const language = await this.traxLanguageFromStorage


const LFS = localStorage.getItem('fmCommLang')
console.log("Value of traxLanguageFromStorage:", LFS);
 

if (LFS === "fr") {
  userTraxLanguage = "FR";
  console.log("Language set to FR");
} else {

  userTraxLanguage = "EN";
  console.log("Language set to EN");
}
          console.log('FM Comm Lang Helper ', userTraxLanguage) 

          const payload = {
            "startDate": apiActivationDate, // mm/dd/yyyy
            "endDate": dateRange.fifteenWeeksAhead,
            "teacher": userToken,
            "modified": "", // Dynamically add last login timestamp
            "language": userTraxLanguage,
            "groupOnly": null,
            "privateOnly": null,
          }

          const url = process.env.VUE_APP_API_URL 
          console.log('Get classes payload ', payload)

          // const response = await axios.post('/api/classes2', payload, {
          const response = await axios.post(url + '/classes2', payload);

          this.handleResponse(response);
        } catch (error) {
          console.error('Error fetching classes:', error);
          this.loading = false;
          this.$router.push('/login');
        }
      },

      getLocalStorageValue(key, defaultValue = null) {
  
        try {
          const storedValue = localStorage.getItem(key);
          if (storedValue !== null) {
            // Attempt to parse the stored value as JSON
            // return JSON.parse(storedValue);

            return storedValue;
          }
        } catch (error) {
          console.error(`Error retrieving ${key} from localStorage:`, error);
        }
        return defaultValue;
      },

      setDateRange() {
        // Helper function to format date as 'mm/dd/yyyy'
        function formatDate(date) {
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const year = date.getFullYear();
          return `${month}/${day}/${year}`;
        }

        // Parse the startDate
        // const [startMonth, startDay, startYear] = startDate.split('/').map(Number);
        // const startDateObj = new Date(startYear, startMonth - 1, startDay);

        // Get the current date 
        const currentDate = new Date();

        // Calculate the future date (15 weeks = 105 days) - current+ 15 weeks
        const futureDate = new Date(currentDate);
        futureDate.setDate(futureDate.getDate() + 105);

        // Format the dates
        const formattedCurrentDate = formatDate(currentDate);
        const formattedFutureDate = formatDate(futureDate);

        // Return the result as an object
        return {
          currentDate: formattedCurrentDate,
          fifteenWeeksAhead: formattedFutureDate
        };
      },

      getTodaysDate() {
        // const today = new Date();
        // const month = String(today.getMonth() + 1).padStart(2, '0');
        // const day = String(today.getDate()).padStart(2, '0');
        // const year = today.getFullYear();
        // return `${month}/${day}/${year}`;

        const today = new Date();
        const sixWeeksAgo = new Date(today);
        const sixWeeksAhead = new Date(today);

        sixWeeksAgo.setDate(today.getDate() - 42); // 6 weeks = 42 days
        sixWeeksAhead.setDate(today.getDate() + 42); // 6 weeks = 42 days


        const formatDate = (date) => {
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const year = date.getFullYear();
          return `${month}/${day}/${year}`;
        };

        return {
          today: formatDate(today),
          sixWeeksAgo: formatDate(sixWeeksAgo),
          sixWeeksAhead: formatDate(sixWeeksAhead)
        };
      },
      
      formatDateMoment(dateString) {
      // Check if Moment.js is available
        if (typeof moment === 'undefined') {
          console.error('Moment.js is not loaded. Please include it in your project.');
          return null;
        }

        // Parse the input date
        const momentDate = moment(dateString, "MM/DD/YYYY");

        // Check if the date is valid
        if (!momentDate.isValid()) {
          console.error('Invalid date format. Please use mm/dd/yyyy.');
          return null;
        }

        // Format the date to 'Mon, June 21' format
        return momentDate.format("ddd, MMMM D");
      },

      // async cleanClasses(classData) {
      //   alert('Checking classes for bugs')
      //   // Loop through the array and update isPostponed field if status contains 'postponed'
      //   classData.forEach(classObj => {
      //     if (classObj.status.toLowerCase().includes('postponed')) {
      //       classObj.isPostponed = true;
      //     } else {
      //       classObj.isPostponed = false;
      //     }
      //   });
      //   // Loop over all classes and set the isPostponed to true if the status has postponed
      //   return classData
      // },
      // Populate Today's Classes with all classes after a specified date 
      // Will need to be refactored for production  

      // getClassesForToday() {
      //   const today = new Date();
      //   const formattedToday = `${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}/${today.getFullYear()}`;

      //   return this.allClasses.filter(classObj => classObj.scheduleDate === formattedToday);
      // },

      // Funciton does not appear to be working at the moment...
      today() {
        const targetDate = new Date(); // This creates a new Date object with the current date and time

        return this.allClasses.filter(c => {
          // Parse the scheduleDate string to a Date object
          const scheduleDate = new Date(c.scheduleDate);

          // Compare only the date parts (year, month, day) of both dates
          return (
            scheduleDate.getFullYear() === targetDate.getFullYear() &&
            scheduleDate.getMonth() === targetDate.getMonth() &&
            scheduleDate.getDate() === targetDate.getDate()
          );
        })
        .sort((a, b) => {
          // Parse the start times to Date objects
          const startTimeA = new Date(`${a.scheduleDate} ${a.startTime}`);
          const startTimeB = new Date(`${b.scheduleDate} ${b.startTime}`);

          // Compare the start times
          return startTimeA - startTimeB;
        });
      },

      classesToday() {
        const targetDate = new Date('06/06/2024');
        console.log('target ', targetDate)
        // alert('targ')
        return this.allClasses.filter(c => {
          const [month, day, year] = c.scheduleDate.split('/');
          const scheduleDate = new Date(`${year}-${month}-${day}`);
          return scheduleDate > targetDate;
        });
      },

      // Populate attendance due   
      reportsPending() {
        const currentDate = new Date();
        const currentTime = currentDate.getTime();

        return this.allClasses.filter(c => {
            const [month, day, year] = c.scheduleDate.split('/');
            const scheduleDate = new Date(year, month - 1, day); // month is 0-indexed in JS Date

            // Parse the start and end times from the event
            const [startHours, startMinutes] = c.startTime.split(':');
            scheduleDate.setHours(startHours, startMinutes, 0, 0);

            // Check if the event is today and the start time has passed
            const isToday = scheduleDate.toDateString() === currentDate.toDateString();
            const hasStartTimePassed = scheduleDate.getTime() < currentTime;

            // Check if the event is before today
            const isBeforeToday = scheduleDate < currentDate;

            // Check if the event is not postponed
            const isNotPostponed = c.isPostponed === "" || c.isPostponed === false;

            const isNotLocked = c.isLocked !== "true"

            // Condition for today's event
            const conditionToday = isToday && hasStartTimePassed && c.studentCount > c.attendancesCount && isNotPostponed && isNotLocked;

            // Condition for past events
            const conditionPast = isBeforeToday && c.studentCount > c.attendancesCount && isNotPostponed && isNotLocked;
            // Final outcomes check
            // const conditionOutcome = c.status !== 'STH'

            return conditionToday || conditionPast 
        });
      },

      async forceLogout() {
        Swal.fire({
            title: 'Logged In Elsewhere?',
            text: 'You may be currently logged in on another browser or device. Re-logging in to use Trax here will automatically log you out on the other browser or device.',
            icon: 'warning',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false
          }).then(async (result) => {
          if (result.isConfirmed) {  
            await sessionStorage.clear()
            // await localStorage.clear()
            window.location.replace(process.env.VUE_APP_URL + "/login"); 
          }
        });
      },

      async handleResponse(response) {
        // console.log('Res ostie ', response)
        // TODO : fake the 422 and perform a redirect to login
        // response.data.message = 'Request failed with status code 422'
        console.log('[fetchClasses] response -> ', response.data)

        if(response.data.message === 'Request failed with status code 422') {
          // Force logout
          await this.forceLogout()
        }

        if (response.data.status === 'ERROR') {
          await this.forceLogout()
          const resJSON = JSON.stringify(response.data.response)
          // alert(`There was an error fetching classes -> ${resJSON}`);
          // Clear local storage
          // localStorage.clear();
          // Clear session storage
          // await sessionStorage.clear()
          // await localStorage.clear()
          window.location.replace(prcoess.env.VUE_APP_URL + "/login"); 
          // return;
        }

        console.log('Response from FM for Classes.vue', response);
        const strResponse = JSON.stringify(response);

        if (response.data === 'An error occurred: FileMaker Server returned error 401, No records match the request') {
          // alert('An error occurred: FileMaker Server returned error 401, No records match the request');
          this.eventData = [{ message: 'No classes found'}]; // Create a dummy class to stop UI breaking
          this.loading = false;
          console.log('t or f', this.loading);
          return;
        }

        this.updateUserSession(response.data);
        sessionStorage.setItem('events2', strResponse);
        // this.allClasses = await this.cleanClasses(response.data);
        // console.log(typeof checkIsPostponed)
        // this.allClasses = await checkIsPostponed(response.data);
        this.allClasses = response.data;

        // Fake isPostponed for testing UI
        // this.allClasses[2].isPostponed = true;
        this.loading = false;
        this.userFirstName = sessionStorage.getItem('userFirstName');
        clearInterval(this.interval);
      },
      setDateLangaugeFormat() {
        return formatToFrenchDate(this.eventData.isoStart);
      },
      // Populate Editable tab 
      completeAttendance() {
        const complete = this.allClasses.filter(c => {
  
          // Check for duration override and fix
          
          // const fixedOverrideDurFormat = async (str) => await str.includes(',') ? str.replace(',', '.') : str;
          // c.overrideDuration = fixedOverrideDurFormat(c.overrideDuration)

          // if(c.duration !== "00:00:00") {
          //   const classDurationFromHelper = compareAndConvertDurations(c)
          //   c.duration = classDurationFromHelper 
          // } else {
          //   alert('nooo...')
          // }
          // const dateToFrench = formatToFrenchDate(c.isoStart);
          // const languageDate = this.setDateLangaugeFormat(c)
          // c.scheduleDate = dateToFrench
          
          
    

          // console.log('s count ', c.studentCount)
          // console.log('s count ', c.attendancesCount)
          return c.studentCount === c.attendancesCount && c.isLocked === false && c.outcome !== 'EMPTY'
        })
        return complete
      },  

      updateUserSession(data) {
        // Check if data is an array and has at least one element
        if (Array.isArray(data) && data.length > 0) {
          const userFirstName = data[0].teacherFirstName || '';
          const userLastName = data[0].teacherLastName || '';
          const userFullName = `${userFirstName} ${userLastName}`.trim();

          sessionStorage.setItem('userFirstName', userFirstName);
          sessionStorage.setItem('userLastName', userLastName);
          sessionStorage.setItem('userFullName', userFullName);
        } else {
          // Handle the case when data is empty or not an array
          console.warn('updateUserSession: Invalid or empty data received');
          
          // Optionally, clear the session storage or set default values
          sessionStorage.removeItem('userFirstName');
          sessionStorage.removeItem('userLastName');
          sessionStorage.removeItem('userFullName');
        }
      },
      
      startFakeNetworkDelay() {
        this.interval = setInterval(() => {
          this.value = this.value === 100 ? 0 : this.value + 10;
          this.loading = true;
          if (this.value === 100) {
            this.loading = false;
            this.userFirstName = sessionStorage.getItem('userFirstName');
            clearInterval(this.interval);
          }
        }, 200);
      },

      formatDuration(timeString) {
          // Split the timeString into hours, minutes, and seconds
          const [hours, minutes, seconds] = timeString.split(':').map(Number);

          // Calculate the total hours
          const totalHours = hours + (minutes / 60) + (seconds / 3600);

          // If total hours is an integer, return it as an integer
          if (Number.isInteger(totalHours)) {
              return `${totalHours} hrs`;
          } else {
              // Format the total hours to have one decimal place
              const formattedHours = totalHours.toFixed(1);
              // Return the formatted duration
              return `${formattedHours} hrs`;
          }
      },

      getCurrentDate() {
                // Get the current date
        const currentDate = new Date();

        // Extract the day, month, and year components
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
        const year = currentDate.getFullYear();

        // Pad single-digit day and month values with leading zeros if needed
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedMonth = month < 10 ? '0' + month : month;

        // Format the date as MM/DD/YYYY
        const formattedDate = `${formattedMonth}/${formattedDay}/${year}`;

        console.log(formattedDate); // Output: 04/29/2024 (example for April 29, 2024)
        return formattedDate

      },

      classesToday() {
        console.log('find the year ', this.allClasses)
        // alert('')
        const filteredItems = this.allClasses.filter(item => {
          // Extract the year, month, and day from the scheduleDate string
        const [month, day, year] = item.scheduleDate.split('/').map(part => parseInt(part, 10));
        
          // Check if the year is 2024 and if the month and day are January 1st
          return year === 2024 && month === 1 && day === 1;
          // return year === 2024

        });
        this.todayClassCount = filteredItems.length
        this.todayClasses = filteredItems
        // return filteredItems;
      },

      cardDateFormat(d) {        
        // CLASS FULL DATA NOT LOADING - DURATION IS NOT DEFINED WHEN LOADING THE CLASS DETAIL
        const dayAbbreviations = {
          Sunday: 'Sun',
          Monday: 'Mon',
          Tuesday: 'Tue',
          Wednesday: 'Wed',
          Thursday: 'Thu',
          Friday: 'Fri',
          Saturday: 'Sat',
        };

        const monthAbbreviations = {
          January: 'Jan',
          February: 'Feb',
          March: 'Mar',
          April: 'April',
          May: 'May',
          June: 'Fri',
          July: 'Sat',
          August: 'Aug',
          September: 'Sept',
          October: 'Oct',
          November: 'Nov',
          December: 'Dec'
        };
        // Split the date string into components
        const parts = d.match(/(\w+), (\d+)(\w+) (\w+) (\d+)/);

        if (parts && parts.length === 6) {
          const dayOfWeek = dayAbbreviations[parts[1]] || parts[1]; // "Wednesday"
          const dayOfMonth = parts[2]; // "15th"
          const month = monthAbbreviations[parts[4]] || parts[4]; // "November"
          const year = parts[5]; // "2023"
          // year is messed up 
          console.log('Day of the week:', dayOfWeek);
          console.log('Day of the month:', dayOfMonth);
          console.log('Month:', month);
          console.log('Year:', year);

          return { dayWeek: dayOfWeek, dayMonth: dayOfMonth, month: month, year: year }
        } else {
          console.log('Invalid date format');
        }
      },

      format24hrTime(time) {
        // console.log('Time -> ', time) // 08:45:00
        const timeString = toString(time)

        const m = moment(time, 'HH:mm').format('HH:mm');
        // const formattedTime = m.format('HHmm');
        // console.log('Mo -> ', m)
        return m
      },

      formatDate(inputDate) {
        const parsedDate = moment(inputDate, 'MM/DD/YYYY')
        const formattedDate = parsedDate.format('dddd, Do MMMM YYYY')
        // console.log('FD->', formattedDate)
        return formattedDate
      }, 

      async getPendingClasses() {
        // Logic -> Before current date with missing attendance or  
        // Needs debugging ...not showing right day and if() not working correctly below
        const tomorrowDate = moment().subtract(10, 'day').startOf('day'); // Tomorrow's date at midnight
        const currentTime = moment(); // Current time to check if class has finished
        this.fake2Moro = tomorrowDate

        return this.allClasses.filter(e => {
          console.log('DATA ', e)

          // const result = checkConditions(Date, Endtime, array1, array2);
          // console.log(result);
          const eventDate = moment(e['fieldData']['scheduleDate']);
          const formattedDate = eventDate.format('MM/DD/YYYY');
          console.log('2moro ', eventDate)

          // NEEDS TESTING...
          // Bug appears to be in the vue formatting of the day for the event 
          // returns Tue 20 sept...which was a wednesday. ie. moment seems to be correct
          
            if(eventDate < tomorrowDate && e['portalData']['class_REGISTRATIONS'].length !== e['portalData']['class_ATTENDANCES'].length) {
              // must add logic for classes where current time is later than class end time - may need to split logic into second IF 
              return e
            }
          }
        ) 
      },

      // Check conditions are met for attendance due
      async checkConditions(Date, Endtime, array1, array2) { 
        const tomorrowDate = moment().add(1, 'day').startOf('day'); // Tomorrow's date at midnight
        const currentTime = moment(); // Current time
        const date = moment(Date);
        const endTime = moment(Endtime);

        // Check conditions
        const condition1 = date.isBefore(tomorrowDate);
        const condition2 = endTime.isBefore(currentTime);
        const condition3 = array1.length < array2.length;

        return condition1 && condition2 && condition3;
      },

      todayFilter(e) {
        if(e['fieldData']['scheduleDate'] < '09/20/2023') {
          return e
        } 
        const allEvents = this.upcomingClasses
        console.log('Events today -> ', allEvents)
        return allEvents.map(e => {
            return e['fieldData']['scheduleDate'] 
        })
      },

      // formatDate(d) {
      //   const formatted = moment(d).format("ddd, Do MMM, gggg")
      //   const splitD = formatted.split(',')
      //   console.log('D fromat ', splitD)
      //   // extract individual date elements 
      //   return splitD
      // },

      // GET THE LATEST CLASS RECORD FROM FM 
      async getSelectedClassRecord() {},  

      async attendanceForm(e) {
        // alert('class ...')
        console.log('Go to class clicked [classes.vue] ', e)
        const selectedClass = JSON.stringify(e)
        sessionStorage.setItem('Selected', selectedClass)

        // Destructure payload values for single class query
        const { pkClasses, type, fkTeacher, } = e

        console.log('params ', pkClasses, type, fkTeacher)
        // const modCheck = classesModule.checkForRecordModifedinFM(e)
        let classEvent = {}
        let color

        // Get name of single student  
        // if(e['portalData']['class_REGISTRATIONS'].length === 1) {
        //   classEvent.name = e['portalData']['class_REGISTRATIONS'][0]['class_reg_STUDENTS::firstName'] + ' ' + e['portalData']['class_REGISTRATIONS'][0]['class_reg_STUDENTS::lastName']
        //   classEvent.groupNo = e['fieldData']['groupName'] || ""
        //   console.log('Class event before attendance.vue [dash] -> ', classEvent)
        // } else {
        //   classEvent.name = e['fieldData']['class_reg_students_CLIENTS::clientName']
        //   classEvent.groupNo = e['fieldData']['groupNo'] 
        // }

        // const currentDate = new Date(e['fieldData']['scheduleDate']);
        // const dayBeforeDate = new Date(currentDate);
        // dayBeforeDate.setDate(currentDate.getDate() - 1);
        // console.log(e['fieldData']['scheduleDate']) // eg. 07/03/2023
        // console.log('day before ', dayBeforeDate) 
        // alert(`set date to day before to test the attendance lock: ${dayBeforeDate}`)

        // const dayLater = new Date(currentDate);
        // dayLater.setDate(currentDate.getDate() + 1)
        // const twentyFourHoursInMillis = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

        // const timeDiff =  dayLater.getTime() - currentDate.getTime()

        // let isLocked = false

        // make a fake current date one day before the date of the class
        // if (currentDate.getTime() > dayBeforeDate.getTime() || timeDiff >= twentyFourHoursInMillis ) {
        //   console.log('Class date is before the current date. Attendance access dened.');
        //   // sessionStorage.setItem('isLocked', JSON.stringify(true));
        //   sessionStorage.setItem('isLocked', JSON.stringify(false)); // set to false to override deny access
        // } else {
        //   console.log('Class date is after the current date. Allow attendance form access.');
        // }

        // New class data - feb 24
        // { "pkClasses": 49860, "fkSchedule": 86, "fkTeacher": 1, "classesNumber": "CLAS049860", "scheduleDate": "09/19/2023", "startTime": "13:00:00", "endTime": "14:30:00", "duration": 1.5, "type": "Corporate Private", "classType": "Classes", "cutoffDate": "", "teacherNotes": "", "status": "Default", "outcomeNotes": "Node note", "teacherFirstName": "Bryan", "teacherLastName": "Montréal", "modificationCount": "", "modifiedBy": "ACA", "modifiedOn": "11/27/2023 18:07:45", "clientName": "Monsieur Projets Inc.", "groupName": "Schedule Group 2", "isPostponed": 0, "meetingURL": "https://www.google.ca/?client=safari", "registrations": [ { "pkRegs": 1645, "fkClient": 112, "fkStudent": 1223, "firstName": "", "lastName": "", "status": "Startup" }, { "pkRegs": 1647, "fkClient": 112, "fkStudent": 1225, "firstName": "Lua", "lastName": "Lamontagne", "status": "Startup" }, { "pkRegs": 1649, "fkClient": 112, "fkStudent": 1227, "firstName": "Mark", "lastName": "Fonseca", "status": "Startup" } ], "attendances": [ { "pkAttendances": 996, "fkRegistration": 1645, "firstName": "", "lastName": "", "status": "present", "remark": "" }, { "pkAttendances": 997, "fkRegistration": 1647, "firstName": "Lua", "lastName": "Lamontagne", "status": "present", "remark": "" }, { "pkAttendances": 998, "fkRegistration": 1646, "firstName": "Christa", "lastName": "Kitagawa", "status": "Fonesca", "remark": "" } ] }
        
        // classEvent = { ...classEvent,
        //   "color": 'blue',  
        //   "classID": e.pkClasses || 'Data Missing', 
        //   "type": e.type || 'Data Missing' , 
        //   "date": e.scheduleDate, 
        //   "start": e.startTime, 
        //   "end": e.endTime, 
        //   "noStudents": e., 
        //   "registrations": e['portalData']['class_REGISTRATIONS'] , 
        //   "noteToTeacher": e['fieldData']['teacherNotes'], 
        //   "details": [ { "note": "no notes here" }, { "msg": "hello attendance msg" } ] 
        // }
        // classEvent = { ...classEvent,
        //   "color": 'blue',  
        //   "classID": e['fieldData']['__pk_Classes'], 
        //   "type": e['fieldData']['type'] || 'Data Missing' , 
        //   "date": e['fieldData']['scheduleDate'], 
        //   "start": e['fieldData']['startTime'], 
        //   "end": e['fieldData']['endTime'], 
        //   "noStudents": e['portalData']['class_REGISTRATIONS'].length, 
        //   "registrations": e['portalData']['class_REGISTRATIONS'] , 
        //   "noteToTeacher": e['fieldData']['teacherNotes'], 
        //   "details": [ { "note": "no notes here" }, { "msg": "hello attendance msg" } ] 
        // }

        // console.log('class obj ready for att -> ', classEvent)

        // sessionStorage.setItem('routeFrom', 'dash')
        // classesModule.setIndexForCurrentClass(e)
        // console.log('indx of event in calendar', e)

        // const fullData = await classesModule.getFullClassData(e['fieldData']['__pk_Classes']) 

        // console.log('Full class data [] -> ', fullData)

        // const selectedClassFull = fullData

        // sessionStorage.setItem('selectedClassFull', selectedClassFull)
        // alert('session...')
        // await sessionStorage.setItem('selectedClass', JSON.stringify(classEvent))
        this.$router.push({ name: 'attendance', params: { classObj: e }}) 
        // this.$router.push({ name: 'attendance', query: { event: e }})
      }
    },
  }
  </script>
  
<style scoped>

.info-text {
  color: #1b80d1 !important;
}

.override-duration-highlight {
  background-color: rgb(250, 175, 21);
  border-color: rgb(250, 175, 21);
  cursor: default;
  pointer-events: none;
  color: #674416;
  border-radius: 16%;
  padding: 3px;
  margin-left: 7px;
} 

.classes-page-tab-title {
  margin-bottom: 10px;
  margin-top: 7px;
  color: rgb(12 12 12 / 81%);
  font-size: 22px;
  font-weight: 100;
  background: rgb(205 225 249 / 33%);
  padding: 12px;
}
.done-outcome {
  background: rgb(67 172 187 / 24%);
  padding: 6px 11px;
  border-left: 3px solid rgb(75 159 75);
}

.done-outcome span {
  color: #4e6764;
}

.nss-outcome {
  background: rgb(67 172 187 / 24%);
  padding: 6px 11px;
  border-left: 3px solid rgb(75 159 75);
}
.nss-outcome span {
  color: #8f0565;
}

.custom-chip {
  /* color: aliceblue; */
  /* background-color: #f2f5ff; */
  /* background:#edf5ff; */
  /* background-color: #53a0ff24; */
  cursor: default !important;
  pointer-events: none !important;
  border-radius: 9px;
}
.custom-chip span {
  color: #6e7073;
}
v-chip__content {
  color: purple !important;
}
.wrapper {
  border: 1px solid red;
}
.page-title-wrapper {
  margin-bottom:30px;
}
.page-header {
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  color: #f6fdfe;
  font-size: 19px;
  margin-right: 18px;

  /* background-color: #013b85; */
  /* background: linear-gradient(43deg, #055ccbc4, #3966ad); */
  /* background: linear-gradient(43deg, #055ccbc4, #2187d5); */
  background: linear-gradient(43deg, #015884de, #5499cf);  
  padding: 11px;
  padding-left: 20px;
  padding-right: 20px;
  /* margin-bottom: 24px; */
    /* font-weight: 100; */
  /* color: #002e68a6; */
  /* background-color: #083b86; */
  /* background-color: #ffffff75;
  padding: 11px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
  font-weight: 600; */
  align-items: center;
}

#welcome {
  /* color: rgb(97 139 148 / 65%) !important; */
  padding: 18px 36px 1px 20px;
  background-color:#edf4f7ad;
  margin-bottom: 34px;
  margin-right: 17px;
  margin-top: -16px;
  border-radius: 10px;
}

.v-slide-group__content .v-tabs-bar__content {
  background-color: #083c80 !important;
}
v-slide-group__content v-tabs-bar__content {
  background-color: #c1d7ea !important; 
}
.v-item-group {
  /* border-bottom: 1px solid #e1e1e1 !important; */
  border-bottom: 1px solid #bbb9b9 !important;
}

.v-slide-group__wrapper {
  border-bottom: 1px solid #bbb9b9 !important;
}

.custom-tabs {
  margin-bottom: 0px;
  border-bottom: 1px solid #dad8d8 !important;
}
.v-tab--active {
  /* background-color: aquamarine !important; */
}
.tabs>.v-tabs-bar .v-tab:hover(.v-tab--active), .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-btn, .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon {
    color: rgba(43, 53, 251, 0.54);
    background-color: #dadada5e;
}
.v-tab--active::before {
  color: rgb(95, 113, 249) !important;
}
.v-main__wrap v-content__wrap {
  margin-top: -20px;
}

.NoEventsToday {
  padding: 20px;
  padding: 20px;
  background: #8bd6f647;
  color: #7a837e;
  border-radius: 5px;
  margin-top: 42px;
}

.caption {
  /* background-color: #5b5959; */
}
.classLinkBtn:hover {
  background-color: #d9dada !important;
  color: #5a5959 !important;
}
.class-detail-item {
  /* display: block; */
  flex: 0 1 auto; /* Default */
  list-style-type: none;
  border: 0px solid grey;
  /* margin: 0px 20px 0 10px; */
  /* background: #fafafa; */
  margin-right: 12px;
  /* padding: 8px; */
  /* background-color: rgb(219 236 255); */
  /* background-color: rgb(230 231 233); */
  border-radius: 12px;
}

.event-card {
  /* Add styling for the card, e.g., background color, border, and padding */
  padding: 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1) !important; /* Customize the shadow */
  border: 1px soild lightblue;
}

.event-date-time,
.event-client-info {
  flex: 1;
  /* Use flexbox to distribute space evenly between date/time and client info */
}

.event-date,
.event-time,
.event-client-name,
.event-client-type {
  /* Style date, time, client name, and client type as needed */
  padding: 0px 12px;
  border-left: 0px solid #61b6b9;
}
.event-client-name {
  color: #3e5d7c;
  margin-bottom: 0px;
  font-size: 23px;
  font-weight: 400;
  padding: 0px 19px;
}

.event-details {
  /* Add styling for event details like location, time span, note, outcome */
}

.event-location,
.event-time-span,
.event-note,
.event-outcome {
  /* Style event details items individually */
}
.event-details-top {
  font-size: 18px;
}
.event-label>i {
  margin-right: 13px;
  color: #535556;
  font-size: 15px !important;
  
}
.event-label {
  /* margin-right: 13px; */
  color: #535556;
  font-size: 15px !important;
}

.secondary-detail {
  margin-right: 0px;
  /* margin-left: 5px; */
  margin-left: 12px;
  font-size: 14px;
  /* font-style: italic; */
  /* color: #204b7a; */
}
.secondary-detail span:hover {
    cursor: default;
    background:#e0e0e0;
}

.col-title {
  color: #575757;
  font-size: 14px;
    font-style: italic;
}

.text-wrap {
  padding-left: 33px;
}

.status-text {
  
}
.class-link-btn {
  margin-bottom: 4px;
  margin-right: 6px;
  /* background: linear-gradient(135deg, #447491, #396adfcc); */
  /* background: linear-gradient(135deg, #0087f7, #396adfcc); */
  background: #0087f7;
}

.class-link-btn:hover {
  background-color: #116bb4;
  color: #ffffff;
  /* border: 2px solid #083b83; */
  /* background-color: lighten(yellow, 10%); */
  transform: scale(1); /* Prevent button from growing in size */
}
/* To prevent the button from moving */
.class-link-btn .v-btn__content {
  transition: none;
}

.summary {
  /* background-color: whitesmoke; */
  /* background-color: #5074980f; */
  border-left: 0px solid #b5b5b5;
  padding-left: 0px;
}

.terminal {
  background-color: #000;
  color: #fff;
  font-family: 'Menlo', monospace;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 24px;
  margin-left: 1px;
  margin-right: 1px;
  /* margin: 40px 0px 0px; */
}

.label {
  color: #00ff00; /* Green color for labels */
}

.loading-container {
  min-height: 100px;
  width: 40%;
  background-color:;
  color: #757575; /* slightly darker grey */
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px;
  border-radius: 4px;

}

.loading-text {
  margin-top: 20px;
  margin-bottom: 30px ;
  font-size: 22px;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.v-window__container {
  background-color: transparent !important;
}
.theme--light.v-tabs-items {
  /* background: transparent !important; */
  background: #ffffffdb;
}
.v-tab {
  margin-right: 16px;
}
.v-window-item v-window-item--active {
  padding: 17px !important
}

.v-alert__icon.v-icon {
    font-size: 24px;
    margin-top: 2px;
}

</style>