import { padEnd } from "lodash";

export default {
  loadingMessage: {
    fetchingClasses: 'Récupération des classes en cours...veuillez patienter',
    fetchingClass: 'Récupération du cours en cours...veuillez patienter'
  },
  message: {
    hello: 'Bonjour',
    welcomeText: 'Veuillez vérifier vos cours du jour ci-dessous.'  
  },
  title: {
    myClasses: 'Mes Cours',
    today: 'Aujourd\'hui',
    attendanceDue: 'Présences en attente',
    recentlySubmitted: 'Modifiable',
  },
  tab: {
    today: "Aujourd'hui",
    due: 'Présences en attente',
    submitted: "Modifiable",
  },
  sideNav: {
    classes: 'Cours',
    schedule: 'Horaire',
    payroll: 'Paie',
    profile: 'Profil'
  },
  editableTabContent: {
    modifiableInfoText: 'Les classes dont la présence peut être modifiée apparaîtront dans cette liste. Ces classes peuvent être modifiées une seule fois pendant 12 heures apres leur soumission.'
  },
  detail: {
    title: 'Détail de la classe',
    endSessionDate: 'Date de fin actuelle sujette à changement: ',
    reports: 'Rapports',
    attendance: 'Présence',
    postponeReschedule: 'Reporter ou reprogrammer',
    schedule: 'Horaire',
    quickSelect: 'Sélection rapide',
    quickSelectText: 'Utilisez les boutons ci-dessous pour choisir le même statut de présence pour tous les participants',
    alerts: {
      notModifiable: 'Cette classe n\'est pas modifiable.',
    },
    attendanceOptions: {
      present: 'Présent',
      absentNotice: 'Absence (prévue)',
      absentNoNotice: 'Absence (non-prévue)',
      absentLMC: 'Absence (annulation sous 24h)',
      reset: 'Remettre à zéro'
    },
    attendanceTableHeaders: {
      student: 'Apprenant(e)',
      attendance: 'Statut',
      lateLeft: 'En retard / Parti(e) tôt'
    },
    attendanceTableRows: {
      noStudentName: 'Aucune Nom'
    },
    classDuration: {
      label: 'Durée du cours',
      changeDurationLabel: 'Changer',
      durationChangeForm: {
        popupTitleBar: 'Changer Duration',
        infoBoxText: 'SVP remplir ...',
        guidelineForNote: 'SVP ajouter un note...',
        cancelBtnText: 'Annuler',
        confrimBtnText: 'Confirmer'
      }
    },
    postponeRescheduleTab: {
      cannotPostponeRescheduleAlert: 'Les cours en groupe ne peuvent pas être reportés via Trax.',
      formUseAlert: 'N\'utilisez ce formulaire que si le cours aura lieu à une date alternative à celle prévue.',
      classPostponedBy: 'Cours reporté par',
      postponedByLabelTeacher: 'Instructor',
      postponedByLabelStudent: 'Participant(e)',
      postponeRescheduleOptions: 'Choisissez parmi les options suivantes',
      postponeOptionsLabelReschedule: 'Reprogrammer', 
      postponeOptionsLabelAddToEndOfSession: 'Ajouter à la fin de la session',
      postponeOptionsLabelUnknown: 'Je ne sais pas',
      selectNewDateHeader: 'Veuillez sélectionner une nouvelle date et/ou une nouvelle heure pour ce cours',


    },
    scheduleTab: {
      title: {
        contractDetails: 'Détails du contrat',
        classesSchedule: 'Emploi du temps des cours',
      },
      contractListLabel: {
        course: 'Cours',
        client: 'Client',
        language: 'Langue',
        student: 'Étudiant(e)',
        level: 'Niveau',
        session: 'Session',
        day: 'Jour',
        time: 'Horaire',
        cutOffDate: 'Date limite',
        total: 'Totale',
        unscheduledHours: 'Heures non planifiées'

      },
      contractListValue: {
        noCutOffSet: 'Aucune date limite',

      }
    },
    popupText: {
      accessDenied: 'Access..',
      classNotStarted: 'The class is not yet started and attendances cannot be submitted at this time. Please contact ACA for assistance.'
    }
  },
  calendar: {
    title: 'Horaire',
  },
  profile: {
    userProfileCardTitle: 'Profil',
    username: 'Nom',
    email: 'Courriel',
    noEmail: 'Non disponible',
    activationDate: 'Date d\'activation sur Trax.',
    preferredLanguage: 'Langue préférée de Trax',
    switchLanguage: 'Changer langue de Trax',
    save: 'Enregistrer les paramètres',
  },
  pay: {
    title: 'Paie',
    week: 'Semaine'

  },
  buttonText: {
    goToClassTxt: 'Aller au cours',
    editClass: 'Modifier le cours',
  }
}