<template>
  <div>
  <!-- DATE RANGE -->
  <h1>Date Range Formatter for FM Queries</h1>
  <p>Gets a date range between 1 month ago and 2 months into future from current date
    and formats for use in the FM API query.</p>
  <br>
  <v-row
    align="center"
    justify="center"
  >
    <v-btn depressed @click="getDateRange">
      New Date Range
    </v-btn>
  </v-row>
  <br>
    <span>{{ dates }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
     dates: new Date()
    }
  },
  methods: {
    getDateRange(){
      // Format for FM Query 
      // "query":[{"scheduled date": "03/08/2013...03/15/2013",  
      // "schedule time START": "04:00:00"}]}
      const today = new Date()
      const start = new Date(today)
      const future = new Date(today)

      start.setDate(start.getDate() - 30) // Do we need records more than month period
      future.setDate(start.getDate() + 60) // How far in future: Not all classes avaialble

      today.toDateString()
      const pastDate = start.toLocaleDateString("en-US")
      const futureDate = future.toLocaleDateString("en-US")

      future.toLocaleDateString("en-US")

      console.log('Start ', start.toLocaleDateString("en-US")); // 9/17/2016
      
      this.dates = pastDate + '...' + futureDate
    },
    goToDash(){ // First view after login
      this.user = this.input.username
      localStorage.setItem('loggedIn', true)
      localStorage.setItem('User', this.user)
      this.$emit('clicked', this.user)
      this.$router.push('/dashboard')
    }
  }
}
</script>