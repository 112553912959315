import { Entity } from "@/types";
export const DEFAULT_ROW_PER_PAGE = 10;

export function getDefaultPagination(): Pagination {
  return {
    page: 1,
    totalItems: 0,
    rowsPerPage: DEFAULT_ROW_PER_PAGE,
    pages: 0
  };
}


export function getPagination(items: Entity[]) {
  console.log('From getPagination in [store-util] : ', items)
  const totalItems = items ? items.length : 0;
  const pages = Math.ceil(totalItems / DEFAULT_ROW_PER_PAGE);
  return { totalItems, pages, page: 1, rowsPerPage: DEFAULT_ROW_PER_PAGE };
}

export function getDateRange(num) {
  if(num === 1) {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  
    const formatDate = (date) => {
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    };
  
    return {
      start: formatDate(startOfMonth),
      end: formatDate(endOfMonth)
    };

  } else {
    const today = new Date();
    const sixWeeksAgo = new Date(today);
    const sixWeeksAhead = new Date(today);

    sixWeeksAgo.setDate(today.getDate() - 42); // 6 weeks = 42 days
    sixWeeksAhead.setDate(today.getDate() + 42); // 6 weeks = 42 days


    const formatDate = (date) => {
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    };

    return {
      today: formatDate(today),
      sixWeeksAgo: formatDate(sixWeeksAgo),
      sixWeeksAhead: formatDate(sixWeeksAhead)
    };
  }
}