// src/appSetup.js
import axios from 'axios';
import i18n from './i18n';

export async function setupApp() {

  try {
    // Assuming you have the user's token stored after login
    const token = sessionStorage.getItem('token');
    const langPref = localStorage.getItem('fmUserLanguage');

    if (!token) {
      throw new Error('User not logged in');
      this.$router.push('/login'); 
    }
    // Check if user language preference in local storage
    // const langPreferenceExists = await checkLocalStorageValue('fmUserLanguage')
    console.log('value ? ', langPref)

    // Setting up user selected language - translations in Trax ONLY
    if(langPref) {
      // set i18n 
      // const prefLang = await localStorage.getItem('fmCommLang');
      i18n.locale = langPref; 
    } else {
        // Set to default
        i18n.locale = 'en'; 
        // const payload = { "teacherToken": token }
        // const response = await axios.post(process.env.VUE_APP_API_URL + '/user', payload)
        // const { commLang } = response.data;

        // if(commLang === "English") {
        //   localStorage.setItem('fmUserLanguage', 'en');
        //   i18n.locale = 'en';
        // } else {
        //   localStorage.setItem('fmUserLanguage', 'fr');
        //   i18n.locale = 'fr';
        // }
      } 
    } catch (error) {
      console.error('Failed to set up app:', error);
      // Fallback to a default language if there's an error
      i18n.locale = 'en'; 
    }
 
  function checkLocalStorageValue(key) {
    const value = localStorage.getItem(key);
    return value !== null && value !== '';
  }

}