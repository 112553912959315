import { User, UserInfo } from "@/types";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import { 
  login, 
  logoutFM, 
  getTeacherCreds, 
  setUserPassword, 
  forgotPassword } from "@/utils/demo-api";
import {
  getUser,
  getToken,
  setToken,
  setUser,
  cleanSession,
  setFMID 
} from "@/utils/app-util";

import axios from 'axios'
import Swal from 'sweetalert2'

const api = process.env.VUE_APP_API_URL

export interface UserState {
  callingAPI: boolean;
  searching: string;
  user: User;
  token: string;
  // mode: string;
  userInfo: UserInfo;
  signedIn: boolean;
  // id: string
}

@Module({ store, dynamic: true, name: "userModule" })
class UserModule extends VuexModule implements UserState {
  public callingAPI = false;
  public searching = "";
  user = getUser();
  token = getToken();
  // fm_id = setFMID(id)
  signedIn = false

  public userChanged = false

  get userFromStorage(){
    //=========// 
    //FM ALPHA - April 24
    return { firstname: 'Prison', lastname: 'Mike' }
    //=========//

    if(sessionStorage.getItem('user')) {
      console.log('Getting user from session... ')
      // return sessionStorage.getItem('fm_id')
      return sessionStorage.getItem('user')

    } else {
      console.log('No User Logged In ')
      return 'No User'
    } 
    // return sessionStorage.getItem('fm-user')
  }

  public userInfo = {} as UserInfo;
  // signedIn = false;

  // get isSignedIn(): boolean {
  //   const usr = this.user.email 
  //   alert(`get isSignedIn Works...?, ${JSON.stringify(usr)}`)
  //   return this.user.email && this.token ? true : false;
  //   // true = shows the navigation 
  //   // return true 
  // }

  get isSignedIn(): boolean {
    // sessionStorage.removeItem('user')
    // const user = sessionStorage.getItem('user')
    const user = sessionStorage.getItem('token')
    console.log('user from session -> ',  user)

    if(!user || user === null || user === 'No Token Returned' || user === 'forgot' ) {
      return false
    } 
    // return this.user.email && this.token ? true : false;
    return true
  }

  // get isSignedIn(): boolean {
  //   return this.signedIn
  // }

  // get signedInUser(): UserInfo {
  //   return this.user
  // }
  get signedInUser(){
    return this.user
  }

  @Mutation
  private loginLoading() {
    this.callingAPI = !this.callingAPI;
  }
  @Mutation
  private globalSearching() {
    this.searching = this.searching === "" ? "loading" : "";
  }

  @Mutation
  private setUser(_user: User): void {
    this.user = _user;
  }

  @Mutation
  private setToken(_token: string) {
    this.token = _token;
  }

  @Mutation
  private setUserInfo(_userInfo: UserInfo) {
    this.userInfo = _userInfo;
  }

  @Mutation
  private setUserSignedIn(): void {
    console.log('setUserSignedIn')
    this.signedIn = true;
  }

  @Mutation
  private toggleUserChanged() {
    this.userChanged = !this.userChanged;
    console.log('USER CHANGED -> ', this.userChanged)
  }

  @Action({ rawError: true })
  async setUserChanged() {
    this.toggleUserChanged()
  }

  @Action({ rawError: true })
  async resetPasswordRequest(email: string): Promise<TODO>{
  // Promise<TODO> {
   console.log('password reset from ForgotPassword[user.ts] ', email)
   return new Promise(function(resolve, reject) {
    forgotPassword("forgotPassword", { email })
      .then(res => {
        console.log('FM DATA .. ', res)
       
        // if(!res.success) { 
        //   resolve({ response: res, success: false })
        // } 
        // console.log('FM DATA .. ', res.data.accessToken)
        // console.log('userModule.signIn -> sign in successful...check storage for details.')
        // setToken(res.data.accessToken)
        // setUser(JSON.stringify(res.data.user))
        // resolve({ response: res, success: true })  
      })
    })

  }

  @Action({ rawError: true }) 
  public async checkUserInStorage(): Promise<TODO> {
    return new Promise((resolve, reject) => {
      // const user = sessionStorage.getItem('fm-user')
      // const parsedUser = JSON.parse(user)
      // const usr = this.user.email 

      // const usr = sessionStorage.getItem('fm-user')
      // const token = sessionStorage.getItem('fm-token')
      // const loggedInUser = sessionStorage.getItem('user')
      const loggedInUser = sessionStorage.getItem('token')
      console.log('[user.ts - checkUserInStorage] Promise ...', loggedInUser) // Check this is accessible for use below


      // const email = user['email']
      // alert(`USER: ${JSON.stringify(usr)}`)
      // console.log('userModule.checkUserInStorage -> ', fmID)
      // if(usr && token) {
      if(loggedInUser) {
        console.log('userModule.checkUserInStorage -> user and token exist')
        this.setUserSignedIn()
        resolve(true)
      } else {
        console.log('userModule.checkUserInStorage -> user not found in storage...promise reject')
        reject(false)
      }
  })
}

  @Action({ rawError: true })
  public async getUserDataFromFM():Promise<TODO> {

    return new Promise(async function(resolve, reject) {
      const token = sessionStorage.getItem('token');

      try {
        const payload = {
          "teacherToken": token,
        }

        console.log('payload for get user: ', payload)
        const response = await axios.post('/api/user', payload)
        console.log('get user response: ', response)
        // Check that the user is returned properly      
        sessionStorage.setItem('userProfile', JSON.stringify(response))

      } catch(error) {
        console.log('Get user endpoint err : ', error)
        await this.forceLogout()
      }
    })
  }

  async forceLogout() {

    Swal.fire({
        title: 'Logged In Elsewhere?',
        text: 'You may be currently logged in on another browser or device. Please log out from there and try logging in again here.',
        icon: 'warning',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      }).then(async (result) => {
          if (result.isConfirmed) {  
            alert('22')
            await sessionStorage.clear()
            // await localStorage.clear()
            window.location.replace(process.env.VUE_APP_URL + "/login"); 
          }
      });
  }

  // Custom function to log in to FM, retrieve and store FM session 
  @Action({ rawError: true })
  public async signIn(userInfo: { username: string; password: string }): Promise<TODO> {
    const { username, password } = userInfo;
    console.log('USER : ', username )
    // SIGN IN TO FM
    // const { data } = await login("login", { username, password });
    return new Promise(function(resolve, reject) {
      login("login", { username, password })
        .then(res => {
          console.log('FM DATA .. ', res)

          // if(res.code === '403') {}
          
          if(!res.success) { 
            resolve({ response: res, success: false })
          } 
          console.log('FM DATA .. ', res.data.accessToken)
          console.log('userModule.signIn -> sign in successful...check storage for details.')
          setToken(res.data.accessToken)
          setUser(JSON.stringify(res.data.user))
          resolve({ response: res, success: true })  
        })
      })

    // // NB: Must be set to push to dashboard and for conditional rendering nav and menus
    // this.setToken(data.accessToken);
    // this.setUser(data.user);
    
  }

  @Action({ rawError: true })
  async logout(num) { // Originally not async for dummy logout
    this.setToken("");
    this.setUser({} as User);
    cleanSession(); // still required?
    await localStorage.removeItem('userProfile')
    await sessionStorage.clear()
  }

  // Check user is admin
  @Action({ rawError: true })
  public async validateAsAdmin(userID: string): Promise<TODO> {
    // const { userID } = userInfo;
    console.log('USER ID : ', userID )
    return new Promise(function(resolve, reject) {
        try {
          // API call 
          const url = api + '/validate' 
  
          // axios.post(url, { token: token, tID: teacher_id })
          // axios.post(url, options)
      
          // API v2 - no auth / server handles FM login and tokens
          axios.post(url, { uid: userID })
            .then((response) => {
              console.log('validate ', response)
              resolve(response)
            })
      

        } catch (error) {
          console.log('Error validating user -> ', error)
          reject(error)
          
        }
      })
  }

  @Action({ rawError: true })
  public async createAPIPasswordForTeacher(user) {
  console.log('user.createAPIPass ', JSON.stringify(user))
  // Call change password route with userInfo 
  const response = await setUserPassword("setUserPassword", user).then(res => {
    console.log('Res demo-api password set -> ', res)
  });
  return 'userModule.apiPasswordCalled'
  }

  @Action({ rawError: true })
  public async getMongoTeacher(userInfo: { username: string; password: string }): Promise<TODO> {
      console.log('Getting teacher credentials from Mongo collection... ')
      const { username, password } = userInfo; // Password not required
      // console.log('this call ....', t)
      // See demo-api.ts for this function
      const data  = await getTeacherCreds("getTeacherCreds", { username, password }); // must pass object
      console.log('MONGO RESPONSE -> ', typeof(data)) // Returns teacher FM ID 
      return new Promise(function(resolve, reject) {
        const result = JSON.stringify(data.data)
        if( result === "Teacher not found. No account") { 
          console.log('Reject in promise ....')
          resolve(false)
        }

      // this.setFMID('T0000475')
      // Temp solution - save fm_id direct to session here
      const user = JSON.stringify(data.data)
      console.log('T ID [user.ts]', user) 
      const id  = JSON.stringify(data.data.teacher_id)
      const isAdmin = JSON.stringify(data.data.isAdmin)
      sessionStorage.setItem('user', user)
      sessionStorage.setItem('fm_id', id) 
      sessionStorage.setItem('isAdmin', isAdmin)
      resolve(true)
    })
  }
}

export const userModule = getModule(UserModule);
