// Select and convert duration for display 

export function compareAndConvertDurations(eventData) {

    // Convert decimal hours to HH:MM:SS format - only used for overrideDuration
    function decimalToHHMMSS(decimal) {
      const hours = Math.floor(decimal);
      const minutes = Math.round((decimal - hours) * 60);
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
    }

    function convertToDecimalHours(timeString) {
      // Split the time string into hours, minutes, and seconds
      const [hours, minutes, seconds] = timeString.split(':').map(Number);
    
      // Calculate the decimal hours
      const decimalHours = hours + minutes / 60 + seconds / 3600;
    
      // Round to two decimal places
      const roundedDecimalHours = Math.round(decimalHours * 100) / 100;
    
      // Return the result with "hrs" appended
      return `${roundedDecimalHours} hrs`;
    }
  // if (eventData.overrideDuration !== 'NaN:NaN:00') {
  if(eventData.overrideDuration === "") {
    // return this.eventData.duration
    return { duration: convertToDecimalHours(eventData.duration), type: 'default' }
  }
  // Convert the override to hh:mm:ss
  const overrideDurationConverted = decimalToHHMMSS(eventData.overrideDuration)

  console.log('decimal convert ', overrideDurationConverted)
  console.log('event in duration help ', eventData)

  // Convert durations to minutes for easy comparison
  function toMinutes(duration) {
    console.log('here ', eventData)
    const [hours, minutes] = duration.split(':').map(Number);
    return hours * 60 + minutes;
  }

  // Pass the two duraiton fields to convert to minutes
  const minutes1 = toMinutes(eventData.duration);
  const minutes2 = toMinutes(overrideDurationConverted); //pass converted override value -> hh:mm:ss

  // Class duration change has been approved by ACA Admin and file updated
  if (minutes1 === minutes2) {
    // If durations are equal, fomat to x hrs x mins and return the first duration
    // return this.eventData.duration;
    return eventData.duration
  } else {
    // const durationToDecimal = convertToDecimalHours(overrideDurationConverted)
    // If durations are not equal, return the override
    return { duration: convertToDecimalHours(overrideDurationConverted), type: 'override' }
  }

}