<template>
  <div class="two-fa-input-container">
    <v-sheet class="avatar-container">
      <v-img
        class="login-avatar"
        src="@/assets/img/small-aca.jpeg"
      />
    </v-sheet>
    <p class="instruction">Please enter the 6-digit code you received:</p>
    <div class="input-container">
      <input
        v-for="(digit, index) in codeDigits"
        :key="index"
        v-model="inputCodes[index]"
        class="digit-input"
        type="text"
        maxlength="1"
        :ref="'digitInput' + index"
        @input="focusNextInput(index)"
      />
    </div>

    <button v-if="!isProcessing" class="submit-button" @click="submitCode" :disabled="isProcessing">
      <span >Submit</span>
    </button>

<button v-else class="submit-button processing-button">
      <div class="processing-content">
      <span class="processing-text">Processing...please wait</span>
      <span class="spinner"></span>
    </div>

</button>
    
  </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  data() {
    return {
      codeDigits: [1, 2, 3, 4, 5, 6], // Assuming you have 6 input fields
      inputCodes: Array(6).fill(''), // Initialize an array to store input values
      isProcessing: false
    };
  },
  methods: {
    goToLogin () {
      window.location.replace(process.env.VUE_APP_URL + "/login");
    },
    focusNextInput(index) {
      // Focus the next input field
      if (index < this.codeDigits.length - 1 && this.inputCodes[index].length === 1) {
        this.$refs['digitInput' + (index + 1)][0].focus();
      }
    },

    async submitCode() {
      // alert('sending code for FM validation...please wait')
      this.isProcessing = true

      const t = sessionStorage.getItem('mfaToken')
      // const tStr = JSON.stringify(t)
      // console.log(t)

      try {
        const token = sessionStorage.getItem('mfaToken')
        // const url = '/api/2fa';
        // const url = 'http://localhost:3001/api/2fa';
        const url = process.env.VUE_APP_API_URL + '/2fa';
        // Concatenate the input codes to form a 6-digit code
        // this.enteredCode = this.inputCodes.join('');
        const enteredCode = this.inputCodes.join('');
        console.log('Entered code:', this.enteredCode);

        const ip = await this.getUserIPAddress()

        const payload = {
          token: token,
          code: enteredCode,
          language: 'EN',
          ipaddress: ip
        };

        console.log('mfa payload ', payload)

        // Headers (if needed)
        const headers = {
          'Content-Type': 'application/json',
          // Add any additional headers here
        };

        // POST request to the NODE endpoint
        // const response = await axios.post('http://localhost:3001/api/2fa', payload, { headers });
        const response = await axios.post(url, payload, { headers }); // production
        console.log('Response from endpoint:', response.data);

        const swalHTML = '<p style="color: grey;font-family: helvetica">A problem occured with your authentication. Please contact ACA for further assistance</p>'

        // if(response.data === 'Invalid multi-factor authentication code') {
        if(response.data.name === 'Error') {
          this.isProcessing = false

          Swal.fire({
          title: 'Error',
          html: swalHTML ,
          icon: 'error',
          confirmButtonText: 'Close',
          // willClose: this.goToLogin       // Removed () from here
        })

        }
        if(response.data.status === 'OK') {
          console.log('code response ', response.data.response)
          await sessionStorage.setItem('token', response.data.response.token)

          // Get User Data - needed before dash load
          await this.fetchUserData()
          this.goToDashboard()
        }
      } catch (error) {
        console.log('Error sending request to endpoint:', error);
        // Handle error gracefully if needed
        // error.response.data.response
        if(error) {
          const swalHTML = '<p style="color: grey;font-family: helvetica">A problem occured with your authentication. Please try again later. If this issue persists, please contact ACA for further assistance.</p>'
          console.log('2FA', error)
          Swal.fire({
            title: 'Error',
            html: swalHTML ,
            icon: 'error',
            confirmButtonText: 'Close',
            willClose: this.goToLogin   
          })
        }
      }
    
      // OLD TEST CODE - WORKS BY PASSING 2FA

      // // Call to backend to validate code
      // const codeIsValid = true
      // // Handle submission logic here
      // if(codeIsValid) {
      //   // Retrieve user's IP address
      //   const ipAddress = await this.getUserIPAddress();
         
      //   // Save token to local storage for all requests
      //   // const token = this.$route.params.token;
      //   // localStorage.setItem('token', token);
      //   localStorage.setItem('ipAddress', ipAddress);
      //   localStorage.removeItem('reg_token')
      //   this.goToDashboard()

      // } else {
      //   // Display invalid code error message
      // }
    },

    // Move to helper / services
    async getUserIPAddress() {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
      } catch (error) {
        console.error('Error fetching IP address:', error);
        return null;
      }
    },

    async fetchUserData() {
        // alert('fetching user from 2fa before going ot dash..does token exist in local')
        const token = sessionStorage.getItem('token');

        const base_url = process.env.VUE_APP_API_URL
        console.log('Base ', base_url)

        try {
          const payload = {
            "teacherToken": token,
          }

          const response = await axios.post(process.env.VUE_APP_API_URL + '/user', payload)

          console.log('fetchUser[2fa]: ', response)
          // Check that the user is returned properly
        
          await sessionStorage.setItem('userProfile', JSON.stringify(response))
          // alert('user set in local ..')
          return

        } catch(error) {
          console.log('Get user endpoint err : ', error)
          // alert('get user error in console?')
        }
    },

    goToDashboard() {
      // this.$router.push("/dashboard");
      // Router not working correctly - fix in next iteration
      // router.push("/dashboard");
     
      // Add BASE_URL + /dashboard 
      const url = process.env.VUE_APP_URL + '/classes'
      window.location.replace(url); 
      this.isProcessing = false
    }
  }
};
</script>

<style scoped>
.two-fa-input-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatar-container {
  max-width: 200px; /* Set the maximum width for the avatar container */
  margin-bottom: 20px; /* Add some bottom margin */
}

.login-avatar {
  width: 100%;
  height: auto;
}

.instruction {
  font-size: 18px;
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px; /* Increased vertical spacing */
}

.digit-input {
  width: 60px;
  height: 60px;
  font-size: 24px;
  text-align: center;
  border: 2px solid #ccc; /* Default light grey border */
  border-radius: 10px;
  margin: 0 10px; /* Added spacing between input boxes */
  transition: border-color 0.3s ease;
}

.digit-input:focus {
  outline: none;
  border-color: rgba(0, 123, 255, 0.5);
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.3);
}

.submit-button {
  padding: 12px 40px; /* Adjusted padding */
  font-size: 18px; /* Adjusted font size */
  border-radius: 8px; /* Slightly rounded edges */
  background: linear-gradient(135deg, #007bff, #408cff); /* Refined gradient */
  border: none;
  color: #fff;
  cursor: pointer;
  transition: box-shadow 0.3s ease; /* Added transition for shadow */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
  position: relative;

  min-width: 250px; /* Adjust this to match your button's width */
  height: 60px; /* Adjust this to match your button's height */

}

.processing-button {
  padding: 12px 40px; /* Adjusted padding */
  font-size: 18px; /* Adjusted font size */
  border-radius: 8px; /* Slightly rounded edges */
  background: #e0effb;
  border: none;
  color: #fff;
  cursor: none;
  transition: box-shadow 0.3s ease; /* Added transition for shadow */
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.1);
  position: relative;

  min-width: 250px; /* Adjust this to match your button's width */
  height: 60px; /* Adjust this to match your button's height */
}

.processing-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.processing-text {
  margin-right: 17px;
  font-size: 18px;
  color: rgb(3 59 130);
}

.button-content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.spinner {
  display: inline-block;
  width: 26px;
  height: 26px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #033b83;
  animation: spin-48386b9f 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
</style>
