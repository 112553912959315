<template>
  <v-container style="margin: 20px 0px; padding: 0px;">
    <v-card>
      <v-card-title style="background: #3b75c8; color: aliceblue; font-size: 22px;">
        {{ $t('detail.scheduleTab.title.classesSchedule') }}
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="eventData.schedule"
        class="elevation-1"
        :hide-default-footer="true"
        :items-per-page="eventData.schedule.length"
      >
        <template v-slot:item.index="{ index }">
          <span class="index-column">{{ index + 1 }}</span>
        </template>
        <template v-slot:item.date="{ item }">
          <!-- {{ item }} -->
            {{ formatIsoDate(item) }}
          <!-- {{ formatDate(item.scheduleDate) }} -->
        </template>
        <template v-slot:item.time="{ item }">
          {{ formatAndCleanTime(item.startTime, item.endTime) }}
        </template>
        <template v-slot:item.instructor="{ item }">
          {{ item.teacherFirstName }} {{ item.teacherLastName }}
        </template>
        <template v-slot:item.duration="{ item }">
          {{ item.duration }}
        </template>
        <template v-slot:item.status="{ item }">
          <span style="color: #5656656">
          <v-chip
            :color="getStatusColor(item.status)"
            class="custom-chip"
            pill
          >
            {{ item.status }}
          </v-chip>

          <br>
          {{ eventData.attendances.length }} 

          {{ eventData.registrations.length }}
          {{ item }}
          <em v-if="item.status === 'Class done' && eventData.attendances.length === 0 || item.status === 'Class done' && eventData.attendances.length < eventData.registrations.length">
            <small style="font-size: 12px;">
              <i><b>(attendance report required)</b></i>
            </small>
          </em>
        </span>

        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { getLocalStorageValue } from '../utils/localStorageHelper';
import { formatToFrenchDate, formatToFrenchDateTime, formatToShortFrenchDate, formatEnglishDateToFrench, formatToShortEnglishDate } from '../utils/dateFormatter.js';


export default {
  name: 'ScheduleTable',
  props: {
    eventData: {
      type: Object,
      required: true
    },
    headers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      traxLanguageFromStorage: getLocalStorageValue('fmUserLanguage'),
      isLoading: false,
      error: null,
    };
  },
  methods: {
    formatIsoDate(c) {
      // console.log('Format the  date:', c.isoStart); // For debugging
      if(this.traxLanguageFromStorage === 'en') {
        return formatToShortEnglishDate(c.isoStart);      
      }
        return formatToShortFrenchDate(c.isoStart);      
    },

    formatTimeAMPM(startTime, endTime) {
      function to12HourFormat(time24) {
        const [hour, minute] = time24.split(':').map(Number);
        const period = hour >= 12 ? 'PM' : 'AM';
        const hour12 = hour % 12 || 12;
        return `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
      }

      function calculateDuration(start, end) {
        const [startHour, startMinute] = start.split(':').map(Number);
        const [endHour, endMinute] = end.split(':').map(Number);

        let hours = endHour - startHour;
        let minutes = endMinute - startMinute;

        if (minutes < 0) {
          minutes += 60;
          hours -= 1;
        }

        return { hours, minutes };
      }

      const startFormatted = to12HourFormat(startTime);
      const endFormatted = to12HourFormat(endTime);

      const duration = calculateDuration(startTime, endTime);
      const durationString = `${duration.hours}hr${duration.hours !== 1 ? 's' : ''}${duration.minutes > 0 ? ` ${duration.minutes}min` : ''}`;

      return `${startFormatted} - ${endFormatted} (${durationString})`;
    },

    formatAndCleanTime(startTime, endTime) {
      const timeString = this.formatTimeAMPM(startTime, endTime);
      return timeString.replace(/\s*\(\d+hrs\)/, '');
    },

    formatDate(date) {
      const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
      const newDate = new Date(date);
      const formattedDate = newDate.toLocaleDateString('en-US', options);

      // Extract day and add appropriate suffix
      const day = newDate.getDate();
      const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
                     (day % 10 === 2 && day !== 12) ? 'nd' :
                     (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

      // Format the final date string
      return formattedDate.replace(/(\d+)/, `${day}${suffix}`);
    },

    getStatusColor(status) {
      switch (status) {
        case 'Scheduled':
          return 'green';
        case 'Done':
          return 'blue';
        case 'Cancelled':
          return 'red';
        default:
          return 'grey';
      }
    }
  }
}
</script>

<style scoped>
/* Add any scoped styles here */
.v-chip__content { color: white !important}
.custom-chip { color: white !important}
</style>
