<template>
  <!-- <div class="tab-wrapper" @click="check">
    <div v-show="isActive"><slot></slot></div>
  </div> -->
  <!-- <div class="tabs">
    <div>
      <button v-for="(tab,index) in tabs" :key="index" @click="current = index">
        {{ tab }}
      </button>
    </div>
    <div class="tab-content">
      <div v-show="current === 0" class="tab1">Content one</div>
      <div v-show="current === 1" class="tab2">Content two</div>
    </div>
  </div> -->
</template>

<script>

export default {
  props: {
    selected: { default: false }
  },
  data() {
    return {
              isActive: false,
              // selected: true
              tabs: ['tab1', 'tab2'],
              current: 0
          }
  },
  methods: {
    // tabClick(i) { 
    //   current = i
    // },
    check() { this.checked = !this.checked; }
  },
  computed: {
    href() {
            return '#' + this.name.toLowerCase().replace(/ /g, '-');
        }
  },
  mounted() {
    this.isActive = this.selected;
  }
};
</script>

  <!-- Vue.component('tab', {
      
      template: `

          <div v-show="isActive"><slot></slot></div>

      `,
      
      props: {
          name: { required: true },
          selected: { default: false}
      },
      
      data() {
          
         
          
      },
      
      computed: {
          
         
      },
      
      mounted() {
          
          
          
      }
  }); -->