export default async function convertData(jsonData) {
  console.log('on the road')
  alert('adapter works....')

  // Not neccessary ? just include keys from api_CLASSES that must change in the api_PFUS
  const json2 = {};
  
  // Define key mappings from the second JSON to the first JSON
  const keyMappings = {
    "__pk_PFU": "__pk_Classes"
    // Add other key mappings here
  };
  
  function adaptKeys(jsonData, keyMappings) {
    const adaptedJson = {};
  
    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        const adaptedKey = keyMappings[key] || key; // change to whatever is in kepMappings or keep the same
        adaptedJson[adaptedKey] = json[key];
      }
    }
  
    return adaptedJson;
  }
  
  // Use the adapter function to adapt the keys in the first JSON document
  const adaptedJson1 = adaptKeys(json1, keyMappings);
  
  console.log(adaptedJson1);
  

}