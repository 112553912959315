var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.event.outcome !== 'EMPTY'),expression:"event.outcome !== 'EMPTY'"}]},[_c('v-col',{staticStyle:{"padding":"0px","margin-top":"0px"}},[_c('div',{class:[
      'ma-1 d-flex align-center',
        {
          'done-outcome': _vm.event.outcome === 'DONE',
          'poi-outcome': _vm.event.outcome !== 'DONE'
        }
      ],style:(_vm.getOutcomeStyle(_vm.event.outcome))},[_c('v-icon',{style:({ color: _vm.getIconColor(_vm.event.outcome) })},[_vm._v(" "+_vm._s(_vm.getIcon(_vm.event.outcome))+" ")]),_c('span',{staticStyle:{"line-height":"22px","margin-left":"12px","margin-top":"2px","color":"#686868","font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.event.status)+" ")]),(_vm.event.status === 'Class done' && _vm.event.studentCount > _vm.event.attendancesCount)?_c('span',{staticStyle:{"margin-left":"12px","color":"#686868","font-size":"15px","font-weight":"400"}},[_c('i',[_c('b',[_vm._v("(attendance report required)")])])]):_vm._e()],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }