<template>
  <article>
    <header class="tabs">
      <!-- <ul>
        <li v-for="(tab, index) in tabs" :key="index">
    {{ tab }} 
    {{ tab.data.attrs['name'] }}


          <div class="nav-item"
              @click="selectTab(tab)">
            {{ tab.name }}
          </div>
        </li>
      </ul> -->
    <Tab />    
    
    </header>
    <section class="tabs-details">
      <slot></slot>
    </section>
  </article>
</template>

<script>
  import Tab from '@/components/Tab.vue'
  export default {
    components: { 
     Tab
  },
    data: () => {
      return {
      }
    },
    methods: {
      selectTab(selectedTab) {
        this.tabs.forEach(tab => {
          tab.isActive = tab.name === selectedTab.name;
        });
      }
    },
    created() {

    },
    mounted() {
      console.log('TABS in created[Tabs.vue]-> ',  this.$slots.default)
      // const slotJSON = JSON.parse(this.$slotsdefault)
      // console.log('JSON in created[Tabs.vue]-> ',  slotsJSON)
      this.tabs = this.$slots.default    
    }
  }
</script>

<style scoped>
  .tabs {
    border-bottom: 1px solid #fefefe;
    margin: 0 10px;
  }

  .tabs-details {
    padding: 10px;
  }

  ul {
    display: flex;
    padding: 0;
    list-style: none;
  }

  li {
    margin-right: 40px;
  }

  .nav-item {
    cursor: pointer;
  }

</style>