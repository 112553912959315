/* eslint-disable */
// @ts-ignore
import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueProgressBar from 'vue-progressbar';
// import VueI18n from 'vue-i18n'
import i18n from './i18n'
import { setupApp } from './appSetup';

// Vue.use(VueI18n); // Tell Vue to use VueI18n

// const language = localStorage.getItem('commLang')

// console.log('Lang in main -> ', language)
// alert('33')

// Create VueI18n instance
// const i18n = new VueI18n({
//   locale: l, 
//   messages: {
//     en: {
//       message: {
//         hello: 'Welcome back',
//         welcomeText: 'Please review your classes for the day below.'
//       },
//       title: {
//         today: 'Classes Today',
//         myClasses: 'My Classes',
//         timesheet: 'My Timesheet'
//       },
//       tab: {
//         today: "Today",
//         due: 'Attendance Due',
//         submitted: 'Recently Submitted'

//       },
//       sideNav: {
//         classes: 'Classes',
//         schedule: 'Schedule',
//         payroll: 'Payroll',
//         profile: 'Profile'
//       },
//       detail: {
//         title: 'Class Detail',
//         reports: 'Reports',
//         attendance: 'Attendance',
//         attendanceOptions: {
//           present: 'Present',
//           absentNotice: 'Absent (Early Notice)'
//         }
//       }
//     },
//     fr: {
//       message: {
//         hello: 'Bonjour',
//         welcomeText: 'Veuillez vérifier vos cours du jour ci-dessous.'
        
//       },
//       title: {
//         myClasses: 'Mes Cours',
//         today: 'Cours Aujourd\'hui',
//       },
//       tab: {
//         today: "Aujourd\'hui",
//         due: 'Présences en attente',
//         submitted: "Envoyé récemment",
//       },
//       sideNav: {
//         classes: 'Cours',
//         schedule: 'Horaire',
//         payroll: 'Paie',
//         profile: 'Profil'
//       },
//       detail: {
//           title: 'Détail de la classe',
//           reports: 'Rapports',
//           attendance: 'Présence',
//           attendanceOptions: {
//             present: 'Présent',
//             absentNotice: 'Absent (Préavis)'
//           }

//       }
//     },
//   },
// });


// Progress bar options
const options = {
  color: 'blue', // '#bffaf3'
  failedColor: '#874b4b',
  thickness: '3px',
  transition: {
    speed: '0.1s',
    opacity: '0.3s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
};

Vue.config.productionTip = false;

Vue.use(VueProgressBar, options);
  
async function initializeApp() {
  await setupApp();
  new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
}

initializeApp().catch(error => {
  console.error('Failed to initialize app:', error);
  // You might want to show an error message to the user here
});
