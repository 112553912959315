export async function checkIsPostponed(classData) {
  console.log('setIsPostponed[utils] ', classData['data'][0]['status'])
  // alert('set called....') 

  if (typeof classData === 'object' && classData !== null) {
    // alert('object catch...');
  console.log('Obj ', classData)
    if (classData['data'][0]['status'].toLowerCase().includes('postponed')) {
      classData['data'][0].isPostponed = true
      return classData
    }
  } else {
    // Loop through the array and update isPostponed field if status contains 'postponed'
    await classData.forEach(classObj => {
      if (classObj.status.toLowerCase().includes('postponed')) {
        classObj.isPostponed = true;
      } else {
        classObj.isPostponed = false;
      }
    });
  }
  return classData;
}
  

