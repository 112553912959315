// forceLogout.js
export default {
  data() {
    return {
      inactivityTimeout: null,
      inactivityDuration: 1800000, // 30 minutes
      // inactivityDuration: 20000, // 20 seconds for testing


      isLoggedIn: true, // Assume logged in initially
    };
  },
  mounted() {
    this.startInactivityTimer();
  },
  beforeDestroy() {
    this.stopInactivityTimer();
  },
  methods: {
    startInactivityTimer() {
      if (this.isLoggedIn) {
        this.resetInactivityTimer();
        this.addActivityListeners();
      }
    },
    stopInactivityTimer() {
      this.removeActivityListeners();
      clearTimeout(this.inactivityTimeout);
    },
    resetInactivityTimer() {
      clearTimeout(this.inactivityTimeout);
      if (this.isLoggedIn) {
        this.inactivityTimeout = setTimeout(this.logout, this.inactivityDuration);
      }
    },
    addActivityListeners() {
      ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'].forEach(
        event => window.addEventListener(event, this.resetInactivityTimer)
      );
    },
    removeActivityListeners() {
      ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'].forEach(
        event => window.removeEventListener(event, this.resetInactivityTimer)
      );
    },
    logout() {
      if (this.isLoggedIn) {
        console.log('User logged out due to inactivity');
        // alert('logged out')
        this.isLoggedIn = false;
        this.stopInactivityTimer();
        sessionStorage.clear();
        window.location.href = process.env.VUE_APP_URL + "/login";
      }
    },
  },
};