import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { NavigationGuardNext, Route } from 'vue-router'
import { userModule } from "@/store/modules/user";
import { FMModule } from "@/store/modules/fm";
import ErrorPage from "@/components/404.vue";
import Dashboard from "@/pages/Dashboard.vue";
import Dash from "@/pages/Dash.vue";

import Classes from "@/pages/Classes.vue";
import Hours from "@/pages/Hours.vue";
import About from "@/pages/About.vue";
import Login from "@/pages/Login.vue";
import Postpone from "@/pages/PostponeCancel.vue";

import ChangePassword from "@/components/ChangePassword.vue";
import RegisterForm from "@/pages/RegisterForm.vue";


// Custom ACA pages
import Dates from "@/pages/Dates.vue";
import Calendar from "@/pages/CalendarWS.vue";
import Reg from "@/pages/Reg.vue";
import Attendance from "@/pages/Attendance.vue";
import UserProfile from "@/pages/UserProfile.vue";
import TeacherList from "@/pages/TeacherList.vue";
import PrivateClassesList from "@/pages/PrivateClassesList.vue";
import ForgotPassword from "@/pages/ForgotPassword.vue";
import Admin from "@/pages/Admin.vue";
import { checkFMToken } from '@/utils/fm-api';
import Timesheet from "@/pages/Timesheet.vue";
import Payroll from "@/pages/payroll.vue";

import TokenError from "@/pages/TokenError.vue";
import RegConfirm from "@/pages/RegConfirm.vue";
import TwoFactorCheck from "@/pages/2FACheck.vue";


// SET UP FM AUTH CHECK HERE
// function requireAuth(to: TODO, from: TODO, next: TODO) {
//   console.log('Checking Auth token')

//   if (!userModule.isSignedIn) {
//   if (FMModule.isTokenValid) {
//     console.log('TOKEN INVALID...go to login')
//     next({
//       path: "/login",
//       query: { redirect: to.fullPath }
//     });
//   } else {
//     console.log('TOKEN...?????')
//     next();
//   }
// }

async function requireAuth(to: Route, from: Route, next: NavigationGuardNext) {
  console.log(`userModule.isSignedIn ${userModule.isSignedIn}`);
  console.log(`Navigating to: ${to.path}`);
  console.log(`Route object:`, to);

  // Check if the route is '/forgot'
  if (to.path === '/forgot') {
    next(); // Allow navigation to '/forgot' without redirecting
    return;
  }

  try {
    const isUserSignedIn = await userModule.checkUserInStorage();
    console.log('User signed in -> ', isUserSignedIn);

    if (isUserSignedIn) {
      console.log('User signed in true in middleware [router-checkUserInStore]');
      const user = userModule.getUserDataFromFM();
      next();
    } else {
      console.log('User not signed in..');
      next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
    }
  } catch (error) {
    console.error('Error checking user authentication:', error);
    next({
      path: "/login",
      query: { redirect: to.fullPath }
    });
  }
}

Vue.use(VueRouter);

  const routes: Array<RouteConfig> = [
    { path: "/", name: "root", component: Classes, beforeEnter: requireAuth },
    { path: "/forgot", component: ForgotPassword, name: "forgot-password" },
    { path: "/login", component: Login, name: "Login" },
    { path: "/404", component: ErrorPage, name: "ErrorPage" },
    { path: "/token-error", component: TokenError, name: "TokenError" },
    { path: "/reg-confirm", component: RegConfirm, name: "RegConfirm" },
    { path: "/2fa-check/:token", name: "2fa-check", component: TwoFactorCheck },

  {
    path: "/classes",
    component: Classes,
    name: "classes",
    meta: {
      title: 'Classes'
    },
    beforeEnter: requireAuth
  },
  {
    path: "/pay",
    component: Payroll,
    name: "pay",
    meta: {
      title: 'Pay'
    },
    beforeEnter: requireAuth
  },  
  // {
  //   path: "/classes",
  //   component: Classes,
  //   name: "dashboard",
  //   meta: {
  //     title: 'Dashboard'
  //   },
  //   beforeEnter: requireAuth
  // },
  { path: "/about", component: About, name: "about", beforeEnter: requireAuth },
 
  {
    path: "/changePassword",
    component: ChangePassword,
    name: "ChangePassword"
  },
  { path: "/", redirect: "/classes" },
  { path: "*", redirect: "/404" },
  {
    path: "/admin",
    component: TeacherList,
    name: "admin",
    beforeEnter: requireAuth
  },
  {
    path: "/register",
    component: RegisterForm,
    name: "register",
    props: true
  },

  // ACA CUSTOM ROUTES
  { path: "/dates", component: Dates, name: "dates", beforeEnter: requireAuth },
  { path: "/calendar", component: Calendar, name: "calendar", meta: { title: 'Calendar' }, beforeEnter: requireAuth },
  { path: "/attendance", component: Attendance, props: true, name: "attendance", meta: { title: 'Attendance' }, beforeEnter: requireAuth },
  { path: "/user", component: UserProfile, name: "user", beforeEnter: requireAuth },
  { path: "/developer", component: PrivateClassesList, name: "developer", beforeEnter: requireAuth },
  { path: "/postpone", component: Postpone, name: "postpone", meta: { title: 'Postpone or Cancel' }, beforeEnter: requireAuth },

  { path: "/hours", component: Hours, name: "hours",
    // beforeEnter: requireAuth
  },
  { path: "/fm-error", component: ErrorPage, name: "ErrorPage" },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
