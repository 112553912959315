// src/utils/classCardHelper.js

/**
 * Checks if the event type contains the word 'phone' (case-insensitive)
 * @param {Object} eventData - The event data object
 * @returns {boolean}
 */
export function checkIfPhoneClass(eventData) {
   // Access the type property to trigger the getter
   const eventType = eventData.type;
  
   console.log('eData ', eventType);
   
  if (typeof eventData.type !== 'string') {
    console.warn('eventData.type is not a string');
    return false;
  }
  
  const result = eventData.type.toLowerCase().includes('phone');
  console.log('Result:', result);
  return result;
}