import { DB } from './demo-data';
import { Entity } from '@/types';
// import url from 'url';
// import querystring from 'querystring';
import { getSeachFilters } from './app-util';
import axios from 'axios'
// import { rejects } from 'assert';


// DB is dummy data such as from REST API as JSON 
// TODO is same as <any> - defined in global.d.ts (Must change to other name)
const ds: TODO = DB;  

const EXPAND = "_expand"
const EMBED = "_exbed" // ??

function getModel(action: string) {
  if (action.includes("/")) {
    return action.substring(0, action.indexOf("/"))
  }
  else {
    return action;
  }
}

function getId(action: string): number {
  if (action.includes("/")) {
    return parseInt(action.substring(action.indexOf("/") + 1))
  }
  else {
    return 0
  }
}

function getExpand(qs: TODO) {
  if (EXPAND in qs) {
    return qs[EXPAND];
  }
  else return ''
}

// function parseRequest(req: string) {
//   const parsedUrl = url.parse(req);
//   // const parsedQs = querystring.parse(parsedUrl.query);
//   const model = getModel(parsedUrl.pathname);
//   const id = getId(parsedUrl.pathname);
//   // const exp = getExpand(parsedQs)
//   const filters = getSeachFilters(parsedQs)
//   return { model, id, exp, filters }
// }


//-------------------------------------------------------------------------------------------//

export function getTeacherData(action: string): Promise<TODO> {
  console.log('API URL -> ', process.env.VUE_APP_API_URL)

  console.log('getAllTeachers [demo-api] ', action)

  return new Promise(function (resolve, reject) {
    const working = true 

    // if(working) {
    //   resolve(true)
    // } else {
    //   reject(false)
    // }
    // FM TOKEN - TODO: move to same server as FM API (ie. 3001 and same Digital Ocean in production)
    const url = process.env.VUE_APP_API_URL + '/allTeachers'
    axios.get(url)
      .then((response) => {
        console.log('Teachers : ', response)     
        resolve(response)
      })
      // TODO: Graciously handle error 
      .catch(err => {
        reject({
         code: 403,
         err: err,
         text: "Error getting teachers", // Catch on frontend and present friendly user alert
      }) 
    })
  })
}

//-------------------------------------------------------------------------------------------//

// Temporart workarounds for TS errors when npm run serve
export function postData(action: string, data: Entity): void {}
export function getData(action: string): void {}
export function putData(action: string, data: Entity): void {}
export function deleteData(action: string): void {}



// CREATE
// export function postData(action: string, data: Entity): Promise<TODO> {
//   const { model } = parseRequest(action)
//   return new Promise(function (resolve, _reject) {
//     ds[model].push(data);
//     setTimeout(resolve, 200, { data: data });
//   });
// }


// ORIGINAL 
// export function getData(action: string): Promise<TODO> {
//   console.log("ACTION : ", action)
//   const { model, id, exp, filters } = parseRequest(action)
//   return new Promise(function (resolve, _reject) {
//     const expandModel = exp
//       ? exp === "category"
//         ? "categories"
//         : exp + "s"
//       : exp;

//     console.log(model);
//     let result: TODO;
//     let expand: string, expandId: number;
//     console.log(expandModel);
//     if (model in ds) {
//       if (id && id > 0) {
//         result =
//           ds[model][ds[model].findIndex((d: { id: number }) => d.id === id)];
//         if (expandModel) {
//           expand =
//             expandModel === "categories"
//               ? "category"
//               : expandModel.substr(0, expandModel.length - 1);
//           expandId = result[expand + "Id"] as number;
//           result[expand] =
//             ds[expandModel][
//             ds[expandModel].findIndex((d: { id: number }) => d.id === expandId)
//             ];
//         }
//       } else {
//         result = ds[model].map((m: { [x: string]: TODO }) => {
//           if (expandModel) {
//             expand =
//               expandModel === "categories"
//                 ? "category"
//                 : expandModel.substr(0, expandModel.length - 1);
//             expandId = m[expand + "Id"] as number;
//             m[expand] =
//               ds[expandModel][
//               ds[expandModel].findIndex((d: { id: number }) => d.id === expandId)
//               ];
//           }
//           return m;
//         });
//       }

//       if (filters !== null && filters !== undefined
//         && Object.keys(filters).length > 0) {
//         result = result.filter(
//           row => Object.keys(filters).every(
//             prop => filters[prop](prop,row)
//           )
//         )
//       }
//     }
//     setTimeout(resolve, 500, { data: result });
//   });
// }


// UPDATE
// export function putData(action: string, data: Entity): Promise<TODO> {
//   const { model, id } = parseRequest(action)
//   return new Promise(function (resolve, _reject) {
//     const idx = ds[model].findIndex((d: { id: number }) => d.id === id);
//     ds[model][idx] = Object.assign({}, data);
//     setTimeout(resolve, 200, { data: data });
//   });
// }


// DELETE
// export function deleteData(action: string): Promise<TODO> {
//   const { model, id } = parseRequest(action)
//   return new Promise(function (resolve, _reject) {
//     if (id > 0) {
//       ds[model].splice(ds[model].findIndex((d: Entity) => d.id === id), 1);
//     }
//     setTimeout(resolve, 200, { status: 200 });
//   });
// }

export function logoutFM(action: string, data: TODO ): Promise<TODO> {
  console.log('DAT TOK  ', data)
  const token: TODO = data
  const options = {
    headers: {'Authorization': token}
  };

  return new Promise(function (resolve, reject) {
    // FM TOKEN
    const url = '/api/logout'
    // const url = 'http://localhost:3001/api/logout'

    axios.delete(url, options)
        .then((response) => {
          console.log('FM logged out OK?  :', response)    
            resolve('Logged out..')     
        })
        // TODO: Graciously handle error
        .catch(err => { reject({
          code: 403,
          err: err,
          text: "Error logging out",
        }) 
      })
  })
}

export function login(action: string, data: TODO ): Promise<TODO> {

  const credentials = {
    _username: data.username,
    _password: data.password
  }

  return new Promise(function (resolve, reject) {
    // FM TOKEN
    // const url = '/api/login'
    // const url = 'http://localhost:3001/api/login'
    const url = process.env.VUE_APP_API_URL + '/login'

    axios.post(url, credentials)
      .then((response) => {
        // NOTE: FMS can return body as embedded string. Must be parsed back to JSON to
        // access the auth token. V2 will store single token on the server and handle auth client-side
        // console.log('TYPE ->' , typeof(response)) // response already JSON.parse() on server
        console.log('response[demo-api.ts - login line 177]', response)
        
        // const FM_AUTH_TOKEN = response.data.response.token

        // Check token returned 
        // if(FM_AUTH_TOKEN) { // WORKING!!
        if(response) { 
         // const accessToken = response.data.response.token
         console.log('FM AUTH SUCCESS...', response)
     
         const teacher_fname = credentials._username.split(' ')[0]
         const teacher_sname = credentials._username.split(' ')[1]

         resolve({
          data: {
           // accessToken: 12329890489,
            user: {
              firstname: teacher_fname,
              lastname: teacher_sname,
              email: 'michael@lesateliers.com',
            },
            success: true
          }
         })
        } else {
        // alert('ERROR HERE ...invlaid Uname or Password / Atlas DB error...')
        reject({
          code: 403,
          text: "Invalid FM username or password. Please try again.",
          success: false
        });
      }

        // const parsedResponse = JSON.parse(response.data["body"])
        // console.log('TOKEN[parsedResponse] -> ', parsedResponse.response.token)
        // console.log('PARSED SERVER SIDE[should be token] -> ',response.data.response.token ) 
        // const data  = response.data["body"]
        // console.log('TYPE BODY ->' , typeof(data)) 
        // const tk = FM_TOKEN.response        
        // console.log('REFACTOR FM LOGIN REPSONSE[demo-api.ts] -> ', FM_TOKEN)
        // console.log('LOGIN RESPONSE -> ', data);
        // const tokenString = JSON.stringify(data["response"]["token"])
        // console.log('TOKEN -> ', tokenString);

      })
      // TODO: Graciously handle error
      .catch(err => { console.log('FM LOGIN ERR[demo-api.ts]', err); reject(err) })
  })
}

export function setUserPassword(action: string, data: TODO ): Promise<TODO> {
  // const { username, password } = data.userInfo
  console.log('demo-api[setUserPassword] called for ', data)
 
  return new Promise(function (resolve, reject) {
    const url = '/api/set-password'
    axios.get(url, { params: data } )
      .then((response) => {
        console.log('Response from set password :', response)
        alert(`response[Password Reset]...${JSON.stringify(response)}`)
        // const id = response.data.teacher_id
        resolve(response)
      })
      // TODO: Graciously handle error 
      .catch(err => {
        reject({
         code: 403,
         err: err,
         text: "Something went wrong with set password ..", // Catch on frontend and present friendly user alert
      }) 
    })
  })
}

export function forgotPassword(action: string, data: TODO ): Promise<TODO> {
  // const { username, password } = data.userInfo
  console.log('demo-api[forgotPassword] called for ', data)
 
  return new Promise(function (resolve, reject) {
    const url = '/api/forgot'
    axios.get(url, { params: data } )
      .then((response) => {
        console.log('Response from set password :', response)
        alert(`response[Password Reset]...${JSON.stringify(response)}`)
        // const id = response.data.teacher_id
        resolve(response)
      })
      // TODO: Graciously handle error 
      .catch(err => {
        reject({
         code: 403,
         err: err,
         text: "Something went wrong with set password ..", // Catch on frontend and present friendly user alert
      }) 
    })
  })
}

export function getTeacherCreds(action: string, data: TODO ): Promise<TODO> {

  console.log('USER [demo-api] ', data)

  const credentials = {
    _username: data.username,
    _password: data.password  
  }

  return new Promise(function (resolve, reject) {
    // FM TOKEN - TODO: move to same server as FM API (ie. 3001 and same Digital Ocean in production)
    // const url = '/api/getTeacher'
    // const url = 'http://localhost:3001/api/getTeacher'
    // const url = 'http://localhost:3001/api/auth'
    const url = 'http://localhost:3001/fm-auth'


    // const url = process.env.VUE_APP_API_URL + '/getTeacher'

    axios.get(url, { params: credentials })
      .then((response) => {
        
        console.log('Teacher Record:', response)
        alert(`response ... ${JSON.stringify(response)}`)
        if(response.data === 'Invalid Password') {
          reject('Invalid Password')
        }
     
        const id = response.data.teacher_id
        resolve(response)
      })
      // TODO: Graciously handle error 
      .catch(err => {
        console.log('ERROR -> ', err)
        reject({
         code: 403,
         err: err,
         text: "Invalid username or password", // Catch on frontend and present friendly user alert
      }) 
    })
  })
}

export function checkFMToken(action: string, data: TODO ): Promise<TODO> {
  const token: TODO = data
  console.log('CheckFMToken', token)

  const options = {
    headers: {'Authorization': token}
  };

  return new Promise(function (resolve, reject) {
    // FM TOKEN
    const url = '/api/token_auth'
    axios.post(url, options)
        .then((response) => {
          console.log('Token checked OK? ', response)

          // const result =  response.data.response.data[0]['fieldData']['tokenIsValid']
         // console.log('MSG ', response.data['messages'][0]['message'] )
          //  let token = ''

         // if(token === '') {
          if(response.data['messages'][0]['message'] !== 'Invalid FileMaker Data API token (*)') {
            // Call check connection to begin the interval FMS poll
            // dispatch('checkFMConnnection')
            console.log('RES IN TOKEN CHECK ...should still be valid', response)
            // alert('TOKEN GOOD')
            // Set time of check to append to message 
            // this.checkTime = (new Date().toLocaleTimeString());
            // this.message = 'Token still valid at ' + ' ' + this.checkTime

            resolve(true)
            // reject(false)
         
          // } else {
          //   // Set time of check to append to message 
          //   // this.checkTime = (new Date().toLocaleTimeString());
          //   // this.message = 'Token invalid at ' + ' ' + this.checkTime
        
          //   console.log('FM VALIDATION RESPONSE FAILED : ', response)
          //   // localStorage.clear()
          //   // commit('SET_TK_STATUS', response) 
          //   reject(false)
          //   // resolve(true)

          // }
        }
      }).catch(err => { 
        console.log('errrrrrr ... ', err) 
      reject(err) })
  })
}

