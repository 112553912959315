
import { Component } from 'vue-property-decorator';
import Swal from 'sweetalert2';
import Vue from 'vue';
import { userModule } from '@/store/modules/user';
import {
  getUser,
  getUserPrivateClasses,
  getFMToken,
  cleanSession,
} from "@/utils/app-util";


@Component
export default class App extends Vue {
  public isSaveDisabled = true;

  public selectedLanguage = localStorage.getItem('fmUserLanguage') || 'en';
    
  get fullLanguage() {
    if(this.selectedLanguage) {
      if(this.selectedLanguage === 'fr') {
        return 'Francais'
      } else {
        return 'English'
      }
    }
  }
  get signedIn() {
    return userModule.isSignedIn;
  }

  get user() {
    // User will only be in storage if Admin has chosen from TeacherList.vue
    let user = sessionStorage.getItem('userProfile')
    const userObj = JSON.parse(user)
    user = userObj.data 
    console.log('User from Teacher List -> ', user)
    this.profileItems = user
    return user
    // if(userObj !== undefined) {
    //   return userObj
    // } else {
    //   return userModule.user;
    // }
  }

  get fm_token() {
    return getFMToken();
  }

  get userPrivateClasses() {
    return this.privateClasses
  }

  created() {}

  mounted() {
    const pClasses = getUserPrivateClasses()
    this.privateClasses = pClasses
  }

  changeLanguage(lang: string) {
    // Your function logic here
    console.log("Button clicked, function triggered!", lang);
    this.selectedLanguage = lang
  }

  async save() {
 
    try {
      await localStorage.setItem('fmUserLanguage', this.selectedLanguage) 
      await localStorage.setItem('fmCommLang', this.selectedLanguage) 
      await localStorage.setItem('fmUserLang', this.selectedLanguage) 

      // If save is successful, show the alert
      const result = await Swal.fire({
        title: 'Settings Saved!',
        text: 'Your settings have been successfully saved.',
        icon: 'success',
        confirmButtonText: 'Return to my classes',
        confirmButtonColor: '#3085d6',
      });

      if (result.isConfirmed) {
        // Redirect to dashboard
        // this.$router.push('/classes'); // Adjust the route as needed
        window.location.replace(process.env.VUE_APP_URL + "/classes"); 
      }
    } catch (error) {
      // If there's an error during save, show an error alert
      Swal.fire({
        title: 'Error!',
        text: 'There was an error saving your settings.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }

  }
}
