
import Vue, { h } from "vue";
import { Component } from "vue-property-decorator";
import { userModule } from "@/store/modules/user";
import axios from 'axios'
import bcrypt from 'bcryptjs';
// import { Module } from "@/store/modules/mongo";

// Custom API modules 
import { checkFMToken, getPrivateClasses } from '@/utils/fm-api'; // Custom API MODULE
import { classesModule } from "@/store/modules/classes";
import{
  cleanSession,
  setFMID
} from "@/utils/app-util";
// import axios from 'axios'
import { Route } from 'vue-router';

@Component
export default class Login extends Vue {

    // Use a navigation guard to check if the user is signed in before each route navigation
    // async beforeRouteEnter(to: Route, from: Route, next: any) {
    //   const user = await localStorage.getItem('userProfile')
    //   const userJSON = JSON.parse(user)

    //   console.log(`Navigating to: ${to.path}`);
    //   console.log(`Route object:`, to);
    //   console.log('User?? ', userJSON)

    //   if(user) {
    //     // alert('back to dash...')
    //   } else {
    //     // alert('login load')
    //   }

    //   if (userModule.isSignedIn) {
    //     // alert('before route enter working ??? ...')
    //     // If the user is not signed in, redirect to the login page
    //     next('/classes');
    //   } else {
    //     next();
    //   }
    // }

  public username = "";
  private password = "";
  private error = false;
  public errorText = "";
  private show = false;
  public isLoading = false;
  public forgot = true
  //=======================================//

  private async handleForgotPassword() {
    await localStorage.setItem('token', 'forgot')
    window.location.replace(process.env.VUE_APP_URL + '/forgot'); 
  }

   // AUTH FM A - API v3 - Aug 2024
  private async login2() {
    this.isLoading = true;

    try {
      // Get IP
      const ipResponse = await axios.get('https://api.ipify.org?format=json');

      const loginCreds = {
        username: this.username,
        password: this.password,
        ip: ipResponse.data.ip
      }
      console.log('Creds ', loginCreds)

      // LOGIN REQUEST 
      const response = await axios.post(process.env.VUE_APP_API_URL + '/login', loginCreds);
      
      console.log('login response status undefined: ', response)   

      // console.log('Login success ? ', typeof response.data['name'])
      // const resName = response.data.name 
      // console.log('response name ', resName)
      // console.log('response name2 ', response)

      const errorLwr = response.data.name
      const statusLwr = response.data.status 
      let message = response.data.message

      if (errorLwr === 'ERROR' || errorLwr === 'Error' || errorLwr === 'error' 
        || statusLwr === 'ERROR' || statusLwr === 'Error' || statusLwr === 'error') {
          console.log('Error in login response -> ', response)

        if(response.data.message !== undefined && response.data.message === "Request failed with status code 400") {
          message = 'An error occured with your login. Please try again later.' 
          this.isLoading = false;
          message = response.data.response

        }  

        if(response.data.status !== undefined && response.data.status === "ERROR") {
          console.log('Login Error: ', response.data)
          message = response.data.response
        }
        
        // Dev only 
        if(message === "connect ECONNREFUSED 0.0.0.0:8443") {
          // Docker not running
          console.log('Check Docker is running....')
          // message = "An error with your login. Please try again laters."
          message = response.data.response

        } 

        if (response.data.response === 'FileMaker Server returned error 401, No records match the request') {
          this.isLoading = false;
          message = 'Sorry, there was an error with your login. Please contact ACA if this error persists.'
        }

        // if (typeof response.data.message === 'string') {
        //   alert('2')
        //   this.errorText = message || response.data.message
        //   this.isLoading = false;
        //   return
        // } 

        if (response.data.message === 'Request failed with status code 500') {
          // message = 'An error occured with your login. Please try again later.'
          message = response.data.response
          this.isLoading = false;
        }
        // console.log('Login success response? ', response)
        // this.errorText = response.data.response
        // await this.fetchUserData()
        // this.successDashRedirect(response)
        this.error = true
        this.username = ''
        this.password = ''
        this.errorText =  message || response.data.message
        this.isLoading = false;

      } else {

        const token = response?.data?.response?.token ?? 'No Token Returned'
        const mfaToken = response?.data?.response?.mfaToken ?? 'No MFA Token Returned'

        if(token === 'No Token Returned') {
          this.username = ''
          this.password = ''
          this.error = true
          this.errorText = "An error occured. Please try again later."
          this.isLoading = false;
        }

        // Saving tokens to local 
        sessionStorage.setItem('token', token)
        sessionStorage.setItem('mfaToken', mfaToken)
        console.log('Login response from PY -> ', response)
        console.log('Session token -> ', token)

        if(token !== 'No Token Returned' && mfaToken !== 'No MFA Token Returned') {
          // await this.fetchUserData()
          this.successDashRedirect(response)
        } else {
          // alert('No token or MFA token returned...error with')
          console.log('No MFA token returned?..')
        }
      }
    } catch (error) {
      // Handle any errors
      console.error('Login failed:', error);
      this.username = ''
      this.password = ''
      this.error = true
      this.errorText = error.response.data.response
      this.isLoading = false;
    } 
    // finally {
    //   console.log('Finally block of login response with no response?')

    //   this.$set(this, 'errorText', "An error occurred with your login. Please try again later.");
    //   this.isLoading = false;
    //   this.$forceUpdate();
    //   // this.isLoading = false;
    // }
  }

  resetForm() {
  }

  private async successDashRedirect(response) {
    console.log('Login successful. Going to dash ', response.data)
        let connError 
        // Dev error debuggin
        if(response.data.code === "ECONNREFUSED") {
          connError = 'There was a problem with your log in.'
        }

        const token = response?.data?.response?.token ?? 'No Token Returned'
        const mfaToken = response?.data?.response?.mfaToken ?? 'No MFA Token Returned'

        if(token === 'No Token Returned') {
          this.username = ''
          this.password = ''
          this.error = true
          this.errorText = connError || "Invalid Credentials. Please try again."
          this.isLoading = false;
        }
        
        // Saving tokens to local 
        sessionStorage.setItem('token', token)
        sessionStorage.setItem('mfaToken', mfaToken)
        console.log('Login response from PY -> ', response)
        console.log('Session token -> ', token)

        if (response.data.response.mfaRequired === 1) {
          this.$router.push({ name: '2fa-check', params: { token: token } });            
        } else {
          await this.fetchUserData()
          this.goToDashboard()
        }

  }

//   private async login2() {

//     console.log('Login 2')
//     this.isLoading = true;

//     // Hash password before sending to login
//     // const hashedPassword = bcrypt.hashSync(this.password);
//     // console.log('Hashed pw ', hashedPassword)
//     // console.log('PW should be the same every time -> ', hashedPassword)

//     // Get users IP
//     const response = await axios.get('https://api.ipify.org?format=json');
//     // console.log('ip ', response.data.ip)

//     const loginCreds = {
//       username: this.username,
//       password: this.password,
//       ip: response.data.ip
//     }

//     // LOGIN REQUEST 
//     try {
//     // axios.post('/api/login', loginCreds)
//     axios.post(process.env.VUE_APP_API_URL + '/login', loginCreds) 
//       .then((response) => {
//         console.log('Login success1 ? ', response)
//         this.isLoading = false
//         // alert('stop')

//         console.log('Login success ? ', typeof response.data['name'])
//         const resName = response.data['name']
//         console.log('ResName ', resName)

//         // Bypass dash for UI access
//         if(false) {
//           // alert('Bypass..')
//           localStorage.setItem('token', '138490ujakjsdjh0')
//           this.goToDashboard()
//         }

//         // if(resName == 'Error' || response.data.message === 'Request failed with status code 422') {
//         if(response.data.status === "Error") {
//           alert('error!!')

//           console.log('Error ', response.data)
//           this.error = true
//           // alert('NAME ERROR....')
//           this.username = ''
//           this.password = ''
//           this.errorText = response.data.status 

//           // FAKE login success to debug 
//           // Need token to allow the classes page to load
//           // localStorage.setItem('token', '30294irjaskljf')
//           // this.goToDashboard()

//           // Move to error handling module 
//           if(response.data.response === 'FileMaker Server returned error 401, No records match the request') {
//             this.errorText = 'User not found'
//           }

//           if(typeof response.data.message === 'string') {
//             this.errorText = response.data.message
//             return
//           } 

//           if(response.data.message === 'Request failed with status code 500') {
//             this.errorText = response.data.message
//           }
//           this.errorText = response.data.response

//         }
//         else {
//           console.log('Login successful. Going to dash ', response)

//           // alert('check store..')
//           const token = response?.data?.response?.token ?? 'No Token Returned'
//           const mfaToken = response?.data?.response?.mfaToken ?? 'No MFA Token Returned'
// \
//           // Saving tokens to local 
//           localStorage.setItem('token', token)
//           localStorage.setItem('mfaToken', mfaToken)

//           console.log('Login response from PY -> ', response)
//           console.log('Session token -> ', token)

//           // localStorage.setItem('token', token)
//           // alert('Check token saved in Local Storage')
//           console.log('Logging in to FMS..PYTHON with ', loginCreds)

//           // const url = process.env.VUE_APP_URL + '/2fa-check'

//           if(response.data.response.mfaRequired === 1) {
//             this.$router.push({ name: '2fa-check', params: { token: token } });            
//           }
//           else {
//             // alert('2fa not required..go to dash')
//             // const url = process.env.VUE_APP_URL + '/classes'
//             // window.location.replace(url); 
//             // window.location.replace(url); 
//             // Get the user data here 
//             this.fetchUserData()

//             this.goToDashboard()
//           }
//         }
//       })
 
//     } catch (error) {
//       // Handle any errors
//       console.error('Login failed:', error);
//     } finally {
//       this.isLoading = false;
//     }
//   }  

  private async fetchUserData() {
        const token = sessionStorage.getItem('token');
        const base_url = process.env.VUE_APP_API_URL

        try {
          const payload = {
            "teacherToken": token,
          }

          const response = await axios.post(process.env.VUE_APP_API_URL + '/user', payload)

          console.log('get user response in login fetchUser: ', response['data'])

          // Check that the user is returned properly!! 
          // Currently 
          // seems like login is good BUT...
          // token is being removed from session before the get user call 
          // FIX THIS NOW
          await sessionStorage.setItem('userProfile', JSON.stringify(response))
          const commLang = response.data.commLang
          // console.log('commLang from data ', commLang)

          if (localStorage.getItem('fmCommLang') === null) {
            alert('setting to fm lang')
            if(commLang === "English") {
              await localStorage.setItem('fmCommLang', JSON.stringify("en"))
              await localStorage.setItem('fmUserLang', JSON.stringify("en"))
              await localStorage.setItem('fmUserLanguage', JSON.stringify("en"))

            } else {
              await localStorage.setItem('fmUserLang', JSON.stringify("fr"))
              await localStorage.setItem('fmCommLang', JSON.stringify("fr"))
              await localStorage.setItem('fmUserLanguage', JSON.stringify("fr"))

            }
          }
          // await localStorage.setItem('fmCommLang', JSON.stringify(commLang))
          await localStorage.setItem('userProfile', JSON.stringify(response))
          return

        } catch(error) {
          console.log('Get user endpoint err : ', error)
          // alert('get user error in console?')
        }
  }

  // private async forgotPassword() {
  //   router.push("/forgot-password")
  // }

  //=====================================//

  
  private async resetLogin(){
      // await cleanSession();
      this.username = ""
      this.password = ""
      this.error = true
      // this.text = "Incorrect username or password"
  }


  // not triggering on page load??
  private created() {

    const signedIn = false
    console.log('signed in[login created hook]? ', signedIn)

    // sessionStorage.removeItem('token')
    // sessionStorage.clear()
    // alert('Login [created]')
    this.username = "",
    this.password = ""
    // sessionStorage.removeItem('signedIn')
    // remove user from storage
    // userModule.logout();
    console.log("Login.vue[created]");
  }

  //====================================//

  private goToDashboard() {
    // this.$router.push("/dashboard");
    // alert('Redirecting to dashboard...')
    console.log('Redirecting to Dash...')
    // alert('redirect...is app mounted??')

    // Router not working correctly - fix in next iteration
    // router.push("/dashboard");

    // Add BASE_URL + /dashboard 
    const url = process.env.VUE_APP_URL + '/classes'
    console.log('VUE URL ', url)
    window.location.replace(url); 
  }

  //====================================//
  // API v1 - gets teacher creds from local json file
  private async getTeacherCredsFromArray() {
    // Must retry promise
    const teacher = await _teacherData.filter(t => {
      return t.teacher_name === this.username
    })
    
    if(teacher[0] === undefined) {
      this.text = 'Teacher not found. Please check your username is spelled correctly.'
      this.error = true
      // this.resetLogin()
    }
    const id = JSON.stringify(teacher[0].teacher_id)
    //alert(`Teacher from JSON -> ${JSON.stringify(teacher[0].teacher_id)}`)
    sessionStorage.setItem('fm_id', id)
  }

  private mounted() {
    // alert('Mounted hook[login]')
    // location.reload()
  }
  //====================================//

  // API v2 - Get teacher from Atlas
  public async getTeacherCredsFromDB() {
    console.log('Searching for TEACHER in ATLAS...please wait...')
    let teacherName, teacher, message 
    // Temporary workaround for change of name in FM
    // Change name in FM back to Michael for produciton 
    if(this.username ==='Mike McCann') { 
      teacherName = 'Michael McCann' // Name registered in Mongo Collection
      teacher = {username: teacherName, password: this.pass};
    } else { // For all other teachers - use for production
      teacher = {username: this.username, password: this.pass};
    } 
    const tdeet = JSON.stringify(teacher)
    // console.log('Teacher obj b4 atlas  :: ', tdeet) 

    userModule.getMongoTeacher(teacher)
      .then((result) => { 
        console.log('MONGO TEACHER RESULT :: ', result)  // -> true if teacher found
        // alert(`teach : ${JSON.stringify(result)}`)
        // sessionStorage.setItem('viewAsTeacher', null)
        if(result) {
          const teacherObjToStore = {
            "firstname": 'no',
            "lastname": 'name',
            "email": "noteach@aca.com",
            "teacher_id": 0
          }
          const t_id = teacherObjToStore.teacher_id

          sessionStorage.setItem('viewAsTeacher', JSON.stringify(teacherObjToStore))
          this.goToDashboard()
          // return true
        }
      })
      .catch((result) => { 
        console.log('[login.vue] ATLAS ERROR :: ', result)  
        this.text = result.text
        this.resetLogin()
      });
  }

  private dismissError() {
    this.error = false
    // this.resetLogin()
  }

  //====================================//

  // API v2 
  private async login() {
    console.log('Searching for TEACHER in ATLAS...please wait...')
    let teacherName, teacher, message 
    // Temporary workaround for change of name in FM
    // Change name in FM back to Michael for production 
    if(this.username ==='Mike McCann') { 
      teacherName = 'Michael McCann' // Name registered in Mongo Collection
      teacher = {username: teacherName, password: this.pass};
    } else { // For all other teachers - use for production
      teacher = {username: this.username, password: this.pass};
    } 
    const tdeet = JSON.stringify(teacher)
    // console.log('Teacher obj b4 atlas  :: ', tdeet) 

    userModule.getMongoTeacher(teacher)
      .then((result) => { 
        console.log('Login Success: ', result)  // -> true if teacher found
        // alert(`teach : ${JSON.stringify(result)}`)
        // sessionStorage.setItem('viewAsTeacher', null)
        if (result) {
          const teacherObjToStore = {
            "firstname": 'no',
            "lastname": 'name',
            "email": "noteach@aca.com",
            "teacher_id": 0
          }
          const t_id = teacherObjToStore.teacher_id

          sessionStorage.setItem('viewAsTeacher', JSON.stringify(teacherObjToStore))
          
          this.goToDashboard()
          // return true
        }
      })
      .catch((err) => { 
        console.log('[login.vue] ATLAS ERROR :: ', err)  
        this.text = err.text
        this.resetLogin()
      });
  }  

  get emailRules() {
    return [
        (v: string) => !!v || 'Email is required',
        (v: string) => /.+@.+\..+/.test(v) || 'Email must be valid'
      ];
  }

  get passwordRules() {
    return [
        v => !!v || 'Password is required'
      ];
  }
}

