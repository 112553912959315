
import Table from "@/components/Table.vue";
import TableHeaderButtons from "@/components/TableHeaderButtons.vue";
import SearchPanel from "@/components/SearchPanel.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import { debounce } from "lodash";
import {
  buildSearchFilters,
  buildJsonServerQuery,
  clearSearchFilters
} from "@/utils/app-util";
import { Component } from "vue-property-decorator";
import Vue from "vue";
import { Entity } from "../types";
import { classesModule } from "@/store/modules/classes";
import { getFMData, getPrivateClasses } from '@/utils/fm-api'; // MAIN FM API TEST
import { appModule } from "@/store/modules/app";

@Component({
  components: {
    Table,
    TableHeaderButtons,
    SearchPanel,
    ConfirmDialog
  }
})
export default class CustomerList extends Vue {
  private data 

  public fmData = []
  private test = ''
  
  public dialog = false;
  public dialogTitle = "Customer Delete Dialog";
  public dialogText = "Do you want to delete this customer?";
  private showSearchPanel = false;
  public right = true;
  public search = "";

  public headers = [
    {
      text: "Date",
      value: "item['fieldData']['scheduleDate']",
      sortable: false
    },
    {
      text: "Client",
      left: true,
      value: "fieldData['class_reg_students_CLIENTS::clientName']"
    },
    { text: "Group#", value: "fieldData['classesNumber']" },
    { text: "No. Students", value: "portalData['class_REGISTRATIONS'].length" },
    { text: "Outcome", value: "pending" },
    { text: "Actions", value: "", sortable: false }
  ];

  private searchFilter = {
    contain: {
      firstname: "",
      lastname: "",
      email: "",
      mobile: ""
    }
  };


    // value: "['Group Schedule::Corporate client Company name_display']"

  // private customerId = "";
  private query = "";
  private color = "";
  private quickSearchFilter = "";
  private itemId = -1;

  private noDataMsg = "";

  edit(item: Entity) {
    this.$router.push(`customer/${item.id}`);
  }

  remove(item: Entity) {
    this.itemId = item.id;
    this.dialog = true;
  }

  add() {
    this.$router.push("NewCustomer");
  }

  onConfirm() {
    // customerModule.deleteCustomer(this.itemId);
    this.dialog = false;
  }
  onCancel() {
    this.itemId = -1;
    this.dialog = false;
  }

  updateSearchPanel() {
    this.rightDrawer = !this.rightDrawer;
  }

  cancelSearch() {
    this.showSearchPanel = false;
  }

  closeSnackbar() {
    appModule.closeNotice();
  }


  // get items() {
  //   return customerModule.items;
  // }

get fmData1() {
  let result = getPrivateClasses("getPrivateClasses").then((r) => {
    console.log('Result in [fmData]PrivateClassesList ', r)
    result = r
    // return result
  })
  return result
}

  get items() {
      return classesModule.getClasses;
      // return this.fmData
    }
  get pagination() {
    return classesModule.pagination;
  }
  get loading() {
    return appModule.loading;
  }
  get mode() {
    return appModule.mode;
  }
  get snackbar() {
    return appModule.snackbar;
  }

  get notice() {
    return appModule.notice;
  }

  get rightDrawer() {
    return this.showSearchPanel;
  }

  set rightDrawer(_showSearchPanel: boolean) {
    this.showSearchPanel = _showSearchPanel;
  }

  get quickSearch() {
    return this.quickSearchFilter;
  }


  async created() {

    const classes = await classesModule.getAllClasses()
    console.log('[dev page] items in created returned from store -> ', classes) // empty
    // Get schedule from sessionStorage if classes is empty
    if(sessionStorage.getItem('events')){
      alert('Getting events from storage...')
      // Must check here if the latest mod date in storage is earlier than the last FM mod date
      // Is it possible to update single item and save back 
      const evFromStorage = JSON.parse(sessionStorage.getItem('events'))
      const ev = evFromStorage[3]["fieldData"]["z_ModifiedOn"]
      console.log('Grabbed -> ', ev) 

      // Can move to classes.ts ?
      this.fmData = JSON.parse(sessionStorage.getItem('events'))

      console.log('Events from storage ', this.fmData)

    } else { // nothing in storage
      alert('Getting classes from FM...')
      this.fmData = await classesModule.getAllClasses() // New FM API 
    }
 
    console.log('fm in Created -> ', this.fmData) 

    // On page refresh classes do not display in UI 
    // Observer returned 

    // this.fmData = await classesModule.getAllClasses()

    // .then((results) => {
    //   console.log('results back in PrivateClassesList -> ', results)
    //   this.fmData = results
    // })

    //  // Change to fm-api later
    //  if(classesModule.getClasses.length < 1){
    //   // this.getEvents()
    //   // alert('No API data available for this user')
    //   this.noDataMsg = "No API data available for this user"
    
    // } else {
    //   console.log('Private Classes List ')
    //   console.log('-------------------- ')
    //   console.log(JSON.parse(JSON.stringify(classesModule.getClasses)))


    // }
    // classesModule.getAllPrivateClasses();
  }

  async mounted() { this.test = 'Message from mounted'}
}
