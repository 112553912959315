import { 
  getTeacherData, 
  getData 
} from '@/utils/demo-api'

import { Customer, Order, Entity, Teacher } from '@/types';
import { appModule } from './app';
import { getDefaultPagination, getPagination } from '@/utils/store-util';
import { get } from 'lodash';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { userModule } from './user';

export interface CustomerState {
  items: Entity[];
  pagination: Pagination;
  loading: boolean;
  customer: Customer; //= new Customer() ;
  customers: Customer[];
  orders: Order[];
  orderList: Order[];
}

@Module({ store, dynamic: true, name: 'customerModule' })
class CustomerModule extends VuexModule implements CustomerState {
  public items: Entity[] = [];
  public teachers: Entity[] = [];

  public pagination = getDefaultPagination();
  public loading = true;
  public customer = {} as Customer;
  public customers: Customer[] = [];
  public orders: Order[] = [];
  public orderList: Order[] = [];

  get getCustomers() {
    return this.customers;
  }

  @Action getOrders(): void {
    // getData('orders/').then(res => {
    //   if (res.data && res.data.length > 0) {
    //     const orderList = res.data.map((c: TODO) => {
    //       c.text = c.firstname + ' ' + c.lastname;
    //       c.value = c.id;
    //       return c;
    //     });
    //     // this.setOrderList(orderList);
    //   }
    // });
  }
  @Action getCustomerById(id: string): void {
    //this.context.commit('setLoading', { loading: true });
    // this.setLoading(true);
    if (id) {
      // getData('customers/' + id).then(
      //   res => {
      //     const customer = Object.assign({}, res.data);
      //     customer.avatar = `..${customer.avatar}`;
      //     // this.setCustomer(customer);
      //     // this.setLoading(false);
      //   },
      //   (err: TODO) => {
      //     console.log(err);
      //   }
      // );
    } else {
      // this.setCustomer({} as Customer);
      // this.setLoading(false);
    }
  }

  //----------------------------------------------------------------//

  @Action getAllTeachers(): void {

    // Block non-admin 
    // Get teacher from storage 
    const user = sessionStorage.getItem('user')
    const pUser = JSON.parse(user)
    console.log('UD here ', pUser._id)
    const uid = pUser._id

    // API call to auth teacher as admin
    userModule.validateAsAdmin(pUser).then(res => { 
      console.log('response from validate: ', res)
      if(res.data.authorized === false) {
        alert('authorized returned false -> see console for response')   
        window.location.href = process.env.VUE_APP_URL + "/dashboard"
        // return false
      } else {
        alert('authorized..getting teachers')
        getTeacherData('getTeacherData').then(res => {
          console.log('getTeacherData[customers.ts] -> should be true from promise ? ', res)
          const teachers = res.data;
          // teachers.forEach((item: Teacher) => {
          const teachersList =  teachers.map(item => {
            const nameArr = item.teacher_name.split(' ')
            let fname
            let sname
    
            // Format double-barrel names
            if(nameArr.length === 3) {
              fname = nameArr[0] + '-' + nameArr[1]
              sname = nameArr[2]
            } else {
              fname = nameArr[0]
              sname = nameArr[1]
            }
               // const t_id = this.data[i].teacher_id
            const t_id = item.teacher_id
    
            console.log('Teacher -> ', fname + ' ' + sname + ' ' + t_id)
    
            const teacherObj = {
              "firstname": fname,
              "lastname": sname,
              "email": "teacherName@lesateliers.ca",
              "teacher_id": t_id
            }
            return teacherObj
          })      
          
          // return teacherData
          this.setDataTable(teachersList);
          this.setLoading(false);
        });
      }
    }) 

    // this.context.commit('setLoading', { loading: true });
    // this.setLoading(true);

  }

  //----------------------------------------------------------------//

  @Action getAllCustomers(): void {
    // this.context.commit('setLoading', { loading: true });
    // this.setLoading(true);
    // getData('customers?_embed=orders').then(res => {
    //   const customers = res.data;
    //   customers.forEach((item: Customer) => {
    //     item.orderAmount = item.orders && item.orders?.length; // : 0;
    //   });

      // const teachers =  _teacherData.map(t => {
      //   const nameArr = t.teacher_name.split(' ')
      //   let fname
      //   let sname

      //   // Format double-barrel names
      //   if(nameArr.length === 3) {
      //     fname = nameArr[0] + '-' + nameArr[1]
      //     sname = nameArr[2]
      //   } else {
      //     fname = nameArr[0]
      //     sname = nameArr[1]
      //   }
      //   // const t_id = this.data[i].teacher_id
      //   const t_id = t.teacher_id

      //   console.log('Teacher -> ', fname + ' ' + sname + ' ' + t_id)

      //   const teacherObj = {
      //     "firstname": fname,
      //     "lastname": sname,
      //     "email": "teacherName@lesateliers.ca",
      //     "teacher_id": t_id
      //   }
      //   return teacherObj
      // })
      // return teacherData
      // this.setDataTable(teachers);
      
      this.setLoading(false);
  }

  @Action setDataTable(items) {
    const pagination = getPagination(items);
    this.setPagination(pagination);
    this.setItems(items);
  }

  @Mutation setItems(teachers: any[]): void {
    this.items = teachers;
  }
  @Mutation setPagination(pagination: TODO): void {
    this.pagination = pagination;
  }
  @Mutation setLoading(loading: boolean): void {
    this.loading = loading;
  }
  
}


  // @Action getAllCustomers(): void {
  //   // this.context.commit('setLoading', { loading: true });
  //   this.setLoading(true);
  //   getData('customers?_embed=orders').then(res => {
  //     const customers = res.data;
  //     customers.forEach((item: Customer) => {
  //       item.orderAmount = item.orders && item.orders?.length; // : 0;
  //     });
  //     this.setDataTable(customers);
  //     this.setLoading(false);
  //   });
  // }

  // @Action searchCustomers(searchQuery: string): void {
  //   this.setLoading(true);
  //   getData('customers?_embed=orders&' + searchQuery).then(res => {
  //     const customers = res.data;
  //     customers.forEach((p: TODO) => {
  //       p.orderAmount = p.orders?.length;
  //     });
  //     this.setDataTable(customers);
  //     this.setLoading(false);
  //   });
  // }

  // @Action quickSearch(headers: TableHeader[], qsFilter: SeachQuery): void {
  //   this.setLoading(true);
  //   // TODO: Following solution should be replaced by DB full-text search for production
  //   getData('customers?_embed=orders').then(res => {
  //     const customers = res.data.filter((r: TODO) =>
  //       headers.some((header: TODO) => {
  //         const val = get(r, [header.value]);
  //         return (
  //           (val &&
  //             val
  //               .toString()
  //               .toLowerCase()
  //               .includes(qsFilter)) ||
  //           false
  //         );
  //       })
  //     );
  //     customers.forEach((item: Customer) => {
  //       item.orderAmount = item.orders.length;
  //     });
  //     this.setDataTable(customers);
  //     this.setLoading(false);
  //   });
  // }

  // @Action deleteCustomer(id: number): void {
  //   deleteData(`customers/${id.toString()}`)
  //     .then(_res => {
  //       this.getAllCustomers();
  //       appModule.sendSuccessNotice('Operation is done.');
  //     })
  //     .catch((err: TODO) => {
  //       console.log(err);
  //       appModule.sendErrorNotice('Operation failed! Please try again later. ');
  //       appModule.closeNoticeWithDelay(1500);
  //     });
  // }

  // @Action saveCustomer(customer: Customer): void {
  //   if (!customer.id) {
  //     postData('customers/', customer)
  //       .then(res => {
  //         const customer = res.data;
  //         this.setCustomer(customer);
  //         appModule.sendSuccessNotice('New customer has been added.');
  //         appModule.closeNoticeWithDelay(1500);
  //       })
  //       .catch((err: TODO) => {
  //         console.log(err);
  //         appModule.sendErrorNotice('Operation failed! Please try again later. ');
  //         appModule.closeNoticeWithDelay(1500);
  //       });
  //   } else {
  //     putData('customers/' + customer.id.toString(), customer)
  //       .then(res => {
  //         const customer = res.data;
  //         this.setCustomer(customer);
  //         appModule.sendSuccessNotice('Customer has been updated.');
  //         appModule.closeNoticeWithDelay(1500);
  //       })
  //       .catch((err: TODO) => {
  //         console.log(err);
  //         appModule.sendErrorNotice('Operation failed! Please try again later. ');
  //         appModule.closeNoticeWithDelay(1500);
  //       });
  //   }
  // }

  // @Action setDataTable(items: Customer[]) {
  // @Action setDataTable(items) {
  //   const pagination = getPagination(items);
  //   this.setPagination(pagination);
  //   this.setItems(items);
  // }

  // @Mutation setOrderList(orders: Order[]): void {
  //   this.orders = orders;
  // }
 
  // @Mutation setCustomer(customer: Customer): void {
  //   this.customer = customer;
  // }
// }

export const customerModule = getModule(CustomerModule); // Customers;