import { DB } from './demo-data';
import { Entity } from '@/types';
import { getSeachFilters } from './app-util';
import axios from 'axios'
import { userModule } from '@/store/modules/user';
import convertData  from './adapter.js';
import { last } from 'lodash';
import { getLocalStorageValue } from '@/utils/localStorageHelper';
// import url from 'url';
// import querystring from 'querystring';

// DB is dummy data such as from REST API as JSON 
// TODO is same as <any> - defined in global.d.ts (Must change to other name)
const ds: TODO = DB;  
const EXPAND = "_expand"
const fmtoken = sessionStorage.getItem('fm-token')
const api = process.env.VUE_APP_API_URL 
console.log('THE ENV API URL -> ', api)

// ACA API 
//==========================================================================================//
// API V2 - MAIN REQUEST FOR PENDING CLASSES AFTER SUCCESSFUL LOGIN

export function submitTimesheet(action: string, data: object): Promise<TODO> { 
  const url = api + '/timesheet'

  console.log(data)
  alert('[fm-api]check con...')
  return new Promise((resolve, reject) => {

    axios.post(url, { data: data })
    .then((response) => {
      console.log('fm response ', response)
      resolve(response)
    })
  })
}

// TODO - function to check if the FM connection is available
function checkFMConnectionAvailable(): Promise<TODO> { 
  const url = api + '/checkFMConnectionAvailable'
  return new Promise((resolve, reject) => {
    axios.post(url)
    .then((response) => {
      resolve(response)
    })
  })
}

export function rescheduleClass(payload: object): Promise<TODO> {

  console.log('PL [rescheduleClass]fm-api ', payload)
  
  return new Promise((resolve, reject) => { 
    let url  = process.env.VUE_APP_API_URL + '/reschedule' 
    // let url  = '/api/reschedule' // PRODUCTION - ACA SERVER 

    axios.post(url, payload)
      .then((response) => {
        console.log('RESPONSE api/reschedule: ', response)
        // console.log('Res[fm-api]/reschedule -> ', response['data']['messages'][0])

        // convertData(response)
        
        // if (response['data']["messages"][0]["code"] == 100) {
        //   alert('FM RESPONSE[fm-api]/myPrivateClasses -> File is missing')
        // }

        // if (response['data']["messages"][0]["code"] == 952) {
        //   console.log('FM TOKEN INVALID ERROR...', response['data']["messages"][0]["code"])
        //   alert('FM RESPONSE[fm-api]/myPrivateClasses -> TOKEN INVALID')
        //   this.$router.push('/fm-err');
        // }

        // if (response['data']["messages"][0]["code"] == 401) {
        //   console.log('No records match the request...')
        //   this.$router.push('/fm-err');
        // }

        let results = JSON.stringify(response)
        console.log('Results from new FM API call -> ', results)

        sessionStorage.setItem('pfu_events', JSON.stringify(response.data.response.data))

        resolve(response)
      })
      .catch(err => { reject(err) })
  })
}


export function getPFUClasses(action: string): Promise<TODO> { 
  let url  = api + '/pfus' ;

  return new Promise((resolve, reject) => { 
    // resolve('Success from fm-api getPFUs')
    axios.post(url, { FM_ID: 111 })
      .then((response) => {
        console.log('RESPONSE api/pfus: ', response)
        console.log('Res[fm-api]/pfus -> ', response['data']['messages'][0])

        convertData(response)
        
        if (response['data']["messages"][0]["code"] == 100) {
          alert('FM RESPONSE[fm-api]/myPrivateClasses -> File is missing')
        }

        if (response['data']["messages"][0]["code"] == 952) {
          console.log('FM TOKEN INVALID ERROR...', response['data']["messages"][0]["code"])
          alert('FM RESPONSE[fm-api]/myPrivateClasses -> TOKEN INVALID')
          this.$router.push('/fm-err');
        }

        if (response['data']["messages"][0]["code"] == 401) {
          console.log('No records match the request...')
          this.$router.push('/fm-err');
        }

        let results = JSON.stringify(response)
        console.log('Results from new FM API call -> ', response)

        sessionStorage.setItem('pfu_events', JSON.stringify(response.data.response.data))

        resolve(response.data.response.data)
      })
      .catch(err => { reject(err) })
  })
}

export function getClass(event: any): Promise<TODO> { 
  console.log('FM ev ', event) // classID only from calendar - full object from classes 
  // let url  = api + '/getClass' ;
  // let url  = '/api/getClass' ; Production
  let url = process.env.VUE_APP_API_URL + '/getClass'
  console.log('Class event passed to getClass[fm-api]', event)
  const { pkClasses, type } = event
  console.log('class id[fm-api] ', pkClasses)
  // alert('getClass called')

  const token = sessionStorage.getItem('token')
  let classType;

  return new Promise((resolve, reject) => { 
    // resolve('works...')
    if(event.groupClass) {
      // alert('fm-api[getClass] - if(event.groupClass)')
      classType = 'Group'
    }

    let userTraxLanguage

    if(getLocalStorageValue('fmUserLanguage') === "fr") { // uses /util/localStorageHelper
      userTraxLanguage = "FR"
    } else {
      userTraxLanguage = "EN"
    }

    // Request payload example
    const payload = {
      "classId": pkClasses || event.classID,
      "teacherId": token,
      "language": userTraxLanguage
    }

    console.log('payload check for lang setting ', payload)
    
    axios.post(url, payload)
      .then((response) => {
        const res = typeof response.data
        console.log('Check res..', response.data)

        // Checking if there is a FM error message returned 
        if(res === 'string') {
          console.log('string res ...', res)
        }
        console.log('Class from FM ', response)
        resolve(response)

      })
      .catch(err => { 
        console.log('ERROR fm-api[getClass]: ', err)
        reject(err) 
      })
    })
}

// export function getPrivateClasses(action: string, data: TODO ): Promise<TODO> {
export function fmTimesheetPost(action: string, payload: object): Promise<TODO>  { 
  // console.log(payload)
  // alert('check for timesheet payload in fm-api ....')
  return new Promise((resolve, reject) => {})

}

// V2 API 
export function getPrivateClasses(action: string): Promise<TODO> {
  alert('FM-API CALLED [getPrivateClasses]....')
  // v2 check for local last data timestamp
  // const lastDataTimestamp = sessionStorage.getItem('loginDateTime')
  const lastDataTimestamp = localStorage.getItem('lastModDate')

  // if(lastDataTimestamp) {
  //   console.log('Last data -> ', lastDataTimestamp)
  //   alert('check the time...')
  // }
  
  // const token: TODO = data.token
  // const teacher_id: TODO = data.t_ID

  // const connCheck = checkFMConnectionAvailable().then(res => {
  //   console.log('CONN CHECK -> ', res['data'])
  // })

  // Temporary date range for developement
  // const dateRange: TODO = "01/02/2021...01/25/2021"

  // Retrieve date range for current period stored in separate MongoDB collection 
  // Extract to seperate helper file or handle entirely on server 
  const dateRange: TODO = "11/10/2020...01/25/2021"

  // Check localStorage for last data request date 
  // If date in LS, add date to the payload 

  const events = sessionStorage.getItem('events')
  console.log('[fm-api] - classes from session storage  ', events, typeof events)

  let url = api + '/classes2' ;

  // if(events) {
  //   let dateFromStorage = sessionStorage.getItem('lastModDate')
  //   alert(`last mod -> ${JSON.stringify(dateFromStorage)}`)
  // } 
  // console.log('Get with token and teacher_id : ', token + teacher_id)
  // Check persisted state for data 
  // If persisted state -> check for any modified timestamp later than last login timestamp  

  return new Promise((resolve, reject) => {

    // Work on polling for Last Mod Update feature
    // if(events !== 'undefined') {
    //   alert('Events in storage...must run lastMod check in FM')
    //   const eventsFromStore = JSON.parse(events)
    //   console.log('Events ', typeof eventsFromStore)
    //   resolve(eventsFromStore)
    // }

    // Note: "_kf_group_schedule_id": "= " is to avoid returning Groups from WP2Classes layout
    // Only Groups will have a value in this field
    const privateClassEvents = [];
    const viewAdmin = sessionStorage.getItem('viewAsTeacher')

    if(viewAdmin !== undefined) {
      console.log('Viewing as : ', viewAdmin)
      alert('reached the view as teacher here in fm-api ... check console. ')
    }
    
    // Example query to limit to date range
    // const query = JSON.stringify({"query": [{"Student::First Name":"*","_kf_teacher_id": teacher_id,"scheduled date": dateRange}]})
  
    // const options = {
    //   headers: {
    //     // 'Authorization': token,
    //     data: query
    //   }
    // };

      // API v2 endpoints - retrace these steps to get FM sync working...
      // todo - the check endpoint is not getting triggered and LMD not logging
      // if(lastModDate) { 
      //   console.log('LMD -> ', typeof lastModDate)
      //   url = api +'/check-modified-records'
      // } else {  
      //   url = api + '/classes' 
      // }
      // axios.post(url, { token: token, tID: teacher_id })
      // axios.post(url, options)

      // API v2 - no auth / server handles FM login and tokens
      // axios.post(url, { FM_ID: 13, modifiedDate: lastModDate })
      // axios.post(url, { FM_ID: 13, lastDataRequest: lastDataTimestamp })

      const token = localStorage.getItem('token');
      const payload = {
        "startDate": "05/21/2024", // mm/dd/yyyy
        "endDate": "05/25/2024",
        "teacher": token,
        "modified": "", // Dynamically add last login timestamp
        "language": "EN",
        "groupOnly": null,
        "privateOnly": null,
      }

      axios.post(url, payload)
        .then((response) => {
          console.log('NEW : ', typeof(response.data))
          // console.log('Res[fm-api]/classes -> ', response['data']['messages'][0])

          // PHASE 2 - can grab name from "z_ModifiedBy" to notify teacher of admin that made the update
          // console.log('WHAT IS[fm] -> ',response.data.response.data[0]['fieldData']['class_STAFF::firstName']) // object

          // console.log('MSG from FM -> ', response.data['messages'][0]['message']) // works with genie

          // IMPORTANT: Handle invalid token here 
          // {"data":{"messages":[{"code":"952","message":"Invalid FileMaker Data API token (*)"}],...
          // if(response['data']["messages"][0]["code"] == 100) {
          //   alert('FM RESPONSE[fm-api]/myPrivateClasses -> File is missing')
          // }

          // if(response['data']["messages"][0]["code"] == 952) {
          //   console.log('FM TOKEN INVALID ERROR...', response['data']["messages"][0]["code"])
          //   alert('FM RESPONSE[fm-api]/myPrivateClasses -> TOKEN INVALID')
          //   this.$router.push('/fm-err');
          // }

          // if(response['data']["messages"][0]["code"] == 401) {
          //   console.log('No records match the request...')
          //   // alert('FM RESPONSE[fm-api]/classes -> No records match the request')
          //   this.$router.push('/fm-err');
          //   // resolve(response)
          // }
        
          let results = JSON.stringify(response)
          console.log('Results from new FM API call -> ', response.data)
          console.log('Resposne as stringify for save to session? ', results)
          sessionStorage.setItem('calEvents', JSON.stringify(response.data))

          resolve(response.data)

          // Handle no classes for given teacher 
          // if(response.data['messages'][0]['message'] === 'No records match the request') {
          //   alert('No Private classes found for teacher')
          // }

        // Token not checked in API v2 - token handled on server-side  
        // TODO: refactor to check for no classes found and alert user  
        // Replace with -> response.data.response.data.length ?? 
        
        
        // if(response.data['messages'][0]['message'] !== 'Invalid FileMaker Data API token (*)') { 
          // console.log('RES IN TOKEN CHECK ...should still be valid', response)        
          // this.checkTime = (new Date().toLocaleTimeString());
          // this.message = 'Token still valid at ' + ' ' + this.checkTime

          // Iterate the classes and push to private class events 
          // Single array to store all classes in v2
          
          // response.data.response.data.forEach((doc) => {
            // ATTENTION: DO NOT USE THIS CONSOLE LOG - CAUSES APP TO CRASH AND FORCE LOGOUT IF UNDEFINED
            // Issue: Some private class records do not have a student 
            // Bug fixed in Calendar.vue 'if(c['portalData']['Student'][0] !== undefined ) {...'   
            // console.log('NAME DOC :  ', doc['portalData']['Student'][0]["Student::First Name"])
            // privateClassEvents.push(doc)
          // })
          
          // console.log('First Private Class in Array : ', privateClassEvents[0])
          
          // Main Field data returned from genie
          // class_STAFF::firstName: "Juanita"
          // class_STAFF::lastName: "Ottawa"
          // classesNumber: "CLAS000051"
          // duration: "1.5 Hours"
          // endTime: "10:30:00"
          // startTime: "09:00:00"
          // outcomeNotes: ""
          // scheduleDate: "10/10/2022"
          // status: ""
          // teacherNotes: ""
          // type: "Corporate Private Online"
          // __pk_Classes: 51
          // _fk_Group: ""
          // _fk_Schedule: 1
          // _fk_Teacher: 2


          // alert('Check console for NAME student...') 

          // if(privateClassEvents.length === response.data.response.dataInfo.returnedCount){
          //   resolve(privateClassEvents)
          // }

        // } else { // This else should never trigger in API v2 
          
        // Set time of check to append to message 
          // this.checkTime = (new Date().toLocaleTimeString());
          // this.message = 'Token invalid at ' + ' ' + this.checkTime
          
          // console.log('FM VALIDATION RESPONSE FAILED : ', response)
          // alert('Before reject in fm-api...check console for resposne...' )
          // localStorage.clear() 
          // alert('new logout')
          
          // userModule.logout()
          // reject(false)
        // }
      })
      // TODO: Graciously handle error
      .catch(err => { reject(err) })
  })
}
  
// Make request to FM for the class that has been clicked on to get the very latest data
export function getOneClass(action: string): Promise<TODO> {
  
  const dateRange: TODO = "11/10/2020...01/25/2021"


  // {"query":[{"_fk_Teacher": "1", "scheduleDate": "*", "z_ModifiedOn": ">06/11/2023 10:49:34" }]}

  let url  = api + '/class/' + 1396 ;

  return new Promise((resolve, reject) => {
    const singleClassEvent = [];
    const viewAdmin = sessionStorage.getItem('viewAsTeacher')

    if(viewAdmin !== undefined) {
      console.log('You are viewing a single class as : ', viewAdmin)
      alert('reached the view as teacher here in fm-api ... check console. ')
    }
      axios.post(url, { FM_ID: 13 })

        .then((response) => {

          console.log('NEW : ', response)
          console.log('Res[fm-api]/classes -> ', response['data']['messages'][0])
          console.log('Res please -> ', response)
          console.log('MSG from FM -> ', response.data['messages'][0]['message'] ) // works with genie
          console.log('Resposne ok? ', JSON.stringify(response))
          if(response['data']["messages"][0]["code"] == 100) {
            alert('FM RESPONSE[fm-api]/myPrivateClasses -> File is missing')
          }

          if(response['data']["messages"][0]["code"] == 952) {
            console.log('FM TOKEN INVALID ERROR...', response['data']["messages"][0]["code"])
            alert('FM RESPONSE[fm-api]/myPrivateClasses -> TOKEN INVALID')
          }

          if(response['data']["messages"][0]["code"] == 401) {
            console.log('No records match the request...')
            this.$router.push('/fm-err');
            resolve(response)
          }
        
          let results = JSON.stringify(response)
          console.log('Results from new FM API call -> ', response)
          sessionStorage.setItem('events', JSON.stringify(response.data.response.data))
          resolve(response.data.response.data)
      })
      .catch(err => { reject(err) })
  })
}
  
//===========================================================================//

export function submitNonTeachingHours(action: string, data: TODO ): Promise<TODO> {
  alert(`fm-api[submitNonTeachingHours] -> ${data}`)
  return new Promise((resolve, reject) => {

    const config = {
      method: 'post',
      headers: { 
        'Content-Type': 'application/json'
      },
      data: data
    }

    const url = '/api/hours' 
    axios.post(url,config)
    // ref- https://stackoverflow.com/questions/44715661/cannot-access-this-in-axios-post-method-in-vuejs-2
      .then((response) => { 
        console.log('fm-api[submitNonTeachingHours] resp -> ', response)
        resolve(response)
      }).catch(err => {
        reject(err)
      })
  })
}

// Not used
export function getExistingRecordsForTeacher(action: string, data: TODO ): Promise<TODO> {
  // alert(`fm-api -> ${JSON.stringify(data.token)}`)
  return new Promise((resolve, reject) => {
    // add config and API request based on the 
    // OR USE function below ?? might be broken one with token not working?

    // See how the classes APIs work below and follow strictly end-to-end

    const config = {
      method: 'post',
      headers: { 
        'Authorization': data.token, 
        'Content-Type': 'application/json'
      },
      data: data.query
    }

    const url = '/checkAttendanceRecordExists'
    
    axios.post(url,config)
    // ref- https://stackoverflow.com/questions/44715661/cannot-access-this-in-axios-post-method-in-vuejs-2
      .then((response) => { // do not use "(function (response)){..} or 'this' is undefined below
        // const message = response.data.messages[0].message
        const twoWeekAttendance = []

        twoWeekAttendance.push(response)  
        console.log('twoWeek ARRAY IN FM_API ', twoWeekAttendance)
        alert(`fm-api.getExisitngRecordsForTeacher - Attendance returned : ${twoWeekAttendance[0]}`)

        resolve(twoWeekAttendance)
        // return response
        
      })
      .catch(function (error) {
      console.log('ERR in Attendance.checkAttendanceRecordsInFM : ', error);
      reject()
      });
    })
}

//======================================================================================//

export function checkRecordExistsForStudent(action: string, data: TODO): Promise<TODO> {
  // alert(`fm-api.checkRecordExistsForStudent : fmtoken = ${this.fmtoken}`)

  return new Promise((resolve, reject) => { 

    const config = {
      method: 'post',
      headers: { 
        'Authorization': this.fmtoken, 
        'Content-Type': 'application/json'
      },
      data: data.query
    }

    // const url = 'http://localhost:3001/api/checkAttendanceRecordExists'
    const url = '/api/checkAttendanceRecordExists'
    
    axios.post(url,config)
    // ref- https://stackoverflow.com/questions/44715661/cannot-access-this-in-axios-post-method-in-vuejs-2
      .then((response) => { // do not use "(function (response)){..} or 'this' is undefined below
        const message = response.data.messages[0].message
        if(message !== "No records match the request") { // RECORD FOUND 
          console.log('RECORD NOT FOUND ..... ')
          resolve(true)
        } else {
          console.log('err finding attendance record....check server console')
          reject(false)
        }
      })
      .catch(function (error) {
      console.log('ERR in Attendance.checkAttendanceRecordsInFM : ', error);
      });
  
  })
}

//=========================================================================================//

export function getAttendanceRecordsForTeacher(action: string, data: TODO ): Promise<TODO> {
  // Date Range 2 weeks either side of current date
  return new Promise((resolve, reject) => {
    // Pass student id to query
    // const data = JSON.stringify({"query":[{"ClassDate":"12/01/2020","class_id":"CLA000249280","_fk_student_id":"S00019599"}]});
    // const data = JSON.stringify({
    // "query":[ {"ClassDate":class_date,"class_id": class_id,"_fk_student_id": student_id} ]
    // });
    // const config = data.config

    const config = {
      method: 'post',
      headers: { 
        'Authorization': data.token, 
        'Content-Type': 'application/json'
      },
      data: data.query
    }
    // const url = 'http://localhost:3001/api/checkAttendanceRecordExists'
    const url = '/api/checkAttendanceRecordExists'
    
    axios.post(url,config)
    // ref- https://stackoverflow.com/questions/44715661/cannot-access-this-in-axios-post-method-in-vuejs-2
      .then((response) => { // do not use "(function (response)){..} or 'this' is undefined below
        const message = response.data.messages[0].message
        if(message !== "No records match the request") { // RECORD FOUND 
          console.log('RECORD WAS NOT FOUND ..... ')
          resolve(true)

        } else {
          console.log('err ')
          reject(false)

        }
      })
      .catch(function (error) {
      console.log('ERR in Attendance.checkAttendanceRecordsInFM : ', error);
      });
  })
}

//=============================================================================//

export function checkRecordExists(action: string, data: TODO ): Promise<TODO> {

  alert(`C REC EXISTS : ${JSON.stringify(data)}`)

  return new Promise((resolve, reject) => {
    // Pass student id to query
    // const data = JSON.stringify({"query":[{"ClassDate":"12/01/2020","class_id":"CLA000249280","_fk_student_id":"S00019599"}]});
    // const data = JSON.stringify({
    // "query":[ {"ClassDate":class_date,"class_id": class_id,"_fk_student_id": student_id} ]
    // });
    // const config = data.config

    const config = {
      method: 'post',
      headers: { 
        'Authorization': data.token, 
        'Content-Type': 'application/json'
      },
      data: data.query
    }
    // const url = 'http://localhost:3001/api/checkAttendanceRecordExists'
    const url = '/api/checkAttendanceRecordExists'
    
    axios.post(url,config)
    // ref- https://stackoverflow.com/questions/44715661/cannot-access-this-in-axios-post-method-in-vuejs-2
    .then((response) => { // do not use "(function (response)){..} or 'this' is undefined below
      console.log('checkRecordExists response ', response.data.messages[0].message)
      // if student record -> add isSubmitted flag to local attendance object 
      // to disable checkbox and ignore when submitting remaining attendance
      // code: "401"
      // message: "No records match the request"
      const message = response.data.messages[0].message
      if(message !== "No records match the request") { // RECORD FOUND 
        console.log('RECORD WAS NOT FOUND ..... ')
        resolve(true)
        // this.attendance[i].attendanceRecordExists = true
        // console.log('RECORD ALREADY EXISTS ', this.attendance[i])
        // // this.skippedCount += 1
        // if(this.skippedCount === this.numRecordsToSubmit) { // FIX
        //   // console.log(`skippy ${this.skippedCount}`)
        //   return this.skippedCount
        // }  
      } else {
        console.log('RECORD WAS FOUND ..... skipping')

        // this.skippedCount += 1
        reject(false)
          // if(this.skippedCount === this.numRecordsToSubmit) {} 
      }
    })
    .catch(function (error) {
    console.log('ERR in Attendance.checkAttendanceRecordsInFM : ', error);
    });
  })
}

//=====================================================================//

export function logoutFM(action: string, data: TODO ): Promise<TODO> {
  console.log('TOKEN : fm-api.logoutFM -  ', data)
  const token: TODO = data
  const options = {
    headers: {'Authorization': token}
  };

  return new Promise(function (resolve, reject) {
    // FM TOKEN
    // const url = 'http://localhost:3001/api/logout'
    const url = '/api/logout'
    axios.delete(url, options)
        .then((response) => {
          // If message is "Invalid" session already closed
          // {"messages":[{"code":"952","message":"Invalid FileMaker Data API token (*)"}],"response":{}}
          console.log('FM logged out OK?  :', response)    
          resolve('Logged out..')     
        })
        // TODO: Graciously handle error
        .catch(err => { reject({
          code: 403,
          err: err,
          text: "Error logging out",
        }) 
      })
  })
}

//=====================================================================//

export function login(action: string, data: TODO ): Promise<TODO> {

  const credentials = {
    _username: data.username,
    _password: data.password
  }
  console.log('Credent ', credentials)

  return new Promise(function (resolve, reject) {
    // FM TOKEN
    // const url = 'http://localhost:3001/api/login'
    const url = '/api/login'
    axios.post(url, credentials)
        .then((response) => {
          console.log('Token Received OK?  :', response.data.response.token)

          // Successful response format
          // {"response":{"token":"9a39a589c72ab52b4d7df85d7f2056dedab0f19db02bb82a4da9"},"messages":[{"code":"0","message":"OK"}]}

          if(response.data.response.token) { // WORKING!!
            const accessToken = response.data.response.token
            // const teacherName = 
            resolve({
              data: {
                accessToken: accessToken,
                user: {
                  firstname: credentials._username,
                  lastname: 'Doe',
                  email: 'm@e.com',
                  fm_id: 'T000XXXX'
                }
              }
            })     
          } else {
            reject({
              code: 403,
              text: "Invalid FM user account or password",
            });
          }
        })
        // TODO: Graciously handle error
        .catch(err => { reject(err) })
  })
}

//===============================================================================//

// ALTERNATIVE NODE SERVER THAT PREVIOUSLY HANDLED GETTING TEACHER CREDS FROM ATLAS
// NOTE: server runs on localhost:3002 

export function getTeacherCreds(action: string, data: TODO ): Promise<TODO> {
  const credentials = {
    _username: data.username,
    _password: data.password
  }

  return new Promise(function (resolve, reject) {
    // FM TOKEN - TODO: move to same server as FM API (ie. 3001 and same Digital Ocean in production)
    const url = 'http://localhost:3002/api/single-teacher'
    axios.get(url, { params: credentials })
      .then((response) => {
        console.log('Teacher Record:', response.data.teacher_id)
        const id = response.data.teacher_id
        resolve(id)
      })
      // TODO: Graciously handle error 
      .catch(err => {reject({
        code: 403,
        err: err,
        text: "Teacher not found in Atlas", // Catch on frontend and present friendly user alert
      }) 
    })
  })
}

//==============================================================================================//

// Currently working request to check FM Token is still valid on page mount
// See 'About' page for simple implementation
// Use to keep session open longer while app is idle
// Check for schedule updates
// Persist schedules to Mongo
// UPDATE: replace server-side API with call to FMS endpoint from docs
// GET: https://{{server}}/fmi/data/v1/validateSession

export function checkFMToken(action: string, data: TODO ): Promise<TODO> {
  const token: TODO = data
  console.log('CheckFMToken', token)

  // const options = {
  //   headers: {'Authorization': token}
  // };

  return new Promise((resolve, reject) => {
    // const url = 'http://localhost:3001/api/token_auth'
    const url = '/api/token_auth'
    axios.post(url, { token: token })
      .then((response) => {
        console.log('Token checked OK? ', response)
        console.log('MSG from FM ', response.data['messages'][0]['message'] )
      if(response.data['messages'][0]['message'] !== 'Invalid FileMaker Data API token (*)') { 
        console.log('RES IN TOKEN CHECK ...should still be valid', response)        
        // this.checkTime = (new Date().toLocaleTimeString());
        // this.message = 'Token still valid at ' + ' ' + this.checkTime
        resolve(true)
      } else {
        // Set time of check to append to message 
        // this.checkTime = (new Date().toLocaleTimeString());
        // this.message = 'Token invalid at ' + ' ' + this.checkTime
        console.log('FM VALIDATION RESPONSE FAILED : ', response)
        // localStorage.clear() 
        reject(false)
      }
    })
    // TODO: Graciously handle error
    // .catch(err => { reject(err) })
  })
}

//=================================================================================//

export function getRegistrationRecords(action: string, data: TODO ): Promise<TODO> {
  const token: TODO = data
  console.log('Get Reg Records with token : ', token)

  // const options = {
  //   headers: {'Authorization': token}
  // };

  return new Promise((resolve, reject) => {
    const url = 'http://localhost:3001/api/myPrivateClasses'
    // const url = '/api/myPrivateClasses'
    axios.post(url, { token: token })
      .then((response) => {
        console.log('REg ok? ', response)
        console.log('MSG from FM ', response.data['messages'][0]['message'] )
      if(response.data['messages'][0]['message'] !== 'Invalid FileMaker Data API token (*)') { 
        console.log('RES IN TOKEN CHECK ...should still be valid', response)        
        // this.checkTime = (new Date().toLocaleTimeString());
        // this.message = 'Token still valid at ' + ' ' + this.checkTime
        resolve(response)
      } else {
        // Set time of check to append to message 
        // this.checkTime = (new Date().toLocaleTimeString());
        // this.message = 'Token invalid at ' + ' ' + this.checkTime
        console.log('FM VALIDATION RESPONSE FAILED : ', response)
        // localStorage.clear() 
        reject(false)
      }
    })
    // TODO: Graciously handle error
    .catch(err => { reject(err) })
  })
}

//==================================================================================//

// Not needed in V2 
export function getGroupClasses(action: string, data: TODO ): Promise<TODO> {
  // const token: TODO = data.token
  const teacher_id: TODO = data.t_ID

  // const dateRange: TODO = "01/04/2021...01/10/2021"
  const dateRange: TODO = "01/02/2021...01/25/2021"

  // console.log('GROUP API CALL : ', token + teacher_id) 
  // Check persisted state for data 
  // If persisted state -> check for any modified timestamp later than last login timestamp    

  return new Promise((resolve, reject) => {
    // Note: "_kf_group_schedule_id": "= " is to avoid returning Groups from WP2Classes layout
    // Only Groups will have a value for this field
    const groupClassEvents = [];
    // Query for Privates
    // const query = JSON.stringify({"query": [{"_kf_group_schedule_id": "= ", "_kf_teacher_id": teacher_id,"scheduled date": dateRange}]})
    // Query for Groups
    const query = JSON.stringify({"query": [{"_kf_teacher_id": teacher_id,"scheduled date": dateRange}]})
  
    const options = {
      headers: {
        //'Authorization': token,
        data: query
      }
    };

    // const url = 'http://localhost:3001/api/myGroupClasses'
    const url = '/api/myGroupClasses'
      // axios.post(url, { token: token, tID: teacher_id })
      axios.post(url, options)
        .then((response) => {
          // console.log('Reg ok? ', response)
          // console.log('MSG from FM ', response.data['messages'][0]['message'])
          console.log('MSG from FM ', response.data)

          // Handle no group classes found for teacher 
          // if(response.data['messages'][0]['message'] === 'No records match the request') {
          //   alert('No Group classes found for teacher')
          // }


        if(response.data['messages'][0]['message'] !== 'Invalid FileMaker Data API token (*)') { 
          console.log('RES IN TOKEN CHECK ...should still be valid', response)        
          // this.checkTime = (new Date().toLocaleTimeString());
          // this.message = 'Token still valid at ' + ' ' + this.checkTime
          response.data.response.data.forEach((doc) => {


            // console.log('NAME DOC :  ', doc['portalData']['Student'][0]["Student::First Name"])
            const companyName = doc['fieldData']['Group Schedule::Corporate client Company name_display']
            const groupNo = doc['fieldData']['Registration::group number']
            const startTime = doc['fieldData']['schedule time START']
            const endTime = doc['fieldData']['schedule time END']
            const scheduledDate = doc['fieldData']['scheduled date']
            const noteToTeacher = doc['fieldData']['Note To Teacher']
            const students = doc['portalData']['Student classmates startup']
            const registrations = doc['portalData']['Registration']

            console.log('COMP : ', companyName)
        
            // const firstName = doc['portalData']['Student'][0]["Student::First Name"]
            // const lastName = doc['portalData']['Student'][0]["Student::Last Name"]
            // const studentID = doc['portalData']['Student'][0]["Student::_kp_studen_id"]


          //   "fieldData":{
          //     "_kp_classes_id":"CLA00000XXXX",
          //     "_kf_teacher_id":"99999999",
          //     "scheduled date":"03/19/2013",
          //     "schedule time START":"02:00:00",
          //     "schedule time END":"02:30:00",
          //     "_kf_group_schedule_id":"8273849",
          //     "outcome":"",
          //     "Registration::type of class":"Corporate Group Online",
          //     "_kf_registration_id":"3939393",
          //     "Registration::group number":"B",
          //     "Group Schedule::Corporate client Company name_display":"Company Name",
          //     "Group Schedule::_kp_group_schedule_id":"4449999"
          //  },

            // privateClassEvents.push({ firstName: firstName, lastName: lastName, studentID: studentID })
            if(companyName && students.length) {
              groupClassEvents.push(doc)
            } else {
              return
            }
          })
          resolve(groupClassEvents)
        } else {
          // Set time of check to append to message 
          // this.checkTime = (new Date().toLocaleTimeString());
          // this.message = 'Token invalid at ' + ' ' + this.checkTime
          console.log('FM VALIDATION RESPONSE FAILED : ', response)
          // localStorage.clear() 
          reject(false)
        }
      })
      // TODO: Graciously handle error
      .catch(err => { reject(err) })
  })
}


// FM READ - for developer testing in VUE
export function getFMData(action: string): Promise<TODO> {

  alert('demo-api [getFMData]')
  const results = true
  return new Promise(function (resolve, _reject) {
    if(!results) {
      console.log( 'false')
      _reject(false)
    } else {
      console.log( 'true')
      const msg = '[getFMData] Promise works!'
      resolve(msg)
    }
  })
}

//=======================================================================================//
// HELPER FUNCTIONS (not currenly used)
//=======================================================================================//

function getModel(action: string) {
  if (action.includes("/")) {
    return action.substring(0, action.indexOf("/"))
  }
  else {
    return action;
  }
}

function getId(action: string): number {
  if (action.includes("/")) {
    return parseInt(action.substring(action.indexOf("/") + 1))
  }
  else {
    return 0
  }
}

function getExpand(qs: TODO) {
  if (EXPAND in qs) {
    return qs[EXPAND];
  }
  else return ''
}

// function parseRequest(req: string) {
//   const parsedUrl = url.parse(req);
//   // const parsedQs = querystring.parse(parsedUrl.query);
//   const model = getModel(parsedUrl.pathname);
//   const id = getId(parsedUrl.pathname);
//   // const exp = getExpand(parsedQs)
//   // const filters = getSeachFilters(parsedQs)
//   // return { model, id, exp, filters }
// }

// export function getData(action: string): Promise<TODO> {
  // const { model, id, exp, filters } = parseRequest(action)
  // return new Promise(function (resolve, _reject) {
  //   const expandModel = exp
  //     ? exp === "category"
  //       ? "categories"
  //       : exp + "s"
  //     : exp;

    // console.log(model);
    // let result: TODO;
    // let expand: string, expandId: number;
    // console.log(expandModel);
    // if (model in ds) {
    //   if (id && id > 0) {
    //     result =
    //       ds[model][ds[model].findIndex((d: { id: number }) => d.id === id)];
    //     if (expandModel) {
    //       expand =
    //         expandModel === "categories"
    //           ? "category"
    //           : expandModel.substr(0, expandModel.length - 1);
    //       expandId = result[expand + "Id"] as number;
    //       result[expand] =
    //         ds[expandModel][
    //         ds[expandModel].findIndex((d: { id: number }) => d.id === expandId)
    //         ];
    //     }
    //   } else {
      //   result = ds[model].map((m: { [x: string]: TODO }) => {
      //     if (expandModel) {
      //       expand =
      //         expandModel === "categories"
      //           ? "category"
      //           : expandModel.substr(0, expandModel.length - 1);
      //       expandId = m[expand + "Id"] as number;
      //       m[expand] =
      //         ds[expandModel][
      //         ds[expandModel].findIndex((d: { id: number }) => d.id === expandId)
      //         ];
      //     }
      //     return m;
      //   });
      // }

    //   if (filters !== null && filters !== undefined
    //     && Object.keys(filters).length > 0) {
    //     result = result.filter(
    //       row => Object.keys(filters).every(
    //         prop => filters[prop](prop,row)
    //       )
    //     )
    //   }
    // }
    // setTimeout(resolve, 500, { data: result });
  // });
// }

// export function postData(action: string, data: Entity): Promise<TODO> {
//   const { model } = parseRequest(action)
//   return new Promise(function (resolve, _reject) {
//     ds[model].push(data);
//     setTimeout(resolve, 200, { data: data });
//   });
// }

// // export function putData(action: string, data: Entity): Promise<TODO> {
// //   const { model, id } = parseRequest(action)
// //   return new Promise(function (resolve, _reject) {
// //     const idx = ds[model].findIndex((d: { id: number }) => d.id === id);
// //     ds[model][idx] = Object.assign({}, data);
// //     setTimeout(resolve, 200, { data: data });
// //   });
// // }

// export function deleteData(action: string): Promise<TODO> {
//   const { model, id } = parseRequest(action)
//   return new Promise(function (resolve, _reject) {
//     if (id > 0) {
//       ds[model].splice(ds[model].findIndex((d: Entity) => d.id === id), 1);
//     }
//     setTimeout(resolve, 200, { status: 200 });
//   });
// }











