<template>
  <div class="home center" style="margin-top: 60px;">
    <v-card class="mx-auto" color="" max-width="800" subtitle="The #1 Vue UI Library">
      <v-card-title>
        <v-icon class="err-icon" large left>
          mdi-information
        </v-icon>
        <span class="title font-weight-light">NETWORK ERROR</span>
      </v-card-title>

      <v-card-text class="headline font-weight-bold">
        <p style="color: rgb(113 112 112);
            font-weight: 400;margin-left: 7px;">
            {{ msg }}
        </p>

          <!-- If this error persists please contain your ACA Administration. -->

      </v-card-text>



      <v-card-actions>
        <v-list-item class="grow">
          <!-- <v-icon class="" large left>
            mdi-notify
          </v-icon> -->
          <!-- <v-list-item-avatar color="grey darken-3">
            <v-img class="elevation-6" src="@/assets/avatar0.png"></v-img>
          </v-list-item-avatar> -->


          <v-list-item-content style="padding: 13px;
              background-color: #013b84;
              color: aliceblue;margin-bottom: 10px;">
            <v-list-item-title>If this error persists, please notify ACA Administration</v-list-item-title>
          </v-list-item-content>
        

          <!-- <v-row align="center" justify="end">
            <router-link to="/login">
              <v-icon class="mr-1" link="/login" router>mdi-login</v-icon>
            </router-link>
          </v-row> -->
        </v-list-item>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { createDecorator } from 'vue-class-component';
export default {
  name: 'ErrorPage',
  data() {
    return {
      msg: "Sorry, there was an error loading your page. Please try again later."
    };
  },
  // Catch FM related errors 
  created() {
    const error = JSON.stringify(sessionStorage.getItem('fm-err'))
    // console.log('STRING -> ', error)
    // const err = JSON.stringify(error)
    // alert(`This is -> ${err}`)
    console.log('type ', typeof error)
    // alert(`Error now : -> ${error}`);
  }
};
</script>

<style scoped>
.router-link-active {
  text-decoration: none !important;
}
.home-icon {
  color: lightgrey
}
.err-icon {
  color: #ff413a !important;
}
</style>
