/* eslint-disable */
// @ts-ignore
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
// import '@mdi/font/css/materialdesignicons.css'
// import { preset } from 'vue-cli-plugin-vuetify-preset-fortnightly/preset';

console.log('Vuetify[veutify.ts]', Vuetify)

Vue.use(Vuetify);

export default new Vuetify({
    // preset,
    dark: false, // Set to true for dark theme
    themes: {
        light: {
            primary: 'pink', // Your custom primary color
            secondary: '#6b82fa'
        },
    },
});