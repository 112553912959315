<template>
  <div v-if="hasPhysicalAddress" class="address-container">
    <div class="address-row">
      <span class="address-chip">
        <v-icon class="address-icon">mdi-map-marker</v-icon>
        <small class="address-text">{{ formattedAddress }}</small>
      </span>
      <span v-if="address['floor/room']" class="additional-info">
        <v-icon>mdi-office-building</v-icon>
        <small><strong>Room:</strong> {{ address['floor/room'] || 'TBD' }}</small>
      </span>
      <span v-if="address.guidelines" class="additional-info">
        <v-icon>mdi-information</v-icon>
        <small>{{ address.guidelines }}</small>
      </span>
      <span v-if="address.parking" class="additional-info">
        <v-icon>mdi-car</v-icon>
        <small>{{ address.parking }}</small>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormattedAddress',
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasPhysicalAddress() {
      return this.event.physicalClass && 
             this.event.physicalClass.length > 0 && 
             this.event.physicalClass[0].building !== '';
    },
    address() {
      return this.hasPhysicalAddress ? this.event.physicalClass[0] : {};
    },
    formattedAddress() {
      if (!this.hasPhysicalAddress) return '';

      const parts = [
        this.address.building,
        this.address.address,
        this.address.city,
        this.address.province,
        this.address.postalCode,
      ].filter(part => part && part.trim() !== '');

      return parts.join(', ');
    },
  }
}
</script>

<style scoped>
.address-container {
  margin-top: 8px;
  margin-left: 6px;
}

.address-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
}

.address-chip {
  display: inline-flex;
  align-items: center;
  background-color: #d4f3ff;
  border-radius: 16px;
  padding: 2px 15px 4px 5px;
  cursor: default;
  margin-left: -11px;
}

.address-icon {
  font-size: 21px;
  margin-right: 4px;
}

.address-text {
  margin: 0;
  font-size: 14px;
}

.additional-info {
  display: inline-flex;
  align-items: center;
  gap: 0px;
  font-size: 18px;
  cursor: default;
  margin-left: 6px;
}
.additional-info .v-icon {
  margin-right: 8px;
}

.additional-info .v-icon {
  font-size: 21px;
}

</style>