import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";

import { UserState } from "./modules/user";
import { AppState } from "./modules/app";

import { ClassState } from "./modules/classes";
import { AttendanceState } from "./modules/attendance";


// ACA 
// import { FM_UserState } from "./modules/fmuser";



Vue.use(Vuex);

// MM - Add all imported state modules to be used in app to the interface
interface RootState {
  appState: AppState;
  userState: UserState;
  classState: ClassState;
  attendanceState: AttendanceState;
}

// const state = { user: '', token: '', products: [] /* etc. */ }
//   const initialStateCopy = JSON.parse(JSON.stringify(state))

//   export const store = new Vuex.Store({ state, /* getters, mutations, etc. */ })

//   export function resetState() {
//     store.replaceState(initialStateCopy)
//   }

// MM - Store with RootState passed
export default new Vuex.Store<RootState>({
  // plugins: [createPersistedState({ storage: window.sessionStorage })] // !debug ? [createPersistedState({ storage: window.sessionStorage })] : [],
});
