
import OutcomeStatus from '../components/OutcomeStatus.vue'; 
import EndSession from '../components/EndSession.vue'; 

import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
import { userModule } from "@/store/modules/user";
import axios from 'axios'
import moment from 'moment';
import router from '@/router';
import modal from "../components/Modal";
import outcome from "../components/OutcomePopup.vue"
import Duration from "../components/DurationChange.vue"
import { classesModule } from '@/store/modules/classes';
import Swal from 'sweetalert2'
import { getFMToken, getSeachFilters } from '@/utils/app-util';
import { checkIsPostponed } from '@/utils/classDataFixer.js';
import { checkFMToken, checkRecordExists, rescheduleClass, getClass } from '@/utils/fm-api'; // Custom API MODULE
import ConfirmDialog from "@/components/ConfirmDialog.vue";
// import PostponeForm from "@/components/postponeForm.vue";
import { at, forEach, get, has } from 'lodash';
// import DatePicker from 'vue2-datepicker'


// Components 
import Tabs from "@/components/Tabs.vue";
import ContractDetails from '@/components/ContractDetails.vue';
import ParticipantsTable from '@/components/ParticipantsTable.vue';
import ScheduleTable from '@/components/ScheduleTable.vue';
import { formatToFrenchDate, formatToFrenchDateTime } from '../utils/dateFormatter.js';
import { getLocalStorageValue } from '../utils/localStorageHelper';
import { checkIfPhoneClass } from '../utils/classCardHelper.js';
import { compareAndConvertDurations } from '../utils/classDurationHelper.js';
import FormattedAddress from '../components/FormattedAddress.vue';


// BREAKS CURRENT COMPONENT - BLANK SCREEN
// @Component({
//   components: {
//     ConfirmDialog
//   }
// })

export default  {

  components: { 
    FormattedAddress,
    EndSession,
    OutcomeStatus,
    ContractDetails,
    ParticipantsTable,
    ScheduleTable,
    DatePicker,
    // 'confirm-dialog': ConfirmDialog, 
    modal,
    outcome,
    Tabs,
    Duration
    // EventCard
  },
  data() {
    return {
      isPhoneClass: null,
      isLoading: true,
      userLanguage: '',
      traxLanguageFromStorage: getLocalStorageValue('fmUserLanguage'),
      formCompleted: false,
      lateLeftValues: [],
      showOverrideDurationDialog: false,
      postponedByOption: null,
      stickyNotes: ['Click to add custom notes', 'another note. there are three for testing', 'this will be from registration and unique to each student. The notes will be retrieved separately by the API and stored to array which will be referenced by the index of each iteration of by the student reg id itself.'],
      currentNoteIndex: -1,
      stickModalTitle: '',
      modalOpen: false,
      selectedValues: [],
      allSelected: '',
      time1: null,
      time2: null,
      time3: null,
      showPostponeOrCancelForm: false,
      hideAttendance: false,
      showNewDateString: false,
      modal: false,
      newDate: null,
      showCancelForm: false,
      showPPForm: false,
      postponeSelectChoice: '',
      ppRadioSelection: null,
      cancelSelectOptions: ['Cancellation (More than 24 hours before class - with reason)', 'Cancellation (More than 24 hours before class - without reason)', 'Last Minute Cancellation (24 hours before class - with reason)', 'Last Minute Cancellation (Less than 24 hours before class - without reason)' ],
      postponeTeacherSelectOptions: ['Add class to the end of the session', 'Choose another date', 'Do not know'],
      currentTime: moment().format('MMMM D, YYYY, HH:mm:ss'),
      dummyEarlierTime:  moment().subtract(5, 'hours').format('HH:mm:ss'),
      selectedDuration: 'Default',
      selectedHours: null,
      selectedMinutes: null,
      selectedClassDuration: '',
      note: '',
      isLocked: sessionStorage.getItem('isLocked'),
      durationNote: '',
      durationHrs: 0,
      durationMins: 0,
      intrvl: null,
      processing: false,
      sendClicked: false,
      submitProgress: 0,
      showSendBtn: false,
      recordCreated: false,
      classDuration: 0,
      selectedPPCancelOption: null, // You can set a default option if needed
      PPCancelOptions: [
        { label: this.$t('detail.postponeRescheduleTab.postponedByLabelTeacher'), value: 'by teacher' },
        { label: this.$t('detail.postponeRescheduleTab.postponedByLabelStudent'), value: 'by student' }],
      tabs: [
        { title: this.$t('detail.attendance'), icon: 'mdi-account-check'}, 
        { title: this.$t('detail.postponeReschedule'), icon: 'mdi-calendar-arrow-right'}, 
        { title: this.$t('detail.schedule'), icon: 'mdi-calendar'}, 
        // { title: 'Course Account', icon: 'mdi-clipboard-text' },  
        // { title: 'Other Documents', icon: 'mdi-clipboard'}
      ],
      currentTab: 0,
      dataMissing: 'Data missing',
      selectLabel: 'Class outcome selection',
      selectDisabled: true,
      // modalText: 'This will be replaced',
      fmtoken: '',
      message: '',
      eventData: {},
      attendance: [],
      attendanceSubmitDisabled: true,
      regIDs: [],
      // outcomeOptions: ['Attendance Form', 'LMC', 'PS', 'PT', 'NS'],
      selectedOption: null,
      selectedTotal: 0,
      outcomeOptions: [],
      outcome: '',
      outcomeExists: false,
      outcomeChoice: '',
      showForm: false,
      displayDate: null,
      studentCount: 0,
      studentsPresent: false,
      skippedCount: 0,
      numRecordsToSubmit: 0,
      dialogTitle:"Attendance done Dialog",
      dialogText: "Please confirm",
      dialog: false,
      test: true,
      showModal: false,
      modalTitle: 'Postpone Class',
      modalType: '',
      showOutcomePopUp: false,
      attendanceIncomplete: false,
      presentTotal: 0,
      absentTotal: 0,
      startTime: null,
      endTime: null,
      numRadiosSelected: 0,
      fullJSON: null,
      attendanceRecs: null,
      hasAttendanceRecs: true,
      hasCompleteAttendance: false,
      showSubmitSection: false,
      unjustifiedCount: 0,
      radioValues: [],
      isNoteRequired: true,
      enableDurationConfirm: false,
      overrideDuration: null,
      defaultDurationCheckbox: false,
      scheduleID: null,
      rescheduleDate: null,
      rescheduleTime: null,
      rescheduleOption: null,
      doubeleBookingConfirmed: 0,

      // Schedule table 
      headers: [
        { text: '#', value: 'index', sortable: false, width: '50px' },
        { text: 'Date', value: 'date' },
        { text: 'Time / Defualt Duration', value: 'time' },
        { text: 'Teacher', value: 'instructor' },
        { text: 'Duration', value: 'duration' },
        { text: 'Status', value: 'status' },
      ],
      studentHeaders: [
        { text: 'Student', value: 'name' },
        { text: 'Classes Absent', value: 'absence' },
        { text: 'Hours Attended/Contract Hours', value: 'contract' },
      ],
      events: [
        {
          date: '2023-05-20',
          time: '10:00 - 11:30 AM',
          duration: '1.5 hours',
          status: 'Done',
          instructor: 'Ryan Lee',
        },
        {
          date: '2023-05-21',
          time: '2:30 - 4:30 PM',
          duration: '2 hours',
          status: 'Scheduled',
          instructor: 'Ryan Lee'
        },
        {
          date: '2023-05-22',
          time: '9:00 - 10:00 AM',
          duration: '1 hour',
          status: 'Postponed',
          instructor: 'Ryan Lee'
        },
        {
          date: '2023-05-20',
          time: '10:00 - 11:30 AM',
          duration: '1.5 hours',
          status: 'Done',
          instructor: 'Ryan Lee',
        },
        {
          date: '2023-05-21',
          time: '2:30 - 4:30 PM',
          duration: '2 hours',
          status: 'Scheduled',
          instructor: 'Ryan Lee'
        },
        {
          date: '2023-05-22',
          time: '9:00 - 10:00 AM',
          duration: '1 hour',
          status: 'Postponed',
          instructor: 'Ryan Lee'
        },
      ],
      }
  },

  computed: {
    checkingPhoneClass() {
      const cpc = checkIfPhoneClass(this.eventData);
      console.log('cpc ', cpc)
      return checkIfPhoneClass(this.eventData);
    },

    formatIsoDate() {
      console.log('Format the  date:', this.eventData.isoStart); // For debugging
      return formatToFrenchDate(this.eventData.isoStart);
    },
    formattedDateTime() {
      return formatToFrenchDateTime(this.eventData.isoStart);
    },

    lateLeftCheckboxValues() {
      // Map lateLeftValues array to ensure it is either true or false
      return this.lateLeftValues.map(value => value || false);
    },
    isNoteRequired() {
      return (this.selectedHours || this.selectedMinutes) && !this.durationNote.trim();
      // return (this.selectedHours !== null || this.selectedMinutes !== null) && !(this.selectedHours === 0 && this.selectedMinutes === 0) && !this.durationNote.trim();
    },

    isAttendanceSubmitDisabled() {
      return this.attendanceSubmitDisabled;
    },

    isTextareaCompleted() {
      return this.durationNote.trim() !== "";
    },

    isButtonDisabled(){
      // if(this.ppRadioSelection === '') {
      //   return true
      // } else 
      if(this.ppRadioSelection !== '') {
        // alert('what was checked?')

        if(this.ppRadioSelection !== 'reschedule') { 
          return true
        }   

        if(this.ppRadioSelection === 'reschedule') {        
          const dateTimeSelected = this.time1 !== null && this.time2 !== null
          console.log(dateTimeSelected)
          // Require more checks here to make sure the new date/time is valid
          // alert('check ... should be true if both date and time selected...')
          if(dateTimeSelected) {
            this.showSubmitSection = true
            return true
          }
          // return dateTimeSelected
        } else { // all other options enable button
    
          return false
        }
      }
    },
    // Not working...
    // duration() {
    //   return this.classDuration
    // }
  },

  async beforeCreate(){
    const currentDate = new Date();
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    const currentDateTimeString = currentDate.toLocaleString('en-US', options).replace(',', '');
    sessionStorage.setItem('currentDateTime', currentDateTimeString);

    // this.userLanguage = localStorage.getItem('fmUserLanguage')
    // Create a FM call with the latest data
    // In FM service, if the mod date of a record in the current period is later that the query time 
    // make a new FM call retrieve all records
    // Replace the classes store
    // Then load the current version of the class
    // Replicate for each page load in app

    // this.fmtoken = await getFMToken()
    // alert(`attendance.beforeCreate token here -> ${this.fmtoken}`)
    // Check FM token - TODO: change checkFMToken to the /validateSession FM API endpoint
    
    // const validateSession = await checkFMToken("checkFMToken", this.fmtoken)
    // if(validateSession) {
        // alert(`Session Valid :  ${validateSession}`)
      // console.log('Session valid')
    // }
    // else {
        // alert('beforeCreate : validateSession = false.. loading login')
      // this.$router.push("/login")
    //}
  },

  async created () {

    // Set Class duration 
    // Get the language for the page here to set the dates in FR or EN
    // console.log('TLang -> ', this.traxLanguageFromStorage)

    // Set condition for phone classes to show / hide number 
    this.updatePhoneClass();
    // const language = JSON.stringify(localStorage.getItem('fmUserLanguage'))
    // console.log('User ', language)
    // this.userLanguage = language

    // if() {
    //   Swal.fire({
    //       title: 'Sorry, a problem occurred loading this class.',
    //       text: 'You may be logged in elsewhere. Please log out of other browsers or devices and try logging in again.',
    //       icon: 'warning',
    //       confirmButtonText: 'OK'
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         sessionStorage.clear()
    //         localStorage.clear()
          // window.location.replace(process.env.VUE_APP_URL + '/login'); // Redirects to the login page
    //       }
    //     });
    // }
    // Access the object passed from the previous route
    const classObj = this.$route.params.classObj;

    // Now you can use myObject as needed
    console.log('class obj [attedance - created]-> ', classObj);
    // alert('class object in console...')

    // Get the token from store
    const token = sessionStorage.getItem('token')
    // console.log('token form storage -> ', token);
    // alert('check token in console... ')

    this.enableDurationConfirm = true
    this.selectedOption = 'Default';
    console.log('API URL from .env -> ', process.env.VUE_APP_API_URL)
    

    if(sessionStorage.getItem('Selected')) {
      // const classFromStorage = sessionStorage.getItem('selectedClass')
      const classFromStorage = await sessionStorage.getItem('Selected')
      const classToJSON = JSON.parse(classFromStorage)

      // alert('Class from storage... check console')

      // API call - Request to get single class from FM [fm-api.ts]
      const singleClass = await getClass(classToJSON)
      const checkPPStatus = await checkIsPostponed(singleClass)

      // console.log('sc ', singleClass.data[0].registrations.length)
      // console.log('sc ', singleClass.data[0].attendances.length)

      // Set student count and attendnce count on the class detail data 
      // Required to conditionally add note 'attendance required' to the outcome status 
      // Use case: FM admin selects 'Class Done' but the teacher still must submit attendance
      // - see OutcomeStatus.vue  
      singleClass.data[0].studentCount = singleClass.data[0].registrations.length
      singleClass.data[0].attendancesCount = singleClass.data[0].attendances.length

      this.isLoading = false

      // console.log('PP fixed ', checkPPStatus)
      sessionStorage.setItem('singleClass', JSON.stringify(singleClass))

      // console.log('single class from FM -> ', singleClass['data'][0]['registrations'])
      // Registrations schema
      //   {
      //   "pkRegs": "R00070085",
      //   "fkClient": "COR00000468",
      //   "fkStudent": "S00039323",
      //   "firstName": "Carole",
      //   "lastName": "Jacques",
      //   "status": "Active"
      // },

      // Registrations array 
      const regs = singleClass['data'][0]['registrations']
      // Attendance array to check for existing records
      const attendanceArr = singleClass['data'][0]['attendances']

      console.log('CFS -> ', attendanceArr)

      // Filter registrationsArray based on whether the registration ID exists in any object in attendanceArr
      const newArray = regs.map((reg, i) => {
          // Check if any object in otherArray has the same registration ID
          const foundAttendance = attendanceArr.find(a => a.fkRegistration === reg.pkRegs);
          
          if (foundAttendance) {
              // Found an existing attendnace for the student - create the object with the existing values
              let attIdString = attendanceArr[i].pkAttendances.toString()
              // alert(`type: ${typeof(attIdString)}`)

              return {
                pkAttendances: attIdString,
                studentReg: reg.pkRegs,
                status: attendanceArr[i].status,
                lateLeft: attendanceArr[i].lateLeft || false,
                remarks: '',
              };
          } else {
              // No Attendance record exists - create an object with default values
              return {
                  pkAttendances: null,
                  studentReg: reg.pkRegs,
                  status: '',
                  lateLeft: false,
                  remarks: '',
              };
          }
      });

      this.attendance = newArray
      // console.log('new one ', newArray)
      // alert('check new array now')


    //   singleClass['data'][0]['registrations'].forEach((r,i) => {
    //     this.lateLeftValues.push(false)
    //     console.log('idx ', r)
    //     console.log('left ', this.lateLeftValues)

    //     const studentAttendanceRecord = singleClass['data'][0]['attendances'][i]
    //     console.log('SAR ', studentAttendanceRecord)
    //     // If attendance exists do not push to the new attendance array 
    //     // If the attendance is changed then put
    //     // if(studentAttendanceRecord === undefined) {
    //     //   alert('no attendance exists for student')
    //     // } 
    //     // let att = studentAttendanceRecord.status || ''

    // //     {
    // //   "pkAttendances": 59,
    // //   "fkRegistration": "R00070085",
    // //   "firstName": "Carole",
    // //   "lastName": "Jacques",
    // //   "status": "PRESENT",
    // //   "remark": ""
    // // },

    //   // Prebuild attendances
    //   this.attendance.push({ studentReg: r.pkRegs , status: '', remarks: '', lateLeft: false })
    //   console.log('attendance Arr precompiled ', this.attendance)


    //     // alert('check attendance in view')
    //   }) 

    //   console.log('single ', singleClass)
    //   alert('check for single class api..')
      // this.eventData = singleClass // switch to this when endpoint is working
      
      // this.eventData = await JSON.parse(classFromStorage)
      this.eventData = singleClass.data[0]

      // If attedance already submitted add to registrations of each student
      await this.addExistingAttendanceToSelectedValues()
      // console.log('Selected List ', this.selectedValues)

      this.setClassDuration();  

    } else {   
      const selectedClass = sessionStorage.getItem('Selected')
      const parsedJson = JSON.parse(selectedClass)
      console.log('JSON parsed Class : ', parsedJson) 
      this.eventData = parsedJson
      
      // this.eventData = {
      //   color: 'orange',
      //   name: 'Fakeblock Inc',
      //   classID: 'CLAS0000123',
      //   groupNo: 'GRP12345',
      //     type: fD['type'],
      //     date: start,
      //     start: start,
      //   end: end,
      //     noStudents: 2,
      //     registrations: registrations,
      //     noteToTeacher: noteToTeacher,
      //     details: [{ note: noteToTeacher },
      //     { msg: 'hello there'}]

      // }
      // console.log('DA -< ', this.eventData)

      Swal.fire({
          title: 'Sorry, a problem occured loading this attendance form.',
          text: 'Please check your classes page and try again later.',
          icon: 'warning',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) {
            // Redirect to a new URL
            window.location.href = '/classes'; // Replace with your desired URL
          }
        });
    }
},

  // get duration() {
  //   console.log('CD ', this.eventData)
  //   return this.classDuration
  // },
  get outcomeStatus() {
    return this.outcomeExists
  },

  get getEvent() {
    return this.eventData
  },
  
  get currentDate() {
    const CD = moment()
    const dayAndTime = CD._d
    console.log('CD -> ', CD._d)
    return dayAndTime
  },

  getCurrentDateTime() {
    const currentDate = new Date();
    const currentDateTimeString = currentDate.toISOString(); // Convert date to string format
    localStorage.setItem('currentDateTime', currentDateTimeString);
    return currentDateTimeString
  },

  methods: {

    shouldShowStudentPhone() {
      const validTypes = [
        'Phone follow-up',
        'Suivi téléphonique',
        'Public Private phone',
        'Public Private phone',
        'Business Private phone',
        'Corporate Private phone',
        'Phone follow-up'
      ];
      
      return validTypes.includes(this.eventData.type) && this.eventData.virtualAccount === '';
    },
  

    setClassDuration() {

      // Call class duration helper with the event data
      this.selectedClassDuration = compareAndConvertDurations(this.eventData)

      console.log('Dur Helper back ', this.selectedClassDuration)
    },

    updatePhoneClass() {
      this.isPhoneClass = this.checkIfPhoneClass();
      console.log('Phone now ', this.isPhoneClass)    
    },

    checkIfPhoneClass(){
      console.log('Check if phone: ', this.eventData)
      // console.log('Check if platform: ', this.eventData.virtualClass.platform)
      if (typeof this.eventData.type !== 'string') {
        console.warn('eventData.type is not a string');
        return false;
      }
      console.log('eData ', this.eventData.type.toLowerCase().includes('phone'))
      return this.eventData.type.toLowerCase().includes('phone');
    },

    formatEndSessionDate(date) {      
      return moment(date, 'MM/DD/YYYY').format('MMMM Do, YYYY');
    },

    async onTabChange(newIndex) {
      console.log('Tab changed to:', newIndex);
      // Add any additional logic you want to execute on tab change

      // get classes 
      const cfs = await sessionStorage.getItem('events2')
      const parsedCfs = JSON.parse(cfs)

      // Postpone tab
      if(newIndex === 1) {
        console.log('store class', parsedCfs.data[0])
        this.setRescheduleRange() // +/- 6 days of class date
      }
    },

    setRescheduleRange() {
      console.log('date ', this.eventData.scheduleDate)
    },

    formatAndCleanTime(startTime, endTime) {
      const timeString = this.formatTimeAMPM(startTime, endTime);
      return timeString.replace(/\s*\(\d+hrs\)/, '');
    },

    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString('en-US', options);
    },

    // getStatusColor(status) {
    //   const statusColors = {
    //     Done: 'green',
    //     Postponed: 'orange',
    //     Scheduled: 'blue',
    //   };
    //   return statusColors[status] || 'grey';
    // },
  
    lateLeftChange(i){
      console.log('att ', this.attendance)
      // alert('late clicked..')
      console.log("Before toggle:", this.attendance[i]['lateLeft']);
      // this.attendance[i]['lateLeft'] = !this.attendance[i]['lateLeft'];
      console.log("After toggle:", this.attendance[i]['lateLeft']);

      // Check that all attendance has been filled before enableing submit button
      if(this.eventData.attendances.length === this.eventData.registrations.length) {
        this.attendanceSubmitDisabled = false
      } 
    },

    addExistingAttendanceToSelectedValues() {
      // function updateRegistrationStatus(registrations, attendances) {
        const attendances = this.eventData['attendances']

        this.eventData['registrations'].forEach(registration => {
          // Find the corresponding attendance entry
          const attendance = attendances.find(attendance => attendance.fkRegistration === registration.pkRegs);
          
          // If an attendance entry exists for this registration
          if (attendance) {
            // Add the status to the selectedValues array
            this.selectedValues.push(attendance.status);
          } else { 
            this.selectedValues.push(''); // Add an empty string to selected Array / student has no attendance 
          } 
        });
      // }
    },

    tabClicked(i) {
      // alert('tab click ')
      console.log('tab num ', i)
      this.currentTab = i

      if(i === 2) {
        // get classes in +/- 6 days of selected class
        console.log('SE ', this.eventData)

       // SCHEDULE API REQUEST
       axios.post('http://localhost:3001/schedule', { scheduleID: 4 })
        .then((response) => {
          console.log('Response from schedule request -> ', response.data.scheduleID)
          this.scheduleID = response.data.scheduleID

            // Extract the JWT token from the response
        })
      }
    },

    confirmDurationChange() {
      // alert('emitted form dur change modal')
    },

    confirmDefault() {
      // alert('enable send button')
      // this.isAttendanceSubmitDisabled = false
      this.attendanceSubmitDisabled = true
    },

    selectCustomDuration(selectDurationType) {
      console.log('hrs ', typeof this.selectedHours)

      // Hours OR Minutes must have a value
      if(this.selectedMinutes === 0 && this.selectedHours === 0) {
        // alert('Cant be 0 0')
        if(this.selectedMinutes > 0 || this.selectedHours > 0) {

          this.overrideDurationSelected = true

          // Check if note is empty
          if(this.durationNote.length > 9) {
            // alert('note is good too')
            // Enable confirm button - duration and note complete
            this.enableDurationConfirm = true
          } else {
            // make show note alert true here 

          }
        }
      }
      // This function will be called when either v-select value changes
      //  console.log(selectType)
      this.isNoteRequired()

      this.attendanceSubmitDisabled = true

     if (selectDurationType === 'hours') {
        console.log('Selected hours:', this.selectedHours);
        // Handle hours logic here
      } else {
        console.log('Selected minutes:', this.selectedMinutes);
        // Handle minutes logic here
      }
    // console.log('hours ', this.selectedHours)
    },

    cancelDurationOverrideForm() {
      // Reset all values of the form
      this.showOverrideDurationDialog = false
      this.selectedHours = null
      this.selectedMinutes = null
      this.durationNote = ''
      this.enableDurationConfirm = true
      this.overrideDuration = null
    },  

    async overrideClassDuration(overrideDurationData) {
      // Attendance has already been submitted 
      if(this.eventData.attendances.length > 0) {
        this.attendanceSubmitDisabled = false
      }
      console.log('Override ', overrideDurationData)
      this.overrideDuration = await overrideDurationData.hours + '.' + overrideDurationData.mins
      this.overrideNote = overrideDurationData.overrideNote
      console.log(this.overrideDuration)
    },

    handlePPByChange(selection) {
      console.log('postpone value: ', selection)
      if(selection === 'by teacher') {
        return'PT' 
      } else {
        return 'PS'
      }
      console.log("Selected option:", this.postponedByOption );
    },

    isPresent(i) {
      console.log('student radio[isPresent func] ',  this.selectedValues)
      if(this.selectedValues[i] === 'PRESENT') {
        return false
      } else {
        return true
      }
      // alert('check')
    },

    showFullNoteDialog(student, note, index) {
      this.currentNoteIndex = index
      this.selectedNote = note
      this.stickModalTitle = student
      // this.dialog = true;
      this.modalOpen = true
    },

    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    },

    closeStickyNotesModal(){
      this.modalOpen = false
    },

    saveNote(index, note) {
      // Form within a form for the following stickies feature (ie. separate from attendance report)
      // For production must sent the note to FM when saved in popup
      // then wait for success response
      // then get the new data if not in the response - ask Bruno
      // then save the new note to session storage 
      // retreive the note from storage and set it on the local data object
      // finally updates in the UI row for the specific student
      this.$set(this.stickyNotes, index, note);
      // this.stickyNotes[index] = note
      console.log('Stickies ', this.stickyNotes)
      this.modalOpen = false;
      // alert(`${index}`)
    },

    openNotesModal(s, index) {
      this.modalOpen = true;
      this.stickModalTitle = s
      console.log(s)
      // alert('notes... check the student / index')
    },

    goToPostponePage() {
        this.$router.push({ name: "postpone" });
    },

    async handleDatePickersChange() {
      console.log('Date time 1 ', this.time1)
      console.log('Date time 2', this.time2)

      // alert('check time 1 and 2')
      if (this.time1 !== null && this.time2 !== null) {
        // Both date-pickers have values, so you can call your function here

        // Format the date and time 
        const rescheduleDateFormatted = await this.formatRescheduleDate(this.time1)
        const rescheduleTimeFormatted = await this.formatRescheduleTime(this.time2)

        this.rescheduleDate = rescheduleDateFormatted
        this.rescheduleTime = rescheduleTimeFormatted

        // alert('reformat yyyy/mm/dd')
        this.displayNewDate();
      }
    },

    formatRescheduleDate(dateString) {
      const date = new Date(dateString);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}/${month}/${day}`;
    },

    formatRescheduleTime(dateString) {
      const date = new Date(dateString);

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${hours}:${minutes}`;
    },

    displayNewDate() {
      console.log('Both date-pickers have values: ', this.time1 + ' : ' + this.time2);
      // Check dates are within restrictions here
      this.showNewDateString = true
    },

    togglePostponeOrCancelForm() {
      this.showPostponeOrCancelForm = !this.showPostponeOrCancelForm;
      console.log(this.showPostponeOrCancelForm)
      // alert('check...')
    },

    onDateSelected() {
      // alert('got it...')
      this.modal = false
      this.showNewDateString = true
    }, 

    formatNewDate(inputDate) {
      // const formattedDate = moment(inputDate).format('dddd, Do MMMM, YYYY');
      const formattedDate = moment(this.time1).format('dddd, Do MMMM, YYYY');
      const formattedTime = moment(this.time2).format('hh:mm');
      return { date: formattedDate, time: formattedTime };
    },

    formatReschduleDate(dateTimeStr) {
      let formattedDate;
      // Splitting the input string by " : " to separate the two dates
      var dates = dateTimeStr.split(" : ");

      // Processing each date
      // dates.forEach((date_str) => {
      //   date_str = date_str.split(" GMT")[0];
      //   var date_object = new Date(date_str);
      //   formattedDate = date_object.toDateString() + " " + date_object.toLocaleTimeString();
      // });

        // Processing each date
        const formatted_dates = dates.map(function(date_str) {
        // Splitting the date string to separate date and time
        const [date_portion, time_portion] = date_str.split(" GMT")[0].split(" ");

        // Converting the date portion to a Date object
        const date_object = new Date(date_portion);

        // Formatting the Date object to mm/dd/yyyy format
        const formatted_date = `${date_object.getMonth() + 1}/${date_object.getDate()}/${date_object.getFullYear()} ${time_portion}`;
        console.log('ready ', formatted_dates);

        return formatted_date;
      });
        // return formattedDate
    }, 

    setRescheduleOption() {

      console.log('selection ', this.ppRadioSelection)
      // alert('yes...')
      if(this.ppRadioSelection === 'reschedule') { 
        this.rescheduleOption = 1; return 
      } else if(this.ppRadioSelection === 'add') { this.rescheduleOption = 2; return }
      else { this.rescheduleOption = 3; return }
    },

    showConfirmAlert(result) {

      Swal.fire({
        title: 'Confirmation',
        icon: 'warning',
        text: result.data.response,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#eea300',
        confirmButtonText: 'Yes, proceed',
        cancelButtonText: 'Close'
      }).then((result) => {
        if (result.isConfirmed) {
          // Call the function to reschedule
          this.doubleBookingConfirmed = 1
          this.submitNewDate()
          
        } else {
          this.processing = false
          this.$nextTick(() => {
            console.log('Processing state updated');
          });
          // User clicked Close, do nothing and stay on the page
          // console.log('User chose to stay on the page');
        }
      });
    },
        
    async submitNewDate() {
      this.processing = true
      console.log('reschedule date time ', this.rescheduleDate + ' ' + this.rescheduleTime)

      // Check if proceed with double booking is true
      const dbv = this.getDoubleBookingValue() 
      console.log('dbv true or false ? -> ', dbv)

      const ppBySelection = await this.handlePPByChange(this.postponedByOption)
      console.log('Postponed By: ', this.postponedByOption)
      
      // API payload from docs
      // {
      //   "classId": "string",
      //   "type": "string",
      //   "teacherId": "string",
      //   "rescheduleDateTime": "string",
      //   "notes": "string"
      // }
      const classFromStorage = sessionStorage.getItem('singleClass')
      const classParsed = JSON.parse(classFromStorage)
      console.log('cfs reschedule', classParsed['data'][0])
      const token = sessionStorage.getItem('token')
      await this.setRescheduleOption()

      // const classId = session.storage.getItem()

      // mm/dd/yyyy
      // const fDate = this.formatReschduleDate(this.time1 + ' : ' + this.time2)
      // console.log('Sending this date: ', fDate)

      const payload = {
        "classId": classParsed['data'][0]['pkClasses'],
        "language": this.traxLanguageFromStorage,
        "teacherId": token,
        "rescheduleDate": this.rescheduleDate || '',
        "rescheduleTime": this.rescheduleTime || '',
        "notes": ppBySelection,
        "option": this.rescheduleOption,
        "doubleBooked": dbv,
        "email": true
      }

      console.log('Payload[submitNewDate] ', payload)
      // alert('check reschedule PLOAD...')

      //   {
      //   "classId": "string",
      //   "teacherId": "string",
      //   "language": "string",
      //   "rescheduleDate": "string",
      //   "rescheduleTime": "string",
      //   "notes": "string",
      //   "option": 0,
      //   "doubleBooked": true,
      //   "email": true
      // }

      // call fm-api 
      // const result = rescheduleClass({ date: this.time1, time: this.time2, classID: this.eventData.classID  })
      const result = await rescheduleClass(payload)

      console.log('resched ', result)
      let swalBtnText
      let swalIcon

      if (result.data.response === "The class was rescheduled succesfully") {
        swalBtnText = 'OK'
        swalIcon = 'Success'
      } else if (result.data.response === "A class is already scheduled for that date and time, are you sure you want to proceed?") {
          // Need a check to see if double book has been confirmed by teacher to avoid loop  
          this.showConfirmAlert(result)    
      } else if (result.data.response === "This class cannot be postponed  + or - 6 days from the original date, please select a different date or contact ACA") {
          // this.showConfirmAlert(result)
          Swal.fire({
            title: '',
            icon: 'info', 
            text: result.data.response,
            confirmButtonText: 'OK',
            // willClose:     
          }).then(() => {
            this.processing = !this.processing
            console.log('Reschedule cancelled...', this.processing)
          });

      } else {
        swalBtnText = 'Close'
        swalIcon = 'success'
        Swal.fire({
          title: '',
          icon: swalIcon, 
          text: result.data.response,
          confirmButtonText: swalBtnText,
          // willClose:     
        }).then(function () {
          // If request succeeds redirect to dash to get the updated class record
          // alert('Decide to stay on form or go to dash if the reschedule succeeds..')
          if(swalIcon = "Success") {
            window.location.replace(process.env.VUE_APP_URL + '/classes');
          } 
          console.log('Sweet alert success closed...')
        });   
      }
    },

    getDoubleBookingValue() {
      if(this.doubleBookingConfirmed === 1) {
        return true
      } 
      return false 
    },

    selectNewClassDate() {
    },

    cancelClassClick() {
      this.showPPForm = false
      this.showCancelForm = true
    }, 

    ppClick() {
      this.showPPForm = true
      this.showCancelForm = false
    },

    postponeRadioSelection() {
      // if(this.ppRadioSelection === 'byTeacher') {
      //   this.ppRadioSelection = 'byStudent'
      //   console.log('those ', this.ppRadioSelection)
      // } else {
      //   this.ppRadioSelection = 'byTeacher'
      //   console.log('those ', this.ppRadioSelection)
      // }
    },

    handleDurationOptionChange() {
      // alert('handle selection')
      // If "Enter different duration" is selected, deselect "Default"
      if (this.selectedDuration === 'Duration Form') {
        this.selectedDuration = 'Duration Form';
      }
    },

    async getFullClassDuration() {
      const fullClass = await sessionStorage.getItem('selectedClassFull')
      const parsedClass = await JSON.parse(fullClass)
      console.log('PC -> ' , parsedClass[0]['fieldData']['duration'])
      this.classDuration = await parsedClass[0]['fieldData']['duration']
      console.log('Parsed -> ' , this.classDuration)
    },

    getDynamicLabel() {
      return this.durLabel
    }, 
    
    updateNote(){
      console.log('teacher note for class: -> ', this.note)
    },

    // duration() {
    //   alert('Custom duration added....')
    //   console.log('Class duration : ' + this.durationHrs + ':' + this.durationMins)
    // },

    // getAttendanceStatus(regID) {
    //   console.log('ID-> ', regID)
    //   const attendanceRecords =  this.fullJSON[0]['portalData']['class_ATTENDANCES']
    //   const studentAttRecord = attendanceRecords.filter(r => r["class_ATTENDANCES::_fk_Registration"] === regID)
    //   console.log('rec ', studentAttRecord)
    //   const attStatusForClass = studentAttRecord[0]["class_ATTENDANCES::status"]

    //   if(attStatusForClass === 'absent') {
    //     return false
    //   } else { 
    //     return attStatusForClass
    //   }
    // },

  // refactor to catch undefined error and alert in console
  getAttendanceStatus(regID) {
    try {
      const attendanceRecords = this.fullJSON[0]['portalData']['class_ATTENDANCES'];
      console.log('ID-> ', attendanceRecords);

      const studentAttRecord = attendanceRecords.filter(r => r["class_ATTENDANCES::_fk_Registration"] === regID);
      console.log('Attendance record exists ->  ', studentAttRecord);
      const attStatusForClass = studentAttRecord[0]["class_ATTENDANCES::status"];

      if(attStatusForClass === 'absent') {
        return false;
      } else { 
        return attStatusForClass;
      }
    } catch(error) {
      // alert("An error occurred while retrieving attendance status.");
      console.error('Att err  -> ', error);
    }
  },


  // attendanceNotcomplete() {
  //   const attendanceTotal = this.fullJSON[0]['portalData']['class_ATTENDANCES'].length
  //   const regTotal = this.eventData['registrations'].length

  //   const sum = regTotal - attendanceTotal
  //   if(attendanceTotal > 1 && attendanceTotal < regTotal && regTotal !== 0) {
  //   // if(regTotal !== 0 && sum !== 0) {
  //     return true
  //   } else {
  //     return false
  //   }

  // },

  classHasRegistrations() {
    // const regCount = this.fullJSON[0]['portalData']['class_ATTENDANCES'].length + this.eventData['registrations'].length
    // console.log('COUNT ', regCount)
    
    // 3 cases 
    // class has no students, class has incomplete attendance, class has complete attendance

    console.log('Event data : ', this.eventData)
    // alert('console..')

    if(this.eventData['registrations'].length !== 0) {
      // alert('[clasHasRegistrations] function')
      return true
    } else {
      return false
    }
  },

  durationRadioSelected() {
    this.selectedDuration = this.selectedDuration === 'custom' ? 'Duration' : ''
    // console.log('Selected ', this.selectedDuration)
  },

  formatDate(d) {
    console.log('Date to format here -> ', d)
    // const formatted = moment(d).format("ddd, Do MMM, gggg")
    return moment(d).format("ddd, Do MMM, gggg")
  },
  // Build out - attendance data already in store 
  attendanceSubmitted(student) {
    console.log('ATT already -> ', this.fullJSON)
    // let submitted = false
    const hasBeenSubmitted = this.fullJSON.filter(e => {
      if(e['portalData']['class_REGISTRATIONS::__pk_Registrations'] === student['class_ATTENDANCES::_fk_Registration']) {
      }

      // return e['portalData']['class_REGISTRATIONS::__pk_Registrations'] === student['class_ATTENDANCES::_fk_Registration'] 
    })

    // if(hasBeenSubmitted.length > 0) {
    //   alert('sjakldjasl')
    //   console.log('REG ', hasBeenSubmitted)
      
    //   submitted = true 
    // }
    return hasBeenSubmitted
  },

  // 12 hr
  formatTimeAMPM(startTime, endTime) {
    function to12HourFormat(time24) {
      const [hour, minute] = time24.split(':').map(Number);
      const period = hour >= 12 ? 'PM' : 'AM';
      const hour12 = hour % 12 || 12;
      return `${hour12}:${minute.toString().padStart(2, '0')} ${period}`;
    }

    function calculateDuration(start, end) {
      const [startHour, startMinute] = start.split(':').map(Number);
      const [endHour, endMinute] = end.split(':').map(Number);

      let hours = endHour - startHour;
      let minutes = endMinute - startMinute;

      if (minutes < 0) {
        minutes += 60;
        hours -= 1;
      }

      return { hours, minutes };
    }

    const startFormatted = to12HourFormat(startTime);
    const endFormatted = to12HourFormat(endTime);

    const duration = calculateDuration(startTime, endTime);
    const durationString = `${duration.hours}hr${duration.hours !== 1 ? 's' : ''}${duration.minutes > 0 ? ` ${duration.minutes}min` : ''}`;

    // return `${startFormatted} - ${endFormatted} (${durationString})`;
    return `${startFormatted} - ${endFormatted}`;

  },

  format24hrTime(time) {
    console.log('Time -> ', time) // 08:45:00
    const timeString = toString(time)

    const m = moment(time, 'HH:mm').format('HH:mm');
    // const formattedTime = m.format('HHmm');
    // console.log('Mo -> ', m)
    return m
  },

  getNumOfExistingRecords(){
    const numExistingRecordsForClass = this.eventData.registrations.filter(s => {
      return s.attendanceSubmitted === true
    })
    return numExistingRecordsForClass.length
  },
  // Check all students have had attendance status selected
  checkStatusSelectedForAllStudents(){   
    const allRadiosSelected = this.attendance.filter(a => {
      return a['attendanceStatusSelected'] === undefined
    }) 
    if(allRadiosSelected.length !== 0){
      return false
    } else { return true }
  },

  // New outcome select handler
  selectOutcome(){
    this.selectedOption = this.outcomeChoice
    console.log('Selected Outcome = :', this.selectedOption)
  },

  // Handler function for closing outcome popup 
  async newSaveAtt(e){
    if(e === null){ 
      // alert(`New save att : ${JSON.stringify(e)}`);  
      this.presentTotal = 0 
      location.reload() // Reset form

    } else {
      console.log('Outcome selection back -> ', e)
      this.showOutcomePopUp = false
      this.outcome = e
      this.selectedTotal = 0
      // save attendance to FM here
      console.log('popup shud be false -> ', this.showOutcomePopUp)
      await this.saveAttendance() // Follow existing attendance and outcome submit 
      this.submitOutcome() 
    }
  },

  hasPresentStudent(attRec){
    return attRec.present === true
  },

  // Wrapper to filter attendance array with different key/value params
  async filterAttendanceArray(key,val){
    console.log('the key ', JSON.stringify(key))
    console.log('this attendance ', JSON.stringify(this.attendance))
    const attendanceCompletedArr = await this.attendance.filter(a => {
      // Need to check if attendance has already been submitted for the student and return true
      // to fake those radios being checked
      return a[key] === val                
    })
    console.log('the attendancCompletedArr ', JSON.stringify(attendanceCompletedArr))
    return attendanceCompletedArr
  },

  async checkAllRadiosHaveValues(){
    // alert('Checking All radios have values..')
    // Pass custom key/value params to mapOverAttendanceArray
    // const statusSelectedForAllStudents = await this.mapOverAttendanceArray('attendanceStatusSelected',true)
    const attendanceStatusResults = await this.filterAttendanceArray('attendanceStatusSelected',true)
    const totalCheckedRadiosOnForm = attendanceStatusResults.length + this.getNumOfExistingRecords() 
    console.log('Total checked radios -> ', totalCheckedRadiosOnForm)
    // console.log('Len master arr -> ', this.attendance.length)
    // Checking that all radios have values before enabling submit and outcome selection 
    // numRadiosChecked = attendanceStatusResults.length + numRecordsAlreadySubmitted

    if(totalCheckedRadiosOnForm === this.attendance.length){
      this.attendanceComplete = true

      if(!this.outcomeExists) {
        this.selectDisabled = false
        this.selectLabel = 'Please select an outcome for this class' 

        const minimumOneStudentPresent = await this.checkOnePresent()
        // Check for one student present 
        console.log('check present ->', minimumOneStudentPresent.length)
        // Set the available outcome selection - if one student is present give only 'Done' option
        if(minimumOneStudentPresent.length === 0){ // if one present exists on form class must be done
          // alert(` the alert : ${JSON.stringify(minimumOneStudentPresent)}`)
          this.outcomeOptions = [
              'Postponed by Student', 'Postponed by ACA / Statutory holiday', 'Postponed by Teacher', 'Last Minute Cancelation by Teacher', 'Last Minute Cancellation','No Show']
            console.log('OC -> ', this.outcomeOptions)
        } else { 

            // At least one student present 
            this.outcomeOptions = ['Done']
        }
        // alert('check now after one present called...')
      } else {
        // Set the title in the select here
        this.selectDisabled = false
        this.selectLabel = 'Class outcome already submitted' 
        this.outcomeOptions = ['Class outcome already submitted']
      }
    }
  },

  async checkOnePresent(){
    const numPresentChecked = this.attendance.filter(a => { 
      return a['status'] === 'Present'
    })
    return numPresentChecked  
  },

  // TODO
  checkRadioValues() {
    // return this.radioValues.every(num => arr.includes(0));
    // return this.radioValues.every(num => num === this.radioValues[0]);
    console.log('Radio values in check ', this.radioValues)
    // alert('check the console...')
    if(this.radioValues.includes('2') && !this.radioValues.includes('1')) {
      return 'NS'
    } else {
      return this.radioValues.includes(1);
    }
  },
  // Form Reset
  clearAttendance() {
    // alert('clear attendance ....')
    this.attendance.forEach(a => {
      a.status = '',
      a.lateLeft = false
    })
    this.selectedValues = []
    this.attendanceSubmitDisabled = true
    console.log('Form reset complete[clearAttendance] -> ', this.attendance)
  },

  handleAllAbsentNotice() {
    Swal.fire({
        title: 'Attention',
        text: 'You have selected Absent (Early Notice) for ALL students. To postpone or reschedule please choose menu tab 2.',
        icon: 'warning',
        confirmButtonText: 'Ok',
      }).then(() => { // anonymous function needed for 'this' to work inside the Swal callback
        // Reset the attendance form  
        this.selectedValues = Array(this.eventData.registrations.length).fill('');
        console.log('Sweet alert success closed...')
        this.attendanceSubmitDisabled = true
      })
  },
  // handleSelectSameOption(value, num) {
  async quickSelect(value, type) {

    // Handle disable for button if form reset
    // if(value === null) { 
    //   alert('reset...')
    //   await this.clearAttendance()
      
    //   // Vue.set(this, 'attendanceSubmitDisabled', true);
    //   console.log('btn disabled ', this.attendanceSubmitDisabled)
    //   this.selectedValues = Array(this.eventData.registrations.length).fill('');
    //   // return // return out to ensure the button is not re-enable below
    // } 
    // else {

    // this is absent early notice - if manually selected must trigger swal too
    if(value === 'ABSENT_NOTICE') { 
      // alert('postpone...')
      this.handleAllAbsentNotice()
    } else {
      // Set the initial value for all rows
      this.selectedValues = Array(this.eventData.registrations.length).fill(value);
      console.log('ARR ', this.selectedValues)

      // Iterate the attendance array and set the attendance for all students
      // this.attendance.forEach(s => s['status'] = value)
      // console.log('Attendance is complete ', this.attendance)
      // this.attendanceSubmitDisabled = false

      this.selectedValues.forEach((s, index) => 
        this.updateAttendanceRadio(index, value, null)
      )
      console.log('Attendance is complete ', this.attendance)

      this.attendanceSubmitDisabled = false
      // alert('check that the else is triggered for all radios ..btn yes')

    }

  },

// CLASS ATTENDANCE DATA FOR NEW ENDPOINT
//   {
//   "classId": "string",
//   "classesNumber": "string",
//   "teacherId": "string",
//   "clientName": "string",
//   "type": "string",
//   "groupName": "string",
//   "scheduleDate": "string",
//   "startTime": "string",
//   "endTime": "string",
//   "duration": "string",
//   "overrideNote": "string",
//   "overrideDuration": "string",
//   "outcome": "string",
//   "classNote": "string",
//   "attendance": [
//     {
//       "studentReg": "string",
//       "firstName": "string",
//       "lastName": "string",
//       "status": "string",
//       "lateLeft": true
//     }
//   ]
// }
  async isFormComplete() {   
    // Test all students have radio selected 
    // this.formCompleted = await this.eventData.registrations.length === this.attendance.length
    // Check that each student has attendance status - ie. teacher has selected an option for all students
    this.formCompleted = await this.attendance.every(obj => obj.status !== '');

    // console.log('form complete check ', this.formComplete)
    // alert('stop')
    // return formComplete
  },

  async updateAttendanceRadio(idx,val,num) {
    // alert('radio clicked...[updateAttendanceRadio]')

    // 1. Console.log the event and check the same as the data above
    console.log('Student from Reg: ', this.eventData.registrations[idx].pkRegs)
    // const regId =  this.eventData.registrations[idx].pkRegs

    // Check if attendance already exists in the array - ie. user already selected a radio and then changed it
    // Needed to stop multiple objects for the same student being created in attendance array
    // const attendanceExistsForUser = this.attendance.find(item => item.studentReg === regId);

    // if(attendanceExistsForUser) {
    //   alert('User in array...do update only')
    //   console.log('U ', attendanceExistsForUser)
    //   attendanceExistsForUser.status = val
    //   attendanceExistsForUser.lateLeft = false
    //   // this.lateLeftValues[idx] = false
    // } 
    // else {
    // const selectedStudent = this.eventData.registrations[idx]
    // console.log('reg student: ', selectedStudent)

    // PRESENT
    // ABSENT_NOTICE
    // ABSENT_LMC
    // ABSENT_NS

    // Attendance array update for the FM A /attendances API Endpoint
    // this.attendance[idx] = {
    //   studentReg: selectedStudent.pkRegs,
    //   lateLeft: false,
    //   status: val,
    //   remarks: ""
    // }
    this.attendance[idx].status = val
    // this.attendance.push({
    //   studentReg: selectedStudent.pkRegs,
    //   lateLeft: false,
    //   status: val,
    //   remarks: ""
    // })

    // console.log('Attendance Array: ', this.attendance)
    // alert('fixed?')

    // API FM A for attendance array to attach
    //   "attendance": [
    //     {
    //       "studentReg": "R00070777",
    //       "status": "Present",
    //       "lateLeft": true,
    //       "remarks": "fire at home"
    //     }
    //   ]
  // }
    // Logic to endable submit button 
    // Client-side validation - will require server-side to ensure data is complete and avoid FM errors
    // 2. Is attendance complete
    await this.isFormComplete()

    console.log('FORM COMPLETE? ', this.attendance.length, this.formCompleted)

    if(this.formCompleted) {
      // alert('complete')
      if(this.attendance.every(obj => obj.status === 'ABSENT_NOTICE')) {
        console.log('aa ', this.attendance)
        // alert('all notice .. call [handleAllAbsentNotice]')
        this.handleAllAbsentNotice()
      }
      this.attendanceSubmitDisabled = false
    }

    // alert('Check Attendance Status..')

    // Enable submit button if all radios selected
    if(this.radioValues.length === this.eventData.registrations.length) {
      this.attendanceSubmitDisabled = false
    }

    // this.allSelected = '' 
    this.selectedValues[idx] = val // set the attendance status of the student referenced by index
    // console.log('ARR in updated ', this.selectedValues)

    this.radioValues[idx] = num

    console.log('radioValues array state: ', this.radioValues)
    // alert('look array state..')
    // this.attendanceRecs[index]['class_ATTENDANCES::status']
    
    // NOT NEEDED WITH NEW BUNDLED ATTENDANCE - ADD GLOBAL SUBMIT PROGRESS INSTEAD?
    // this.attendance[idx]['progress'] = 'pending'

    // console.log('RADIO -> ', JSON.stringify(this.attendance))
    console.log('RADIO clicked -> track the value is correct ', JSON.stringify(this.eventData.attendance[idx]))
    // alert('Stop...')

    // Change values passed to 1, 2, 3 
    // 0 = absent, 1 = present, 2 = absent (unjustified)
    // radioValuesArr[null, null, null]
    // Use idx passed by radio click to change the value of each array slot 
    // eg. student idx 0, value 1 = [1, null, null]
    // On each radio selection check the array values and select outcome accordingly

    // OUTCOME SELECTION 
    // At least one value is 1 = one present => outcome = DONE
    // Does not matter if other are mix of 0 and 2

    // All 0 = all absent but teacher knew -> outcome = Postponed or Cancelled 
    // - prompt teacher to use 2nd tab
    const result = this.checkRadioValues()

    console.log('THIS -> ', this.eventData.noStudents)
    // alert('result back...')

    // After all attendance has been selected check if at least ONE is present
    // If not display Postpone / Cancellation options 
    // Use the same logic to decide if the submit is enabled 
    if(this.radioValues.length === this.eventData.noStudents) {
      if(!result) {
        console.log('resp form radios check ', this.checkRadioValues)
        // alert('all students are absent / teacher knew / postpone or cancel class')
      }
      if(result) {
        // alert('one present...submit normal report')
      }
      else {
        // alert('At least one student = unjustified AND all others absent = no show')
      }
    }

    // One slot = 2 and others are 0 = at least one student unjustified = 
    // = No Show => can submit attendance form
    // FM can verify which student(s) was were unjustified and which the teacher knew about 
    
    if(num === 2) { 
      this.attendance[idx]['status'] = 'Absent (Unjustified)'
      console.log('Absent (unjustified) selected for: ', JSON.stringify(this.attendance[idx]));
      this.attendance[idx]['attendanceStatusSelected'] = true
      // console.log('Attendance status CHECK -> ', this.attendance[idx])

      // Check if form is complete 
      this.checkAllRadiosHaveValues()
    }

    if(num === 0) {     
      // Absent was selected - remove present key from student attendance 
      // delete this.attendance[idx]['present']
      this.attendance[idx]['status'] = 'Absent'
      console.log('Absent selected for: ', JSON.stringify(this.attendance[idx]));
      this.attendance[idx]['attendanceStatusSelected'] = true
      // console.log('Attendance status CHECK -> ', this.attendance[idx])

      // Check if form is complete
      this.checkAllRadiosHaveValues()

      // if(this.presentTotal > 0) {
      //   this.presentTotal--
      //   alert(`TOTAL:  -> ${JSON.stringify(this.presentTotal)}`)
      // }

      // if(this.selectedTotal === this.attendance.length) {
      //   // check attendance for one present 
      //   const presentCheck = await this.attendance.some(this.hasPresentStudent)
      //   console.log('PCHECK -> ', presentCheck)

      //   // if(this.presentTotal > 0) {
      //   if(presentCheck) {
      //     this.studentsPresent = true
      //   } else {
      //     this.studentsPresent = false
      //   }
      // // alert(`All attendance filled :  -> ${JSON.stringify(this.selectedTotal)}`)  
      //   this.showOutcomePopUp = true
      // }
    } else {
      // delete this.attendance[idx]['absent']
      this.attendance[idx]['status'] = 'Present'
      this.attendance[idx]['attendanceStatusSelected'] = true
      console.log(JSON.stringify(this.attendance[idx]))

      // If at least one present selected class must be done -> leave only 'done' in v-select 
      this.outcomeOptions = ['Done']
      console.log('update outcome list ', this.outcomeOptions)
      this.checkAllRadiosHaveValues()

      // if(this.presentTotal === 0){ 
      //   this.presentTotal++ 
      //   alert(`TOTAL:  -> ${JSON.stringify(this.presentTotal)}`)
      // }

      // if(this.selectedTotal === this.attendance.length || this.selectedTotal > this.attendance.length) {
      //   alert(`All attendance filled :  -> ${JSON.stringify(this.selectedTotal)}`)
      //     this.studentsPresent = true
      //     this.showOutcomePopUp = true
      // }
    }
    // }
    // If trying to submit a form with all absent prompt for an outcome
  },

  async closeModal1(e){
    // alert(`Event type check ${e}`)
    if(this.selectedOption !== 'Attendance Form' && e === 'confirm') {
      await this.saveAttendance() 
      this.submitOutcome() 
    } 
    if(this.selectedOption === 'Attendance Form') { // Attendance form was not selected 
      this.submitOutcome() 
      this.modalTitle = ''
      this.showModal = false
      // Get updated event from classes module
      const updatedClassEvent = classesModule.getUpdatedEvent
      // alert(`attendance : Post attendance updated event from classes module -> ${JSON.stringify(updatedClassEvent)}`)
      sessionStorage.setItem('submittedClass', JSON.stringify(updatedClassEvent))

    }  
    else {
      this.modalTitle = ''
      this.showModal = false
    }
  }, 

  checkAttendanceRecordStatus(i) { // Used to disable checkbox / change status ico / show/hide elements

    // console.log('Attendance.checkAttendanceRecordStatus : ', this.attendance[0].attendanceRecordExists)
    return this.attendance[i].attendanceRecordExists
  },
  
  // FIX PRIVATE FORM NOT SHOWING & CONNECT DIALOG CONFIRM & TEST THOROUGHLY WITH FM LAYOUT
  // cancelModal() {
  //   this.showModal = false
  // },

  otherStatusSelected() { // classOutcomeSelected
      // this.outcome = this.selectedOption
      console.log('Attendance :', this.selectedOption)
      // Need switch case for each of the options to further customize the dialog
      if(this.selectedOption !== 'Attendance Form') {
        this.modalTitle = this.selectedOption
        this.modalText = `Choosing this option will mark automatically mark and submit all participants absent for this class. Are you sure you want to proceed?`
        this.modalType = 'confirm'
        this.showModal = true
      } 
      // console.log('Attendance OUTCOME :', this.outcome)
      // TODO
      // For each student call the mark absent ??
  },

    // updateAttendance(index){ 
    //   if(index === 0) {
    //     this.attendance[index]['present'] = true
    //     console.log('A PRIVATE FORM...', this.attendance[0]) 
    //   } else {
    //     console.log('INDEX of group student attendance record : ', this.attendance[index])
    //   }
    // },
    
  updateRemarks(i, text) {
    this.attendance[i].remarks = text
    console.log(this.attendance)
  }, 

  async checkAttendanceRecordsInFM() { 
    // iterate the students, check for exisiting attendance records to avoid duplicates in FM
    // Disable re-submitting 

    await Promise.all(this.attendance.map(async (s,i) => {
      // await sleep(10 - i);
      // const token = getFMToken()
      const student_id = s.sID
      const class_id = s.classID
      const class_date = s.classDate

      const data = JSON.stringify({
        "query":[ {"ClassDate":class_date,"class_id": class_id,"_fk_student_id": student_id} ]
        });

      const config = {
        method: 'post',
        url: '/api/checkAttendanceRecordExists',
        headers: { 
          // 'Authorization': token, 
          'Content-Type': 'application/json'
        },
        data : data
      };

      // call check funciton 
      console.log('Check Attendance record promise ', s);
      this.callAttendanceFM(config,i)
    }));

    // this.attendance.forEach((s, i) => { 
    //   // this.skippedCount++
    //   // alert(`here ${this.studentCount} + ${this.skippedCount}`)

    //   // if(this.skippedCount === this.studentCount) {
    //   //   alert('Complete...modify?')
    //   // }
    //   const stdnt = JSON.stringify(s)
    //   alert(` Student : ', ${stdnt} `)
    //   // const classID = JSON.stringify(s)
    //   alert(` STATS : ', ${student_id} + ${class_id}`)

    //   // Pass student id to query
    //   // const data = JSON.stringify({"query":[{"ClassDate":"12/01/2020","class_id":"CLA000249280","_fk_student_id":"S00019599"}]});
    // })
  },

  callAttendanceFM(config,i) {

      axios(config)
        // ref- https://stackoverflow.com/questions/44715661/cannot-access-this-in-axios-post-method-in-vuejs-2
      .then((response) => { // do not use "(function (response)){..} or 'this' is undefined below
        console.log('checkAttendanceRecordsInFM response ', response.data.messages[0].message)
      //   // if student record -> add isSubmitted flag to local attendance object 
      //   // to disable checkbox and ignore when submitting remaining attendance

      //   // code: "401"
      //   // message: "No records match the request"
        const message = response.data.messages[0].message
        if(message !== "No records match the request") { // RECORD FOUND 
          this.attendance[i].attendanceRecordExists = true
          console.log('RECORD ALREADY EXISTS ', this.attendance[i])
          // this.skippedCount += 1
          if(this.skippedCount === this.numRecordsToSubmit) { // FIX
            // console.log(`skippy ${this.skippedCount}`)
            return this.skippedCount
          }  
        } else {
          this.skippedCount += 1
            if(this.skippedCount === this.numRecordsToSubmit) {
            // console.log(`skippy ${this.skippedCount}`)
            return this.skippedCount
          } 
        }
      })
      .catch(function (error) {
        console.log('ERR in Attendance.checkAttendanceRecordsInFM : ', error);
      });
  },

  formatDateForDisplay() { // for display ie. 6th January 2021
    // return {{ new Date() | dateFormat('YYYY.MM.DD') }}
    // console.log('CLASS DATE : ', date)
    const classDateStart = this.eventData.startTime
    const classDateEnd = this.eventData.endTime

    const classDate = classDateStart.split(' ')[0]; // Remove time from string
    const stime = classDateStart.split(' ')[1]; // Remove date from string
    const etime = classDateEnd.split(' ')[1]

    const displayDate = moment(this.eventData.date).format("dddd, Do MMM, gggg") // eg. Monday, 30th Nov 2020
    
    console.log('CLASS DATE : ', displayDate)
    console.log('TIMES : ', stime + ' ' + etime)

    this.displayDate = displayDate
    this.startTime = stime
    this.endTime = etime
    // return  displayDate
  },

  async isAttendanceComplete(){
    // alert('Called...')
    function studentAttendanceNotSubmitted(student){
      console.log('STUDENT ATT CHECK :', JSON.stringify(student))
      const theStudent = JSON.stringify(student)
      // alert('CHECKING ATTENDANCE...')
      return theStudent.attendanceSubmitted === false 
    }

    const attendanceIncomplete = await this.eventData.registrations.some(studentAttendanceNotSubmitted)
    // alert('incomplete .... ', attendanceIncomplete)
    if(attendanceIncomplete){ this.attendanceIncomplete === true } 
    // return attendanceIncomplete    
  },

  async createStudentAttendance() {
    // const classDate = await this.formatDate(this.eventData.start)
    // Why is this undefined??.....need to get class from storage into attendance form
    console.log('[createStudentAttendance] -> ',this.eventData)

    // const Jparse = JSON.parse(this.eventData)
    if(this.eventData['registrations'].length > 1) {
      console.log('Number of Students: ', this.eventData['registrations'].length)
      this.studentCount = this.eventData['registrations'].length
    } else {
      this.studentCount = 1
    }

    let attendanceRecord

    const today = moment().format('MM/DD/YYYY');
    const classDate = moment(this.eventData.start).format('MM/DD/YYYY');

    // Push group student details (fname, sname, id, regId for this session, attendanceStatus, notes)
    // if(this.eventData.grpScheduleID) { // Group 

    if(this.studentCount > 0) { // At least one student  
    
      this.eventData.registrations.forEach((s,i) => {
        console.log('CLASS ID : ', this.eventData.classID)

        // const student = JSON.stringify(s)
        // alert(`RECORD :  ${student}`)

          // Get registration record ID's for students for this session

          // IS THIS NEEDED FOR NEW FM ??
          // In FM different reg_id for different sessions exist for same student - required to create attendance record
          // const RID = this.eventData.registrations.filter(r => {
          //   return s["Student classmates startup::_kp_student_id"] === r["Registration::_kf_student_id"]
          // }).filter(r => { return r["Registration::_kp_registration_id"] })


          const RID = this.eventData.registrations.filter(r => {
            // Matching the student id to reg id and returning
            return s["class_REGISTRATIONS::_fk_Student"] === r["class_REGISTRATIONS::_fk_Student"]
          }).filter(r => { return r["class_REGISTRATIONS::__pk_Regs"] })

          // Debugging
          // alert(`Student reg ID:  ${JSON.stringify(RID[0]["class_REGISTRATIONS::__pk_Regs"])}`)

        //     "recordId": "152",
        // "class_REGISTRATIONS::_fk_Student": 122,
        // "class_reg_STUDENTS::firstName": "Lynn",
        // "class_reg_STUDENTS::lastName": "Francoeur",
        // "class_REGISTRATIONS::__pk_Registrations": 152,
        // "modId": "0"
          
          // attendanceRecord = {
          //   fname: s["class_reg_STUDENTS::firstName"],
          //   sname: s["class_reg_STUDENTS::lastName"],
          //   sID: s["class_REGISTRATIONS::_fk_Student"],
          //   sessionRegId: s["class_REGISTRATIONS::__pk_Registrations"],
          //   classDate: classDate,
          //   classID: this.eventData.classID,
          //   grpScheduleID: this.eventData.grpScheduleID,
          //   teacher_id: 1,
          //   // attendanceSubmitted: s["attendanceSubmitted"]
          // }

          attendanceRecord = {
            "_fk_Class": this.eventData["__pk_Classes"],
            "_fk_Teacher": this.eventData["_fk_Teacher"],
            "status": "Present",
            "remark": "VUE to API attendance tests",
            "_fk_Registration": s["class_REGISTRATIONS::__pk_Regs"]
          }

          //             {
          //     "fieldData": {
          //       "_fk_Class": "481",
          //       "_fk_Teacher": "1",
          //       "status": "present",
          //       "remark": "new record class complete",
          //       "_fk_Registration": "155"
          //     }
          // }

          console.log("Student object with regID :  ", attendanceRecord)
          this.attendance.push(attendanceRecord)
      })
    } else { // Private
    // alert(`CLASS DATE ${JSON.stringify(this.eventData['students'][0]['fname'])}`)
      console.log('NO STUDENTS IN CLASS.. CHECK DEVELOPER API FOR DATA')

      // attendanceRecord = {
      //   fname: this.eventData['students'][0]['fname'],
      //   sname: this.eventData['students'][0]['lname'],
      //   sID: this.eventData['students'][0].sID,
      //   sessionRegId: this.eventData.privateRegistrationID,
      //   classDate: classDate,
      //   classID: this.eventData.classID,
      //   grpScheduleID: '', // NO GROUP ID FOR PRIVATE - field must be blank in FM for conditional logic
      //   isPrivate: this.eventData.isPrivate,
      //   teacher_id: this.eventData.teacher_id,
      // }
      // console.log('Attendance Private with teacher_id :: ', attendanceRecord)
      // this.attendance.push(attendanceRecord)
    }
  }, 

  async checkOneStudentPresent(){
    const result = this.attendance.filter(a => {
      return a['present'] === true
    })
    if(result.length > 0){
      return true
    } else { return false }
  },

  fireSwalSuccess() {
    Swal.fire({
          title: 'Success',
          text: 'Attendance was submitted successfully. Thank You!',
          icon: 'success',
          confirmButtonText: 'Close',
          willClose: this.goToDash       // Removed () from here
        }).then(function () {
          console.log('Sweet alert success closed...')
        });  
  },

  displayHello(){
    // CHECK WHAT IS GOING WRONG
    console.log('DH.. ', this.submitProgress)
    if(this.submitProgress < 100) {

      this.submitProgress = this.submitProgress + 25
      console.log('Processing after.. ', this.submitProgress)

      // Update progress circle here .....

      if(this.submitProgress == 100) {
        clearInterval(this.intrvl)
        console.log('stopped...')
        const tout = setTimeout(this.fireSwalSuccess, 1000)    
      } else {
        console.log('continue...')
      }
    }
  },

  save() {
    this.processing = !this.processing
    this.intrvl = setInterval(this.displayHello, 1000);
    // console.log('SP.. ', this.submitProgress)

    // function displayHello(){
    //   console.log('Processing.. ', this.submitProgress)
    //   this.submitProgress + 25
    //   console.log('Processing after.. ', this.submitProgress)

    //   if(this.submitProgress === 100) {
    //     clearInterval(intrvl)
    //   } else {
    //     console.log('continue...')
    //   }
    // }
  },

  allRadioValuesAreTheSame() {
    const firstValue = this.selectedValues[0]

    for (let i = 1; i < this.selectedValues.length; i++) {
      if (this.selectedValues[i] !== firstValue) {
        return false;
      } else {
        return firstValue
      }
    }
  },

  // ALPHA SUBMIT - May 2024
  async submitAttendance() {
    this.processing = true
    this.attendanceSubmitDisabled = true

    console.log('submitting attendance...please wait ', this.attendance)
    // If pkAttendance exists -> go to update endpoint with pkAttendance record ID in payload
    // else -> go to regular attendance endpoint and create a new record

    // NB. must add an empty string for pkAttendances to each student report - will fail on FM if not there
    this.attendance.forEach(a => {
        // Catch update conditions 
        if(a.status !== a.prevStatus) {
          // Check if record exists
          if(a.pkAttendances) {
            console.log('Record exists -> ', a.pkAttendances)
            // Need update route here with the PK of the specific attendance
          } else {
            console.log('No record exists ->', a)
            a['pkAttendances'] = ''
          }
        }
      })
    console.log(this.attendance)
    const eData = this.eventData
    
    const classId = this.eventData.pkClasses
    const token = sessionStorage.getItem('token')

    const payload = {
      "classId": classId,
      "teacherId": token,
      "language": 'EN',
      "overrideNote": this.overrideNote || '',
      "overrideDuration": this.overrideDuration || '',
      "attendance": this.attendance
    }

    console.log('new pay2 ', payload)
    const url = process.env.VUE_APP_API_URL + '/attendance4'
    console.log('URL ', url)
    // axios.post('/api/attendance4', payload, {
    axios.post(url, payload, {

        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(async response => {
      console.log('FM A Attendance submit response: ', response)
      // alert('check attendance response...')

      if(response.data.response === 'Success') {
        this.processing = false
        this.isAttendanceSubmitDisabled = false
        Swal.fire({
          title: 'Success',
          text: 'Attendance was submitted successfully. Thank You!',
          icon: 'success',
          confirmButtonText: 'Go to my classes',
          willClose: this.goToDash     
        }).then(function () {
          console.log('Sweet alert success closed...')
        });   
      } else {
        this.processing = false
        this.isAttendanceSubmitDisabled = false
        console.log('Attendance submit error :: ', response)
        // alert('error submitting attendance. See console.')
        Swal.fire({
          title: 'Access denied!',
          text: response.data.response + '. Please contact ACA for assistance.',
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: 'OK',
          willClose: this.goToDash       
        }).then(function () {
          console.log('Sweet alert failure closed...')
        });   
      }
    })



//     {
//   "classId": "CLA000482037",
//   "overrideNote": "",
//   "overrideDuration": "",
//   "classNote": "",
//   "attendance": [
//     {
//       "studentReg": "R00070777",
//       "status": "Present",
//       "lateLeft": true,
//       "remarks": "fire at home"
//     }
//   ]
// }
    const fullFMClassData = sessionStorage.getItem('selectedClassFull')
      const fullClassJSON = JSON.parse(fullFMClassData)

    const rv = await this.selectedValues
    if(rv.includes('present')) {
      console.log('class is done ',rv)
    } else {
      const allValuesTheSame = this.allRadioValuesAreTheSame()
      console.log('Class not done ... ', allValuesTheSame)

      if(allValuesTheSame === 'lmc') {
        console.log('Class was Complete LMC')
      }
    }


    // Build new data here 
    // classId, scheduleDate, teacherId, clientId, groupNum, classDuration, altDuration, classNotes 
    // attendance array - { studentId, regId, firstName, lastName, attendanceStatus, lateLeftEarly }

    let attendanceReport = {}
    console.log('Attendance obj2 ', this.attendance)
    // attendanceReport['clientName'] = fullClassJSON[0]['fieldData']['class_reg_students_CLIENTS::clientName']
    // attendanceReport['type'] = await fullClassJSON[0]['fieldData']['type']
    // attendanceReport['classId'] = await this.eventData.classID
    // attendanceReport['classesNumber'] = await fullClassJSON[0]['fieldData']['classesNumber']
    // attendanceReport['teacherId'] = await fullClassJSON[0]['fieldData']['_fk_Teacher']
    // attendanceReport['startTime'] = await fullClassJSON[0]['fieldData']['startTime']
    // attendanceReport['endTime'] = await fullClassJSON[0]['fieldData']['endTime']
    // attendanceReport['scheduleDate'] = await this.eventData.date
    // attendanceReport['duration'] = await fullClassJSON[0]['fieldData']['duration']
    // attendanceReport['overrideDuration'] = '1.25'
    // attendanceReport['overrideNote'] = 'Michael had emergency'
    // attendanceReport['attendance'] = await this.attendance
    // attendanceReport['outcome'] = 'Done'

    // New attendance bundle
    console.log('Attendance array ', this.attendance)

    console.log('AttendanceReport ', attendanceReport)


// Bruno API request body expects
//     {
//   "classId": "string",
//   "classesNumber": "string",
//   "teacherId": "string",
//   "clientName": "string",
//   "type": "string",
//   "groupName": "string",
//   "scheduleDate": "string",
//   "startTime": "string",
//   "endTime": "string",
//   "duration": "string",
//   "overrideNote": "string",
//   "overrideDuration": "string",
//   "outcome": "string",
//   "classNote": "string",
//   "attendance": [
//     {
//       "studentReg": "string",
//       "firstName": "string",
//       "lastName": "string",
//       "status": "string",
//       "lateLeft": true
//     }
//   ]
// }

    // {
    //   "classId": "13498",
    //   "classesNumber": "CLAS049874",
    //   "teacherId": "1"
    //   "clientName": "FakeBlock Inc",
    //   "type": "Corporate Group",
    //   "groupName": "Group 2",
    //   "scheduleDate": "11/02/2024",
    //   "startTime": "14:00",
    //   "endTime": "15:30",
    //   "duration": "1.5"
    //   "overrideDuration": "1.25"  // equal to 1 hour and 15 minutes,
    //   "overrideNote": "emergency"
    //   "outcome": 'done'
    //   "attendance": [
    //     { "studentReg": "1645", "firstName": "Michael", "lastName": "Bluth", "status": "present", "lateLeft": true }
    //     ...
    //   ]
    // }
  },

  // IMP: FIX THE POSSIBILITY FOR TEACHER TO SUBMIT THE SAME FORM TWICE!!!
  async saveAttendance(){
    // this.processing = !this.processing
    this.sendClicked = !this.sendClicked

    // Catch incomplete attendance - combine with outcome check above and use same modal alert
    // Function will return false if this.attendance has 1 or more students without attendance status
    // const r = await this.checkStatusSelectedForAllStudents()
    const attendanceCheckResult = await this.checkStatusSelectedForAllStudents()

    // Catch incomplete attendance
    if(attendanceCheckResult === false && !this.attendanceComplete) {
      console.log('ATTENDANCE NOW -> ', attendanceCheckResult)
      let swalText

      if(this.eventData.noStudents > 1) {
        swalText = 'Please select an attendance status for all students to continue.'
      }
      else { 
        swalText = 'Please select an attendance status for the student.'
      }

      Swal.fire({
        title: 'Attendance is missing',
        text: swalText,
        icon: 'warning',
        confirmButtonText: 'OK'
      })
      // alert('attendance not complete...')
      return
    } 

    // Catch empty custom class duration
    if(this.selectedDuration === 'custom') {
      // alert('Duration catch trigger...')

      // Check if neither hours or minutes have been entered
      if(this.selectedHours === null || this.selectedMinutes === null) {
        // alert('no custom duration..')
        // Swal.fire({
        //   title: 'Duration Information Required',
        //   text: 'Please complete the duration section for this class before submitting attendance. Note that both hours and minutes must be selected and a note completed before you can submit.',
        //   icon: 'Information',
        //   confirmButtonText: 'Close'
        // })
          
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Class Duration section must be completed before submitting attendance.',
          confirmButtonText: 'Close'
        })
        return
      } else {
      }
    } 

    // Catch empty outcome or incomplete attendance
    if(this.outcomeChoice === '' && !this.outcomeExists) {
        //alert('To proceed, an outcome must be selected and attendance status completed for each student...')
        Swal.fire({
        title: 'Class outcome is missing',
        text: 'Please select an outcome to continue.',
        icon: 'warning',
        confirmButtonText: 'OK'
      }) 
      return
    }

    let responseCount = 0
    let failedCount = 0

    this.attendance.forEach((s,index) => {
      console.log('Student attendnace pending -> ', s)
      // alert('check...')
      // console.log('Reg -> ', this.eventData.registrations)
    
      // Mark students absent if not attendance form
      // if(this.selectedOption !== 'Done'){ 
      //   s.present = false
      // }

      // Confirmed working as of 6:20 on monday 7 nov - perfomred several tests  - maybe hacky?
      const theStudentReg = this.eventData.registrations.find(r => r['class_REGISTRATIONS::__pk_Regs'] === s['_fk_Registration'])
      
      // UNDEFINED
      console.log('StudentRegId ->', theStudentReg )

      // V IMPORTANT - DO NOT CREATE DUPLICATE FM RECORDS - SKIP SUBMIT 
      if(theStudentReg.attendanceSubmitted === true) {
        // if(s.attendanceSubmitted) {
        console.log('Attendance has already submitted for this student -> ', s)
        responseCount++
        return 
      } else {
        console.log('The res count is = ', responseCount)
        s.sIndex = index
        s.classId = this.eventData.classID
        s._fk_Registration = theStudentReg['class_REGISTRATIONS::__pk_Regs']
        s.durationOverride = 1
        console.log('Student attendance record to be sent to FM : ', s )

        const data = JSON.stringify({"attendance": s})

      // const data = JSON.parse({"attendance": this.attendance})

      // if(!s.attendanceRecordExists) { // false = skip any previosly submitted attendance records

      // PICK UP FROM HERE 
      // - BUG FAST REDIRECT TO CALENDAR AND EVENT CARD NOT UPDATED WITH HIDDEN ATTENDANCE BUTTON
      // - TEST WITH MORE THAN ONE RECORD MISSING IN A CLASS
      // - ADD UX FOR FEEDBACK ABOUT UPLOAD SUCCESS, CONFRIM, AND REDIRECT TO DASH AFTER
      // - SWEET ALERT WITH W. CHECK MARK ANIMATION
      // - REMAIN ON UPDATED ATTENDANCE FORM AFTER CONFIRM WITH SUBMIT REMOVED,GREEN CHECKS AND INPUTS DISABLED 
      // if(!s.attendanceSubmitted) { // false = skip any previosly submitted attendance records
        console.log('SUBMITTING ATTENDANCE FOR : ', JSON.stringify(s))
        // alert('check console....')

        // Config and request options
        const options = {
          method: 'post', 
          url: process.env.VUE_APP_API_URL  + '/attendance3',
          headers: { 
            'Authorization': this.fmtoken,
            'ContentType': 'application/json',
            'Access-Control-Allow-Headers':'Content-Type',
            data: data
          }
        }

        // Use in testing only - emulates an attendance record not being created in a group class
        // if(index === 2 ) {
        //   // skip this record
        //   alert('skipping this record..')
        //   return 
        // }  

        axios(options)
          .then(response => {

            // Update progress component 
            // this.submitProgress = this.submitProgress + 25 
            // make 100 / number of students to increment correct percentage for each record

            // alert('% done??')
            // Handle failed / errors here

            const pRes = JSON.parse(response['data']['body'])
            const recordId = pRes['response'].recordId

            if(recordId !== undefined) {
              console.log('FM api_ATTENDANCE response 200 : recordId -> ', recordId)
              this.recordCreated = true
            } 

          
            // For some crazy reason FM response comes back with the 'body' embedded as a string inside 
            // a data' object ...why???
            // console.log('FM attendance response -> type of body : ', typeof(response.data['body'])) // string
            // Need to parse body and then strinigfy to be able to read the values
            var resBody = JSON.parse(response.data['body']);
            // This is the result for FM success
            // {"response":{"recordId":"220","modId":"0"},"messages":[{"code":"0","message":"OK"}]}
            
            // alert(resBody.response.recordId); // debugging

            // v2 Refactor - check for recordID in response and handle error accordingly
            // Response from FM if success
            // {statusCode: 200, body: '{"response":{"recordId":"101","modId":"0"},"messages":[{"code":"0","message":"OK"}]}', headers: {…}, request: {…}}
            
            
            const res = JSON.parse(response.data['body'])
            const code = res.messages[0]['code']
            // eg. "code":"1708","message":"Parameter value is invalid"
            console.log('Attendnace submit response code : ', code) // Use to find and handle common error codes
            // TODO: add failed to the response count or faildCount 
            // and calculate if all or only some failed?? 
            if(code !== '0') {
              // some err codes - if one record need to delete and start again?
              // https://support.claris.com/s/article/FileMaker-Server-Event-Log-Messages-1503692957592?language=en_US
              // console.log('SUBMIT FAILED: HANDLE THIS ERROR :')

              if(code === '952'){ // invalid token - logout
                failedCount++
                // alert('[attendance.vue] 952 - invalid FM token')
                // Dialog to notify teacher needs to log in and check the attendance report
                // alert('FM Session Token Invalid..logging out')
                // this.modalText = 'Session Expired..logging out'
                // this.showModal = true   
                // this.outcome = 'done'
                // alert(`event now -> ${JSON.stringify(this.eventData)}`
                sessionStorage.removeItem('submittedClass')

                // Swal - something went wrong here 
                // Send error to Mongo and display in debugging tab in admin vue?
              
                // classesModule.updateEvent(this.eventData)
                // Replace with sweet alert
                // this.showModal = true;
                // this.logout()
              } else { // Token is still valid - continue with other submissions
                failedCount++
                // alert('Submit failed (NOT invalid token error). Check client/server console for error')
                console.log('FM Err message ', response.messages[0])  
              }  
              responseCount++
              // alert(`res count -> ${responseCount}`)
            }
            
            // Handle this response - VV IMPORTANT to avoid teacher thinking they has succesfully submitted 
            // OK {"messages":[{"code":"1708","message":"Parameter value is invalid"}],"response":{}}
            // FIX THIS TRIGGERED ON SUCCESSFULL ATTENDANCE SUBMIT
            // ADD PRIVATE ATTENDANCE
            // ADD CONFIRM DIALOGUE AND SUCCESS SNACKBAR
            // FINISH FILEMAKER TABLES - ADD CLASS OUTCOMES, connection to ATTENDANCE etc.

            // SWITCH CASE for code checking ??
            // FM RECORD CREATED SUCCESSFULLY
            if(response.status === 200 && code !== '952') { // 200 not always success : check with postman
              // TODO: add this code to 'if' {code: "952", message: "Invalid FileMaker Data API token (*)"}
              
              // Set student attendance status to submitted on form 
              // Need to double verify with a record ID number returned from FM 
              // ie. if(recordID !== null) means record was created -> then
              s.attendanceSubmitted = true
              responseCount++ 

              console.log('Submit success:', responseCount)
              console.log('===============================')
              console.log('DATA RESPONSE :', response['data']) // check for FM record no.
              // const redirectPath = this.$route.query.redirect || "/dashboard";
              console.log("rCOUNT : ", responseCount)
              // this.checkIfFinished(responseCount) 

              //AFTER SUCCESSFUL ATTENDANCE SUBMISSION MUST UPDATE THE LOCAL DATA
              // s = Attendance to pass to classes to update student attendanceSubmitted status?  
              // classDate: (...)
              // classID: "CLA000245999"
              // fname: (...)
              // grpScheduleID: (...)
              // isPrivate: true
              // present: true
              // remarks: "aldsal"
              // sID: "S00017053"
              // sIndex: 0
              // sessionRegId: "R00044835"
              // sname: "Stéphan"
              // teacher_id: "T00000475"

              // alert(`isPrivate =  ${JSON.stringify(this.eventData.isPrivate)}`)
              
              // if(this.eventData.isPrivate === false) {
              //   s['studentFromGroup'] = true
              //   classesModule.updateAttendanceStatusAndOutcomeForClass(s)
              // } else { 
              //   s['studentFromGroup'] = false 
              //   classesModule.updateAttendanceStatusAndOutcomeForClass(s)
              // }

              console.log('Student attendance data to submit : ', JSON.stringify(s))   
              // this.checkIfAllRecordsSubmittedForClass(index)

              // Records submit should be finished here...

              // NEED TO FIX NO REDIRECT TO DASH AFTER SUBMIT OF SINGLE CLASS IN A GROUP 
              // if(responseCount === this.attendance.length) {

              // if(this.submitProgress === 100) { 
                // 100% complete - all attendance should have been attempted here

                // Check failed count here 
                console.log(this.failedCount , ' records failed. Alert the user.')
                // alert('check for failed count...')

                // alert('all done??') WORKING
                if(recordId) {
                  console.log('Finished -> ', recordId)
                }

              // if(index === this.attendance.length - 1) {

                // Attendance should be complete - disable buttons to avoid resubmit
                // And remove from Dash Attendance Due
                this.eventData.isAttendanceComplete = true

                if(this.outcomeExists === false) {
                  this.eventData.outcome = this.selectedOption
                  this.submitOutcome()
                }

                // if(this.selectedOption !== 'Attendance Form') {
                //   this.eventData.outcome = this.selectedOption
                  
                // } else {
                //   this.eventData.outcome = 'done'
                // }
                // alert(`${index} Records processed:  MODAL next ..fired in if(response.status 200) meaning record has been saved successfully in FM. So if last one then show modal dialog to teacher.`)     
                // TODO: dynamically set the message in the modal 
              
                // this.modalText = 'Attendance successfully submitted. Thank you. Close to return to your Dashboard.'
                // this.outcome = 'done'          
                // classesModule.updateEvent(this.eventData)
                // this.modalType = 'info'
                // this.showModal = true;  

              Swal.fire({
                title: 'Success',
                text: 'Attendance was submitted successfully. Thank You!',
                icon: 'success',
                confirmButtonText: 'Close',
                willClose: this.goToDash       // Removed () from here
                }).then(function () {
                  console.log('Sweet alert success closed...')
                });   
              // } 
      
              // At least one student was present or absent but class took place (ie. not PS, PT, NS, LMC) 
              // if(this.selectedOption === 'Attendance Form') {
              //   this.submitOutcome('done')
              // }
            }         
          })
          .catch((error) => {
            // This was triggered when trying to send the missing record after a forced fail
            // on the server ...why not triggered the first time?

            // Increment here??

            Swal.fire({
              title: 'Network Error',
              text: 'There may be an issue with the server or your internet connection. Please try again later.',
              icon: 'warning',
              confirmButtonText: 'Close',
              onClose: this.goToDash       // Removed () from here
              }).then(function () {
                // alert('check console for error...')
                console.log('Sweet alert success closed...')
              });   
                
            })

            // console.log('[attendance.ts] ERR SUBMITING ATTENDANCE : ', error)

        // } else {
        //     console.log('Skipped - Record already exists in FM wp2Classes : ', this.attendance[index])
        //     responseCount++ // fake increment
        //     this.checkIfFinished(responseCount)        
        // }
        // })
      }
    })  
  },

    goToDash() {
      window.location.replace(process.env.VUE_APP_URL + '/classes');

      // const dashRedirect = process.env.VUE_APP_URL + '/dashboard'
      // console.log('REDIRECT -> ', dashRedirect)
      // window.location.replace(dashRedirect);
      // this.$router.push({ name: "dashboard" });

      // handleNavigtion(item: TODO) {
      //   this.menuItem = item.title;
      //   this.$router.push({
      //     name: item.link
      //   });
      // }
    },
    // checkIfAllRecordsSubmittedForClass(index){ Check if all records submitted},

    // Not used??
    closeModal() {
      // Handle attendance for all absent
      if(this.selectedOption !== 'Attendance Form') {
        this.submitOutcome()
        // this.$router.push({ name: "calendar" })

      } else { // should only be to confirm attendance is submitted and move on to outcome record
        this.showModal = false
        this.modalTitle = ''
        this.submitOutcome()
      }
  
    },

    submitOutcome() {
      // alert('submitOutcome')
      console.log('Submitting outcome as this.selectedOption...')  
      // Need to convert fully spelled outcomes to abbreviations for outcome records in FM 

      // DO THIS NEXT - format the nobody present abrreviations before sending to the API endpoint??
      // if(this.selectedOption === 'Last Minute Cancellation'  

      const outcome = {
          class_id:  this.eventData.classID,
          outcome: this.selectedOption,
          teacherNotes: this.note
          // class_date: moment(this.eventData.start).format('MM/DD/YYYY') - V2 API should not need
        } 

      // Set class id and class date here to reduce code

      // alert(`In submit outcome... ${this.fmtoken}`)
      // if(this.selectedOption === 'Attendance Form') {
      //   // this.outcome = 'done'
      //   outcome = {
      //     class_id:  this.eventData.classID,
      //     class_date: moment(this.eventData.start).format('MM/DD/YYYY'),
      //     outcome: 'done'
      //   }
      // } else {
      //   outcome = {
      //     class_id:  this.eventData.classID,
      //     class_date: moment(this.eventData.start).format('MM/DD/YYYY'),
      //     outcome: this.selectedOption
      //   }
      // }
      // TODO: add other values such as submitDate
      
      const data = JSON.stringify(outcome)
      console.log('DATA OUTCOME pre post : ', data)
        // const data = JSON.parse({"attendance": this.attendance})

      // Config and request options
      const options = {
        method: 'post', 
        url: process.env.VUE_APP_API_URL  + '/class-outcome', 
        headers: { 
          // 'Authorization': this.fmtoken,
          'ContentType': 'application/json',
          'Access-Control-Allow-Headers':'Content-Type',
          data: data
        }
      }

      axios(options)
      .then(response => {
        console.log('OUTCOME POST COMPLETE....', response)
        // alert('Check console for outcome success...close returns to calendar')
        this.save()
        // this.$router.push("/dashboard")
      })
    },

    checkIfFinished(responseCount) {
      // Notify teacher that all or some were submitted successfully
      console.log('Check if Submit Finished  ', responseCount)

      if(responseCount === this.attendance.length -1){
            // if(responseCount === this.numRecordsToSubmit) {
              console.log("All Attendance records created. Check FM wp2Attendance : " , responseCount)

              // TODO : proper alert here
              // alert("Attendance Completed. Thank You. Close this alert to return to your schedule.")
              this.dialog = true

              // Add function call to COMFIRM DIALOGUE to separate process of creating Outcome update
              // TODO: MUST SEND OUTCOME TO wp2Classes or Group Layout 

              // Dialog ONE 
              // eg. on CONFIRM - if at least one record submitted successfully
              // this.updateOutcomeInFM('done')

              // DIALOG TWO
              // No records successful
              // msg - there was an error - please try again later

              // DIALOG THREE
              // Error submitting some - please try again later
              
              this.$router.push("/calendar");
            } 
            // return

    },

    async updateOutcomeInFM() {
      console.log('Attendance.updateOutcomeInFM ')
      // Make UPDATE request to the original class record (eg. wp2Classes)
      console.log("Outcome updated in FM : Check FM wp2Classes ")

      return await true
    },

    async logout(){
      this.removeClassFromStorage()
      await userModule.logout();
      router.push('/login') // Change for http replace if causing duplicated event issues
    },

    async cancel() {
      this.removeClassFromStorage() 
      this.$router.push({ name: "calendar" });
    },

    async setEventInSessionStorage(ev) {
      const event = await sessionStorage.setItem('selectedClass',JSON.stringify(this.$route.query.event))
      this.eventData = event
    },

    async getEventInSessionStorage(ev) {
      const event = await JSON.parse(sessionStorage.getItem('selectedClass'))
      console.log('PARSED?:D :', event)
      this.eventData = event
    },
    removeClassFromStorage() {
      this.eventData = {}
      console.log('EVENT GONE  :', this.eventData)
      sessionStorage.removeItem('selectedClass');
    }
  },

  watch: {  
    
    time1: function(newValue, oldValue) {
      this.handleDatePickersChange();
    },
    time2: function(newValue, oldValue) {
      this.handleDatePickersChange();
    },
      // selectToggle: function(){
      //   this.selectDisabled === false
      // },
      selectedOption: function(){
        // switch(this.selected) {
        //   case x:
        //     // code block
        //     break;
        //   case y:
        //     // code block
        //     break;
        //   default:
        //     // code block
        // } 
        if(this.selectedOption === undefined) { 
          console.log('Option undefined ...close form and do nothing ..')
          this.showForm = false 
        }
        if(this.selectedOption === 'Attendance Form') {
          console.log('STATUS SELECTED : DONE = ', this.selectedOption)
          // this.radiosDisabled = false
          this.modalType = 'info'
          this.showForm = true
          // console.log('Shud b null = ', this.radiosDisabled)

        } else if(this.selectedOption  !== null && this.selectedOption !== 'Attendance Form'){
          console.log('STATUS SELECTED : DONE = ', this.selected)
          // Must check all radio buttons absent here
          // this.radiosDisabled = true
          this.showForm = false
          // console.log('Shud b value = ', this.radiosDisabled)
        }
      },  
  }  
}
