import { render, staticRenderFns } from "./2FACheck.vue?vue&type=template&id=1440765a&scoped=true&"
import script from "./2FACheck.vue?vue&type=script&lang=js&"
export * from "./2FACheck.vue?vue&type=script&lang=js&"
import style0 from "./2FACheck.vue?vue&type=style&index=0&id=1440765a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1440765a",
  null
  
)

export default component.exports