<template>
  <div style="margin-top:60px !important;">
    <div class="page-title-wrapper">
      <div class="page-header">
      <h2>
        <!-- My Schedule -->
        {{ $t('calendar.title')}}
      </h2>
      <span style="flex-direction: row;">
        <!-- {{ formatDate(currentDate) }} -->
        <!-- 09, September 2023 -->
         <!-- {{ events }} -->
      </span>
      </div>
    </div>
    <v-row class="fill-height">
      <v-col style="margin-top:0px;">
        <v-sheet height="64">
          <v-toolbar flat>
            <!-- Set today function does not work -->
            <!-- <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
              Today
            </v-btn> -->
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>Day</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>Week</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Month</v-list-item-title>
                </v-list-item>
                <!-- <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 days</v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            :first-interval="firstInterval"
            ref="calendar"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
            @change="updateRange"
          >
          <template v-slot:event="{ event }">
            <div :class="{ 'strike-through': event.outcome === 'POI' || event.outcome === 'POS' , 'truncate': true }">
              {{ event.name }}
              <br>  
            </div>
            <div v-if="event.outcome !== 'POI'">
              {{ formatEventTime(event.start) }} - {{ formatEventTime(event.end) }}
            </div>
          </template>
    
        </v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="500px" flat> 
              <v-toolbar :color="selectedEvent.color" dark>    
                <!-- <v-toolbar-title class="event-card-title" v-if="selectedEvent.noStudents > 1"> -->
                  <v-toolbar-title v-if="selectedEvent.status === 'POI'" style="color: #8d8989;">
                    {{ selectedEvent.name }} 
                  </v-toolbar-title>
                  <v-toolbar-title v-else class="event-card-title">
                  {{ selectedEvent.name }}
                </v-toolbar-title>
                <!-- <v-toolbar-title class="event-card-title" v-else v-html="selectedEvent.groupNo"></v-toolbar-title> -->
                <v-spacer></v-spacer>
                <!-- <v-btn icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>-->
                <!-- <v-btn icon>
                  <v-icon>mdi-heart</v-icon>
                </v-btn> -->
                <!-- <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn> -->
              </v-toolbar>

              <v-card-text v-if="loadingStudents"
                class="d-flex flex-column justify-center align-center" 
                style="min-height: 300px;"
              >
                <template>
                  <p class="text-center mb-4">Fetching class...please wait</p>
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    :size="50"
                  ></v-progress-circular>
                </template>
              </v-card-text>

              <v-card-text v-else 
                style="background-color: white;padding: 16px 40px 32px;"
              >
                <!-- <v-alert info v-if="selectedEvent.noteToTeacher !== ''">
                  {{ selectedEvent.noteToTeacher }}
                </v-alert> -->

                <!-- <pre>{{ this.events.indexOf(selectedEvent) }}</pre> -->
                <!-- <v-row v-if="selectedEvent.noteToTeacher !== ''" class="pt-2 event-alert" no-gutters>
                  <v-alert type="info">
                    I'm an info alert.
                  </v-alert>
                </v-row> -->

              <!--  DEBUGGING -->
                <!-- <pre>
                  {{ selectedEvent }}
                </pre> -->

                <v-row class="pt-4" no-gutters>
                  <v-col cols="1"><v-icon>mdi-calendar</v-icon></v-col>
                  <v-col cols="11" class="pl-1">
                    {{ formatDate2(selectedEvent.date) }}
                    </v-col>
                </v-row>

                <v-row class="pt-4" no-gutters>   
                  <v-col cols="1"><v-icon>mdi-clock</v-icon></v-col>
                  <v-col cols="11" class="pl-1">
                    {{ formatTime(selectedEvent.start) }} - {{ formatTime(selectedEvent.end) }}
                  </v-col>
                </v-row>

                <v-row v-show="selectedEvent.platform[0].platform !== ''" class="pt-4" no-gutters>   
                  <v-col cols="1"><v-icon>mdi-video</v-icon></v-col>
                  <v-col cols="11" class="pl-1">
                    {{ selectedEvent.platform[0].platform }}
                  </v-col>
                </v-row>

                <v-row v-show="selectedEvent.platform[0].platform !== ''" class="pt-4" no-gutters>   
                  <v-col cols="1"><v-icon>mdi-at</v-icon></v-col>
                  <v-col cols="11" class="pl-1">
                    {{ selectedEvent.platform[0].account }}
                  </v-col>
                </v-row>

                <v-row class="pt-4" no-gutters>
                  <v-col cols="1">
                    <v-icon>mdi-account</v-icon>
                  </v-col>
                  <v-col cols="11" class="pl-1">
                    {{ selectedEvent.type }}
                  </v-col>
                </v-row>

                <v-row class="pt-4" no-gutters>
                  <v-col cols="1">
                    <v-icon>mdi-earth</v-icon>
                  </v-col>
                  <v-col cols="11" class="pl-1">
                    {{ selectedEvent.classLanguage }}
                  </v-col>
                </v-row>

                <v-row class="pt-4" no-gutters>
                  <v-col cols="1" v-if="selectedEvent.noStudents > 1"><v-icon>mdi-account-group-check</v-icon></v-col>
                  <v-col cols="1" v-else><v-icon>mdi-account-check</v-icon></v-col>
                  <!-- <v-col cols="11" class="pl-0" v-if="selectedEvent.noStudents > 1"> -->
                  <v-col cols="11" class="pl-1">

                    <!-- <div v-if="selectedEvent.registrations.length > 0">
                    {{  selectedEvent.registrations.length }} Participants
                    </div> -->
                    <!-- Group {{ selectedEvent.groupNo }} - ({{ selectedEvent.students.length }} Students) -->
                    <!-- {{ typeof(selectedEvent.registrations) }} -->

                    <div 
                      v-if="selectedEvent.noStudents > 0" 
                      class="student-list"
                    >
                      <div v-if="loadingStudents">
                        <p style="color:black">
                          Fetching students...please wait
                        </p>
                      </div>
                    <!-- <p 
                        v-for="(student, index) in selectedEvent.registrations" 
                        :key="index"
                        class="student-item"
                      >

                        <v-skeleton-loader
                          v-if="loadingStudents"
                          type="card"
                        ></v-skeleton-loader>

                        <span v-else>
                          {{ student.firstName }} {{ student.lastName }}
                        </span>
                                
                      </p> -->          
                      <p v-else
                        v-for="(student, index) in selectedEvent.registrations" 
                        :key="index"
                        class="student-item"
                      >
                        <span>
                          {{ student.firstName }} {{ student.lastName }}
                        </span>
                      </p>
                    </div>
      
                    <!-- <span v-if="selectedEvent.noStudents === 0">
                      Class currently has no participants
                    </span> -->

                    <!-- </v-list> -->
                    <!-- <span v-else>No studelnt data available...please contact your course coordinator</span> -->
                  </v-col>

                  <!-- <v-col cols="11" class="pl-0" v-if="selectedEvent.isPrivate === true">
                    1 Student
                    <v-list>
                      <v-list-item>
                          <v-list-item-content>
                            <v-list-item-subtitle> 
                              {{ selectedEvent.students[0].fname }} {{selectedEvent.students[0].lname }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                      </v-list-item> 
                    </v-list>
                  </v-col> -->
                </v-row>


                <v-row class="pt-4" no-gutters>
                  <v-col cols="1"><v-icon>mdi-pencil</v-icon></v-col>
                  <!-- <v-col cols="1" v-else><v-icon>mdi-pencil</v-icon></v-col> -->
                  <!-- <v-col cols="11" class="pl-0" v-if="selectedEvent.noStudents > 1"> -->
                  <v-col cols="11" class="pl-1">
                    <span v-if="selectedEvent.noteToTeacher !== ''">
                      {{ selectedEvent.noteToTeacher }}
                    </span>
                    <span v-else><i>No notes</i></span>
                    <span>  {{ selectedEvent.noteToTeacher }}</span>
                  </v-col>
                </v-row>

                <v-row class="pt-4" no-gutters>
                  <v-col cols="1"><v-icon>mdi-information</v-icon></v-col>
                  <!-- <v-col cols="1" v-else><v-icon>mdi-pencil</v-icon></v-col> -->
                  <!-- <v-col cols="11" class="pl-0" v-if="selectedEvent.noStudents > 1"> -->
                  <v-col cols="11" class="pl-1">
                    <span v-if="selectedEvent.outcome === 'EMPTY'"> 
                      Attendance has not been submitted
                    </span>
                    <span v-else class="outcome-no-class">
                      {{ selectedEvent.status }}
                    </span>
                  </v-col>
                </v-row>

                <v-row class="pt-4" no-gutters
                  v-if="selectedEvent.unscheduledHours === 0 || selectedEvent.unscheduledHours === ''"
                >
                  <v-col cols="1"><v-icon>mdi-calendar-end</v-icon></v-col>
                  <!-- <v-col cols="1" v-else><v-icon>mdi-pencil</v-icon></v-col> -->
                  <!-- <v-col cols="11" class="pl-0" v-if="selectedEvent.noStudents > 1"> -->
                  <v-col cols="11" class="pl-1">
                    <span>
                      <strong>
                        Session End:
                      </strong>
                    </span>
                    <span class="end-session-date">
                      
                      {{ selectedEvent.endSessionDate }}
                    </span>
                  </v-col>
                </v-row>
                <!-- <v-row class="pt-2" no-gutters>  
                    <v-col cols="1" v-if="!selectedEvent.isAttendanceComplete">
                      <v-icon color="red">
                        mdi-alert-circle-outline
                      </v-icon> 
                    </v-col>
                    <v-col cols="11" v-if="!selectedEvent.isAttendanceComplete">Attendance incomplete</v-col>         
                    <v-col cols="1" v-if="selectedEvent.isAttendanceComplete"><v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon></v-col>
                    <v-col cols="11" v-if="selectedEvent.isAttendanceComplete">Attendance complete</v-col>
                </v-row> -->
                <!-- <pre>
                  <span v-if="!selectedEvent.outcome || selectedEvent.outcome === ''" v-html="selectedEvent"></span>
                  <span v-html="selectedEvent"></span>
                </pre> -->
                <!-- <v-label v-if="selectedEvent.outcome !== null">Outcome: {{ selectedEvent.outcome }}</v-label> -->
              </v-card-text>

              <v-flex>
                <v-card-actions v-if="selectedEvent.outcome === '' || selectedEvent.outcome === null || !hasAttendanceSubKey">
                  <!-- <v-btn color="primary" class="ma-2" style="color: white;" @click="attendanceForm(selectedEvent)">
                    Go To Class
                  </v-btn> -->
                  <v-btn 
                    color="primary" 
                    class="ma-2" 
                    style="color: white;" 
                    @click="goToClass"
                    :disabled="isButtonClicked"
                  >
                    Veiw class
                  </v-btn>
                  <v-btn color="warning" style="color: white;" @click="handleCardClose()">
                    Close</v-btn>
                </v-card-actions> 

              </v-flex>
            </v-card> 
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getClass } from '@/utils/fm-api'; 
import { classesModule } from '@/store/modules/classes';
import moment from 'moment';
import Swal from 'sweetalert2'
import { indexOf } from 'lodash';
import { 
    formatDDMMYYYYToEnglishDate, 
    formatToFrenchDate, 
    formatToFrenchDateTime, 
    formatDDMMYYYYToFrenchDate } from '../utils/dateFormatter';

import { getLocalStorageValue } from '../utils/localStorageHelper.js';



export default {
  name: 'CalendarComponent',
  data: () => ({
    traxLanguageFromStorage: getLocalStorageValue('fmUserLanguage'),
    firstInterval: 0, // Default to midnight
    loadingStudents: true,
    isLoading: false,
    focus: '',
    type: 'week',
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
  }),
  mounted() {
    // this.setFirstInterval();
    // this.traxLanguage = localStorageHelper('fm')
  },
  methods: {
    goToClass() {
      this.isButtonClicked = true;
      this.buttonText = 'Processing...';
      this.attendanceForm(this.selectedEvent);
    },
    handleCardClose() {
      this.selectedOpen = false;
      this.loadingStudents = true;
      // You can add more logic here if needed
      // console.log('Selected open:', this.selectedOpen);
    },
    getEarliestClassTime() {
      console.log('classes times ', this.events[0])
    },

    // Not working as expected on calendar
    setFirstInterval() {
      // const earliestClassOfDay = this.getEarliestClassTime()
      const now = new Date();
      now.setMinutes(now.getMinutes() - 180); // Subtract 30 minutes

      const adjustedHour = now.getHours();
      const adjustedMinutes = now.getMinutes();

      // Determine the starting interval based on the adjusted time
      this.firstInterval = adjustedHour;

      // If you want to account for half-hour intervals, you might need additional logic
      // depending on how your calendar handles intervals
    },

    formatEventTime(dateTime) {
      return moment(dateTime).format('h:mmA');
    },

    onEventClick(event) {
      // This method is triggered when an event card is clicked
      console.log('Event clicked:', event);
      // Add your logic to incrementally add more data here
    },

    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },

    getEventColor (event) {
      return event.color
    },

    setToday () {
      this.today = new Date();
      if (this.$refs.calendar) {
        this.$refs.calendar.move(this.today);
      }
    },

    prev () {
      this.$refs.calendar.prev()
    },

    next () {
      this.$refs.calendar.next()
    },

    showEvent({ nativeEvent, event }) {
      this.loadingStudents = true
      
      const openCard = () => {
        this.selectedEvent = { ...event }  // Create a shallow copy of the event
        this.selectedElement = nativeEvent.target
        this.selectedOpen = true

        // Use $nextTick to ensure the DOM has updated
        this.$nextTick(async () => {
          try {
            // Load additional data asynchronously
            const singleClass = await getClass(event)
            console.log('GET latest class detail from FM ', singleClass.data[0])
            
            let cardDateFormat 

            if(this.traxLanguageFromStorage === 'fr') {
              cardDateFormat = formatDDMMYYYYToFrenchDate(singleClass.data[0].classEnd)
              console.log('lang in calendar ', this.traxLanguageFromStorage)
            } else {
              cardDateFormat = formatDDMMYYYYToEnglishDate(singleClass.data[0].classEnd)
            }

            // Update the event with additional data
            this.selectedEvent = {
              ...this.selectedEvent,
              participants: singleClass.registrations,
              registrations: singleClass.data[0].registrations,
              classLanguage: singleClass.data[0].classLanguage,
              platform: singleClass.data[0].virtualClass,
              physicalClass: singleClass.data[0].physicalClass,
              endSessionDate: cardDateFormat,
              unscheduledHours: singleClass.data[0].unscheduledHours

            }

            this.loadingStudents = false

            // Store in sessionStorage if needed
            const stringClass = JSON.stringify(singleClass)
            sessionStorage.setItem('fullSchedule', stringClass)

          } catch (error) {
            console.error('Error loading additional data:', error)
          }
        })
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(openCard, 10)
      } else {
        openCard()
      }
      nativeEvent.stopPropagation()
    },

    updateEventInLocalStorage(eventId, newEventData) {
      // Step 1: Retrieve the existing data
      let events = sessionStorage.getItem('events2');

      // Step 2: Parse the data
      events = events ? JSON.parse(events) : [];

      // Step 3: Update the specific event
      const eventIndex = events.findIndex(event => event.classID === eventId);
      if (eventIndex !== -1) {
        events[eventIndex] = { ...events[eventIndex], ...newEventData };
        console.log('new event ', events[eventIndex])
      } else {
        console.warn(`Event with ID ${eventId} not found.`);
        return;
      }

      // Save the updated data
      sessionStorage.setItem('events2', JSON.stringify(events));

      // Verify the update
      const updatedEvents = JSON.parse(sessionStorage.getItem('events2'));
      const updatedEvent = updatedEvents.find(event => event.id === eventId);

      if (!updatedEvent) {
        console.error(`Failed to retrieve updated event with ID ${eventId}`);
        return false;
      }

      // Check if all new data properties are correctly updated
      for (const [key, value] of Object.entries(newEventData)) {
        if (updatedEvent[key] !== value) {
          console.error(`Update verification failed for property: ${key}`);
          return false;
        }
      }

      console.log(`Event with ID ${eventId} successfully updated and verified`);
      return true;
    },

    async attendanceForm(e) {
      console.log('from cal ', e)
      classesModule.setIndexForCurrentClass(e)
      // console.log('THE events -> ', JSON.stringify(this.events))
      sessionStorage.setItem('eventIndex', JSON.stringify(this.events.indexOf(e)))
      console.log('ID in cal -> ', e)
      await sessionStorage.setItem('Selected', JSON.stringify(e))

      // const fullData = await classesModule.getFullClassData(e['classID']) 
      e.pkClasses = e.classID 
      // const fullData = await getClass(e) 
      // console.log('FCal -> ', fullData)
      // sessionStorage.setItem('selectedClassFull', fullData)

      this.$router.push({ name: 'attendance' }) 
      // this.$router.push({ name: 'attendance', query: { event: e }})
    },

    formatEvents(rawEvents) {
      return rawEvents.map(event => {
        const scheduleDate = new Date(event.scheduleDate).toISOString().slice(0, 10);
        const start = `${scheduleDate} ${event.startTime}`;
        const end = `${scheduleDate} ${event.endTime}`;
        const noStudents = event.registrations.length;
        const noteToTeacher = event.teacherNotes;

        // Set colors function
        let color = noteToTeacher ? '#DC143C' : this.colors[this.rnd(0, this.colors.length - 1)];
        let classColor

        if(event.outcome === 'POI' || event.outcome === 'POS') {
          color = '#e9e9e9'
        } else {
          classColor = this.getEventColor(event)
        }

        const fullClassName = event.groupName + ' - ' + event.clientName

        return {
          // color: typeColors[event.type] || 'green', // Default color if type 
          color: classColor,
          name: fullClassName || 'No data',
          classID: event.pkClasses || 'Class has no ID',
          groupNo: event.clientName || 'No data',
          type: event.type,
          date: start || 'No date has been set for this class',
          start: start || 'No time data',
          end: end || 'No time data',
          noStudents: noStudents || 1,
          registrations: event.registrations || 'No registration data',
          noteToTeacher: noteToTeacher,
          details: [{ note: noteToTeacher }, { msg: 'hello TRAX' }],
          overrideDuration: null,
          outcome: event.outcome,
          status: event.status
        };
      });
    },

    getEventColor(e) {
      // if the class is not POSTPONED 
      console.log('in colors')
      if(e.outcome === 'POS' || e.outcome === 'POI' ) {
        return '#b6b6b6'
      }
      // Class type colors
      const typeColors = {
          'Corporate Private online': '#238398',  // Teal
          'Privé corporatif en ligne': '#238398',
          'Phone follow-up': '#e85200',    // Purple
          "Suivi téléphonique":'#e85200',
          'Corporate Semi-Private online': '#2196F3',  // Blue
          'Semi-privé corporatif en ligne': "#2196F3",
          'Corporate Group online': '#2196F3', // Orange
          'Corporate Group onsite': '#219222  '
         
          // Add more types and colors as needed
        };
        // Define a default color
        const defaultColor = '#03a0a7';  // Gray, but you can choose any color
        return typeColors[e.type] || defaultColor 
    },

    formatDate2(d) {
      const formatted = moment(d).format("ddd, Do MMM, gggg")
      return formatted
    },

    formatTime(t) {
      return moment(t).format('HH:mm')
    },

    async updateRange ({ start, end }) {
      this.isLoading = true;
      // const startStr = start.toISOString().split('T')[0];
      // const endStr = end.toISOString().split('T')[0];
      // const storageKey = `events_${startStr}_${endStr}`;

      try {
        // Check session storage first
        const storedEvents = sessionStorage.getItem('events2');
        if (storedEvents) {
          console.log('Fetching events from session storage');
          // this.events = JSON.parse(storedEvents);
          // const events = JSON.parse(storedEvents);
          const parsedEvents = JSON.parse(storedEvents);
          console.log('parsed: ', parsedEvents['data'])

          if (Array.isArray(parsedEvents['data'])) {
            const data = parsedEvents['data']
            this.events = this.formatEvents(data);

            console.log('format ', this.events)
          } else {
            throw new Error('Stored events are not in the expected format');
          }

        } else {
          console.log('Fetching events from API');
          // If not in session storage, fetch from API
          const response = await axios.get('/api/events', {
            params: { start: startStr, end: endStr }
          });
          this.events = response.data;

          // Store in session storage for future use
          sessionStorage.setItem(storageKey, JSON.stringify(this.events));
        }
        console.log('Events loaded:', this.events.length);
      } catch (error) {
        console.error('Error fetching events:', error);
        // Handle the error appropriately
      } finally {
        this.isLoading = false;
      }
    },

    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
  },
}
</script>

<style scoped>

 /* Postponed styles */
.strike-through {
  text-decoration: line-through;
  color: #302e2e;
  /* color: #989696; text color from current trax */
  /* background-color: rgb(233, 233, 233); bg color from current trax - hard-coded in the div wrapping? */
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.outcome-no-class {
  background: rgba(255, 124, 0, 0.11);
  padding: 6px 11px;
  border-left: 3px solid rgb(250, 175, 21);
}

/* .end-session-date {
  background: rgba(59, 247, 184, 0.11);
  padding: 6px 11px;
  border-left: 3px solid rgb(67, 67, 67);
} */

.student-list {
  /* background-color: #eaeceb;
  border: 1px solid lightgrey;
  padding: 8px; */
  margin-top: 0px;
}

.student-item {
  margin: 0;
  padding: 0px 0;
  font-size: 14px;
}

.page-header {
  border-radius: 5px;
  display: flex;
  color: #f6fdfe;
  font-size: 16px;
  margin-right: 0px;
  /* background-color: #013b85; */
  /* background: linear-gradient(43deg, #055ccbc4, #3966ad); */
  background: linear-gradient(43deg, #055ccbc4, #2187d5);
  padding: 10px;
  padding-left: 19px;
  padding-right: 15px;
  align-items: center;
}
</style>