// src/utils/localStorageHelper.js

export function getLocalStorageValue(key, defaultValue = null) {
  try {
    const storedValue = localStorage.getItem(key);
    if (storedValue !== null) {
      console.log(storedValue)
      return storedValue;
    }
  } catch (error) {
    console.error(`Error retrieving ${key} from localStorage:`, error);
  }
  return defaultValue;
}

export function getSessionStorageValue(key, defaultValue = null) {
  try {
    const storedValue = sessionStorage.getItem(key);
    if (storedValue !== null) {
      return storedValue;
    }
  } catch (error) {
    console.error(`Error retrieving ${key} from localStorage:`, error);
  }
  return defaultValue;
}