import { render, staticRenderFns } from "./ConfirmDialog.vue?vue&type=template&id=518bb432&scoped=true&"
import script from "./ConfirmDialog.vue?vue&type=script&lang=ts&"
export * from "./ConfirmDialog.vue?vue&type=script&lang=ts&"
import style0 from "./ConfirmDialog.vue?vue&type=style&index=0&id=518bb432&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518bb432",
  null
  
)

export default component.exports