<template>
  <div>
    <v-btn
      color="rgb(253 191 52)"
      fixed
      bottom
      right
      @click="dialog = true"
    >
      <v-icon style="color:rgb(84 52 10);margin-top: 4px;">mdi-comment-text-outline</v-icon>
      <span style="color: rgb(103 68 21);">
        Feedback Form
      </span>
    </v-btn>

    <v-dialog v-model="dialog" max-width="800px" class="feedback-form">
      <v-card>
        <v-card-title class="dialog-title">
          <span class="headline">Trax Feedback Form</span>
        </v-card-title>
        <v-card-content>
          <div class="asana-embed-container">
            <iframe 
              class="asana-embed-iframe" 
              height="533" 
              width="100%" 
              style="
                  padding-bottom: 10%;
                  border: none;
              "
              src="https://form.asana.com/?k=i3jX6PuYxOearfbwjhdfPg&d=1198869403060525&embed=true"
            ></iframe>
            <!-- <div class="asana-embed-footer">
              <a rel="nofollow noopener" target="_blank" class="asana-embed-footer-link" href="https://asana.com/?utm_source=embedded_form">
                <span class="asana-embed-footer-text">Form powered by</span>
                <div class="asana-embed-footer-logo" role="img" aria-label="Logo of Asana"></div>
              </a>
            </div> -->
          </div>
        </v-card-content>
        <!-- <v-card-actions style="margin-top: -60px;">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false" class="flex-grow-1">Close Form</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'FeedbackFab',
  data() {
    return {
      dialog: false
    }
  }
}
</script>

<style scoped>
.v-dialog__content .v-dialog__content--active img {
  display: none !important
}

.WorkRequestsSubmissionFormHeader-coverImageContainer {
  display: none !important;
  margin-top: -125px !important;
}
.asana-embed-container {
  position: relative;
  padding-bottom: 56.25%; /* Adjust this value to change the aspect ratio */
  height: 0;
  overflow: hidden;
  margin-top: 30px;
}

.asana-embed-container iframe {
  padding-top: 0% !important;
  border: none;
  margin-top: -80px !important; 
}

.asana-embed-container img {
    /* padding-bottom: 10%; */
    display: none !important;
    background: none;
}

html h1 {
  margin-top: 30px !important;
}


.dialog-title {
  background: #215082;
    color: aliceblue;
}
.feedback-form>img {
  display: none !important;
}
iframe>img {
  display: none !important;
}
</style>