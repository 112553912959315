
<template>
<v-container style="margin-top:-20px">
  <v-flex xs12 lg11 mx-auto>
    ABOUT
  </v-flex>
  </v-container>
</template>

<script>
import { userModule } from "@/store/modules/user";
import axios from 'axios'
import { getFMToken, getSeachFilters } from '@/utils/app-util';
import { checkFMToken } from '@/utils/fm-api'; // Custom API MODULE
import router from '@/router';

export default {
   data() {
    return {
     fmtoken: '',
     message: '',
    }
  },
  async mounted () {
    this.fmtoken =  await sessionStorage.getItem('fm-token')
    // Check token exists on session and call @/utils/fm-api function
    if(this.fmtoken){
      checkFMToken("checkFMToken", this.fmtoken)
      .then((r)=> {
        if(r === true) {
          console.log('Token Valid... ', r)
          this.message = 'Token is valid in about..'
        } else {
          console.log('Token Invalid...logging out and clearing storage ', r)
          this.logout()
        }
      }).catch(err => { 
        console.log('Some other error - check API server -> Token Valid :' , err) 
        this.logout()
      })
    } else { // No token in session
      this.logout()
    }
  },
  methods: {
    async logout(){
      await userModule.logout();
      router.push('/login')
    },
  }  
}
</script>
