<template>
  <v-card style="box-shadow: none !important;">
    <!-- {{ eventData }} -->
    <v-card-title style="border-bottom: 1px solid #e4e4e4;color:rgb(87 87 87);font-weight: 500;">
      Viewing week {{ week }}
      <br>
      <v-spacer></v-spacer>
      <span style="margin-left: 0px;
        background-color: ;
        padding: 0px;"> 
        {{ selectedWeek.start }} - {{ selectedWeek.end }} 
        <!-- Sunday, September 03, 2023 - Sunday, September 10, 2023 -->
      </span>
    </v-card-title>

    <v-simple-table class="s-table" fixed-header>
      <template v-slot:default>
        <thead>
          <tr>
            <th v-for="header in tableHeaders" :key="header.value"
              :style="{ width: header.width }">{{ header.text }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(event, index) in eventData" :key="index" :class="getRowClass(event)" style="height: 70px;">
            <td style="background-color: rgb(255, 255, 255);">
              <span class="weekNum" :class="getWkNumBgColor(week)">
                {{ week }}
              </span>
            </td>
            <td style="color: rgb(84 84 84);font-size: 15px;
              font-weight: 500;min-width: 150px !important;max-width: 150px !important;"> 
              {{ event['clientName'] }}
            <br>
            <span style="color: rgb(94 95 95);font-weight: 500;">
              <!-- {{ event.fieldData["SCHEDULES::groupName"].split(' ')[2] }} -->
              {{ event['groupName'] }}  
            </span>
        
            </td>
            <td style="color: #016fcf;font-size: 15px;
              font-weight: 600;max-width: 100px !important;">    
              <span style="color: rgb(94 95 95);
                font-weight: 500;">{{ event['type'] }}
              </span>  
            </td>
            <td>
              {{ event.scheduleDate }}
              <br>
              {{ format24hrTime(event.startTime) }} - {{ format24hrTime(event.endTime)  }} 
            </td>
            <!-- <td>{{ format24hrTime(event.startTime) }} - {{ format24hrTime(event.endTime)  }} </td> -->

            <td style="padding: 0px 32px !important">  
              <!-- {{ event.duration }} -->
              <span v-show="setClassDuration(event).type === 'default'">
                {{ setClassDuration(event).duration }}
              </span> 
              <span v-show="setClassDuration(event).type === 'override'" 
                style=" background-color: rgb(250, 175, 21);
                  border-color: rgb(250, 175, 21);
                  cursor: default;
                  pointer-events: none;
                  color: #674416;
                  border-radius: 16%;
                  padding: 3px;
                  margin-left: -4px;"
                :class="{ 'override-duration-highlight': setClassDuration.type === 'override' }">
                {{ setClassDuration(event).duration }}
              </span>
            </td>
            <!-- <td v-else style="padding: 0px 32px !important">0</td> -->

            <td class="outcome-td" :class="event.status" style="padding: 8px !important">
              <span v-if="event.status === ''">Pending</span>
              <span v-else :color="getColor(event.status)" :class="getStatusClass(event.status)">
                {{ event.status }}
                <!-- {{ event.attendancesCount }}
                {{ event.attendancesCount }} -->

                <br>
                <em v-if="event.status === 'Class done' && event.attendancesCount.length === 0">
                  <small style="font-size: 12px;">
                    <i><b>(attendance report required)</b></i>
                  </small>
                </em>
                <outcome-status :event="event"></outcome-status>
              </span>
            </td>

            <td style="text-align: end;">
              <v-btn v-if="event.status === 'Postponed by Student'" text small class="mr-4" style="background-color: #eb0505">
                <v-icon small color="white">mdi-lock</v-icon>
              </v-btn>
              <v-btn variant="outlined" dense small @click="editClicked(event)">
              <v-icon>
                mdi-chevron-right
              </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import moment from 'moment';
import { compareAndConvertDurations } from '../utils/classDurationHelper.js';


export default {
  props: {
    eventData: Array, // Define a prop to receive the event data array from the parent
    edit: Function,
    selectedWeek: Object,
    week: String
  },

  data() {
    return {
      traxLanguageFromStorage: null,
      totalHours: null,
      tableHeaders: [
        { text: 'Week', value: 'Week',width: '10px' },
        { text: 'Client', value: 'Client',  width: '180px' },
        { text: 'Type', value: 'Type', width: '180px'  },
        { text: 'Date/Time', value: 'Date', width: '100px' },
        // { text: 'Time', value: 'Time' },
        { text: 'Paid Hours', value: 'Length',  align: 'center', width: '150px'  },
        { text: 'Status', value: 'Outcome', width: '150px' },
        { text: '', value: 'View', width: '20px'  },
      ],
    };
  },

  created() {
    // console.log('Events in ST:  ', this.eventData)
    // this.calculateHoursForWeek()
    this.traxLanguageFromStorage = localStorage.getItem('fmCommLang')
  },
  
  // mounted(){
  //   this.selectedWeekEvents()
  // },

  computed: {
    // filteredEvents() {
    //   const startDate = /* specify your start date */;
    //   const endDate = /* specify your end date */;

    //   // Use the filter method to filter events based on the date range
    //   return this.selectedWeekEvents(this.week).filter(event => {
    //     const eventDate = new Date(event.scheduleDate);
    //     return eventDate >= startDate && eventDate <= endDate;
    //   });
    // },

    selectedWeekEvents(w) {
      console.log('wk: ', w)
      // alert('filter events for check week..')
      if(w === 1) {
        return this.eventData[0]
      }
      return this.eventData
    },
  },
  
  methods: {
    setClassDuration(event) {
      if(event.isPostponed === true ) {
        let durationMessage
        if(this.traxLanguageFromStorage === 'en') { 
          durationMessage = 'No Duration' 
        } else { durationMessage = 'Aucune Duration' }
        const classDuration = { "duration": durationMessage, "type": "default" }
        return classDuration
      }
      // Call class duration helper with the event data
      // this.selectedClassDuration = compareAndConvertDurations(event)
      return compareAndConvertDurations(event)
      console.log('Dur Helper back ', this.selectedClassDuration)
    },

    abbreviateStatus(s){
      if (s === 'Postponed by Student') {
        return 'Postponed Student'
      } 
      if(s === 'Done') {
        return 'Done'
      } 
      if (s === '') {
        return 'Pending'
      }
    },
  

    checkIfPayable(event) {
      if(event.status === 'Done' ||   event.status === 'LMC' ||    event.status === 'NS') {
        return   event.duration 
      } else {
        return 0
      }
    },

    format24hrTime(time) {
      // console.log('Time -> ', time) // 08:45:00
      const timeString = toString(time)

      const m = moment(time, 'HH:mm').format('HH:mm');
      // const formattedTime = m.format('HHmm');
      console.log('Mo -> ', m)
      return m
    },

    getWkNumBgColor(n) {
      console.log(n)
      if(n === '2') {
        return 'wk2Color'
      } else {
        return 'wk1Color'
      }
    },

    getStatusClass(status) {
      return 'pp-chip'
    },

    getColor(status) {
      // Define colors logic based on the status field
      console.log(status)
      if (status === 'Done') {
        return ''
      } else if (status === 'Postponed by Student' || status === 'NS' || status === 'PS') {
        return '#ffc95c'
      } else {
        return 'light-blue';
      }
    },
    // Function to determine the custom class for each row
    getRowClass(item) {
      if (item.attendances.length !== item.registrations.length) {
        return 'custom-class'; // Replace 'custom-class' with your desired class name
      }
      return ''; // No custom class if the condition is not met
    },

    editClicked(item) {
      // Call the editEvent function received as a prop
      console.log('class clicked in payroll -> ', item)
      const itemStr = JSON.stringify(item)
      sessionStorage.setItem('Selected', itemStr)
      // alert('pay..')
      this.$router.push({ name: 'attendance', params: { classObj: item }}) 
      // Emit a custom event to notify the parent component
      // this.$emit('edit', item);
    },
  }
  // ... rest of your component code ...
};
</script>

<style>

.pp-chip {
  color: #212121;
}


/* REF for alternating row styles 
https://stackoverflow.com/questions/54508774/how-do-i-make-a-striped-v-data-table-in-vuetify */
/* tbody tr:nth-of-type(even) {
  background-color:rgb(250 ,250, 250);
  } */

  /* tbody tr:nth-of-type(odd) {
    background-color:   aliceblue;
  } */

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: aliceblue;
}
.v-chip { padding: 0px; margin: 0px !important; }

.v-chip__content { 
    color: #080808;
    min-width: 35px;
    text-align: center;
    justify-content: center; 
  }

  /* REF for dynamic color assign by FM field data 
  https://stackoverflow.com/questions/52124081/how-to-add-a-class-conditionally-in-vue */
.outcome-td.PS span { color: #7a5407; }
.outcome-td.Pending span { background-color: transparent!important;color: #7b7b7b; }
.outcome-td.Done span { color: rgb(255 253 253); }
.outcome-td.Done .v-chip { background: #01b6b6 !important; }




.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
 background-color: #d5e4ff;
}

.weekNum { 
    padding: 0px;
    color: rgb(255 255 255);
    padding: 3px 8px;
    font-weight: 600;
}

.wk2Color { background-color: #f7b211; }
.wk1Color { background-color: #31638f }

.s-table {
  padding: 0px;
  box-shadow: none !important;
}

/* .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none !important;
} */

.custom-class {
  /* background-color: #ffe2db; */
}
</style>
